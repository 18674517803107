import { sum } from 'lodash';
import * as React from 'react';
import { dayAfter, formatDateStamp, workingDaysBetween } from '../../../../models/dateStamp';
import {
  NoDataTableDataCell,
  SubHeader,
  Table,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from '../../../../shared/table/Table';
import { assertNotNull } from '../../../../utils/assertNotNull';
import { SubTotalsRow } from '../../../worksheets/prices/WorksheetPricesTable';
import { useClientPortalMetadata } from '../../clientPortalMetadata';
import { ClientPortalUnsortedWorksheet } from './ClientPortalUnsortedWorksheet';

type Props = {
  worksheet: ClientPortalUnsortedWorksheet;
};

export const ClientPortalUnsortedCellCollectionsTable = ({ worksheet }: Props) => {
  const { cells, firstCollectionDate, lastCollectionDate, processingDateOption } = worksheet;
  const metadata = useClientPortalMetadata();

  const collectionDates = workingDaysBetween(
    firstCollectionDate,
    lastCollectionDate,
    metadata.bankHolidays,
  );

  return (
    <div>
      <Table>
        <THead>
          <Tr>
            <Th>Collection Date</Th>
            <Th>Processing Date</Th>
            <Th centreAligned={true} colSpan={cells.length || 1}>
              Quantity
            </Th>
          </Tr>
          {cells.length > 0 && (
            <Tr>
              <SubHeader />
              <SubHeader />
              {cells.map((cell, index) => (
                <SubHeader key={index}>Cell {index + 1}</SubHeader>
              ))}
            </Tr>
          )}
        </THead>
        <TBody>
          {cells.length === 0 ? (
            <Tr>
              <NoDataTableDataCell colSpan={3}>
                -- This worksheet has no cells --
              </NoDataTableDataCell>
            </Tr>
          ) : collectionDates.length === 0 ? (
            <Tr>
              <NoDataTableDataCell colSpan={3}>
                -- There are no working days between the chosen collection dates --
              </NoDataTableDataCell>
            </Tr>
          ) : (
            <>
              {collectionDates.map(collectionDate => (
                <Tr key={collectionDate}>
                  <Td>{formatDateStamp(collectionDate)}</Td>
                  <Td>
                    {processingDateOption === 'NextDay'
                      ? formatDateStamp(dayAfter(collectionDate))
                      : formatDateStamp(collectionDate)}
                  </Td>
                  {cells.map((cell, index) => {
                    const quantity = assertNotNull(cell.collections[collectionDate]).quantity;
                    return (
                      <Td key={index}>
                        {quantity != null ? quantity.toLocaleString() : 'Unknown'}
                      </Td>
                    );
                  })}
                </Tr>
              ))}
              <SubTotalsRow>
                <Td colSpan={2}>Total</Td>
                {cells.map((cell, index) => {
                  const totalQuantity = sum(
                    collectionDates
                      .map(date => cell.collections[date])
                      .map(collection => collection.quantity),
                  );
                  return <Td key={index}>{totalQuantity}</Td>;
                })}
              </SubTotalsRow>
            </>
          )}
        </TBody>
      </Table>
    </div>
  );
};
