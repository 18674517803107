import { some } from 'lodash';
import { Id } from '../../../../models/id';
import { createValidatorWithTopLevelValidator } from '../../../../utils/validation/validation';
import { required } from '../../../../utils/validation/validators';
import { MailingHouseContactForJobForm } from '../../../jobs/dataForJobForm';
import { InternationalWorksheetMailingHouseContact } from '../internationalWorksheet';
import { CreateUpdateInternationalWorksheetMailingHouseContactDto } from './createUpdateInternationalWorksheetMailingHouseContactDto';

export type InternationalWorksheetMailingHouseFormModel = {
  mailingHouseId: Id | null;
  contacts: Array<InternationalWorksheetMailingHouseContactFormModel>;
};

export type InternationalWorksheetMailingHouseContactFormModel = {
  mailingHouseContactId: Id;
  includeOnCommunications: boolean;
};

export const internationalWorksheetMailingHouseValidator = createValidatorWithTopLevelValidator<
  InternationalWorksheetMailingHouseFormModel
>({ mailingHouseId: required<Id>() }, ({ contacts }) =>
  contacts.length > 0 &&
  some(
    contacts,
    (contact: InternationalWorksheetMailingHouseContactFormModel) =>
      contact.includeOnCommunications,
  )
    ? null
    : 'At least one mailing house contact must be selected',
);

export const toCreateUpdateInternationalWorksheetMailingHouseContactDto = (
  formModel: InternationalWorksheetMailingHouseContactFormModel,
): CreateUpdateInternationalWorksheetMailingHouseContactDto => ({
  mailingHouseContactId: formModel.mailingHouseContactId,
  includeOnCommunications: formModel.includeOnCommunications,
});

export const fromInternationalWorksheetMailingHouseContact = ({
  mailingHouseContactId,
  includeOnCommunications,
}: InternationalWorksheetMailingHouseContact): InternationalWorksheetMailingHouseContactFormModel => ({
  mailingHouseContactId,
  includeOnCommunications,
});

export const fromMailingHouseContactForInternationalWorksheetForm = ({
  mailingHouseContactId,
}: MailingHouseContactForJobForm): InternationalWorksheetMailingHouseContactFormModel => ({
  mailingHouseContactId,
  includeOnCommunications: false,
});
