import { some } from 'lodash';
import { Id } from '../../../../models/id';
import { Metadata } from '../../../../models/metadata';
import {
  createValidator,
  createValidatorWithTopLevelValidator,
} from '../../../../utils/validation/validation';
import { noValidate, required } from '../../../../utils/validation/validators';
import { User } from '../../../authentication/user';
import { MailingHouseContactForJobForm } from '../../../jobs/dataForJobForm';
import { canEdit, SortedWorksheetMailingHouseContact } from '../sortedWorksheet';
import { CreateUpdateSortedWorksheetMailingHouseContactDto } from './createUpdateSortedWorksheetMailingHouseContactDto';
import { SortedWorksheetFormModel } from './sortedWorksheetFormModel';

export type SortedWorksheetMailingHouseFormModel = {
  mailingHouseId: Id | null;
  contacts: Array<SortedWorksheetMailingHouseContactFormModel>;
};

export type SortedWorksheetMailingHouseContactFormModel = {
  mailingHouseContactId: Id;
  includeOnCarbonCopyCommunication: boolean;
  includeOnDataCommunication: boolean;
  includeOnLabelsCommunication: boolean;
  includeOnCollectionsCommunication: boolean;
  includeOnConsumablesCommunication: boolean;
};

export const sortedWorksheetMailingHouseValidator = createValidator<
  SortedWorksheetMailingHouseFormModel
>({
  mailingHouseId: required<Id>(),
});

export const sortedWorksheetMailingHouseWithContactsValidator = (
  sortedWorksheet: SortedWorksheetFormModel,
  metadata: Metadata,
  user: User,
) => {
  const { dataProcessingAssigneeUserId, statusCode } = sortedWorksheet;
  const worksheetStatus = metadata.worksheetStatuses[statusCode];

  const canEditWorksheetSpecification = canEdit(
    worksheetStatus.editSortedWorksheetSpecification,
    dataProcessingAssigneeUserId,
    user,
  );
  const canEditMailingHouseContacts = canEdit(
    worksheetStatus.editSortedMailingHouseContacts,
    dataProcessingAssigneeUserId,
    user,
  );

  const customerDataContactSelected = sortedWorksheet.customerContacts.some(
    contact => contact.includeOnDataCommunication,
  );

  return createValidatorWithTopLevelValidator<SortedWorksheetMailingHouseFormModel>(
    {
      mailingHouseId: canEditWorksheetSpecification ? required<Id>() : noValidate,
    },
    ({ contacts }) => {
      if (!canEditMailingHouseContacts) {
        return null;
      }

      if (contacts.length === 0) {
        return null;
      }

      const labelsContactSelected = some(
        contacts,
        (contact: SortedWorksheetMailingHouseContactFormModel) =>
          contact.includeOnLabelsCommunication,
      );
      const collectionsContactSelected = some(
        contacts,
        (contact: SortedWorksheetMailingHouseContactFormModel) =>
          contact.includeOnCollectionsCommunication,
      );
      const consumablesContactSelected = some(
        contacts,
        (contact: SortedWorksheetMailingHouseContactFormModel) =>
          contact.includeOnConsumablesCommunication,
      );
      const mailingHouseDataContactSelected = some(
        contacts,
        (contact: SortedWorksheetMailingHouseContactFormModel) =>
          contact.includeOnDataCommunication,
      );

      const nonDataContactsSelected =
        labelsContactSelected && collectionsContactSelected && consumablesContactSelected;

      if (
        nonDataContactsSelected &&
        (customerDataContactSelected || mailingHouseDataContactSelected)
      ) {
        return null;
      }
      // We only require a mailing house data contact if no customer data contact is selected -
      // see MSUP-98 for details.
      if (nonDataContactsSelected) {
        return 'At least one data contact must be selected, on either the customer or the mailing house';
      }

      return labelsContactSelected && collectionsContactSelected && consumablesContactSelected
        ? null
        : 'At least one mailing house contact must be selected for Labels, Collections, and Consumables';
    },
  );
};

export const toCreateUpdateSortedWorksheetMailingHouseContactDto = (
  formModel: SortedWorksheetMailingHouseContactFormModel,
): CreateUpdateSortedWorksheetMailingHouseContactDto => ({
  mailingHouseContactId: formModel.mailingHouseContactId,
  includeOnDataCommunication: formModel.includeOnDataCommunication
    ? 'LinkAndPassword'
    : formModel.includeOnCarbonCopyCommunication
    ? 'CarbonCopy'
    : 'Never',
  includeOnLabelsCommunication: formModel.includeOnLabelsCommunication,
  includeOnCollectionsCommunication: formModel.includeOnCollectionsCommunication,
  includeOnConsumablesCommunication: formModel.includeOnConsumablesCommunication,
});

export const fromSortedWorksheetMailingHouseContact = (
  worksheetContact: SortedWorksheetMailingHouseContact,
  mailingHouseContact: MailingHouseContactForJobForm,
): SortedWorksheetMailingHouseContactFormModel => ({
  mailingHouseContactId: worksheetContact.mailingHouseContactId,
  // The mailing house contact may have been updated to always be included on categories since
  // the worksheet was edited, so we need to take the value from the MH contact if it's set
  includeOnCarbonCopyCommunication:
    mailingHouseContact.includeOnDataCommunication !== 'LinkAndPassword' &&
    worksheetContact.includeOnDataCommunication !== 'LinkAndPassword' &&
    (mailingHouseContact.includeOnDataCommunication === 'CarbonCopy' ||
      worksheetContact.includeOnDataCommunication === 'CarbonCopy'),
  includeOnDataCommunication:
    mailingHouseContact.includeOnDataCommunication === 'LinkAndPassword' ||
    worksheetContact.includeOnDataCommunication === 'LinkAndPassword',
  includeOnLabelsCommunication:
    mailingHouseContact.includeOnLabelsCommunication ||
    worksheetContact.includeOnLabelsCommunication,
  includeOnCollectionsCommunication:
    mailingHouseContact.includeOnCollectionsCommunication ||
    worksheetContact.includeOnCollectionsCommunication,
  includeOnConsumablesCommunication:
    mailingHouseContact.includeOnConsumablesCommunication ||
    worksheetContact.includeOnConsumablesCommunication,
});

export const fromNonEditableSortedWorksheetMailingHouseContact = ({
  mailingHouseContactId,
  includeOnDataCommunication,
  includeOnLabelsCommunication,
  includeOnCollectionsCommunication,
  includeOnConsumablesCommunication,
}: SortedWorksheetMailingHouseContact): SortedWorksheetMailingHouseContactFormModel => ({
  mailingHouseContactId,
  includeOnCarbonCopyCommunication: includeOnDataCommunication === 'CarbonCopy',
  includeOnDataCommunication: includeOnDataCommunication === 'LinkAndPassword',
  includeOnLabelsCommunication,
  includeOnCollectionsCommunication,
  includeOnConsumablesCommunication,
});

export const fromMailingHouseContactForSortedWorksheetForm = ({
  mailingHouseContactId,
  includeOnDataCommunication,
  includeOnLabelsCommunication,
  includeOnCollectionsCommunication,
  includeOnConsumablesCommunication,
}: MailingHouseContactForJobForm): SortedWorksheetMailingHouseContactFormModel => ({
  mailingHouseContactId,
  includeOnCarbonCopyCommunication: includeOnDataCommunication === 'CarbonCopy',
  includeOnDataCommunication: includeOnDataCommunication === 'LinkAndPassword',
  includeOnLabelsCommunication,
  includeOnCollectionsCommunication,
  includeOnConsumablesCommunication,
});
