import { assertIsNumber } from '../../../../utils/assertIsNumber';
import { integerInRange, positiveInteger } from '../../../../utils/validation/numberValidators';
import { maxLength, notEmpty } from '../../../../utils/validation/stringValidators';
import {
  combineValidators,
  createValidator,
  Validator,
} from '../../../../utils/validation/validation';
import { CreateUpdateInternationalCellDto } from './createUpdateInternationalCellDto';
import { InternationalCell } from './internationalCell';

export type InternationalCellFormModel = {
  internationalCellId: number | null;
  cellReference: string;
  quantity: string;
  weightInGrams: string;
  widthInMillimetres: string;
  heightInMillimetres: string;
  thicknessInMillimetres: string;
};

export const toCreateUpdateInternationalCellDto = (
  formModel: InternationalCellFormModel,
  displayOrder: number,
): CreateUpdateInternationalCellDto => ({
  internationalCellId: formModel.internationalCellId,
  displayOrder,
  cellReference: formModel.cellReference,
  quantity: assertIsNumber(formModel.quantity),
  weightInGrams: assertIsNumber(formModel.weightInGrams),
  widthInMillimetres: assertIsNumber(formModel.widthInMillimetres),
  heightInMillimetres: assertIsNumber(formModel.heightInMillimetres),
  thicknessInMillimetres: assertIsNumber(formModel.thicknessInMillimetres),
});

export const fromInternationalCell = (cell: InternationalCell): InternationalCellFormModel => ({
  internationalCellId: cell.internationalCellId,
  cellReference: cell.cellReference,
  quantity: cell.quantity.toString(),
  weightInGrams: cell.weightInGrams.toString(),
  heightInMillimetres: cell.heightInMillimetres.toString(),
  widthInMillimetres: cell.widthInMillimetres.toString(),
  thicknessInMillimetres: cell.thicknessInMillimetres.toString(),
});

const validInternationalCellMeasurement: Validator<string> = combineValidators<string>(
  notEmpty(),
  integerInRange(0, 10000), // 1 metre max
);

export const internationalCellValidator = createValidator<InternationalCellFormModel>({
  cellReference: combineValidators<string>(notEmpty(), maxLength(200)),
  quantity: combineValidators<string>(notEmpty(), positiveInteger()),
  weightInGrams: combineValidators<string>(
    notEmpty(),
    integerInRange(0, 2000), // 2 kilograms max
  ),
  widthInMillimetres: validInternationalCellMeasurement,
  heightInMillimetres: validInternationalCellMeasurement,
  thicknessInMillimetres: validInternationalCellMeasurement,
});
