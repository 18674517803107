import styled from 'styled-components/macro';
import { FullWidthFixedTable, Td } from '../../../shared/table/Table';
import { medium } from '../../../styling/spacing';

export const WorksheetContactsTable = styled(FullWidthFixedTable)`
  margin-top: ${medium};
`;

export const WorksheetContactDetailsTableData = styled(Td)`
  padding: ${medium};
`;
