import React from 'react';
import styled from 'styled-components/macro';
import { ExpandableSection } from '../../../../shared/ExpandableSection';
import { Panel } from '../../../../shared/Panel';
import { medium } from '../../../../styling/spacing';
import { ClientPortalWorksheetMailingHouseDetails } from '../ClientPortalWorksheetMailingHouseDetails';
import {
  ClientPortalInternationalWorksheet,
  ClientPortalInternationalWorksheetMailingHouseContact,
} from './ClientPortalInternationalWorksheet';
import { ClientPortalInternationalWorksheetContactsTable } from './ClientPortalInternationalWorksheetContactsTable';

type Props = {
  internationalWorksheet: ClientPortalInternationalWorksheet;
};

export const ClientPortalInternationalWorksheetMailingHouseDetails = ({
  internationalWorksheet,
}: Props) => {
  const contacts = internationalWorksheet.mailingHouseContacts;
  const selectedContacts = contacts.filter(contact => contact.includeOnCommunications);
  const unselectedContacts = contacts.filter(contact => !contact.includeOnCommunications);

  return (
    <Panel>
      <ClientPortalWorksheetMailingHouseDetails
        mailingHouse={internationalWorksheet.mailingHouse}
      />
      <ClientPortalInternationalWorksheetContactsTable<
        ClientPortalInternationalWorksheetMailingHouseContact
      >
        contacts={selectedContacts}
      />
      {unselectedContacts.length > 0 && (
        <UnselectedContacts title="Unselected Contacts" initiallyExpanded={false}>
          <ClientPortalInternationalWorksheetContactsTable<
            ClientPortalInternationalWorksheetMailingHouseContact
          >
            contacts={unselectedContacts}
          />
        </UnselectedContacts>
      )}
    </Panel>
  );
};

const UnselectedContacts = styled(ExpandableSection)`
  margin-top: ${medium};
  margin-bottom: 0;
`;
