import { connect, FormikContext } from 'formik';
import { useEffect } from 'react';
import * as React from 'react';
import styled from 'styled-components/macro';
import { FormFieldError } from '../../../shared/form/FormField';
import { Checkbox } from '../../../shared/form/inputs/Checkbox';
import { CheckboxesField } from '../../../shared/form/inputs/CheckboxesField';
import { RadioButtonsField } from '../../../shared/form/inputs/RadioButtonsField';
import { medium } from '../../../styling/spacing';
import { SupplierForJobForm } from '../../jobs/dataForJobForm';
import { getInitialMailingBriefDataCleansingWorksheetFormModel } from './dataCleansing/MailingBriefCreateUpdateDataCleansingWorksheetDto';
import { MailingBriefForecastJob } from './dataForMailingBriefForm';
import { getInitialMailingBriefInternationalWorksheetFormModel } from './international/mailingBriefInternationalWorksheetFormModel';
import {
  anyWorksheetSelected,
  dataSortationRadioOptions,
  forecastJobRadioOptions,
  MailingBriefFormModel,
  splitJobRadioOptions,
} from './mailingBriefFormModel';
import {
  getInitialMailingBriefSingleSortedWorksheetFormModel,
  getInitialMailingBriefSplitJobSortedWorksheetFormModels,
  MailingBriefSortedWorksheetFormModel,
} from './sorted/mailingBriefSortedWorksheetFormModel';
import { getInitialMailingBriefUnsortedWorksheetFormModel } from './unsorted/mailingBriefUnsortedWorksheetFormModel';

const canShowWorksheetElements = (model: MailingBriefFormModel) =>
  model.dataSortationRequired === 'NotRequired' ||
  (model.dataSortationRequired === 'Required' && !!model.forecastJobOption);

const getNonSplitJobMailingHouse = (formValues: MailingBriefFormModel) => {
  const sortedWorksheetMailingHouse =
    formValues.sortedWorksheets.length > 0 ? formValues.sortedWorksheets[0].mailingHouse : null;

  const unsortedWorksheetMailingHouse = formValues.unsortedWorksheet
    ? formValues.unsortedWorksheet.mailingHouse
    : null;

  const internationalWorksheetMailingHouse = formValues.internationalWorksheet
    ? formValues.internationalWorksheet.mailingHouse
    : null;

  const mailingHouses = [
    sortedWorksheetMailingHouse,
    unsortedWorksheetMailingHouse,
    internationalWorksheetMailingHouse,
  ];

  return mailingHouses.find(mailingHouse => !!mailingHouse);
};

const getNonSplitJobCollectionDate = (formValues: MailingBriefFormModel) => {
  const sortedWorksheetCollectionDate =
    formValues.sortedWorksheets.length > 0 ? formValues.sortedWorksheets[0].collectionDate : null;

  const unsortedWorksheetCollectionDate = formValues.unsortedWorksheet
    ? formValues.unsortedWorksheet.collectionDate
    : null;

  const internationalWorksheetCollectionDate = formValues.internationalWorksheet
    ? formValues.internationalWorksheet.collectionDate
    : null;

  const dataCleansingWorksheetProcessingDate = formValues.dataCleansingWorksheet
    ? formValues.dataCleansingWorksheet.processingDate
    : null;

  const collectionDates = [
    sortedWorksheetCollectionDate,
    unsortedWorksheetCollectionDate,
    internationalWorksheetCollectionDate,
    dataCleansingWorksheetProcessingDate,
  ];

  return collectionDates.find(collectionDate => !!collectionDate);
};

const JobTypeSelectionComponent = (props: {
  formik: FormikContext<MailingBriefFormModel>;
  isConvertingForecastJobToLive: boolean;
  hasExistingForecastJob: boolean;
  suppliers: Array<SupplierForJobForm>;
}) => {
  const { formik: formikContext, isConvertingForecastJobToLive } = props;
  const { values: formValues } = formikContext;
  const [hasSortedWorksheets, setHasSortedWorksheets] = React.useState(false);
  const [hasUnsortedWorksheet, setHasUnsortedWorksheet] = React.useState(false);
  const [hasInternationalWorksheet, setHasInternationalWorksheet] = React.useState(false);
  const [hasDataCleansingWorksheet, setHasDataCleansingWorksheet] = React.useState(false);
  const [touched, setTouched] = React.useState(false);

  const nonSplitJobMailingHouse =
    formValues.splitJobOption === 'Split' ? null : getNonSplitJobMailingHouse(formikContext.values);

  const nonSplitCollectionDate =
    formValues.splitJobOption === 'Split' ? null : getNonSplitJobCollectionDate(formValues);

  useEffect(() => {
    if (formValues.dataSortationRequired === 'NotRequired' && !isConvertingForecastJobToLive) {
      setHasSortedWorksheets(false);
      setHasDataCleansingWorksheet(false);
      formikContext.setFieldValue('forecastJobOption', null);
    }
  }, [formValues.dataSortationRequired]);

  useEffect(() => {
    if (
      formValues.forecastJobOption === 'Forecast' &&
      !isConvertingForecastJobToLive &&
      !props.hasExistingForecastJob
    ) {
      setHasSortedWorksheets(true);
      setHasUnsortedWorksheet(false);
      setHasInternationalWorksheet(false);
      setHasDataCleansingWorksheet(false);
      formikContext.setFieldValue('splitJobOption', null);
    }
  }, [formValues.forecastJobOption]);

  useEffect(() => {
    formikContext.setFieldValue(
      'sortedWorksheets',
      hasSortedWorksheets
        ? [
            getInitialMailingBriefSingleSortedWorksheetFormModel(
              nonSplitJobMailingHouse,
              nonSplitCollectionDate,
            ),
          ]
        : [],
    );

    if (!hasSortedWorksheets) {
      formikContext.setFieldValue('splitJobOption', null);
    }
  }, [hasSortedWorksheets]);

  useEffect(() => {
    formikContext.setFieldValue(
      'unsortedWorksheet',
      hasUnsortedWorksheet
        ? getInitialMailingBriefUnsortedWorksheetFormModel(
            props.suppliers,
            nonSplitJobMailingHouse,
            nonSplitCollectionDate,
          )
        : null,
    );
  }, [hasUnsortedWorksheet]);

  useEffect(() => {
    formikContext.setFieldValue(
      'internationalWorksheet',
      hasInternationalWorksheet
        ? getInitialMailingBriefInternationalWorksheetFormModel(
            nonSplitJobMailingHouse,
            nonSplitCollectionDate,
          )
        : null,
    );
  }, [hasInternationalWorksheet]);

  useEffect(() => {
    formikContext.setFieldValue(
      'dataCleansingWorksheet',
      hasDataCleansingWorksheet
        ? getInitialMailingBriefDataCleansingWorksheetFormModel(nonSplitCollectionDate)
        : null,
    );
  }, [hasDataCleansingWorksheet]);

  useEffect(() => {
    if (formValues.splitJobOption === 'Split') {
      if (formValues.forecastJobOption === 'Forecast') {
        const currentSortedElements = formValues.sortedWorksheets;
        const newSortedElement: MailingBriefSortedWorksheetFormModel = getInitialMailingBriefSingleSortedWorksheetFormModel();
        formikContext.setFieldValue('sortedWorksheets', [
          ...currentSortedElements,
          newSortedElement,
        ]);
      }
      formikContext.setFieldValue(
        'sortedWorksheets',
        getInitialMailingBriefSplitJobSortedWorksheetFormModels(formValues.sortedWorksheets[0]),
      );
    }
    if (formValues.splitJobOption === 'NotSplit') {
      formikContext.setFieldValue('sortedWorksheets', [formValues.sortedWorksheets[0]]);
    }
  }, [formValues.splitJobOption]);

  useEffect(() => {
    if (
      !touched &&
      (hasSortedWorksheets ||
        hasUnsortedWorksheet ||
        hasInternationalWorksheet ||
        hasDataCleansingWorksheet)
    ) {
      setTouched(true);
    }
  }, [
    hasSortedWorksheets,
    hasUnsortedWorksheet,
    hasInternationalWorksheet,
    hasDataCleansingWorksheet,
  ]);

  const error = anyWorksheetSelected(formValues)
    ? null
    : 'Please select at least one worksheet type';
  const showError = !!error && touched;

  return (
    <>
      {isConvertingForecastJobToLive ? (
        <>
          <CheckboxesField label="What elements are required?">
            <CheckboxContainer>
              <Checkbox
                name="hasUnsortedWorksheet"
                label="Unsorted"
                value={hasUnsortedWorksheet}
                onChange={newValue => setHasUnsortedWorksheet(newValue)}
              />
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox
                name="hasInternationalWorksheet"
                label="International"
                value={hasInternationalWorksheet}
                onChange={newValue => setHasInternationalWorksheet(newValue)}
              />
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox
                name="hasDataCleansingWorksheet"
                label="Data cleansing"
                value={hasDataCleansingWorksheet}
                onChange={newValue => setHasDataCleansingWorksheet(newValue)}
              />
            </CheckboxContainer>
          </CheckboxesField>
          <RadioButtonsField
            name="splitJobOption"
            label="Is this a split job?"
            options={splitJobRadioOptions}
          />
        </>
      ) : (
        <>
          <RadioButtonsField
            name="dataSortationRequired"
            label="Is data sortation required?"
            options={dataSortationRadioOptions}
          />
          {formValues.dataSortationRequired === 'Required' && (
            <RadioButtonsField
              name="forecastJobOption"
              label="Is this a forecast job?"
              options={forecastJobRadioOptions}
            />
          )}
          {canShowWorksheetElements(formValues) && (
            <>
              {formValues.dataSortationRequired === 'NotRequired' && (
                <CheckboxesField label="What elements are required?">
                  <CheckboxContainer>
                    <Checkbox
                      name="hasUnsortedWorksheet"
                      label="Unsorted"
                      value={hasUnsortedWorksheet}
                      onChange={newValue => setHasUnsortedWorksheet(newValue)}
                      disabled={formikContext.isSubmitting}
                    />
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <Checkbox
                      name="hasInternationalWorksheet"
                      label="International"
                      value={hasInternationalWorksheet}
                      onChange={newValue => setHasInternationalWorksheet(newValue)}
                      disabled={formikContext.isSubmitting}
                    />
                  </CheckboxContainer>
                </CheckboxesField>
              )}
              {formValues.dataSortationRequired === 'Required' &&
                formValues.forecastJobOption === 'NotForecast' && (
                  <>
                    <CheckboxesField label="What elements are required?">
                      <CheckboxContainer>
                        <Checkbox
                          name="hasSortedWorksheets"
                          label="Sorted"
                          value={hasSortedWorksheets}
                          onChange={newValue => setHasSortedWorksheets(newValue)}
                          disabled={formikContext.isSubmitting}
                        />
                      </CheckboxContainer>
                      <CheckboxContainer>
                        <Checkbox
                          name="hasUnsortedWorksheet"
                          label="Unsorted"
                          value={hasUnsortedWorksheet}
                          onChange={newValue => setHasUnsortedWorksheet(newValue)}
                        />
                      </CheckboxContainer>
                      <CheckboxContainer>
                        <Checkbox
                          name="hasInternationalWorksheet"
                          label="International"
                          value={hasInternationalWorksheet}
                          onChange={newValue => setHasInternationalWorksheet(newValue)}
                        />
                      </CheckboxContainer>
                      <CheckboxContainer>
                        <Checkbox
                          name="hasDataCleansingWorksheet"
                          label="Data cleansing"
                          value={hasDataCleansingWorksheet}
                          onChange={newValue => setHasDataCleansingWorksheet(newValue)}
                        />
                      </CheckboxContainer>
                    </CheckboxesField>
                    {hasSortedWorksheets && (
                      <RadioButtonsField
                        name="splitJobOption"
                        label="Is this a split job?"
                        options={splitJobRadioOptions}
                      />
                    )}
                  </>
                )}
            </>
          )}
        </>
      )}
      <FormFieldError for="worksheetTypes" showError={showError} showWarning={false}>
        {error}
      </FormFieldError>
    </>
  );
};

export const JobTypeSelection = connect<
  {
    isConvertingForecastJobToLive: boolean;
    hasExistingForecastJob: boolean;
    suppliers: Array<SupplierForJobForm>;
  },
  MailingBriefFormModel
>(JobTypeSelectionComponent);

const CheckboxContainer = styled.div`
  :not(:last-child) {
    margin-right: ${medium};
  }
`;
