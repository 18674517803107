import * as React from 'react';
import { Id } from '../../../models/id';
import { ApiFetchProps, fetchFromApi } from '../../../shared/higher-order-components/fetchFromApi';
import { Section } from '../../../shared/layout/Section';
import { fetchSecureJson, from } from '../../../utils/api';
import { assertIsNumber } from '../../../utils/assertIsNumber';
import { RouteComponentProps } from '../../../utils/RouteComponentProps';
import { ClientPortalDataCleansingWorksheet } from '../worksheets/dataCleansing/ClientPortalDataCleansingWorksheet';
import { ClientPortalInternationalWorksheet } from '../worksheets/international/ClientPortalInternationalWorksheet';
import { ClientPortalSortedWorksheet } from '../worksheets/sorted/ClientPortalSortedWorksheet';
import { ClientPortalUnsortedWorksheet } from '../worksheets/unsorted/ClientPortalUnsortedWorksheet';
import { ClientPortalJobDetails } from './ClientPortalJobDetails';
import { ClientPortalJobWorksheets } from './ClientPortalJobWorksheets';
import { ClientPortalJobHeader } from './ClientPortalViewJobHeader';

type OwnProps = RouteComponentProps<{
  jobId: string;
}>;

export type ClientPortalJobResponse = {
  jobId: Id;
  jobReference: number;
  customerId: Id;
  customerName: string;
  customerReference: string;
  campaignName: string;
  sortedWorksheets: Array<ClientPortalSortedWorksheet>;
  unsortedWorksheet: ClientPortalUnsortedWorksheet | null;
  internationalWorksheet: ClientPortalInternationalWorksheet | null;
  dataCleansingWorksheet: ClientPortalDataCleansingWorksheet | null;
  hasMultipleSortedWorksheets: boolean;
  hasMultipleUnsortedWorksheets: boolean;
  hasMultipleInternationalWorksheets: boolean;
  hasMultipleDataCleansingWorksheets: boolean;
};

type Props = OwnProps & ApiFetchProps<ClientPortalJobResponse, 'jobRequest'>;
export const ClientPortalJobComponent = (props: Props) => {
  const job = props.jobRequest.response;
  const isForecast =
    job.sortedWorksheets &&
    job.sortedWorksheets.length > 0 &&
    job.sortedWorksheets[0].statusCode === 'Forecast';

  return (
    <>
      <ClientPortalJobDetails job={job} />
      {isForecast && <ClientPortalJobHeader job={job} />}
      <Section>
        <ClientPortalJobWorksheets job={job} />
      </Section>
    </>
  );
};

const fetchJob = (jobId: Id) =>
  fetchSecureJson<ClientPortalJobResponse>(from(`client-portal/jobs/${jobId}`, 'get'));

export const ClientPortalJob = fetchFromApi<OwnProps, Id, ClientPortalJobResponse, 'jobRequest'>(
  props => assertIsNumber(props.jobId),
  fetchJob,
  'jobRequest',
)(ClientPortalJobComponent);

export type ClientPortalJobTableColumn =
  | 'JobReference'
  | 'CampaignName'
  | 'CustomerName'
  | 'CustomerReference'
  | 'CollectionDate';
