import { flatMap, values } from 'lodash';
import { datesBetween, DateStamp, workingDaysBetween } from '../../../../models/dateStamp';
import { Id } from '../../../../models/id';
import { Metadata } from '../../../../models/metadata';
import { AttachmentFormModel } from '../../../../shared/files/attachment';
import { assertIsNumber } from '../../../../utils/assertIsNumber';
import { assertNotNull } from '../../../../utils/assertNotNull';
import { Customer } from '../../../customers/customer';
import { CustomerContact } from '../../../customers/customerContact';
import {
  MailingHouseContactForJobForm,
  MailingHouseForJobForm,
} from '../../../jobs/dataForJobForm';
import { mergeExistingContactsWithActualContacts } from '../../edit-form/worksheetFormModel';
import {
  toAttachmentFormModel,
  toCreateUpdateWorksheetAttachment,
  UnsortedWorksheetAttachmentCategory,
  WorksheetAttachmentCategory,
} from '../../worksheetAttachment';
import {
  fromUnsortedCell,
  toCreateUpdateUnsortedCellDto,
  UnsortedCellFormModel,
} from '../cells/unsortedCellFormModel';
import {
  UnsortedCollectionPresentation,
  UnsortedCollectionScheduleType,
  UnsortedCollectionType,
  UnsortedInvoiceSchedule,
  UnsortedProcessingCentre,
  UnsortedProcessingDateOption,
  UnsortedWorksheet,
  UnsortedWorksheetCustomerContact,
  UnsortedWorksheetMailingHouseContact,
} from '../unsortedWorksheet';
import { CreateUpdateUnsortedWorksheetDto } from './createUpdateUnsortedWorksheetDto';
import {
  fromCustomerContact,
  fromUnsortedWorksheetCustomerContact,
  toCreateUpdateUnsortedWorksheetCustomerContactDto,
  UnsortedWorksheetCustomerContactFormModel,
} from './unsortedWorksheetCustomerContactFormModel';
import {
  fromMailingHouseContactForUnsortedWorksheetForm,
  fromUnsortedWorksheetMailingHouseContact,
  toCreateUpdateUnsortedWorksheetMailingHouseContactDto,
  UnsortedWorksheetMailingHouseContactFormModel,
  UnsortedWorksheetMailingHouseFormModel,
} from './unsortedWorksheetMailingHouseFormModel';

export type UnsortedWorksheetFormModel = {
  type: 'Unsorted';
  worksheetId: Id | null;
  unsortedWorksheetId: Id | null;
  worksheetReference: number | null;
  isNew: boolean;
  isOnCreditHold: boolean;
  estimatedValueInPounds: string;
  isAgencyAgreement: boolean;
  collectionSchedule: UnsortedCollectionScheduleType | null;
  invoiceSchedule: UnsortedInvoiceSchedule | null;
  collectFromCustomer: boolean;
  firstCollectionDate: DateStamp | null;
  lastCollectionDate: DateStamp | null;
  collectionPresentation: UnsortedCollectionPresentation | null;
  collectionType: UnsortedCollectionType | null;
  externalTransportSupplierId: Id | null;
  processingDateOption: UnsortedProcessingDateOption | null;
  processingCentre: UnsortedProcessingCentre | null;
  notes: string;
  customCollectionEmailContent: string;
  mailingHouse: UnsortedWorksheetMailingHouseFormModel;
  customerContacts: Array<UnsortedWorksheetCustomerContactFormModel>;
  cells: Array<UnsortedCellFormModel>;
  attachments: { [category in UnsortedWorksheetAttachmentCategory]: Array<AttachmentFormModel> };
};

export const toCreateUpdateUnsortedWorksheetDto = (
  formModel: UnsortedWorksheetFormModel,
  indexAmongNewWorksheets: number | null,
  metadata: Metadata,
): CreateUpdateUnsortedWorksheetDto => ({
  unsortedWorksheetId: formModel.unsortedWorksheetId,
  indexAmongNewWorksheets,
  estimatedValueInPounds: assertIsNumber(formModel.estimatedValueInPounds),
  isAgencyAgreement: formModel.isAgencyAgreement,
  collectionSchedule: assertNotNull(formModel.collectionSchedule),
  invoiceSchedule: formModel.collectionSchedule === 'Daily' ? formModel.invoiceSchedule : null,
  collectFromCustomer: formModel.collectFromCustomer,
  firstCollectionDate: assertNotNull(formModel.firstCollectionDate),
  lastCollectionDate:
    formModel.collectionSchedule === 'Daily'
      ? assertNotNull(formModel.lastCollectionDate)
      : assertNotNull(formModel.firstCollectionDate),
  collectionPresentation: assertNotNull(formModel.collectionPresentation),
  collectionType: assertNotNull(formModel.collectionType),
  externalTransportSupplierId:
    formModel.collectionType === 'ExternalTransport' ? formModel.externalTransportSupplierId : null,
  processingDateOption: assertNotNull(formModel.processingDateOption),
  processingCentre: assertNotNull(formModel.processingCentre),
  notes: formModel.notes,
  customCollectionEmailContent:
    formModel.customCollectionEmailContent.trim() === ''
      ? null
      : formModel.customCollectionEmailContent,
  mailingHouseId: formModel.collectFromCustomer ? null : formModel.mailingHouse.mailingHouseId,
  mailingHouseContacts: formModel.collectFromCustomer
    ? []
    : formModel.mailingHouse.contacts.map(toCreateUpdateUnsortedWorksheetMailingHouseContactDto),
  customerContacts: formModel.customerContacts.map(
    toCreateUpdateUnsortedWorksheetCustomerContactDto,
  ),
  cells: formModel.cells.map((cell, index) =>
    toCreateUpdateUnsortedCellDto(cell, formModel, index, metadata),
  ),
  attachments: flatMap(formModel.attachments, (attachments, category) =>
    attachments
      .filter(attachment => !attachment.isDeleted)
      .map(attachment =>
        toCreateUpdateWorksheetAttachment(attachment, category as WorksheetAttachmentCategory),
      ),
  ),
});

export const fromUnsortedWorksheet = (
  unsortedWorksheet: UnsortedWorksheet,
  mailingHouses: { [mailingHouseId: number]: MailingHouseForJobForm },
  customer: Customer,
): UnsortedWorksheetFormModel => {
  const mailingHouseContacts =
    unsortedWorksheet.mailingHouse != null
      ? values(mailingHouses[unsortedWorksheet.mailingHouse.mailingHouseId].contactsById)
      : [];

  const unsortedWorksheetMailingHouseContacts = mergeExistingContactsWithActualContacts<
    MailingHouseContactForJobForm,
    UnsortedWorksheetMailingHouseContact,
    UnsortedWorksheetMailingHouseContactFormModel
  >(
    unsortedWorksheet.mailingHouseContacts,
    mailingHouseContacts,
    c => c.mailingHouseContactId,
    fromUnsortedWorksheetMailingHouseContact,
    fromMailingHouseContactForUnsortedWorksheetForm,
    c => c.name,
  );

  const unsortedWorksheetCustomerContacts = mergeExistingContactsWithActualContacts<
    CustomerContact,
    UnsortedWorksheetCustomerContact,
    UnsortedWorksheetCustomerContactFormModel
  >(
    unsortedWorksheet.customerContacts,
    customer.contacts.filter(c => c.includeOnJobsCode !== 'Never'),
    c => c.customerContactId,
    fromUnsortedWorksheetCustomerContact,
    fromCustomerContact,
    c => c.displayOrder,
  );

  return {
    type: 'Unsorted',
    worksheetId: unsortedWorksheet.worksheetId,
    unsortedWorksheetId: unsortedWorksheet.unsortedWorksheetId,
    worksheetReference: unsortedWorksheet.worksheetReference,
    isNew: false,
    isOnCreditHold: unsortedWorksheet.isOnCreditHold,
    estimatedValueInPounds: unsortedWorksheet.estimatedValueInPounds.toString(),
    isAgencyAgreement: unsortedWorksheet.isAgencyAgreement,
    collectionSchedule: unsortedWorksheet.collectionSchedule,
    invoiceSchedule: unsortedWorksheet.invoiceSchedule,
    collectFromCustomer: unsortedWorksheet.collectFromCustomer,
    firstCollectionDate: unsortedWorksheet.firstCollectionDate,
    lastCollectionDate: unsortedWorksheet.lastCollectionDate,
    collectionPresentation: unsortedWorksheet.collectionPresentation,
    collectionType: unsortedWorksheet.collectionType,
    externalTransportSupplierId:
      unsortedWorksheet.externalTransportSupplier &&
      unsortedWorksheet.externalTransportSupplier.supplierId,
    processingDateOption: unsortedWorksheet.processingDateOption,
    processingCentre: unsortedWorksheet.processingCentre,
    notes: unsortedWorksheet.notes,
    customCollectionEmailContent: unsortedWorksheet.customCollectionEmailContent || '',
    mailingHouse: {
      mailingHouseId:
        unsortedWorksheet.mailingHouse && unsortedWorksheet.mailingHouse.mailingHouseId,
      contacts: unsortedWorksheetMailingHouseContacts,
    },
    customerContacts: unsortedWorksheetCustomerContacts,
    cells: unsortedWorksheet.cells.map(fromUnsortedCell),
    attachments: {
      Unsorted: unsortedWorksheet.attachments
        .filter(a => a.category === 'Unsorted')
        .map(toAttachmentFormModel),
    },
  };
};

export const getNewUnsortedWorksheetFormModel = (
  unsortedWorksheet: UnsortedWorksheetFormModel,
): UnsortedWorksheetFormModel => ({
  ...unsortedWorksheet,

  unsortedWorksheetId: null,
  worksheetReference: null,
  isNew: true,
  isOnCreditHold: false,

  estimatedValueInPounds: '',
  firstCollectionDate: null,
  lastCollectionDate: null,

  cells: unsortedWorksheet.cells.map(cell => ({
    ...cell,
    unsortedCellId: null,
    quantity: '',
    itemWeightInGrams: '',
    collectionQuantitiesByDate: {},
  })),

  attachments: {
    Unsorted: [],
  },
});

// We only display the working dates between the start and end collection date on the view / edit volumes screens.
// However we send all days, including non-working days, to the API (but with no collection volumes),
// to avoid needing needing to mess around with getting the bank holidays everywhere.
export const collectionDateRange = ({
  firstCollectionDate,
  lastCollectionDate,
}: UnsortedWorksheetFormModel): Array<DateStamp> =>
  firstCollectionDate != null && lastCollectionDate != null
    ? datesBetween(firstCollectionDate, lastCollectionDate)
    : [];

export const collectionWorkingDateRange = (
  { firstCollectionDate, lastCollectionDate }: UnsortedWorksheetFormModel,
  metadata: Metadata,
): Array<DateStamp> =>
  firstCollectionDate != null && lastCollectionDate != null
    ? workingDaysBetween(firstCollectionDate, lastCollectionDate, metadata.bankHolidays)
    : [];
