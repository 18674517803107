import * as React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as CrossIcon } from '../../images/icons/cross-circle-icon.svg';
import { ReactComponent as ExclamationIcon } from '../../images/icons/exclamation-circle.svg';
import { ReactComponent as TickIcon } from '../../images/icons/tick-circle-icon.svg';
import { fontGrey, formInvalid, formSuccess, warningText } from '../../styling/colours';
import { regularFont } from '../../styling/fonts';
import { narrow } from '../../styling/spacing';

export const fieldLabelTestId = 'field-label';
export const validityIconValidTestId = 'field-label-valid-icon';
export const validityIconInvalidTestId = 'field-label-invalid-icon';
export const validityIconWarningTestId = 'field-label-warning-icon';

export type FieldLabelProps = {
  name: string;
  label: string;
  optional?: boolean;
  disabled?: boolean;
  valid: boolean;
  invalid: boolean;
  showWarning: boolean;
  className?: string;
};

export const FieldLabel = (props: FieldLabelProps) => (
  <LabelContainer className={props.className} data-testid={fieldLabelTestId}>
    <Label
      htmlFor={props.name}
      invalid={props.invalid}
      valid={props.valid}
      showWarning={props.showWarning}
    >
      {`${props.label}${props.optional ? ' (optional)' : ''}`}
    </Label>
    <ValidityIconContainer invalid={props.invalid} showWarning={props.showWarning}>
      {props.valid ? (
        <TickIcon data-testid={validityIconValidTestId} />
      ) : props.invalid ? (
        <CrossIcon data-testid={validityIconInvalidTestId} />
      ) : props.showWarning ? (
        <ExclamationIcon data-testid={validityIconWarningTestId} />
      ) : null}
    </ValidityIconContainer>
  </LabelContainer>
);

export const LabelContainer = styled.div`
  line-height: 1;
  height: ${regularFont};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.label<{ invalid?: boolean; valid?: boolean; showWarning?: boolean }>`
  color: ${props =>
    (props.invalid && formInvalid) ||
    (props.valid && formSuccess) ||
    (props.showWarning && warningText) ||
    fontGrey};
`;

const validityIconSize = '18px';

const ValidityIconContainer = styled.div<{ invalid: boolean; showWarning: boolean }>`
  display: inline-block;
  min-height: ${validityIconSize};
  min-width: ${validityIconSize};
  color: ${props => (props.invalid ? formInvalid : props.showWarning ? warningText : formSuccess)};
  margin-left: ${narrow};
`;
