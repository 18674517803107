import * as React from 'react';
import { ReactComponent as CrossIcon } from '../images/icons/cross-icon.svg';
import { ReactComponent as TickIcon } from '../images/icons/tick-icon.svg';
import { red, successText } from '../styling/colours';
import { Icon } from './Icon';

export const crossIconTestId = 'cross-icon';

export const TickOrCross = ({ value, centered }: { value: boolean; centered?: boolean }) =>
  value ? <Tick centered={centered} /> : <Cross centered={centered} />;

export const Tick = (props: { centered?: boolean }) => (
  <Icon centred={props.centered} size="font" colour={successText}>
    <TickIcon />
  </Icon>
);

export const Cross = (props: { centered?: boolean }) => (
  <Icon centred={props.centered} size="font" colour={red} data-testid={crossIconTestId}>
    <CrossIcon />
  </Icon>
);
