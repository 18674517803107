import { connect, FieldArray, FieldArrayRenderProps, FormikContext, FormikProps } from 'formik';
import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as CrossIcon } from '../../../../images/icons/cross-icon.svg';
import { Button } from '../../../../shared/buttons/Button';
import { IconButton } from '../../../../shared/buttons/IconButton';
import { ItemHeader } from '../../../../shared/layout/Headers';
import { Section, SectionDivider } from '../../../../shared/layout/Section';
import { alertText } from '../../../../styling/colours';
import { bold, regularFont } from '../../../../styling/fonts';
import { narrow } from '../../../../styling/spacing';
import { CollectionDate } from '../collectionDate/CollectionDate';
import {
  CustomerForMailingBriefForm,
  MailingHouseForMailingBriefForm,
} from '../dataForMailingBriefForm';
import { MailingBriefFormModel } from '../mailingBriefFormModel';
import { MailingBriefMailingHouse } from '../mailingHouse/MailingBriefMailingHouse';
import { MailingBriefSortedWorksheetFormFields } from './MailingBriefSortedWorksheetFormFields';
import {
  getInitialMailingBriefSingleSortedWorksheetFormModel,
  MailingBriefSortedWorksheetFormModel,
} from './mailingBriefSortedWorksheetFormModel';

type OwnProps = {
  customers: { [customerId: number]: CustomerForMailingBriefForm };
  mailingHouses: { [mailingHouseId: number]: MailingHouseForMailingBriefForm };
  isConvertingForecastJobToLive: boolean;
  formikProps: FormikProps<MailingBriefFormModel>;
};

type Props = OwnProps & { formik: FormikContext<MailingBriefFormModel> };
const MailingBriefSplitJobSortedWorksheetFormFieldsComponent = (props: Props) => {
  const formikContext = props.formik;

  const addSortedElement = () => {
    const currentSortedElements = formikContext.values.sortedWorksheets;
    const newSortedElement: MailingBriefSortedWorksheetFormModel = getInitialMailingBriefSingleSortedWorksheetFormModel();
    formikContext.setFieldValue('sortedWorksheets', [...currentSortedElements, newSortedElement]);
  };

  const removeSortedElement = (
    arrayHelpers: FieldArrayRenderProps,
    sortedElementIndex: number,
  ) => () => arrayHelpers.remove(sortedElementIndex);

  return (
    <>
      <ItemHeader>Sorted Elements</ItemHeader>
      <FieldArray name={'sortedWorksheets'}>
        {arrayHelpers => (
          <>
            {formikContext.values.sortedWorksheets.map((_, index) => (
              <SortedElementSection key={index}>
                <ItemSubHeader>
                  Sorted element {index + 1}
                  {index > 1 && (
                    <DeleteButton
                      onClick={removeSortedElement(arrayHelpers, index)}
                      colour={alertText}
                    >
                      <CrossIcon />
                    </DeleteButton>
                  )}
                </ItemSubHeader>
                <CollectionDate
                  formikProps={props.formikProps}
                  sortedWorksheetIndex={index}
                  worksheetDependentFieldNamePrefix="sortedWorksheets"
                />
                <MailingBriefMailingHouse
                  formikProps={props.formikProps}
                  mailingHouseResponses={props.mailingHouses}
                  isConvertingForecastJobToLive={props.isConvertingForecastJobToLive}
                  worksheetDependentFieldNamePrefix="sortedWorksheets"
                  sortedWorksheetIndex={index}
                  mailingHouseId={
                    props.formikProps.values.sortedWorksheets[index].mailingHouse.mailingHouseId
                  }
                />
                <MailingBriefSortedWorksheetFormFields
                  sortedWorksheetIndex={index}
                  mailingHouses={props.mailingHouses}
                  customers={props.customers}
                  isConvertingForecastJobToLive={props.isConvertingForecastJobToLive}
                />
                {index !== formikContext.values.sortedWorksheets.length - 1 && <SectionDivider />}
              </SortedElementSection>
            ))}
          </>
        )}
      </FieldArray>
      <AddButton onClick={addSortedElement}>Add new sorted element</AddButton>
    </>
  );
};

export const MailingBriefSplitJobSortedWorksheetFormFields = connect<
  OwnProps,
  MailingBriefFormModel
>(MailingBriefSplitJobSortedWorksheetFormFieldsComponent);

const DeleteButton = styled(IconButton)`
  height: 20px;
  width: 20px;
  margin-top: 8px;
  margin-left: 24px;
`;

const AddButton = styled(Button)`
  margin-bottom: 40px;
`;

const ItemSubHeader = styled.h5`
  font-size: ${regularFont};
  font-weight: ${bold};
  margin-bottom: ${narrow};
`;

const SortedElementSection = styled(Section)`
  margin-left: ${narrow};
`;
