import { floor, some, sumBy } from 'lodash';
import { CarrierCode, carrierSupportsUcidsAndScids } from '../../../models/carrier';
import { DateStamp } from '../../../models/dateStamp';
import { DateTimeStamp } from '../../../models/dateTimeStamp';
import { Id } from '../../../models/id';
import { IncentiveScheme } from '../../../models/incentiveScheme';
import { Metadata, SortedWorksheetEditRestriction } from '../../../models/metadata';
import { getSelectOptions } from '../../../shared/form/inputs/Select';
import { User, userHasPermission } from '../../authentication/user';
import { DataProcessingWorksheetResponse } from '../../data-processing/perform-data-processing/dataProcessingWorksheet';
import { IncludeOnDataCommunicationCode } from '../../mailing-houses/contacts/mailingHouseContact';
import {
  SortedWorksheetConsumable,
  WorksheetConsumableCarrierResponse,
  WorksheetConsumableFulfillment,
} from '../consumables/worksheetConsumable';
import {
  ActiveWorksheetLabelDeliveryService,
  Worksheet,
  WorksheetCustomerContact,
  WorksheetLabelDeliveryService,
  WorksheetMailingHouseContact,
  WorksheetMailingHouseDetails,
} from '../worksheet';
import { SortedCell } from './cells/sortedCell';
import { SortedOutputCell, SortedWorksheetPrices } from './prices/sortedWorksheetPrices';

export type SortedWorksheet = Worksheet & {
  sortedWorksheetId: Id;
  carrierCode: CarrierCode;
  mailingHouse: WorksheetMailingHouseDetails;
  mailingHouseContacts: Array<SortedWorksheetMailingHouseContact>;
  dataToOnepostDate: DateStamp;
  dataToMailingHouseDateTime: DateTimeStamp | null;
  consumablesDate: DateStamp;
  royalMailHandoverDate: DateStamp | null;
  wasCreatedAsForecast: boolean;
  instructions: string;
  isUrgent: boolean;
  isAgencyAgreement: boolean;
  overrideServiceLevelAgreement: boolean;
  reasonForOverridingServiceLevelAgreement: string | null;
  incentiveScheme: IncentiveScheme | null;
  labelDeliveryService: WorksheetLabelDeliveryService | null;
  carrierDeliveryOption: WorksheetCarrierDeliveryOption | null;
  dataProvisioningMethod: WorksheetDataProvisioningMethod | null;
  sftpDataProvisioningDetails: string | null;
  dataProvisioningCustomEmailContent: string | null;
  sftpDataProvisioningFilename: string | null;
  sftpDataProvisioningPassword: string | null;
  extendedSeedsRequired: boolean;
  cells: Array<SortedCell>;
  consumables: Array<SortedWorksheetConsumable>;
  consumablesOrderedDateTime: DateTimeStamp | null;
  consumablesCarrierResponse: WorksheetConsumableCarrierResponse;
  consumablesAdvisedDate: DateStamp | null;
  consumablesReorderDate: DateStamp | null;
  consumablesSchedule: string | null;
  consumablesFulfillment: WorksheetConsumableFulfillment;
  consumablesFulfillmentNotes: string | null;
  customerContacts: Array<SortedWorksheetCustomerContact>;
  zonalUcidCode: string | null;
  nationalUcidCode: string | null;
  zonalScidCode: string | null;
  nationalScidCode: string | null;
  overrideScids: boolean;
  zonalScidCodeOverriddenValue: string | null;
  nationalScidCodeOverriddenValue: string | null;
  reasonForOverridingScids: string | null;
  outputCellsHaveChangeOfServiceOrFormat: boolean | null;
  prices: SortedWorksheetPrices | null;
  dataProcessingAssigneeUserId: string | null;
};

export const activeWorksheetLabelDeliveryServiceDisplayNames: {
  [worksheetLabelDeliveryService in ActiveWorksheetLabelDeliveryService]: string;
} = {
  Anytime: 'Anytime',
  SameDayCourier: 'Same Day Courier',
  Overseas: 'Overseas',
  PreNineAm: 'Pre 9am',
  PreOnePm: 'Pre 1pm',
};

export const worksheetLabelDeliveryServiceDisplayNames: {
  [worksheetLabelDeliveryService in WorksheetLabelDeliveryService]: string;
} = {
  Anytime: 'Anytime',
  NineAm: '9am (Archived)',
  TenAm: '10am (Archived)',
  Noon: 'Noon (Archived)',
  SaturdayTenAm: 'Saturday 10am (Archived)',
  SameDayCourier: 'Same Day Courier',
  Overseas: 'Overseas',
  PreNineAm: 'Pre 9am',
  PreOnePm: 'Pre 1pm',
};

export const worksheetLabelDeliveryServices = Object.keys(
  worksheetLabelDeliveryServiceDisplayNames,
) as Array<WorksheetLabelDeliveryService>;

export type WorksheetCarrierDeliveryOption = 'Imc' | 'CarrierHub';

export const labelDeliveryServiceOptions = getSelectOptions(
  worksheetLabelDeliveryServices,
  worksheetLabelDeliveryServiceDisplayNames,
).filter(option => option.value in activeWorksheetLabelDeliveryServiceDisplayNames);

export const worksheetCarrierDeliveryOptionDisplayNames: {
  [worksheetCarrierDeliveryOption in WorksheetCarrierDeliveryOption]: string;
} = {
  Imc: 'IMC',
  CarrierHub: 'Carrier Hub',
};

export const worksheetCarrierDeliveryOptions = Object.keys(
  worksheetCarrierDeliveryOptionDisplayNames,
) as Array<WorksheetCarrierDeliveryOption>;

export type WorksheetDataProvisioningMethod = 'ClientPortal' | 'SFTP' | 'ProcessedOffsite';

export const canSendWorksheetDataProvisioningEmail = (
  method: WorksheetDataProvisioningMethod | null,
) => method === 'ClientPortal' || method === 'SFTP';

export const worksheetDataProvisioningMethodDisplayNames: {
  [dataProvisioningMethod in WorksheetDataProvisioningMethod]: string;
} = {
  ClientPortal: 'Client Portal',
  SFTP: 'SFTP',
  ProcessedOffsite: 'Processed Offsite',
};

export const worksheetDataProvisioningMethodOptions = Object.keys(
  worksheetDataProvisioningMethodDisplayNames,
) as Array<WorksheetDataProvisioningMethod>;

export type SortedWorksheetMailingHouseContact = WorksheetMailingHouseContact &
  SortedWorksheetMailingHouseContactCommunicationsDetails & {
    sortedWorksheetMailingHouseContactId: Id;
    hasHadDataDownloadEmailSent: boolean;
    isDataAttachmentsDownloadEnabled: boolean;
  };

export type SortedWorksheetMailingHouseContactCommunicationsDetails = {
  mailingHouseContactId: Id;
  includeOnDataCommunication: IncludeOnDataCommunicationCode;
  includeOnLabelsCommunication: boolean;
  includeOnCollectionsCommunication: boolean;
  includeOnConsumablesCommunication: boolean;
};

export type SortedWorksheetCustomerContact = WorksheetCustomerContact & {
  includeOnLabelsCommunication: boolean;
  includeOnDataCommunication: IncludeOnDataCommunicationCode;
  includeOnCollectionsCommunication: boolean;
  includeOnConsumablesCommunication: boolean;
  includeOnConfirmationCommunication: boolean;
};

export type CustomCollectionSchedule = {
  customCollectionScheduleId: Id;
  collectionDate: DateStamp;
  royalMailHandoverDate: DateStamp;
  startBagNumber: number | null;
  endBagNumber: number | null;
  quantity: number;
  notes: string | null;
  collectionEmailSent: boolean;
  confirmedByMailingHouse: boolean;
};

export const isSortedWorksheet = (worksheet: Worksheet): worksheet is SortedWorksheet =>
  worksheet.type === 'Sorted';

export const getCollectionStatus = (
  collectionEmailSent: boolean | null,
  confirmedByMailingHouse: boolean | null,
) => (confirmedByMailingHouse ? 'Confirmed' : collectionEmailSent ? 'Email sent' : 'Pending');

export const getOutputCellCollectionStatus = (outputCell: SortedOutputCell) =>
  outputCell.customCollectionSchedules.length > 0
    ? 'Check schedule'
    : getCollectionStatus(
        outputCell.collectionEmailSent,
        outputCell.collectionConfirmedByMailingHouse,
      );

export const canEdit = (
  restriction: SortedWorksheetEditRestriction,
  dataProcessingAssigneeUserId: string | null,
  user: User,
): boolean =>
  restriction.isEditable &&
  (restriction.requiresPermission == null ||
    userHasPermission(user, restriction.requiresPermission)) &&
  (!restriction.isOnlyEditableByDataProcessingAssignee ||
    dataProcessingAssigneeUserId == null ||
    dataProcessingAssigneeUserId === user.userId);

export const canHaveScidsSet = (
  worksheet: SortedWorksheet | DataProcessingWorksheetResponse,
  metadata: Metadata,
): boolean =>
  carrierSupportsUcidsAndScids(worksheet.carrierCode) &&
  some(worksheet.cells, cell => metadata.sortedCellServiceTypes[cell.serviceType].supportsScids);

export type UcidScidValidationResult = {
  isMissingUcid: boolean;
  isMissingNationalUcid: boolean;
  isMissingZonalUcid: boolean;
  isMissingScid: boolean;
  isMissingNationalScid: boolean;
  isMissingZonalScid: boolean;
  isMissingZonalOrNationalUcidAndScidPair: boolean;
};

export const validatePresenceOfScidsAndUcids = (
  worksheet: SortedWorksheet,
  metadata: Metadata,
): UcidScidValidationResult => {
  const canHaveUcids = carrierSupportsUcidsAndScids(worksheet.carrierCode);
  const canHaveScids = canHaveScidsSet(worksheet, metadata);

  const isMissingNationalUcid = canHaveUcids && worksheet.nationalUcidCode == null;
  const isMissingZonalUcid = canHaveUcids && worksheet.zonalUcidCode == null;
  const isMissingNationalScid =
    canHaveScids &&
    worksheet.nationalScidCode == null &&
    worksheet.nationalScidCodeOverriddenValue == null;
  const isMissingZonalScid =
    canHaveScids &&
    worksheet.zonalScidCode == null &&
    worksheet.zonalScidCodeOverriddenValue == null;
  return {
    isMissingUcid: isMissingNationalUcid && isMissingZonalUcid,
    isMissingNationalUcid,
    isMissingZonalUcid,
    isMissingScid: isMissingNationalScid && isMissingZonalScid,
    isMissingNationalScid,
    isMissingZonalScid,
    isMissingZonalOrNationalUcidAndScidPair:
      canHaveUcids &&
      canHaveScids &&
      (isMissingZonalUcid || isMissingZonalScid) &&
      (isMissingNationalUcid || isMissingNationalScid),
  };
};

export const isSortedWorksheetMissingScidsOrUcids = (
  worksheet: SortedWorksheet,
  metadata: Metadata,
) => {
  const validationResult = validatePresenceOfScidsAndUcids(worksheet, metadata);
  return (
    validationResult.isMissingUcid ||
    validationResult.isMissingScid ||
    validationResult.isMissingZonalOrNationalUcidAndScidPair
  );
};

export const isSortedWorksheetMissingRawDataAttachments = (sortedWorksheet: SortedWorksheet) =>
  !some(sortedWorksheet.attachments, attachment => attachment.category === 'RawData');

export const isSortedWorksheetMissingCells = (worksheet: SortedWorksheet) =>
  worksheet.cells.length === 0;

export const averageTrayFillForLetters = (worksheet: SortedWorksheet): number | null => {
  const trayedLetterCells = worksheet.cells.filter(
    cell => cell.presentation === 'Tray' && cell.format === 'Letter',
  );

  if (trayedLetterCells.length === 0) {
    return null;
  }

  const consumablesOrdered = worksheet.consumables.some(
    consumable => consumable.consumableType === 'Trays',
  );
  if (!consumablesOrdered) {
    return null;
  }

  const trayCount = sumBy(
    worksheet.consumables.filter(consumable => consumable.consumableType === 'Trays'),
    'quantity',
  );
  const volumeInTrays = sumBy(trayedLetterCells, 'quantity');
  return floor(volumeInTrays / trayCount);
};
