import { Id } from '../../../../models/id';
import { CustomerContact } from '../../../customers/customerContact';
import { UnsortedWorksheetCustomerContact } from '../unsortedWorksheet';
import { CreateUpdateUnsortedWorksheetCustomerContactDto } from './createUpdateUnsortedWorksheetCustomerContactDto';

export type UnsortedWorksheetCustomerContactFormModel = {
  customerContactId: Id;
  includeOnCommunications: boolean;
};

export const toCreateUpdateUnsortedWorksheetCustomerContactDto = (
  formModel: UnsortedWorksheetCustomerContactFormModel,
): CreateUpdateUnsortedWorksheetCustomerContactDto => ({
  ...formModel,
});

export const fromUnsortedWorksheetCustomerContact = ({
  customerContactId,
  includeOnCommunications,
}: UnsortedWorksheetCustomerContact): UnsortedWorksheetCustomerContactFormModel => ({
  customerContactId,
  includeOnCommunications,
});

export const fromCustomerContact = ({
  customerContactId,
}: CustomerContact): UnsortedWorksheetCustomerContactFormModel => ({
  customerContactId,
  includeOnCommunications: false,
});
