import { keyBy, keys, mapValues } from 'lodash';
import { DateStamp } from '../../../../models/dateStamp';
import {
  decimal,
  nonNegativeInteger,
  numberInRange,
  positiveInteger,
  positiveNumber,
} from '../../../../utils/validation/numberValidators';
import { maxLength, notEmpty } from '../../../../utils/validation/stringValidators';
import {
  combineValidators,
  createValidator,
  Validator,
} from '../../../../utils/validation/validation';
import { noValidate, oneOf, requiredSelect } from '../../../../utils/validation/validators';
import {
  collectionDateRange,
  UnsortedWorksheetFormModel,
} from '../edit-form/unsortedWorksheetFormModel';
import {
  UnsortedCellFormat,
  UnsortedCellProcessingType,
  unsortedCellReferenceMaxLength,
  UnsortedCellServiceFormatRules,
  UnsortedCellValidationMetadata,
  UnsortedCellVariant,
} from './unsortedCell';
import { UnsortedCellFormModel } from './unsortedCellFormModel';

const requiredInputErrorMessage = 'Enter a value';
const requiredSelectErrorMessage = 'Select a value';

export const unsortedCellValidator = (
  cell: UnsortedCellFormModel,
  worksheetFormModel: UnsortedWorksheetFormModel,
  unsortedCellValidation: UnsortedCellValidationMetadata,
) => {
  const validOptions = getValidOptionsForCell(cell, unsortedCellValidation);

  return createValidator<UnsortedCellFormModel>({
    cellReference: combineValidators(
      notEmpty(requiredInputErrorMessage),
      maxLength(unsortedCellReferenceMaxLength),
    ),
    service: requiredSelect(requiredSelectErrorMessage),
    format: combineValidators(
      requiredSelect(requiredSelectErrorMessage),
      validOptions.validFormats == null ? null : oneOf(validOptions.validFormats, 'Invalid format'),
    ),
    processingType: combineValidators(
      requiredSelect(requiredSelectErrorMessage),
      validOptions.validProcessingTypes == null
        ? null
        : oneOf(validOptions.validProcessingTypes, 'Invalid processing type'),
    ),
    variant: combineValidators(
      requiredSelect(requiredSelectErrorMessage),
      validOptions.validVariants == null
        ? null
        : oneOf(validOptions.validVariants, 'Invalid variant'),
    ),
    quantity:
      worksheetFormModel.collectionSchedule === 'AdHoc'
        ? combineValidators(notEmpty(requiredInputErrorMessage), positiveInteger())
        : noValidate,
    itemWeightInGrams: combineValidators(
      notEmpty(requiredInputErrorMessage),
      positiveNumber(),
      validOptions.minWeight == null || validOptions.maxWeight == null
        ? null
        : numberInRange(validOptions.minWeight, validOptions.maxWeight),
      decimal(3),
    ),
    isPpiPrinted: disabledAddOnValidator(validOptions.isPpiPrintedAvailable),
    isReturnAddressPrinting: disabledAddOnValidator(validOptions.isReturnAddressPrintingAvailable),
    isOverlabel: disabledAddOnValidator(validOptions.isOverlabelAvailable),
    isSignedFor: disabledAddOnValidator(validOptions.isSignedForAvailable),
    isTracked: disabledAddOnValidator(validOptions.isTrackedAvailable),
    collectionQuantitiesByDate: collectionQuantityValidator(worksheetFormModel),
  });
};

const collectionQuantityValidator = (worksheetFormModel: UnsortedWorksheetFormModel) => (
  collections: { [collectionDate in DateStamp]?: string | null },
) => {
  const collectionDates = collectionDateRange(worksheetFormModel);

  return mapValues(keyBy(collectionDates, date => date), date =>
    nonNegativeInteger()(collections[date]),
  );
};

const disabledAddOnValidator = (isAvailable: boolean | null): Validator<boolean> => (
  value: boolean,
) => (isAvailable === false && value ? 'Not available' : null);

type ValidOptionsForUnsortedCell = {
  validFormats: Array<UnsortedCellFormat> | null;
  validProcessingTypes: Array<UnsortedCellProcessingType> | null;
  validVariants: Array<UnsortedCellVariant> | null;
  minWeight: number | null;
  maxWeight: number | null;
  isPpiPrintedAvailable: boolean | null;
  isReturnAddressPrintingAvailable: boolean | null;
  isOverlabelAvailable: boolean | null;
  isSignedForAvailable: boolean | null;
  isTrackedAvailable: boolean | null;
};

export const getValidOptionsForCell = (
  cell: UnsortedCellFormModel,
  unsortedCellValidation: UnsortedCellValidationMetadata,
): ValidOptionsForUnsortedCell => {
  const serviceTypeRules = unsortedCellValidation.serviceTypes;
  const serviceTypeRule = cell.service == null ? null : serviceTypeRules[cell.service] || null;
  const serviceFormatRules: UnsortedCellServiceFormatRules | null =
    serviceTypeRule == null || cell.format == null
      ? null
      : serviceTypeRule.formatRules[cell.format] || null;

  return {
    validFormats:
      serviceTypeRule && (keys(serviceTypeRule.formatRules) as Array<UnsortedCellFormat>),
    validProcessingTypes: serviceFormatRules && serviceFormatRules.allowedProcessingTypes,
    validVariants: serviceFormatRules && serviceFormatRules.allowedVariants,
    minWeight: serviceFormatRules && serviceFormatRules.minWeightInKg,
    maxWeight: serviceFormatRules && serviceFormatRules.maxWeightInKg,
    isPpiPrintedAvailable: serviceFormatRules && serviceFormatRules.isPpiPrintedAvailable,
    isReturnAddressPrintingAvailable:
      serviceFormatRules && serviceFormatRules.isReturnAddressPrintingAvailable,
    isOverlabelAvailable: serviceFormatRules && serviceFormatRules.isOverlabelAvailable,
    isSignedForAvailable: serviceFormatRules && serviceFormatRules.isSignedForAvailable,
    isTrackedAvailable: serviceFormatRules && serviceFormatRules.isTrackedAvailable,
  };
};
