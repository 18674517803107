import { format, parse, startOfToday, startOfTomorrow, subDays } from 'date-fns';

export type DateTimeStamp = string;

export const formatDateTimeStamp = (dateTimeStamp: DateTimeStamp | null): string =>
  dateTimeStamp ? format(parse(dateTimeStamp), 'DD/MM/YYYY HH:mm') : '';

export const toDateTimeStamp = (date: Date): DateTimeStamp => format(date);

export const toDateTime = (dateTimeStamp: DateTimeStamp): Date => parse(dateTimeStamp);

export const getStartOfTomorrowAsDateTimeStamp = (): DateTimeStamp =>
  toDateTimeStamp(startOfTomorrow());

export const getNowAsDateTimeStamp = (): DateTimeStamp => toDateTimeStamp(new Date());

export const getDaysIntoThePastAsDateTimeStamp = (daysIntoThePast: number): DateTimeStamp =>
  toDateTimeStamp(subDays(startOfToday(), daysIntoThePast));
