import * as React from 'react';
import styled from 'styled-components/macro';
import { medium } from '../../styling/spacing';
import { HeaderActionsContext } from './HeaderActionsProvider';

export const HeaderActionsDisplay = () => (
  <ActionsContainer>
    <HeaderActionsContext.Consumer>
      {({ actions }) =>
        actions.map(action => <ActionContainer key={action.id}>{action.content}</ActionContainer>)
      }
    </HeaderActionsContext.Consumer>
  </ActionsContainer>
);

const ActionsContainer = styled.div`
  display: flex;
`;

const ActionContainer = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-right: ${medium};
  }
`;
