import React from 'react';
import { ApiFetchProps, fetchFromApi } from '../../shared/higher-order-components/fetchFromApi';
import { AuthenticatedApiRequest, fetchSecureJson, from } from '../../utils/api';
import { ClientPortalMetadata, ClientPortalMetadataContext } from './clientPortalMetadata';

type OwnProps = {
  children: React.ReactNode;
};

type FetchMetadataProps = ApiFetchProps<ClientPortalMetadata, 'metadataRequest'>;

type Props = OwnProps & FetchMetadataProps;

const ClientPortalMetadataContextProviderComponent = (props: Props) => {
  const { metadataRequest } = props;

  return (
    <ClientPortalMetadataContext.Provider value={metadataRequest.response}>
      {props.children}
    </ClientPortalMetadataContext.Provider>
  );
};

const fetchMetadata = (): AuthenticatedApiRequest<ClientPortalMetadata> =>
  fetchSecureJson<ClientPortalMetadata>(from('client-portal/metadata', 'get'));

const enhance = fetchFromApi<OwnProps, undefined, ClientPortalMetadata, 'metadataRequest'>(
  () => undefined,
  fetchMetadata,
  'metadataRequest',
);

export const ClientPortalMetadataContextProvider = enhance(
  ClientPortalMetadataContextProviderComponent,
);
