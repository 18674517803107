import { some, uniq } from 'lodash';
import { DateStamp, workingDaysBefore } from '../../../../models/dateStamp';
import { Id } from '../../../../models/id';
import {
  after,
  isAWorkingDay,
  isNotASunday,
  onOrAfter,
  onOrBefore,
  requiredDate,
} from '../../../../utils/validation/dateValidators';
import { nonNegativeInteger } from '../../../../utils/validation/numberValidators';
import { notEmpty } from '../../../../utils/validation/stringValidators';
import {
  combineValidators,
  createArrayValidator,
  createValidator,
  createValidatorWithTopLevelValidator,
  Validator,
  ValidatorWithTopLevelValidator,
} from '../../../../utils/validation/validation';
import { noValidate, required, requiredSelect } from '../../../../utils/validation/validators';
import {
  fromSortedCell,
  SortedCellFormModel,
} from '../../../worksheets/sorted/cells/sortedCellFormModel';
import {
  SortedWorksheetFormModel,
  WorksheetPrintingType,
} from '../../../worksheets/sorted/edit-form/sortedWorksheetFormModel';
import {
  SortedWorksheetMailingHouseFormModel,
  sortedWorksheetMailingHouseValidator,
} from '../../../worksheets/sorted/edit-form/sortedWorksheetMailingHouseFormModel';
import { WorksheetLabelDeliveryService } from '../../../worksheets/worksheet';
import { ClientPortalMetadata } from '../../clientPortalMetadata';
import { sortedWorksheetDefaults } from '../../shared/formDefaultValues';
import {
  MailingBriefSortedWorksheet,
  MailingHouseForMailingBriefForm,
} from '../dataForMailingBriefForm';
import {
  getEarliestCollectionDateForMailingBriefJob,
  hasWorksheetRequiringCustomerContactsAndMailingHouse,
  MailingBriefFormModel,
} from '../mailingBriefFormModel';
import {
  fromMailingBriefMailingHouseContactDto,
  MailingBriefMailingHouseContactFormModel,
  MailingBriefMailingHouseFormModel,
} from '../mailingBriefMailingHouseFormModel';
import {
  getInitialMailingBriefSortedCellFormModel,
  getMailingBriefSortedCellValidator,
  MailingBriefSortedCellFormModel,
} from './mailingBriefSortedCellFormModel';

export type MailingBriefSortedWorksheetFormModel = SortedWorksheetFormModel & {
  carrierCode: 'SecuredMail';
  isUrgent: false;
  overrideServiceLevelAgreement: false;
  reasonForOverridingServiceLevelAgreement: null;
  partOfIncentiveScheme: false;
  incentiveScheme: null;
  mailingHouse: MailingBriefMailingHouseFormModel;
  extendedSeedsRequired: false;
  cells: Array<MailingBriefSortedCellFormModel>;
  overrideScids: false;
  zonalScidCodeOverriddenValue: '';
  nationalScidCodeOverriddenValue: '';
  reasonForOverridingScids: '';
  collectionDate: DateStamp | null;
};

export const getInitialMailingBriefSortedWorksheetFormModel = (): Array<
  MailingBriefSortedWorksheetFormModel
> => {
  return [getInitialMailingBriefSingleSortedWorksheetFormModel()];
};

export const getInitialMailingBriefSplitJobSortedWorksheetFormModels = (
  sortedWorksheet?: MailingBriefSortedWorksheetFormModel,
): Array<MailingBriefSortedWorksheetFormModel> => {
  return [
    sortedWorksheet ? sortedWorksheet : getInitialMailingBriefSingleSortedWorksheetFormModel(),
    getInitialMailingBriefSingleSortedWorksheetFormModel(),
  ];
};

export const getInitialMailingBriefSingleSortedWorksheetFormModel = (
  jobLevelMailingHouse?: MailingBriefMailingHouseFormModel | null,
  jobLevelCollectionDate?: DateStamp | null,
): MailingBriefSortedWorksheetFormModel => ({
  type: 'Sorted',
  statusCode: 'Draft',
  worksheetId: null,
  sortedWorksheetId: null,
  worksheetReference: null,
  isNew: true,
  isOnCreditHold: false,
  dataProcessingAssigneeUserId: null,
  estimatedValueInPounds: '0',
  mailingHouse: jobLevelMailingHouse
    ? jobLevelMailingHouse
    : {
        mailingHouseId: null,
        contacts: [],
      },
  carrierCode: 'SecuredMail',
  instructions: '',
  isUrgent: false,
  isAgencyAgreement: false,
  dataToOnepostDate: null,
  dataToMailingHouseDateTime: null,
  consumablesDate: null,
  collectionDate: jobLevelCollectionDate ? jobLevelCollectionDate : null,
  royalMailHandoverDate: null,
  overrideServiceLevelAgreement: false,
  reasonForOverridingServiceLevelAgreement: null,
  partOfIncentiveScheme: false,
  incentiveScheme: null,
  printingType:
    sortedWorksheetDefaults.printingType !== undefined
      ? sortedWorksheetDefaults.printingType
      : null,
  labelDeliveryService:
    sortedWorksheetDefaults.labelDeliveryService !== undefined
      ? sortedWorksheetDefaults.labelDeliveryService
      : null,
  deliveredToCarrier: false,
  carrierDeliveryOption: null,
  dataProvisioningMethod: 'ClientPortal',
  sftpDataProvisioningDetails: '',
  extendedSeedsRequired: false,
  cells: [getInitialMailingBriefSortedCellFormModel()],
  customerContacts: [],
  attachments: {
    RawData: [],
    InputData: [],
    SortedData: [],
    MultisortOutputs: [],
    Proof: [],
    Seed: [],
  },
  overrideScids: false,
  zonalScidCodeOverriddenValue: '',
  nationalScidCodeOverriddenValue: '',
  reasonForOverridingScids: '',
});

export const getInitialMailingBriefSortedWorksheetFormModelFromWorksheet = (
  worksheet: MailingBriefSortedWorksheet,
): MailingBriefSortedWorksheetFormModel => ({
  type: 'Sorted',
  statusCode: 'Forecast',
  worksheetId: worksheet.worksheetId,
  sortedWorksheetId: worksheet.sortedWorksheetId,
  worksheetReference: worksheet.worksheetReference,
  isNew: false,
  isOnCreditHold: false,
  dataProcessingAssigneeUserId: null,
  estimatedValueInPounds: '0',
  mailingHouse: {
    mailingHouseId: worksheet.mailingHouse.mailingHouseId,
    contacts: worksheet.mailingHouse.mailingHouseContacts.map(
      fromMailingBriefMailingHouseContactDto,
    ),
  },
  carrierCode: 'SecuredMail',
  instructions: '',
  isUrgent: false,
  isAgencyAgreement: false,
  dataToOnepostDate: worksheet.dataToOnepostDate,
  dataToMailingHouseDateTime: null,
  consumablesDate: worksheet.consumablesDate,
  collectionDate: worksheet.collectionDate,
  royalMailHandoverDate: null,
  overrideServiceLevelAgreement: false,
  reasonForOverridingServiceLevelAgreement: null,
  partOfIncentiveScheme: false,
  incentiveScheme: null,
  printingType: 'OnepostPrint',
  labelDeliveryService: 'Anytime',
  deliveredToCarrier: false,
  carrierDeliveryOption: null,
  dataProvisioningMethod: 'ClientPortal',
  sftpDataProvisioningDetails: '',
  extendedSeedsRequired: false,
  cells: worksheet.cells.map(fromSortedCell),
  customerContacts: [],
  attachments: {
    RawData: [],
    InputData: [],
    SortedData: [],
    MultisortOutputs: [],
    Proof: [],
    Seed: [],
  },
  overrideScids: false,
  zonalScidCodeOverriddenValue: '',
  nationalScidCodeOverriddenValue: '',
  reasonForOverridingScids: '',
});

export const mailingBriefSortedWorksheetsValidator = (
  formModel: MailingBriefFormModel,
  mailingHousesById: { [mailingHouseId: number]: MailingHouseForMailingBriefForm },
  metadata: ClientPortalMetadata,
  isForecast: boolean,
  isConvertingForecastJobToLive: boolean,
): ValidatorWithTopLevelValidator<Array<MailingBriefSortedWorksheetFormModel>> =>
  createArrayValidator<MailingBriefSortedWorksheetFormModel>(mailingBriefSortedWorksheet =>
    mailingBriefSortedWorksheetValidator(
      formModel,
      mailingBriefSortedWorksheet,
      mailingHousesById,
      metadata,
      isForecast,
      isConvertingForecastJobToLive,
    ),
  );

export const mailingBriefSortedWorksheetValidator = (
  formModel: MailingBriefFormModel,
  mailingBriefSortedWorksheet: MailingBriefSortedWorksheetFormModel,
  mailingHousesById: { [mailingHouseId: number]: MailingHouseForMailingBriefForm },
  metadata: ClientPortalMetadata,
  isForecast: boolean,
  isConvertingForecastJobToLive: boolean,
): Validator<MailingBriefSortedWorksheetFormModel> => {
  const earliestCollectionDate = getEarliestCollectionDateForMailingBriefJob(metadata.bankHolidays);

  const collectionDate =
    formModel.splitJobOption === 'Split'
      ? mailingBriefSortedWorksheet.collectionDate
      : formModel.sortedWorksheets[0].collectionDate;

  return createValidator<SortedWorksheetFormModel>({
    estimatedValueInPounds: combineValidators(notEmpty(), nonNegativeInteger()),
    carrierCode: noValidate,
    mailingHouse:
      isForecast && !isConvertingForecastJobToLive
        ? sortedWorksheetMailingHouseValidator
        : sortedWorksheetMailingHouseWithContactsValidator(
            formModel,
            isConvertingForecastJobToLive,
          ),
    instructions: noValidate,
    printingType: required<WorksheetPrintingType>('Please select a value'),
    incentiveScheme: noValidate,
    labelDeliveryService:
      mailingBriefSortedWorksheet.printingType === 'OnepostPrint'
        ? requiredSelect<WorksheetLabelDeliveryService>()
        : noValidate,
    carrierDeliveryOption: noValidate,
    dataToOnepostDate: combineValidators(
      requiredDate('Please specify the Data to ONEPOST date'),
      mailingBriefSortedWorksheet.collectionDate == null
        ? noValidate
        : onOrBefore(
            workingDaysBefore(mailingBriefSortedWorksheet.collectionDate, 2, metadata.bankHolidays),
            'Must be 2 working days before Collection date',
          ),
    ),
    consumablesDate: noValidate,
    collectionDate: combineValidators(
      requiredDate('Please select a collection date'),
      onOrAfter(earliestCollectionDate),
      isAWorkingDay(metadata.bankHolidays),
    ),
    royalMailHandoverDate: combineValidators(
      requiredDate(),
      after(mailingBriefSortedWorksheet.collectionDate, `Must be later than 'Collection date'`),
      isNotASunday(),
    ),
    cells: createArrayValidator(
      (cell: SortedCellFormModel) =>
        getMailingBriefSortedCellValidator(
          cell,
          mailingBriefSortedWorksheet.mailingHouse.mailingHouseId == null
            ? 'None'
            : mailingHousesById[mailingBriefSortedWorksheet.mailingHouse.mailingHouseId]
                .consumableLimitationCode,
          mailingBriefSortedWorksheet.statusCode === 'Forecast',
          mailingBriefSortedWorksheet.isAgencyAgreement,
          !!collectionDate ? new Date(collectionDate) : null,
          metadata,
        ),
      cells => {
        const partiallyAddressedCells = cells.filter(cell => cell.variant === 'PartiallyAddressed');
        if (
          partiallyAddressedCells.length > 0 &&
          !partiallyAddressedCells.some(cell => Number(cell.quantity) >= 10000)
        ) {
          return 'At least one cell of type "Partially Addressed" must have a quantity of at least 10,000';
        }

        // We require the Mailmark barcode to be unique, since it is used to determine the matching SCID.
        // Ideally, this should be a worksheet level field rather than cell level.
        return uniq(
          cells.filter(cell => cell.mailmarkBarcode != null).map(cell => cell.mailmarkBarcode),
        ).length <= 1
          ? null
          : 'Please select the same Mailmark Barcode for all Mailmark Cells';
      },
    ),
    isAgencyAgreement: noValidate,
    dataToMailingHouseDateTime: noValidate,
    reasonForOverridingServiceLevelAgreement: noValidate,
    dataProvisioningMethod: noValidate,
    customerContacts: noValidate, // Customer contacts are validated at job level on the mailing brief
    attachments: noValidate,
  }) as Validator<MailingBriefSortedWorksheetFormModel>;
};

const sortedWorksheetMailingHouseWithContactsValidator = (
  mailingBriefFormModel: MailingBriefFormModel,
  isConvertingForecastJobToLive: boolean,
) => {
  if (!hasWorksheetRequiringCustomerContactsAndMailingHouse(mailingBriefFormModel)) {
    return noValidate;
  }

  const customerDataContactSelected = mailingBriefFormModel.customerContacts.some(
    contact => contact.includeOnDataCommunication,
  );

  return createValidatorWithTopLevelValidator<SortedWorksheetMailingHouseFormModel>(
    {
      mailingHouseId: required<Id>(),
    },
    ({ contacts }) => {
      if (contacts.length === 0) {
        return null;
      }

      if (
        mailingBriefFormModel.forecastJobOption === 'Forecast' &&
        !isConvertingForecastJobToLive
      ) {
        return null;
      }

      const labelsContactSelected = some(
        contacts,
        (contact: MailingBriefMailingHouseContactFormModel) => contact.includeOnLabelsCommunication,
      );
      const collectionsContactSelected = some(
        contacts,
        (contact: MailingBriefMailingHouseContactFormModel) =>
          contact.includeOnCollectionsCommunication,
      );
      const consumablesContactSelected = some(
        contacts,
        (contact: MailingBriefMailingHouseContactFormModel) =>
          contact.includeOnConsumablesCommunication,
      );
      const mailingHouseDataContactSelected = some(
        contacts,
        (contact: MailingBriefMailingHouseContactFormModel) => contact.includeOnDataCommunication,
      );

      if (mailingBriefFormModel.sortedWorksheets.length > 0) {
        const nonDataContactsSelected =
          labelsContactSelected && collectionsContactSelected && consumablesContactSelected;

        if (
          nonDataContactsSelected &&
          (customerDataContactSelected || mailingHouseDataContactSelected)
        ) {
          return null;
        }
        // We only require a mailing house data contact if no customer data contact is selected -
        // see MSUP-98 for details.
        if (nonDataContactsSelected) {
          return 'At least one data contact must be selected, on either the customer or the mailing house';
        }
        return labelsContactSelected && collectionsContactSelected && consumablesContactSelected
          ? null
          : 'At least one mailing house contact must be selected for Labels, Collections, and Consumables';
      }
      return null;
    },
  );
};
