import { flatMap } from 'lodash';
import { DateStamp } from '../../../../models/dateStamp';
import { Id } from '../../../../models/id';
import { AttachmentFormModel } from '../../../../shared/files/attachment';
import { assertIsNumber } from '../../../../utils/assertIsNumber';
import { assertNotNull } from '../../../../utils/assertNotNull';
import { requiredDate, todayOrLater } from '../../../../utils/validation/dateValidators';
import { nonNegativeInteger } from '../../../../utils/validation/numberValidators';
import { maxLength, notEmpty } from '../../../../utils/validation/stringValidators';
import { combineValidators, createValidator } from '../../../../utils/validation/validation';
import { noValidate, required } from '../../../../utils/validation/validators';
import {
  CreateUpdateWorksheetAttachment,
  OtherWorksheetAttachmentCategory,
  toAttachmentFormModel,
  toCreateUpdateWorksheetAttachment,
  WorksheetAttachmentCategory,
} from '../../worksheetAttachment';
import {
  OtherWorksheet,
  OtherWorksheetProcessingCentre,
  otherWorksheetRequiresMailingHouse,
  otherWorksheetRequiresProcessingCentre,
  OtherWorksheetType,
} from '../otherWorksheet';

export type OtherWorksheetFormModel = {
  type: OtherWorksheetType;
  worksheetId: Id | null;
  otherWorksheetId: Id | null;
  worksheetReference: number | null;
  isNew: boolean;
  isOnCreditHold: boolean;
  estimatedValueInPounds: string;
  collectionDate: DateStamp | null;
  mailingHouseId: Id | null;
  processingCentre: OtherWorksheetProcessingCentre | null;
  notes: string;
  attachments: {
    [category in OtherWorksheetAttachmentCategory]: Array<AttachmentFormModel>;
  };
};

export const fromOtherWorksheet = (otherWorksheet: OtherWorksheet): OtherWorksheetFormModel => {
  return {
    type: otherWorksheet.type,
    worksheetId: otherWorksheet.worksheetId,
    otherWorksheetId: otherWorksheet.otherWorksheetId,
    worksheetReference: otherWorksheet.worksheetReference,
    isNew: false,
    isOnCreditHold: otherWorksheet.isOnCreditHold,
    estimatedValueInPounds: otherWorksheet.estimatedValueInPounds.toString(),
    collectionDate: otherWorksheet.collectionDate,
    mailingHouseId: otherWorksheet.mailingHouse && otherWorksheet.mailingHouse.mailingHouseId,
    processingCentre: otherWorksheet.processingCentre,
    notes: otherWorksheet.notes || '',
    attachments: {
      Other: otherWorksheet.attachments
        .filter(a => a.category === 'Other')
        .map(toAttachmentFormModel),
    },
  };
};

export type CreateUpdateOtherWorksheetDto = {
  otherWorksheetId: number | null;
  type: OtherWorksheetType;
  indexAmongNewWorksheets: number | null;
  estimatedValueInPounds: number;
  collectionDate: DateStamp;
  mailingHouseId: Id | null;
  processingCentre: OtherWorksheetProcessingCentre | null;
  notes: string | null;
  attachments: Array<CreateUpdateWorksheetAttachment>;
};

export const toCreateUpdateOtherWorksheetDto = (
  formModel: OtherWorksheetFormModel,
  indexAmongNewWorksheets: number | null,
): CreateUpdateOtherWorksheetDto => ({
  otherWorksheetId: formModel.otherWorksheetId,
  type: formModel.type,
  indexAmongNewWorksheets,
  collectionDate: assertNotNull(formModel.collectionDate),
  estimatedValueInPounds: assertIsNumber(formModel.estimatedValueInPounds),
  mailingHouseId: formModel.mailingHouseId,
  processingCentre: formModel.processingCentre,
  notes: formModel.notes.trim() === '' ? null : formModel.notes,
  attachments: flatMap(formModel.attachments, (attachments, category) =>
    attachments
      .filter(attachment => !attachment.isDeleted)
      .map(attachment =>
        toCreateUpdateWorksheetAttachment(attachment, category as WorksheetAttachmentCategory),
      ),
  ),
});

export const getNewOtherWorksheetFormModel = (
  otherWorksheet: OtherWorksheetFormModel,
): OtherWorksheetFormModel => ({
  ...otherWorksheet,

  otherWorksheetId: null,
  worksheetReference: null,
  isNew: true,
  isOnCreditHold: false,

  estimatedValueInPounds: '',
  collectionDate: null,

  attachments: {
    Other: [],
  },
});

export const getOtherWorksheetValidator = (formModel: OtherWorksheetFormModel) =>
  createValidator<OtherWorksheetFormModel>({
    estimatedValueInPounds: combineValidators<string>(notEmpty(), nonNegativeInteger()),
    collectionDate: combineValidators<DateStamp | null>(
      requiredDate(),
      formModel.isNew ? todayOrLater() : noValidate,
    ),
    mailingHouseId: otherWorksheetRequiresMailingHouse(formModel.type) ? required() : noValidate,
    processingCentre: otherWorksheetRequiresProcessingCentre(formModel.type)
      ? required()
      : noValidate,
    notes: maxLength(4000),
  });
