import { some } from 'lodash';
import { Id } from '../../../../models/id';
import { createValidatorWithTopLevelValidator } from '../../../../utils/validation/validation';
import { required } from '../../../../utils/validation/validators';
import { MailingHouseContactForJobForm } from '../../../jobs/dataForJobForm';
import { UnsortedWorksheetMailingHouseContact } from '../unsortedWorksheet';
import { CreateUpdateUnsortedWorksheetMailingHouseContactDto } from './createUpdateUnsortedWorksheetMailingHouseContactDto';

export type UnsortedWorksheetMailingHouseFormModel = {
  mailingHouseId: Id | null;
  contacts: Array<UnsortedWorksheetMailingHouseContactFormModel>;
};

export type UnsortedWorksheetMailingHouseContactFormModel = {
  mailingHouseContactId: Id;
  includeOnCommunications: boolean;
};

export const unsortedWorksheetMailingHouseValidator = createValidatorWithTopLevelValidator<
  UnsortedWorksheetMailingHouseFormModel
>({ mailingHouseId: required<Id>() }, ({ contacts }) =>
  contacts.length > 0 &&
  some(
    contacts,
    (contact: UnsortedWorksheetMailingHouseContactFormModel) => contact.includeOnCommunications,
  )
    ? null
    : 'At least one mailing house contact must be selected',
);

export const toCreateUpdateUnsortedWorksheetMailingHouseContactDto = (
  formModel: UnsortedWorksheetMailingHouseContactFormModel,
): CreateUpdateUnsortedWorksheetMailingHouseContactDto => ({
  mailingHouseContactId: formModel.mailingHouseContactId,
  includeOnCommunications: formModel.includeOnCommunications,
});

export const fromUnsortedWorksheetMailingHouseContact = ({
  mailingHouseContactId,
  includeOnCommunications,
}: UnsortedWorksheetMailingHouseContact): UnsortedWorksheetMailingHouseContactFormModel => ({
  mailingHouseContactId,
  includeOnCommunications,
});

export const fromMailingHouseContactForUnsortedWorksheetForm = ({
  mailingHouseContactId,
}: MailingHouseContactForJobForm): UnsortedWorksheetMailingHouseContactFormModel => ({
  mailingHouseContactId,
  includeOnCommunications: false,
});
