import { FormikTouched } from 'formik';
import { some, values } from 'lodash';
import * as React from 'react';
import { navigationConfirmationHistory } from '../../utils/routing';

type Props = {
  shouldConfirmNavigation: boolean;
  message: string;
};

export class NavigationConfirmationPrompt extends React.Component<Props> {
  removeCurrentConfirmationMessage: (() => void) | null = null;

  componentDidMount() {
    this.setNavigationConfirmation();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.shouldConfirmNavigation !== prevProps.shouldConfirmNavigation ||
      this.props.message !== prevProps.message
    ) {
      this.removeNavigationConfirmation();
      this.setNavigationConfirmation();
    }
  }

  componentWillUnmount() {
    this.removeNavigationConfirmation();
  }

  setNavigationConfirmation() {
    if (this.props.shouldConfirmNavigation) {
      this.removeCurrentConfirmationMessage = navigationConfirmationHistory.addNavigationConfirmation(
        this.props.message,
      );
    }
  }

  removeNavigationConfirmation() {
    if (this.removeCurrentConfirmationMessage != null) {
      this.removeCurrentConfirmationMessage();
    }
  }

  render() {
    return null;
  }
}

type FormNavigationWarningProps = {
  touched: FormikTouched<unknown>;
  isSubmitting: boolean;
};

export const NavigationWarningIfFormTouched = (props: FormNavigationWarningProps) => (
  <NavigationConfirmationPrompt
    shouldConfirmNavigation={props.isSubmitting || some(values(props.touched))}
    message="Are you sure you want to leave this page? You have unsaved changes which will be lost."
  />
);
