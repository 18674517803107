import { isEqual } from 'lodash';
import * as React from 'react';
import { UrlState } from 'with-url-state';
import { assertIsNumber } from '../../utils/assertIsNumber';
import { urlStateHistoryAdapter } from '../../utils/routing';
import {
  ControlledPaginatedTable,
  getInitialTableState,
  getPaginationQuery,
  PaginatedTableProps,
  PaginationState,
} from './ControlledPaginatedTable';
import { SortDirection } from './Table';

export type PaginationUrlState<TSortByColumn> = {
  pageNumber: string; // Type is string because the number is saved in the url state.
  sortByColumn: TSortByColumn;
  sortDirection: SortDirection;
  filterQuery: string;
};

export const paginatedTableUrlStateToPaginatedTableState = <TSortByColumn extends unknown>(
  urlState: PaginationUrlState<TSortByColumn>,
): PaginationState<TSortByColumn> => ({
  pageNumber: assertIsNumber(urlState.pageNumber),
  sortByColumn: urlState.sortByColumn,
  sortDirection: urlState.sortDirection,
  filterQuery: urlState.filterQuery,
});

export const paginatedTableStateToPaginatedTableUrlState = <TSortByColumn extends unknown>(
  state: PaginationState<TSortByColumn>,
): PaginationUrlState<TSortByColumn> => ({
  pageNumber: state.pageNumber.toString(),
  sortByColumn: state.sortByColumn,
  sortDirection: state.sortDirection,
  filterQuery: state.filterQuery,
});

export class PaginatedTableWithUrlState<TSortByColumn> extends React.Component<
  PaginatedTableProps<TSortByColumn>
> {
  initialState = getInitialTableState(this.props);

  componentDidMount() {
    this.requestNewListPage(this.initialState);
  }

  requestNewListPage = (state: PaginationState<TSortByColumn>) =>
    this.props.onQueryChange(getPaginationQuery(state));

  setTableStateAndRequestNewListPage = (
    setUrlState: (newState: PaginationUrlState<TSortByColumn>) => void,
    urlState: PaginationUrlState<TSortByColumn>,
  ) => (newState: PaginationState<TSortByColumn>) => {
    const newUrlState = paginatedTableStateToPaginatedTableUrlState(newState);
    if (!isEqual(newUrlState, urlState)) {
      setUrlState(newUrlState);
      this.requestNewListPage(newState);
    }
  };

  render() {
    return (
      <UrlState<PaginationUrlState<TSortByColumn>>
        initialState={paginatedTableStateToPaginatedTableUrlState(this.initialState)}
        config={{ history: urlStateHistoryAdapter }}
        render={({ setUrlState, urlState }) => (
          <ControlledPaginatedTable<TSortByColumn>
            {...this.props}
            setTableState={this.setTableStateAndRequestNewListPage(setUrlState, urlState)}
            tableState={paginatedTableUrlStateToPaginatedTableState(urlState)}
          />
        )}
      />
    );
  }
}
