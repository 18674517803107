import styled from 'styled-components/macro';
import { greyBorder } from '../../styling/colours';
import { medium, wide, wider } from '../../styling/spacing';
import { ButtonGroup } from '../buttons/Button';

export const Section = styled.div`
  margin-bottom: ${wide};
`;

export const SectionHeaderContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

export const SectionHeaderActions = styled(ButtonGroup)`
  margin: 0 0 ${medium} 0;
`;

export const SectionDivider = styled.hr`
  margin: ${wider} 0;
  border: 1px solid ${greyBorder};
`;
