import * as React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as SortAsc } from '../../images/icons/sort-solid-asc.svg';
import { ReactComponent as SortDesc } from '../../images/icons/sort-solid-desc.svg';
import { ReactComponent as SortNone } from '../../images/icons/sort-solid-no-sort.svg';
import {
  black,
  fontGrey,
  greyBorder,
  tdgDarkBlue,
  tdgLightGrey,
  white,
} from '../../styling/colours';
import { narrow } from '../../styling/spacing';
import { fastTransitionDuration } from '../../styling/transitions';
import { Icon } from '../Icon';

export const Table = styled.table`
  border-collapse: collapse;
  border: 1px solid ${black};
`;

export const THead = styled.thead``;

export const TBody = styled.tbody``;

export const TFoot = styled.tfoot``;

export const tableRowOddBackground = white;
export const tableRowEvenBackground = greyBorder;
export const highlightedTableRowOdd = '#cedfff';
export const highlightedTableRowEven = '#adbbd6';

export const Tr = styled.tr<{ highlighted?: boolean }>`
  transition: background-color ${fastTransitionDuration};
  border-bottom: 1px solid ${black};
`;

export const tableHeaderTestId = 'table-header';

export const Th = styled(({ centreAligned, rightAligned, ...props }) => (
  <th {...props} data-testid={props.headerId || tableHeaderTestId} />
))<{
  centreAligned?: boolean;
  rightAligned?: boolean;
}>`
  color: ${tdgDarkBlue};
  background-color: ${tdgLightGrey};
  padding: ${narrow};
  text-align: ${props =>
    (props.centreAligned && 'centre') || (props.rightAligned && 'right') || 'start'};
`;

export const SubHeader = styled(Th)`
  background-color: inherit;
  color: inherit;
`;

export type SortDirection = 'None' | 'Asc' | 'Desc';

export const nextSortDirection: { [sortDirection in SortDirection]: SortDirection } = {
  None: 'Asc',
  Asc: 'Desc',
  Desc: 'Asc',
};

const sortDirectionIcons: { [sortDirection in SortDirection]: React.ReactNode } = {
  None: (
    <Icon size="small" opacity="0.6">
      <SortNone />
    </Icon>
  ),
  Asc: (
    <Icon size="small">
      <SortAsc />
    </Icon>
  ),
  Desc: (
    <Icon size="small">
      <SortDesc />
    </Icon>
  ),
};

type TableHeaderCellProps = {
  children?: React.ReactNode;
  className?: string;
};

export const TableHeaderCell = ({ children, className }: TableHeaderCellProps) => (
  <Th className={className}>
    <TableHeaderCellContentContainer>{children}</TableHeaderCellContentContainer>
  </Th>
);

export type SortableTableHeaderCellProps<TSortByColumn> = TableHeaderCellProps & {
  columnName: TSortByColumn;
  sortByColumn: TSortByColumn;
  sortByColumnSortDirection: SortDirection;
  onSortColumn: (sortByColumn: TSortByColumn, sortDirection: SortDirection) => void;
  className?: string;
};

export const SortableTableHeaderCell = <TSortByColumn extends unknown>(
  props: SortableTableHeaderCellProps<TSortByColumn>,
) => {
  const {
    children,
    columnName,
    sortByColumn,
    sortByColumnSortDirection,
    onSortColumn,
    className,
  } = props;

  const sortDirection: SortDirection =
    columnName === sortByColumn ? sortByColumnSortDirection : 'None';

  return (
    <Th
      onClick={() => onSortColumn(columnName, nextSortDirection[sortDirection])}
      className={className}
    >
      <SortableTableHeaderCellContentContainer>
        {children}
        {sortDirectionIcons[sortDirection]}
      </SortableTableHeaderCellContentContainer>
    </Th>
  );
};

const TableHeaderCellContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SortableTableHeaderCellContentContainer = styled(TableHeaderCellContentContainer)`
  cursor: pointer;
`;

export const Td = styled(({ rightAligned, ...props }) => <td {...props} />)<{
  centred?: boolean;
  rightAligned?: boolean;
}>`
  color: ${fontGrey};
  padding: ${narrow};
  text-align: ${props => (props.centred && 'center') || (props.rightAligned && 'right') || 'start'};
`;

export const noDataTableDataCellTestId = 'no-data-table-data-cell';

export const NoDataTableDataCell = styled(props => (
  <Td {...props} data-testid={props.noDataCellId || noDataTableDataCellTestId} />
))`
  text-align: center;
`;

export const FullWidthTable = styled(Table)`
  width: 100%;
`;

export const FullWidthFixedTable = styled(FullWidthTable)`
  table-layout: fixed;
`;
