import { useEffect, useRef } from 'react';

export const useInterval = (action: () => void, duration: number) => {
  // Store the action in a ref to avoid issues with closures
  // See https://overreacted.io/making-setinterval-declarative-with-react-hooks/
  const savedAction = useRef<() => void>();

  useEffect(() => {
    savedAction.current = action;
  }, [action]);

  useEffect(() => {
    const tick = () => savedAction.current && savedAction.current();
    const id = setInterval(tick, duration);
    return () => clearInterval(id);
  }, [duration]);
};

export const useIntervalWhileWindowActive = (action: () => void, duration: number) => {
  // Store the action in a ref to avoid issues with closures
  // See https://overreacted.io/making-setinterval-declarative-with-react-hooks/
  const savedAction = useRef<() => void>();
  const intervalId = useRef<number | null>(null);

  useEffect(() => {
    savedAction.current = action;
  }, [action]);

  const startInterval = () => {
    if (intervalId.current != null) {
      clearInterval(intervalId.current);
    }

    const id = (setInterval(() => {
      if (document.hidden) {
        clearInterval(id);
        intervalId.current = null;
      } else {
        if (savedAction.current) {
          savedAction.current();
        }
      }
    }, duration) as unknown) as number;

    intervalId.current = id;

    return id;
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && intervalId.current == null) {
        if (savedAction.current) {
          savedAction.current();
        }
        startInterval();
      }
    };
    window.addEventListener('visibilitychange', handleVisibilityChange);
    return () => window.removeEventListener('visibilitychange', handleVisibilityChange);
  });

  useEffect(() => {
    if (document.hidden) {
      return;
    } else {
      const id = startInterval();

      return () => {
        clearInterval(id);
        intervalId.current = null;
      };
    }
  }, [duration]);
};
