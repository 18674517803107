import { mapValues, pickBy, some } from 'lodash';

// ###################################
// See Validation.md for documentation
// ###################################

export type ValidationSchema<T extends object> = {
  [K in keyof T]?: ValidatorWithTopLevelValidator<T[K]>;
};

export type Validator<T> = (model: T) => ValidationResult<T>;

export type ValidationResult<T> = T extends File
  ? string | null
  : T extends Array<infer U> | object
  ? { [K in keyof T]?: ValidationResult<T[K]> }
  : string | null;

export type ValidatorWithTopLevelValidator<T> = (
  model: T,
) => ValidationResultWithTopLevelValidation<T>;

export type ValidationResultWithTopLevelValidation<T> = ValidationResult<T> | (string | null);

export const hasError = <T>(
  validationResult: ValidationResult<T> | ValidationResultWithTopLevelValidation<T>,
): boolean => {
  if (typeof validationResult === 'object') {
    return some(validationResult as object, (subResult: ValidationResult<T[keyof T]>) =>
      hasError(subResult),
    );
  } else {
    return validationResult !== null;
  }
};

export const combineValidators = <T>(...validators: Array<Validator<T> | null>): Validator<T> => (
  value: T,
) => {
  for (const validator of validators) {
    const error = validator && validator(value);
    if (error) {
      return error;
    }
  }
  return null as ValidationResult<T>;
};

const validateFields = <T extends object>(
  model: T,
  validationSchema: ValidationSchema<T>,
): ValidationResult<T> => {
  const results = mapValues<T, ValidationResultWithTopLevelValidation<T[keyof T]>>(
    model,
    (value: T[keyof T], key: string) => {
      const validatorForProperty = validationSchema[key as keyof T];
      return validatorForProperty == null ? null : validatorForProperty(value);
    },
  );

  return pickBy(results, result => hasError(result)) as ValidationResult<T>;
};

export const createValidator = <T extends object>(
  validationSchema: ValidationSchema<T>,
): Validator<T> => (model: T): ValidationResult<T> => validateFields<T>(model, validationSchema);

export const createValidatorWithTopLevelValidator = <T extends object>(
  validationSchema: ValidationSchema<T>,
  topLevelValidator: (model: T) => string | null,
): ValidatorWithTopLevelValidator<T> => (model: T): ValidationResultWithTopLevelValidation<T> => {
  const fieldErrors = validateFields(model, validationSchema);
  const result = hasError(fieldErrors) ? fieldErrors : topLevelValidator(model);
  return result as ValidationResultWithTopLevelValidation<T>;
};

export const createArrayValidator = <T>(
  getItemValidator: (model: T) => Validator<T>,
  topLevelValidator?: (values: Array<T>) => string | null,
): ValidatorWithTopLevelValidator<Array<T>> => (values: Array<T>) => {
  const arrayValidationResult = values.map<ValidationResult<T>>(value => {
    const itemValidator = getItemValidator(value);
    return itemValidator(value) as ValidationResult<T>;
  }) as ValidationResult<Array<T>>;

  return hasError(arrayValidationResult)
    ? arrayValidationResult
    : topLevelValidator == null
    ? null
    : topLevelValidator(values);
};
