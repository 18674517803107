import { DateStamp } from '../../../../models/dateStamp';
import {
  isAWorkingDay,
  onOrAfter,
  requiredDate,
} from '../../../../utils/validation/dateValidators';
import {
  combineValidators,
  createValidator,
  Validator,
} from '../../../../utils/validation/validation';
import { noValidate } from '../../../../utils/validation/validators';
import { ClientPortalMetadata } from '../../clientPortalMetadata';
import {
  getEarliestCollectionDateForMailingBriefJob,
  MailingBriefFormModel,
} from '../mailingBriefFormModel';

export type MailingBriefDataCleansingWorksheetFormModel = {
  processingDate: DateStamp | null;
};

export const mailingBriefDataCleansingWorksheetValidator = (
  formModel: MailingBriefFormModel,
  metadata: ClientPortalMetadata,
) => {
  if (!formModel.dataCleansingWorksheet) {
    return noValidate;
  }
  const earliestCollectionDate = getEarliestCollectionDateForMailingBriefJob(metadata.bankHolidays);

  return createValidator<MailingBriefDataCleansingWorksheetFormModel>({
    processingDate: combineValidators(
      requiredDate('Please select a collection date'),
      onOrAfter(earliestCollectionDate),
      isAWorkingDay(metadata.bankHolidays),
    ),
  }) as Validator<MailingBriefDataCleansingWorksheetFormModel | null>;
};
