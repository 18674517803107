import {
  every,
  flatMap,
  intersection,
  isEqual,
  keyBy,
  mapValues,
  orderBy,
  some,
  uniq,
  values,
} from 'lodash';
import { CarrierCode } from '../../../../models/carrier';
import { DateStamp } from '../../../../models/dateStamp';
import { DateTimeStamp } from '../../../../models/dateTimeStamp';
import { Id } from '../../../../models/id';
import { IncentiveScheme } from '../../../../models/incentiveScheme';
import { Metadata, WorksheetStatusCode } from '../../../../models/metadata';
import { AttachmentFormModel, attachmentValidator } from '../../../../shared/files/attachment';
import { getSelectOptions } from '../../../../shared/form/inputs/Select';
import { assertIsNumber } from '../../../../utils/assertIsNumber';
import { assertNotNull } from '../../../../utils/assertNotNull';
import {
  after,
  dateTimeInTheFuture,
  inTheFuture,
  onOrAfter,
  requiredDate,
  requiredDateTime,
  todayOrLater,
} from '../../../../utils/validation/dateValidators';
import { nonNegativeInteger } from '../../../../utils/validation/numberValidators';
import { maxLength, notEmpty } from '../../../../utils/validation/stringValidators';
import {
  combineValidators,
  createArrayValidator,
  createValidator,
  Validator,
} from '../../../../utils/validation/validation';
import { noValidate, required, requiredSelect } from '../../../../utils/validation/validators';
import { User } from '../../../authentication/user';
import { Customer } from '../../../customers/customer';
import { CustomerContact } from '../../../customers/customerContact';
import {
  MailingHouseContactForJobForm,
  MailingHouseForJobForm,
} from '../../../jobs/dataForJobForm';
import { validScidCode } from '../../../scids/scid';
import { mergeExistingContactsWithActualContacts } from '../../edit-form/worksheetFormModel';
import {
  ActiveWorksheetLabelDeliveryService,
  WorksheetLabelDeliveryService,
} from '../../worksheet';
import {
  editableSortedWorksheetAttachmentCategoriesByStatus,
  emptySortedWorksheetAttachments,
  sortedWorksheetAttachmentCategories,
  SortedWorksheetAttachmentCategory,
  toAttachmentFormModel,
  toCreateUpdateWorksheetAttachment,
  WorksheetAttachmentCategory,
} from '../../worksheetAttachment';
import {
  fromSortedCell,
  getCellValidatorWithOptions,
  SortedCellFormModel,
  toCreateUpdateSortedCellDto,
} from '../cells/sortedCellFormModel';
import {
  activeWorksheetLabelDeliveryServiceDisplayNames,
  canEdit,
  SortedWorksheet,
  SortedWorksheetCustomerContact,
  SortedWorksheetMailingHouseContact,
  WorksheetCarrierDeliveryOption,
  WorksheetDataProvisioningMethod,
} from '../sortedWorksheet';
import { CreateUpdateSortedWorksheetDto } from './createUpdateSortedWorksheetDto';
import {
  fromCustomerContact,
  fromNonEditableSortedWorksheetCustomerContact,
  fromSortedWorksheetCustomerContact,
  SortedWorksheetCustomerContactFormModel,
  toCreateUpdateSortedWorksheetCustomerContactDto,
} from './sortedWorksheetCustomerContactFormModel';
import {
  fromMailingHouseContactForSortedWorksheetForm,
  fromNonEditableSortedWorksheetMailingHouseContact,
  fromSortedWorksheetMailingHouseContact,
  SortedWorksheetMailingHouseContactFormModel,
  SortedWorksheetMailingHouseFormModel,
  sortedWorksheetMailingHouseValidator,
  sortedWorksheetMailingHouseWithContactsValidator,
  toCreateUpdateSortedWorksheetMailingHouseContactDto,
} from './sortedWorksheetMailingHouseFormModel';

export type SortedWorksheetFormModel = {
  type: 'Sorted';
  statusCode: WorksheetStatusCode;
  worksheetId: Id | null;
  sortedWorksheetId: Id | null;
  worksheetReference: number | null;
  isNew: boolean;
  isOnCreditHold: boolean;
  dataProcessingAssigneeUserId: string | null;
  estimatedValueInPounds: string;
  mailingHouse: SortedWorksheetMailingHouseFormModel;
  carrierCode: CarrierCode | null;
  instructions: string;
  isUrgent: boolean;
  isAgencyAgreement: boolean;
  dataToOnepostDate: DateStamp | null;
  dataToMailingHouseDateTime: DateTimeStamp | null;
  consumablesDate: DateStamp | null;
  collectionDate: DateStamp | null;
  royalMailHandoverDate: DateStamp | null;
  overrideServiceLevelAgreement: boolean;
  reasonForOverridingServiceLevelAgreement: string | null;
  partOfIncentiveScheme: boolean;
  incentiveScheme: IncentiveScheme | null;
  printingType: WorksheetPrintingType | null;
  labelDeliveryService: ActiveWorksheetLabelDeliveryService | null;
  deliveredToCarrier: boolean;
  carrierDeliveryOption: WorksheetCarrierDeliveryOption | null;
  dataProvisioningMethod: WorksheetDataProvisioningMethod | null;
  sftpDataProvisioningDetails: string;
  extendedSeedsRequired: boolean;
  cells: Array<SortedCellFormModel>;
  customerContacts: Array<SortedWorksheetCustomerContactFormModel>;
  attachments: { [category in SortedWorksheetAttachmentCategory]: Array<AttachmentFormModel> };
  overrideScids: boolean;
  zonalScidCodeOverriddenValue: string;
  nationalScidCodeOverriddenValue: string;
  reasonForOverridingScids: string;
};

export const toCreateJobSortedWorksheetDto = (
  formModel: SortedWorksheetFormModel,
  indexAmongNewWorksheets: number | null,
): CreateUpdateSortedWorksheetDto => ({
  sortedWorksheetId: null,
  indexAmongNewWorksheets,
  estimatedValueInPounds: assertIsNumber(formModel.estimatedValueInPounds.trim()),
  carrierCode: assertNotNull(formModel.carrierCode),
  mailingHouseId: assertNotNull(formModel.mailingHouse.mailingHouseId),
  mailingHouseContacts: formModel.mailingHouse.contacts.map(
    toCreateUpdateSortedWorksheetMailingHouseContactDto,
  ),
  instructions: formModel.instructions,
  isUrgent: formModel.isUrgent,
  isAgencyAgreement: formModel.isAgencyAgreement,
  dataToOnepostDate: assertNotNull(formModel.dataToOnepostDate),
  dataToMailingHouseDateTime: assertNotNull(formModel.dataToMailingHouseDateTime),
  consumablesDate: assertNotNull(formModel.consumablesDate),
  collectionDate: assertNotNull(formModel.collectionDate),
  royalMailHandoverDate: assertNotNull(formModel.royalMailHandoverDate),
  overrideServiceLevelAgreement: formModel.overrideServiceLevelAgreement,
  reasonForOverridingServiceLevelAgreement: formModel.overrideServiceLevelAgreement
    ? formModel.reasonForOverridingServiceLevelAgreement
    : null,
  incentiveScheme: formModel.partOfIncentiveScheme ? formModel.incentiveScheme : null,
  labelDeliveryService:
    formModel.printingType === 'OnepostPrint' ? formModel.labelDeliveryService : null,
  carrierDeliveryOption: formModel.deliveredToCarrier ? formModel.carrierDeliveryOption : null,
  dataProvisioningMethod: assertNotNull(formModel.dataProvisioningMethod),
  sftpDataProvisioningDetails:
    formModel.dataProvisioningMethod === 'SFTP' ? formModel.sftpDataProvisioningDetails : null,
  extendedSeedsRequired: formModel.extendedSeedsRequired,
  cells: formModel.cells.map(toCreateUpdateSortedCellDto),
  customerContacts: formModel.customerContacts.map(toCreateUpdateSortedWorksheetCustomerContactDto),
  attachments: flatMap(formModel.attachments, (attachments, category) =>
    attachments
      .filter(attachment => !attachment.isDeleted)
      .map(attachment =>
        toCreateUpdateWorksheetAttachment(attachment, category as WorksheetAttachmentCategory),
      ),
  ),
  overrideScids: formModel.overrideScids,
  zonalScidCodeOverriddenValue:
    formModel.overrideScids && formModel.zonalScidCodeOverriddenValue.trim() !== ''
      ? formModel.zonalScidCodeOverriddenValue
      : null,
  nationalScidCodeOverriddenValue:
    formModel.overrideScids && formModel.nationalScidCodeOverriddenValue.trim() !== ''
      ? formModel.nationalScidCodeOverriddenValue
      : null,
  reasonForOverridingScids: formModel.overrideScids ? formModel.reasonForOverridingScids : null,
});

export const fromSortedWorksheet = (
  sortedWorksheet: SortedWorksheet,
  mailingHouses: { [mailingHouseId: number]: MailingHouseForJobForm },
  customer: Customer,
  metadata: Metadata,
  user: User,
  options: {
    effectiveStatus?: WorksheetStatusCode;
    clearDataProcessingAssignee?: boolean;
    isNew?: boolean;
    printingType?: WorksheetPrintingType;
  } = {},
): SortedWorksheetFormModel => {
  const statusCode = options.effectiveStatus || sortedWorksheet.statusCode;
  const dataProcessingAssigneeUserId = options.clearDataProcessingAssignee
    ? null
    : sortedWorksheet.dataProcessingAssigneeUserId;

  const { editSortedMailingHouseContacts, editSortedCustomerContacts } = metadata.worksheetStatuses[
    statusCode
  ];

  const mailingHouseContacts = values(
    mailingHouses[sortedWorksheet.mailingHouse.mailingHouseId].contactsById,
  );

  const sortedWorksheetMailingHouseContacts = canEdit(
    editSortedMailingHouseContacts,
    dataProcessingAssigneeUserId,
    user,
  )
    ? mergeExistingContactsWithActualContacts<
        MailingHouseContactForJobForm,
        SortedWorksheetMailingHouseContact,
        SortedWorksheetMailingHouseContactFormModel
      >(
        sortedWorksheet.mailingHouseContacts,
        mailingHouseContacts,
        c => c.mailingHouseContactId,
        fromSortedWorksheetMailingHouseContact,
        fromMailingHouseContactForSortedWorksheetForm,
        c => c.name,
      )
    : sortedWorksheet.mailingHouseContacts.map(fromNonEditableSortedWorksheetMailingHouseContact);

  const sortedWorksheetCustomerContacts = canEdit(
    editSortedCustomerContacts,
    dataProcessingAssigneeUserId,
    user,
  )
    ? mergeExistingContactsWithActualContacts<
        CustomerContact,
        SortedWorksheetCustomerContact,
        SortedWorksheetCustomerContactFormModel
      >(
        sortedWorksheet.customerContacts,
        customer.contacts.filter(c => c.includeOnJobsCode !== 'Never'),
        c => c.customerContactId,
        fromSortedWorksheetCustomerContact,
        fromCustomerContact,
        c => c.displayOrder,
      )
    : sortedWorksheet.customerContacts.map(fromNonEditableSortedWorksheetCustomerContact);

  return {
    ...sortedWorksheet,

    type: 'Sorted',
    statusCode,
    isNew: !!options.isNew,
    isOnCreditHold: options.isNew ? false : sortedWorksheet.isOnCreditHold,
    dataProcessingAssigneeUserId,

    estimatedValueInPounds: sortedWorksheet.estimatedValueInPounds.toString(),

    mailingHouse: {
      mailingHouseId: sortedWorksheet.mailingHouse.mailingHouseId,
      contacts: sortedWorksheetMailingHouseContacts,
    },

    overrideServiceLevelAgreement:
      customer.serviceLevelAgreementHours == null
        ? false
        : sortedWorksheet.overrideServiceLevelAgreement,
    reasonForOverridingServiceLevelAgreement:
      customer.serviceLevelAgreementHours == null
        ? null
        : sortedWorksheet.reasonForOverridingServiceLevelAgreement,

    sftpDataProvisioningDetails: sortedWorksheet.sftpDataProvisioningDetails || '',

    partOfIncentiveScheme: sortedWorksheet.incentiveScheme != null,
    printingType:
      options.printingType ||
      (sortedWorksheet.labelDeliveryService == null ? 'PrintOwn' : 'OnepostPrint'),
    labelDeliveryService:
      sortedWorksheet.labelDeliveryService &&
      sortedWorksheet.labelDeliveryService in activeWorksheetLabelDeliveryServiceDisplayNames
        ? (sortedWorksheet.labelDeliveryService as ActiveWorksheetLabelDeliveryService)
        : 'Anytime',
    deliveredToCarrier: sortedWorksheet.carrierDeliveryOption != null,
    cells: orderBy(sortedWorksheet.cells, cell => cell.displayOrder).map(fromSortedCell),
    customerContacts: sortedWorksheetCustomerContacts,

    attachments: {
      InputData: sortedWorksheet.attachments
        .filter(a => a.category === 'InputData')
        .map(toAttachmentFormModel),
      SortedData: sortedWorksheet.attachments
        .filter(a => a.category === 'SortedData')
        .map(toAttachmentFormModel),
      MultisortOutputs: sortedWorksheet.attachments
        .filter(a => a.category === 'MultisortOutputs')
        .map(toAttachmentFormModel),
      RawData: sortedWorksheet.attachments
        .filter(a => a.category === 'RawData')
        .map(toAttachmentFormModel),
      Proof: sortedWorksheet.attachments
        .filter(a => a.category === 'Proof')
        .map(toAttachmentFormModel),
      Seed: sortedWorksheet.attachments
        .filter(a => a.category === 'Seed')
        .map(toAttachmentFormModel),
    },

    overrideScids: sortedWorksheet.overrideScids,
    zonalScidCodeOverriddenValue: sortedWorksheet.zonalScidCodeOverriddenValue || '',
    nationalScidCodeOverriddenValue: sortedWorksheet.nationalScidCodeOverriddenValue || '',
    reasonForOverridingScids: sortedWorksheet.reasonForOverridingScids || '',
  };
};

export const getNewDraftSortedWorksheetFormModel = (
  sortedWorksheet: SortedWorksheetFormModel,
): SortedWorksheetFormModel => ({
  ...sortedWorksheet,

  sortedWorksheetId: null,
  statusCode: 'Draft',
  worksheetReference: null,
  isNew: true,
  isOnCreditHold: false,
  dataProcessingAssigneeUserId: null,

  estimatedValueInPounds: '',

  instructions: '',
  isUrgent: false,

  dataToOnepostDate: null,
  dataToMailingHouseDateTime: null,
  consumablesDate: null,
  collectionDate: null,
  royalMailHandoverDate: null,

  cells: sortedWorksheet.cells.map(cell => ({
    ...cell,
    sortedCellId: null,
    cellReference: '',
    quantity: '',
    weightInGrams: '',
  })),

  attachments: emptySortedWorksheetAttachments,
});

export type WorksheetPrintingType = 'OnepostPrint' | 'PrintOwn';

export const worksheetPrintingTypeDisplayNames: {
  [worksheetPrintingType in WorksheetPrintingType]: string;
} = {
  OnepostPrint: 'Onepost Print',
  PrintOwn: 'Print own',
};

export const worksheetPrintingTypes = Object.keys(worksheetPrintingTypeDisplayNames) as Array<
  WorksheetPrintingType
>;

export const printingTypeOptions = getSelectOptions(
  worksheetPrintingTypes,
  worksheetPrintingTypeDisplayNames,
);

export const printingTypeRequiresBundleLabelFormat = (
  printingType: WorksheetPrintingType,
): boolean => printingType !== 'OnepostPrint';

export const maxNumberOfWorksheetsPerJob = 98;

export const getLiveSortedWorksheetValidator = (
  sortedWorksheet: SortedWorksheetFormModel,
  dataToMailingHouseDateMustBeTodayOrLater: boolean,
  customer: Customer,
  metadata: Metadata,
  mailingHousesById: { [mailingHouseId: number]: MailingHouseForJobForm },
  user: User,
  isJobCreatedBeforeSortedCellServiceArchiveDate: boolean,
  isJobCreatedBeforeSortedCellVariantArchiveDate: boolean,
): Validator<SortedWorksheetFormModel> => {
  const worksheetStatus = metadata.worksheetStatuses[sortedWorksheet.statusCode];
  const {
    editSortedWorksheetSpecification,
    editSortedSlaOverride,
    editSortedConsumablesDate,
    editSortedDataToMailingHouseDate,
    editSortedDataToOnepostDate,
    editSortedCollectionDate,
    editSortedRoyalMailHandoverDate,
    editSortedWorksheetAttachments,
    editSortedCells,
    editScids,
    editSortedCustomerContacts,
  } = worksheetStatus;

  const { dataProcessingAssigneeUserId } = sortedWorksheet;

  return createValidator<SortedWorksheetFormModel>({
    ...(canEdit(
      editSortedWorksheetSpecification,
      sortedWorksheet.dataProcessingAssigneeUserId,
      user,
    ) && {
      estimatedValueInPounds: combineValidators(notEmpty(), nonNegativeInteger()),
      carrierCode: required<CarrierCode>('Please select a value'),
      mailingHouse: sortedWorksheetMailingHouseValidator,
      instructions: maxLength(3000),
      printingType: required<WorksheetPrintingType>('Please select a value'),
      incentiveScheme: sortedWorksheet.partOfIncentiveScheme
        ? requiredSelect<IncentiveScheme>()
        : noValidate,
      labelDeliveryService:
        sortedWorksheet.printingType === 'OnepostPrint'
          ? requiredSelect<WorksheetLabelDeliveryService>()
          : noValidate,
      carrierDeliveryOption: sortedWorksheet.deliveredToCarrier
        ? requiredSelect<WorksheetCarrierDeliveryOption>()
        : noValidate,
    }),
    dataToOnepostDate: canEdit(
      editSortedDataToOnepostDate,
      sortedWorksheet.dataProcessingAssigneeUserId,
      user,
    )
      ? requiredDate()
      : noValidate,
    consumablesDate: canEdit(
      editSortedConsumablesDate,
      sortedWorksheet.dataProcessingAssigneeUserId,
      user,
    )
      ? combineValidators(
          requiredDate(),
          sortedWorksheet.isNew
            ? sortedWorksheet.isUrgent
              ? todayOrLater()
              : inTheFuture(
                  'Must be in the future. To set it to today, mark the worksheet as urgent',
                )
            : null,
        )
      : noValidate,
    collectionDate: canEdit(
      editSortedCollectionDate,
      sortedWorksheet.dataProcessingAssigneeUserId,
      user,
    )
      ? combineValidators(
          requiredDate(),
          sortedWorksheet.isUrgent
            ? onOrAfter(sortedWorksheet.consumablesDate)
            : after(sortedWorksheet.consumablesDate, `Must be later than 'Consumables date'`),
        )
      : noValidate,
    royalMailHandoverDate: canEdit(
      editSortedRoyalMailHandoverDate,
      dataProcessingAssigneeUserId,
      user,
    )
      ? combineValidators(
          requiredDate(),
          sortedWorksheet.isUrgent
            ? onOrAfter(sortedWorksheet.collectionDate)
            : after(sortedWorksheet.collectionDate, `Must be later than 'Collection date'`),
        )
      : noValidate,
    cells: canEdit(editSortedCells, sortedWorksheet.dataProcessingAssigneeUserId, user)
      ? createArrayValidator(
          (cell: SortedCellFormModel) =>
            getCellValidatorWithOptions(
              cell,
              sortedWorksheet.carrierCode,
              sortedWorksheet.mailingHouse.mailingHouseId == null
                ? null
                : mailingHousesById[sortedWorksheet.mailingHouse.mailingHouseId]
                    .consumableLimitationCode,
              sortedWorksheet.printingType,
              sortedWorksheet.statusCode === 'Forecast',
              sortedWorksheet.isAgencyAgreement,
              isJobCreatedBeforeSortedCellServiceArchiveDate,
              isJobCreatedBeforeSortedCellVariantArchiveDate,
              !!sortedWorksheet.collectionDate ? new Date(sortedWorksheet.collectionDate) : null,
              metadata,
            ),
          cells => {
            const partiallyAddressedCells = cells.filter(
              cell => cell.variant === 'PartiallyAddressed',
            );
            if (
              partiallyAddressedCells.length > 0 &&
              !partiallyAddressedCells.some(cell => Number(cell.quantity) >= 10000)
            ) {
              return 'At least one cell of type "Partially Addressed" must have a quantity of at least 10,000';
            }

            // We require the Mailmark barcode to be unique, since it is used to determine the matching SCID.
            // Ideally, this should be a worksheet level field rather than cell level.
            return uniq(
              cells.filter(cell => cell.mailmarkBarcode != null).map(cell => cell.mailmarkBarcode),
            ).length <= 1
              ? null
              : 'Please select the same Mailmark Barcode for all Mailmark Cells';
          },
        )
      : noValidate,
    ...(canEdit(editScids, sortedWorksheet.dataProcessingAssigneeUserId, user) &&
      sortedWorksheet.overrideScids && {
        zonalScidCodeOverriddenValue: combineValidators(validScidCode, (zonalOverride: string) =>
          zonalOverride.trim() === '' &&
          sortedWorksheet.nationalScidCodeOverriddenValue.trim() === ''
            ? 'Please enter at least one SCID to override'
            : null,
        ),
        nationalScidCodeOverriddenValue: combineValidators(
          validScidCode,
          (nationalOverride: string) =>
            nationalOverride.trim() === '' &&
            sortedWorksheet.zonalScidCodeOverriddenValue.trim() === ''
              ? 'Please enter at least one SCID to override'
              : null,
        ),
        reasonForOverridingScids: combineValidators(notEmpty(), maxLength(2000)),
      }),
    mailingHouse: sortedWorksheetMailingHouseWithContactsValidator(sortedWorksheet, metadata, user),
    ...(canEdit(editSortedWorksheetSpecification, dataProcessingAssigneeUserId, user) && {
      isAgencyAgreement: getIsAgencyAgreementValidator(sortedWorksheet, customer),
    }),
    dataToMailingHouseDateTime: canEdit(
      editSortedDataToMailingHouseDate,
      dataProcessingAssigneeUserId,
      user,
    )
      ? combineValidators(
          requiredDateTime(),
          dataToMailingHouseDateMustBeTodayOrLater ? dateTimeInTheFuture() : null,
          onOrAfter(sortedWorksheet.dataToOnepostDate, `Cannot be earlier than 'Data to ONEPOST'`),
        )
      : noValidate,
    reasonForOverridingServiceLevelAgreement:
      canEdit(editSortedSlaOverride, dataProcessingAssigneeUserId, user) &&
      sortedWorksheet.overrideServiceLevelAgreement
        ? combineValidators(notEmpty(), maxLength(2000))
        : noValidate,
    dataProvisioningMethod: required(),
    sftpDataProvisioningDetails:
      sortedWorksheet.dataProvisioningMethod === 'SFTP'
        ? combineValidators(notEmpty(), maxLength(1000))
        : noValidate,
    customerContacts: canEdit(editSortedCustomerContacts, dataProcessingAssigneeUserId, user)
      ? getWorksheetCustomerContactValidator(sortedWorksheet, customer)
      : noValidate,
    attachments: canEdit(editSortedWorksheetAttachments, dataProcessingAssigneeUserId, user)
      ? createValidator<SortedWorksheetFormModel['attachments']>(
          mapValues(
            keyBy(
              intersection(
                sortedWorksheetAttachmentCategories,
                editableSortedWorksheetAttachmentCategoriesByStatus(
                  worksheetStatus,
                  dataProcessingAssigneeUserId,
                  user,
                ),
              ),
            ),
            _ => createArrayValidator(() => attachmentValidator),
          ),
        )
      : noValidate,
  });
};

const getIsAgencyAgreementValidator = (
  sortedWorksheet: SortedWorksheetFormModel,
  customer: Customer,
): Validator<boolean> => (isAgencyAgreement: boolean) => {
  if (!isAgencyAgreement) {
    return null;
  }

  const { carrierCode } = sortedWorksheet;
  const { hasAgencyAgreementWithSecuredMail } = customer;

  if (carrierCode === 'SecuredMail') {
    return !hasAgencyAgreementWithSecuredMail
      ? 'Customer does not have an agency agreement with Secured Mail'
      : null;
  } else {
    return `It is not possible to have an agency agreement with ${carrierCode}`;
  }
};

export const worksheetChangesRequiringRerun = (
  originalValues: SortedWorksheetFormModel,
  updatedValues: SortedWorksheetFormModel,
  metadata: Metadata,
): Array<string> => {
  const {
    editSortedWorksheetSpecification,
    editSortedSlaOverride,
    editSortedMailingHouseContacts,
    editSortedCustomerContacts,
    editSortedDataToOnepostDate,
    editSortedDataToMailingHouseDate,
    editSortedConsumablesDate,
    editSortedCollectionDate,
    editSortedRoyalMailHandoverDate,
    editSortedCells,
    editScids,
    editUcids, // We don't do anything with UCIDs as they are not directly editable
    editSortedWorksheetAttachments,
    editRawDataAttachments,
    editInputDataAttachments,
    editSeedAttachments,
    editProofAttachments,
  } = metadata.worksheetStatuses[updatedValues.statusCode];

  const rerunRequiredReasons = [];

  if (
    editSortedWorksheetSpecification.shouldPromptForRerun &&
    (updatedValues.estimatedValueInPounds !== originalValues.estimatedValueInPounds ||
      updatedValues.mailingHouse.mailingHouseId !== originalValues.mailingHouse.mailingHouseId ||
      updatedValues.carrierCode !== originalValues.carrierCode ||
      updatedValues.isAgencyAgreement !== originalValues.isAgencyAgreement ||
      updatedValues.partOfIncentiveScheme !== originalValues.partOfIncentiveScheme ||
      updatedValues.incentiveScheme !== originalValues.incentiveScheme ||
      updatedValues.printingType !== originalValues.printingType ||
      updatedValues.labelDeliveryService !== originalValues.labelDeliveryService ||
      updatedValues.deliveredToCarrier !== originalValues.deliveredToCarrier ||
      updatedValues.carrierDeliveryOption !== originalValues.carrierDeliveryOption ||
      updatedValues.dataProvisioningMethod !== originalValues.dataProvisioningMethod ||
      updatedValues.sftpDataProvisioningDetails !== originalValues.sftpDataProvisioningDetails ||
      updatedValues.instructions !== originalValues.instructions ||
      updatedValues.extendedSeedsRequired !== originalValues.extendedSeedsRequired)
  ) {
    rerunRequiredReasons.push(
      `Worksheet ${updatedValues.worksheetReference} specification modified`,
    );
  }

  if (
    editSortedSlaOverride.shouldPromptForRerun &&
    (updatedValues.overrideServiceLevelAgreement !== originalValues.overrideServiceLevelAgreement ||
      updatedValues.reasonForOverridingServiceLevelAgreement !==
        originalValues.reasonForOverridingServiceLevelAgreement)
  ) {
    rerunRequiredReasons.push(
      `Worksheet ${updatedValues.worksheetReference} SLA override modified`,
    );
  }

  if (
    editSortedMailingHouseContacts.shouldPromptForRerun &&
    !isEqual(updatedValues.mailingHouse.contacts, originalValues.mailingHouse.contacts)
  ) {
    rerunRequiredReasons.push(
      `Worksheet ${updatedValues.worksheetReference} Mailing House Contacts modified`,
    );
  }

  if (
    editSortedCustomerContacts.shouldPromptForRerun &&
    !isEqual(updatedValues.customerContacts, originalValues.customerContacts)
  ) {
    rerunRequiredReasons.push(
      `Worksheet ${updatedValues.worksheetReference} Customer Contacts modified`,
    );
  }

  if (
    editSortedDataToOnepostDate.shouldPromptForRerun &&
    updatedValues.dataToOnepostDate !== originalValues.dataToOnepostDate
  ) {
    rerunRequiredReasons.push(
      `Worksheet ${updatedValues.worksheetReference} Data To ONEPOST date modified`,
    );
  }

  if (
    editSortedDataToMailingHouseDate.shouldPromptForRerun &&
    updatedValues.dataToMailingHouseDateTime !== originalValues.dataToMailingHouseDateTime
  ) {
    rerunRequiredReasons.push(
      `Worksheet ${updatedValues.worksheetReference} Data To Mailing House date modified`,
    );
  }

  if (
    editSortedConsumablesDate.shouldPromptForRerun &&
    updatedValues.consumablesDate !== originalValues.consumablesDate
  ) {
    rerunRequiredReasons.push(
      `Worksheet ${updatedValues.worksheetReference} Consumables date modified`,
    );
  }

  if (
    editSortedCollectionDate.shouldPromptForRerun &&
    updatedValues.collectionDate !== originalValues.collectionDate
  ) {
    rerunRequiredReasons.push(
      `Worksheet ${updatedValues.worksheetReference} Collection date modified`,
    );
  }

  if (
    editSortedRoyalMailHandoverDate.shouldPromptForRerun &&
    updatedValues.royalMailHandoverDate !== originalValues.royalMailHandoverDate
  ) {
    rerunRequiredReasons.push(
      `Worksheet ${updatedValues.worksheetReference} Royal Mail Handover date modified`,
    );
  }

  if (editSortedCells.shouldPromptForRerun && !isEqual(updatedValues.cells, originalValues.cells)) {
    rerunRequiredReasons.push(`Worksheet ${updatedValues.worksheetReference} cells modified`);
  }

  if (
    editScids.shouldPromptForRerun &&
    (updatedValues.overrideScids !== originalValues.overrideScids ||
      updatedValues.reasonForOverridingScids !== originalValues.reasonForOverridingScids ||
      updatedValues.nationalScidCodeOverriddenValue !==
        originalValues.nationalScidCodeOverriddenValue ||
      updatedValues.zonalScidCodeOverriddenValue !== originalValues.zonalScidCodeOverriddenValue)
  ) {
    rerunRequiredReasons.push(`Worksheet ${updatedValues.worksheetReference} SCID modified`);
  }

  if (
    editSortedWorksheetAttachments.shouldPromptForRerun &&
    !isEqual(updatedValues.attachments, originalValues.attachments)
  ) {
    rerunRequiredReasons.push(`Worksheet ${updatedValues.worksheetReference} attachments modified`);
  }

  if (
    editRawDataAttachments.shouldPromptForRerun &&
    !isEqual(updatedValues.attachments.RawData, originalValues.attachments.RawData)
  ) {
    rerunRequiredReasons.push(
      `Worksheet ${updatedValues.worksheetReference} Raw Data attachments modified`,
    );
  }

  if (
    editInputDataAttachments.shouldPromptForRerun &&
    !isEqual(updatedValues.attachments.InputData, originalValues.attachments.InputData)
  ) {
    rerunRequiredReasons.push(
      `Worksheet ${updatedValues.worksheetReference} Input Data attachments modified`,
    );
  }

  if (
    editSeedAttachments.shouldPromptForRerun &&
    !isEqual(updatedValues.attachments.Seed, originalValues.attachments.Seed)
  ) {
    rerunRequiredReasons.push(
      `Worksheet ${updatedValues.worksheetReference} Seed attachments modified`,
    );
  }

  if (
    editProofAttachments.shouldPromptForRerun &&
    !isEqual(updatedValues.attachments.Proof, originalValues.attachments.Proof)
  ) {
    rerunRequiredReasons.push(
      `Worksheet ${updatedValues.worksheetReference} Proof attachments modified`,
    );
  }

  return rerunRequiredReasons;
};

const getWorksheetCustomerContactValidator = (
  sortedWorksheet: SortedWorksheetFormModel,
  customer: Customer,
) =>
  createArrayValidator<SortedWorksheetCustomerContactFormModel>(
    contact => () => ({}),
    (contacts: Array<SortedWorksheetCustomerContactFormModel>) => {
      if (
        some(
          customer.contacts,
          c => c.includeOnJobsCode === 'Optionally' || c.includeOnConfirmationCommunication,
        )
      ) {
        if (every(contacts, contact => !contact.includeOnConfirmationCommunication)) {
          return 'At least one Confirmation contact must be selected';
        }
      }

      return some(contacts, contact => contact.includeOnDataCommunication) ||
        some(sortedWorksheet.mailingHouse.contacts, contact => contact.includeOnDataCommunication)
        ? null
        : 'At least one Mailing House or Customer contact must be selected to receive Data communications';
    },
  );
