import { FieldArray, FormikProps } from 'formik';
import { keyBy } from 'lodash';
import * as React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components/macro';
import { ContactNameAndJobTitle } from '../../../shared/form/ContactNameAndJobTitleContainer';
import { CheckboxesFieldItem } from '../../../shared/form/inputs/CheckboxesField';
import { TopLevelValidationError } from '../../../shared/form/TopLevelValidationError';
import { assertNotNull } from '../../../utils/assertNotNull';
import { DataEmailAccessOptions } from '../../mailing-houses/contacts/MailingHouseContactsFieldArray';
import { WorksheetCheckboxesField } from '../../worksheets/WorksheetCheckboxesField';
import { WorksheetFieldSet } from '../../worksheets/WorksheetFieldSet';
import { CustomerForMailingBriefForm } from './dataForMailingBriefForm';
import { MailingBriefFormModel } from './mailingBriefFormModel';

type Props = {
  customer: CustomerForMailingBriefForm;
  mailingBriefFormModel: MailingBriefFormModel;
  formProps: FormikProps<MailingBriefFormModel>;
};

export const MailingBriefCustomerContactsFieldArray = (props: Props) => {
  const { customer, mailingBriefFormModel, formProps } = props;

  const customerContactsById = useMemo(
    () =>
      keyBy(
        customer.customerContacts.filter(c => c.includeOnJobsCode !== 'Never'),
        c => c.customerContactId,
      ),
    [customer],
  );

  return (
    <WorksheetFieldSet label="Customer Contacts">
      {mailingBriefFormModel.customerContacts.length > 0 ? (
        <FieldArray
          name="customerContacts"
          render={() =>
            customer.customerContacts.map((customerContactResponse, index) => {
              const customerContact =
                customerContactsById[customerContactResponse.customerContactId];
              const alwaysIncludeOnJobs = customerContact.includeOnJobsCode === 'Always';
              const customerContactFieldName = `customerContacts.${index}`;

              return (
                <WorksheetCheckboxesField
                  key={customerContactResponse.customerContactId}
                  renderLabel={() => <ContactNameAndJobTitle customerContact={customerContact} />}
                  label={undefined}
                >
                  {mailingBriefFormModel.sortedWorksheets.length > 0 && (
                    <>
                      <DataEmailAccessOptions>
                        <CheckboxesFieldItem
                          name={`${customerContactFieldName}.includeOnCarbonCopyCommunication`}
                          label="CC"
                          disabled={
                            (alwaysIncludeOnJobs &&
                              (customerContact.includeOnDataCommunication === 'CarbonCopy' &&
                                assertNotNull(
                                  mailingBriefFormModel.customerContacts.find(
                                    contact =>
                                      contact.customerContactId ===
                                      customerContactResponse.customerContactId,
                                  ),
                                ).includeOnCarbonCopyCommunication)) ||
                            customerContact.includeOnDataCommunication === 'LinkAndPassword'
                          }
                          onChange={(newValue: boolean) => {
                            if (newValue) {
                              props.formProps.setFieldValue(
                                `${customerContactFieldName}.includeOnDataCommunication`,
                                false,
                              );
                            }
                          }}
                        />
                        <CheckboxesFieldItem
                          name={`${customerContactFieldName}.includeOnDataCommunication`}
                          label="Data"
                          disabled={
                            alwaysIncludeOnJobs &&
                            customerContact.includeOnDataCommunication === 'LinkAndPassword'
                          }
                          onChange={(newValue: boolean) => {
                            if (newValue) {
                              props.formProps.setFieldValue(
                                `${customerContactFieldName}.includeOnCarbonCopyCommunication`,
                                false,
                              );
                            } else if (
                              // If the contact is set to 'Always' include on CarbonCopy communications, they can be upgraded
                              // to receive Data communications, but if Data is then unchecked, the CC checkbox must be
                              // re-checked to ensure they are still included on CarbonCopy communications
                              customerContact.includeOnDataCommunication === 'CarbonCopy'
                            ) {
                              props.formProps.setFieldValue(
                                `${customerContactFieldName}.includeOnCarbonCopyCommunication`,
                                true,
                              );
                            }
                          }}
                        />
                      </DataEmailAccessOptions>
                      <CheckboxesFieldItem
                        name={`${customerContactFieldName}.includeOnLabelsCommunication`}
                        label="Labels"
                        disabled={
                          alwaysIncludeOnJobs && customerContact.includeOnLabelsCommunication
                        }
                      />
                      <CheckboxesFieldItem
                        name={`${customerContactFieldName}.includeOnCollectionsCommunication`}
                        label="Collections"
                        disabled={
                          alwaysIncludeOnJobs && customerContact.includeOnCollectionsCommunication
                        }
                      />
                      <CheckboxesFieldItem
                        name={`${customerContactFieldName}.includeOnConsumablesCommunication`}
                        label="Consumables"
                        disabled={
                          alwaysIncludeOnJobs && customerContact.includeOnConsumablesCommunication
                        }
                      />
                      <CheckboxesFieldItem
                        name={`${customerContactFieldName}.includeOnConfirmationCommunication`}
                        label="Confirmation"
                        disabled={
                          alwaysIncludeOnJobs && customerContact.includeOnConfirmationCommunication
                        }
                      />
                    </>
                  )}
                  {mailingBriefFormModel.unsortedWorksheet && (
                    <CheckboxesFieldItem
                      name={`${customerContactFieldName}.includeOnUnsortedCommunication`}
                      label="Unsorted confirmation"
                    />
                  )}
                  {mailingBriefFormModel.internationalWorksheet && (
                    <CheckboxesFieldItem
                      name={`${customerContactFieldName}.includeOnInternationalCommunication`}
                      label="International confirmation"
                    />
                  )}
                </WorksheetCheckboxesField>
              );
            })
          }
        />
      ) : (
        <p>This customer does not have any contacts.</p>
      )}
      <CustomerContactsTopLevelValidationError fieldName="customerContacts" {...formProps} />
    </WorksheetFieldSet>
  );
};

const CustomerContactsTopLevelValidationError = styled(TopLevelValidationError)`
  margin-bottom: 0;
`;
