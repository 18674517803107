import { FieldProps } from 'formik';
import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { FormField, InlineFormField } from '../FormField';
import { formInputStyling } from '../formInputStyling';

type InputProps = {
  valid?: boolean;
  invalid?: boolean;
  showWarning?: boolean;
  textTransform?: 'uppercase' | 'lowercase';
  inline?: boolean;
  hidePlaceholderOnFocus?: boolean;
};

export const Input = styled.input<InputProps>`
  ${({ valid, invalid, showWarning, textTransform, inline }) =>
    formInputStyling({ valid, invalid, showWarning, textTransform, inline })};

  ${props =>
    props.hidePlaceholderOnFocus &&
    css`
      &:focus::placeholder {
        color: transparent;
      }
    `}
`;

export type InputFieldProps = (
  | { label: string; inline?: false }
  | { label?: undefined; inline: true }) & {
  optional?: boolean;
  name: string;
  type?: string;
  placeholder?: string;
  hidePlaceholderOnFocus?: boolean;
  disabled?: boolean;
  textTransform?: 'uppercase' | 'lowercase';
  className?: string;
  onChange?: (newValue: string) => void;
  onBlur?: () => void;
};

export const InputField = (props: InputFieldProps) => {
  const InputFormField = props.inline ? InlineFormField : FormField;

  const onChange = (fieldProps: FieldProps) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(event.target.value);
    }

    fieldProps.field.onChange(event);
  };

  const onBlur = (fieldProps: FieldProps) => (event: React.FocusEvent<HTMLInputElement>) => {
    if (props.onBlur) {
      props.onBlur();
    }

    fieldProps.field.onBlur(event);
  };

  return (
    <InputFormField
      name={props.name}
      label={props.label == null ? '' : props.label}
      optional={props.optional}
      type={props.type}
      disabled={props.disabled}
      className={props.className}
    >
      {({ field, form, valid, invalid, showWarning }) => (
        <Input
          {...field}
          id={props.name}
          disabled={props.disabled || form.isSubmitting}
          placeholder={props.placeholder}
          hidePlaceholderOnFocus={props.hidePlaceholderOnFocus}
          invalid={invalid}
          valid={valid}
          showWarning={showWarning}
          textTransform={props.textTransform}
          inline={props.inline}
          onChange={onChange({ field, form })}
          onBlur={onBlur({ field, form })}
          value={field.value == null ? '' : field.value}
          type={props.type}
        />
      )}
    </InputFormField>
  );
};
