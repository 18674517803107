import { isEmpty, orderBy } from 'lodash';
import * as React from 'react';
import {
  FullWidthTable,
  NoDataTableDataCell,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from '../../../../shared/table/Table';
import { TickOrCross } from '../../../../shared/TickOrCross';
import {
  unsortedCellFormatDisplayNames,
  unsortedCellProcessingTypeDisplayNames,
  unsortedCellServiceTypeDisplayNames,
  unsortedCellVariantDisplayNames,
} from '../../../worksheets/unsorted/cells/unsortedCell';
import { UnsortedCollectionScheduleType } from '../../../worksheets/unsorted/unsortedWorksheet';
import { ClientPortalUnsortedCell } from './ClientPortalUnsortedCell';

type Props = {
  cells: Array<ClientPortalUnsortedCell>;
  collectionSchedule: UnsortedCollectionScheduleType;
};

export const ClientPortalViewUnsortedCellsTable = ({ cells, collectionSchedule }: Props) => (
  <FullWidthTable>
    <THead>
      <Tr>
        <Th>Cell Reference</Th>
        <Th>Service</Th>
        <Th>Format</Th>
        <Th>Processing</Th>
        <Th>Variant</Th>
        {collectionSchedule === 'AdHoc' && <Th>Quantity</Th>}
        <Th>Item Weight (g)</Th>
        <Th>PPI Printed?</Th>
        <Th>Return Address Printed</Th>
        <Th>Overlabel?</Th>
        <Th>Signed For?</Th>
        <Th>Tracked?</Th>
      </Tr>
    </THead>
    <TBody>
      {isEmpty(cells) ? (
        <Tr>
          <NoDataTableDataCell colSpan={8}>
            -- This worksheet does not have any cells --
          </NoDataTableDataCell>
        </Tr>
      ) : (
        orderBy(cells, unsortedCell => unsortedCell.displayOrder).map(cell => (
          <Tr key={cell.unsortedCellId}>
            <Td>{cell.cellReference}</Td>
            <Td>{unsortedCellServiceTypeDisplayNames[cell.service]}</Td>
            <Td>{unsortedCellFormatDisplayNames[cell.format]}</Td>
            <Td>{unsortedCellProcessingTypeDisplayNames[cell.processingType]}</Td>
            <Td>{unsortedCellVariantDisplayNames[cell.variant]}</Td>
            {collectionSchedule === 'AdHoc' && <Td>{cell.quantity.toLocaleString()}</Td>}
            <Td>{cell.itemWeightInGrams.toLocaleString()}</Td>
            <Td>
              <TickOrCross value={cell.isPpiPrinted} />
            </Td>
            <Td>
              <TickOrCross value={cell.isReturnAddressPrinting} />
            </Td>
            <Td>
              <TickOrCross value={cell.isOverlabel} />
            </Td>
            <Td>
              <TickOrCross value={cell.isSignedFor} />
            </Td>
            <Td>
              <TickOrCross value={cell.isTracked} />
            </Td>
          </Tr>
        ))
      )}
    </TBody>
  </FullWidthTable>
);
