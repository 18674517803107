import * as React from 'react';
import styled from 'styled-components/macro';
import { ExpandableSection } from '../../../../shared/ExpandableSection';
import { Panel } from '../../../../shared/Panel';
import { medium } from '../../../../styling/spacing';
import { ClientPortalWorksheetMailingHouseDetails } from '../ClientPortalWorksheetMailingHouseDetails';
import { ClientPortalSortedWorksheet } from './ClientPortalSortedWorksheet';
import { ClientPortalSortedWorksheetMailingHouseContactsTable } from './ClientPortalSortedWorksheetMailingHouseContactsTable';

type Props = {
  sortedWorksheet: ClientPortalSortedWorksheet;
};

export const ClientPortalSortedWorksheetMailingHouseDetails = ({ sortedWorksheet }: Props) => {
  const details = sortedWorksheet.mailingHouse;
  const contacts = sortedWorksheet.mailingHouseContacts;

  const selectedContacts = contacts.filter(
    contact =>
      contact.includeOnDataCommunication === 'LinkAndPassword' ||
      contact.includeOnDataCommunication === 'CarbonCopy' ||
      contact.includeOnLabelsCommunication ||
      contact.includeOnCollectionsCommunication ||
      contact.includeOnConsumablesCommunication,
  );

  const unselectedContacts = contacts.filter(
    contact =>
      contact.includeOnDataCommunication === 'Never' &&
      !contact.includeOnLabelsCommunication &&
      !contact.includeOnCollectionsCommunication &&
      !contact.includeOnConsumablesCommunication,
  );

  return (
    <Panel>
      <ClientPortalWorksheetMailingHouseDetails mailingHouse={details} />
      {sortedWorksheet.statusCode !== 'Forecast' && (
        <>
          <ClientPortalSortedWorksheetMailingHouseContactsTable contacts={selectedContacts} />
          {unselectedContacts.length > 0 && (
            <UnselectedContacts title="Unselected Contacts" initiallyExpanded={false}>
              <ClientPortalSortedWorksheetMailingHouseContactsTable contacts={unselectedContacts} />
            </UnselectedContacts>
          )}
        </>
      )}
    </Panel>
  );
};

const UnselectedContacts = styled(ExpandableSection)`
  margin-top: ${medium};
  margin-bottom: 0;
`;
