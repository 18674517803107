import * as React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as MobileIcon } from '../../images/icons/mobile.svg';
import { ReactComponent as PhoneIcon } from '../../images/icons/phone.svg';
import { medium, narrow } from '../../styling/spacing';
import { Icon } from '../Icon';

type Props = {
  landline: string;
  mobile: string;
};

export const ContactCardPhoneNumbers = ({ landline, mobile }: Props) => (
  <PhoneContacts>
    {landline && (
      <ContactMethod>
        <ContactIcon size="font">
          <PhoneIcon />
        </ContactIcon>
        {landline}
      </ContactMethod>
    )}
    {mobile && (
      <ContactMethod>
        <ContactIcon size="font">
          <MobileIcon />
        </ContactIcon>
        {mobile}
      </ContactMethod>
    )}
  </PhoneContacts>
);

export const ContactMethod = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${medium};
`;

export const PhoneContacts = styled.div`
  display: flex;
  flex-flow: row wrap;

  ${ContactMethod}:not(:last-child) {
    margin-right: ${medium};
  }
`;

const ContactIcon = styled(Icon)`
  margin-right: ${narrow};
`;
