import { inRange, range } from 'lodash';
import * as React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as ChevronLeft } from '../../images/icons/chevron-left-solid.svg';
import { ReactComponent as ChevronRight } from '../../images/icons/chevron-right-solid.svg';
import { ReactComponent as Ellipsis } from '../../images/icons/ellipsis-light.svg';
import { black, blue, lightGreyHoverAccent } from '../../styling/colours';
import { narrow } from '../../styling/spacing';
import { Icon } from '../Icon';
import { BorderlessHollowButton } from './Button';
import { IconButton } from './IconButton';

export type PaginationControlProps = {
  totalPages: number;
  currentPage: number;
  visiblePageNumberBuffer?: number;
  setCurrentPage: (currentPage: number) => void;
  disabled?: boolean;
  className?: string;
};

const defaultVisiblePageNumberBuffer = 2;

export const paginationControlTestId = 'pagination-control';
export const paginationCurrentPageIndicatorTestId = 'pagination-control-current-page';
export const paginationEllipsisTestId = 'pagination-control-ellipsis';

export const paginationBackNavTestId = 'pagination-control-back';
export const paginationForwardNavTestId = 'pagination-control-forward';
export const paginationPageNavTestId = (pageNumber: number) =>
  `pagination-control-page-nav-${pageNumber}`;

export const getVisiblePageNumbers = (
  firstPage: number,
  lastPage: number,
  currentPage: number,
  visiblePageNumberBuffer: number,
): Array<number> => {
  if (firstPage < 1 || lastPage < 1 || currentPage < 1) {
    throw new Error('First, last, and current page parameters cannot be less than one.');
  }

  if (visiblePageNumberBuffer < 0) {
    throw new Error('VisiblePageNumberBuffer cannot be less than zero.');
  }

  if (lastPage < firstPage) {
    throw new Error('Last page cannot be less than first page.');
  }

  if (!inRange(currentPage, firstPage, lastPage + 1)) {
    throw new Error('Current page must be between first and last page (inclusive).');
  }

  // 2 end pages, 2 ellipses, total visible page number buffer, and current page.
  const totalItemsToShow = 2 + 2 + visiblePageNumberBuffer * 2 + 1;
  if (lastPage <= totalItemsToShow) {
    return range(firstPage, lastPage + 1);
  }

  const shouldShowStartEllipsis = currentPage - visiblePageNumberBuffer > firstPage + 2;
  const shouldShowEndEllipsis = currentPage + visiblePageNumberBuffer < lastPage - 2;

  if (shouldShowStartEllipsis && shouldShowEndEllipsis) {
    return [
      firstPage,
      ...range(currentPage - visiblePageNumberBuffer, currentPage + visiblePageNumberBuffer + 1),
      lastPage,
    ];
  } else if (shouldShowStartEllipsis) {
    // Minus 2 to account for missing end ellipsis and current page.
    return [1, ...range(lastPage - visiblePageNumberBuffer * 2 - 2, lastPage + 1)];
  } else if (shouldShowEndEllipsis) {
    // Add 3 to account for missing start ellipsis, current page, and exclusive upper bound
    return [...range(1, firstPage + visiblePageNumberBuffer * 2 + 3), lastPage];
  }

  throw new Error('Calculation of page numbers does not match expected cases.');
};

export const PaginationControl = (props: PaginationControlProps) => {
  const { totalPages, currentPage, setCurrentPage, disabled } = props;

  if (totalPages < 0) {
    throw new Error("Total pages can't be negative.");
  }

  const firstPage = 1;
  const lastPage: number = Math.max(1, currentPage, totalPages);
  const visiblePageNumberBuffer: number =
    props.visiblePageNumberBuffer != null
      ? props.visiblePageNumberBuffer
      : defaultVisiblePageNumberBuffer;

  const visiblePageNumbers: Array<number> = getVisiblePageNumbers(
    firstPage,
    lastPage,
    currentPage,
    visiblePageNumberBuffer,
  );

  return (
    <PaginationControlWrapper className={props.className} data-testid={paginationControlTestId}>
      <BackForwardPageNavButton
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={disabled || currentPage === firstPage}
        data-testid={paginationBackNavTestId}
      >
        <ChevronLeft />
      </BackForwardPageNavButton>
      {visiblePageNumbers.map((pageNumber, index, array) => {
        const pageNavButton =
          pageNumber === currentPage ? (
            <CurrentPageIndicator
              key={pageNumber}
              disabled={disabled || false}
              data-testid={paginationCurrentPageIndicatorTestId}
            >
              {pageNumber}
            </CurrentPageIndicator>
          ) : (
            <PageNavButton
              key={pageNumber}
              onClick={() => setCurrentPage(pageNumber)}
              disabled={disabled}
              data-testid={paginationPageNavTestId(pageNumber)}
            >
              {pageNumber}
            </PageNavButton>
          );

        const nextPageNumber: number = array[index + 1];
        return !nextPageNumber || nextPageNumber === pageNumber + 1 ? (
          pageNavButton
        ) : (
          <React.Fragment key={pageNumber}>
            {pageNavButton}
            <PaginationDotsContainer data-testid={paginationEllipsisTestId}>
              <Icon colour={blue} opacity={disabled ? '0.1' : '0.4'} size="small">
                <Ellipsis />
              </Icon>
            </PaginationDotsContainer>
          </React.Fragment>
        );
      })}
      <BackForwardPageNavButton
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={disabled || currentPage === lastPage}
        data-testid={paginationForwardNavTestId}
      >
        <ChevronRight />
      </BackForwardPageNavButton>
    </PaginationControlWrapper>
  );
};

const paginationControlHeight = '44px';

const PaginationControlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PageNavButton = styled(BorderlessHollowButton)`
  height: ${paginationControlHeight};
  width: ${paginationControlHeight};
  padding: 0;
  margin: 0 ${narrow};
  border-radius: 50%;
`;

type CurrentPageIndicatorProps = {
  disabled?: boolean;
};

const CurrentPageIndicator = styled.div<CurrentPageIndicatorProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: 2px solid ${lightGreyHoverAccent};
  border-radius: 50%;
  color: ${black};
  opacity: ${props => (props.disabled ? 0.4 : null)};

  height: ${paginationControlHeight};
  width: ${paginationControlHeight};
  padding: 0;
  margin: 0 ${narrow};
`;

const PaginationDotsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: ${paginationControlHeight};
  width: ${paginationControlHeight};
  padding: 0;
  margin: 0 ${narrow};
`;

const BackForwardPageNavButton = styled(IconButton)`
  height: ${paginationControlHeight};
  width: ${paginationControlHeight};
  margin: 0 ${narrow};
  color: ${blue};
`;
