import { Field, FieldProps, getIn } from 'formik';
import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components/macro';
import { medium } from '../../../styling/spacing';
import { Label } from '../FieldLabel';
import {
  FieldContainer,
  FieldLabelContainer,
  standardVerticalFormFieldSpacing,
} from '../FormField';
import { FormOptionsContext } from '../FormOptionsContext';
import { Checkbox } from './Checkbox';

type CheckboxesFieldProps = {
  children: React.ReactNode;
  className?: string;
} & (
  | { label?: string; renderLabel?: undefined }
  | { label?: undefined; renderLabel?: () => React.ReactNode });

export const CheckboxesField = (props: CheckboxesFieldProps) => (
  <CheckboxesFieldContainer className={props.className}>
    <FieldLabelContainer>
      <Label>{props.label || (props.renderLabel && props.renderLabel())}</Label>
    </FieldLabelContainer>
    {props.children}
  </CheckboxesFieldContainer>
);

const CheckboxesFieldContainer = styled(FieldContainer)`
  margin-bottom: ${standardVerticalFormFieldSpacing};
`;

type CheckboxesFieldItemProps = {
  name: string;
  label?: string;
  disabled?: boolean;
  iAcceptINeedToHandleValidationErrorsMyself?: boolean;
  onChange?: (newValue: boolean) => void;
};

export const CheckboxesFieldItem = ({
  name,
  label,
  disabled,
  iAcceptINeedToHandleValidationErrorsMyself,
  onChange,
}: CheckboxesFieldItemProps) => {
  const onBlur = ({ form, field }: FieldProps) => () => {
    form.setFieldTouched(field.name);
  };

  const onClick = ({ form, field }: FieldProps) => (newValue: boolean) => {
    form.setFieldTouched(field.name);
    form.setFieldValue(field.name, newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const formOptions = useContext(FormOptionsContext);

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const touched = getIn(form.touched, name);
        const error = getIn(form.errors, name);
        const invalid = touched && error;
        const showWarning = formOptions.showWarningsInitially && error;

        if (
          error &&
          !iAcceptINeedToHandleValidationErrorsMyself &&
          process.env.NODE_ENV !== 'production'
        ) {
          throw new Error(
            `Checkbox field ${name} has a validation error: ${error}.
              Validation is not currently supported in the CheckboxesField component,
              as we do not have a way to show errors`,
          );
        }

        return (
          <CheckboxContainer>
            <Checkbox
              name={field.name}
              label={label}
              value={field.value}
              onChange={onClick({ field, form })}
              onBlur={onBlur({ field, form })}
              invalid={invalid}
              showWarning={showWarning}
              disabled={form.isSubmitting || disabled}
            />
          </CheckboxContainer>
        );
      }}
    </Field>
  );
};

const CheckboxContainer = styled.div`
  :not(:last-child) {
    margin-right: ${medium};
  }
`;
