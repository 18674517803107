import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components/macro';
import { ClientPortalErrorBoundary } from '../../shared/ErrorBoundary';
import { regularWeight } from '../../styling/fonts';
import {
  clientPortalSmallHorizontalPadding,
  pageHorizontalPadding,
  pageHorizontalPaddingBreakpoint,
  wide,
  wider,
} from '../../styling/spacing';
import { useWindowWidth } from '../../utils/useWindowWidth';
import { AuthenticationContext } from '../authentication/authenticationContext';
import { DropdownNavList } from '../layout/Header/DropdownNavList';
import { HeaderContainer, Logo, LogoLink } from '../layout/Header/Header';
import { NavLink, navLinkFontSize, StyledNavLink } from '../layout/Header/NavLink';
import logoUrl from '../layout/onepostLogo.svg';
import { ClientPortalUserContext } from './authentication/clientPortalUser';
import {
  clientPortalChangePasswordUrl,
  clientPortalJobsUrl,
  clientPortalMailingBriefUrl,
} from './clientPortalUrls';
import { getClientPortalHomePageUrl } from './RedirectToClientPortalHomePage';

type Props = {
  children?: React.ReactNode;
};

export const ClientPortalLayout = (props: Props) => {
  return (
    <>
      <Header />
      <PageContent>
        <ClientPortalErrorBoundary>{props.children}</ClientPortalErrorBoundary>
      </PageContent>
    </>
  );
};

const PageContent = styled.div`
  margin-top: ${wide};
  padding: 0 ${pageHorizontalPadding} ${wider};

  @media (max-width: ${pageHorizontalPaddingBreakpoint}) {
    padding: 0 ${clientPortalSmallHorizontalPadding} ${wider};
  }
`;

const Header = () => {
  const user = useContext(ClientPortalUserContext);
  const windowWidth = useWindowWidth();

  const navItems = [
    <NavLink key="jobs" to={clientPortalJobsUrl}>
      Jobs
    </NavLink>,
    <NavLink key="mailing-brief" to={clientPortalMailingBriefUrl}>
      Submit Mailing Brief
    </NavLink>,
    <UserName key="user-name" to={clientPortalChangePasswordUrl}>
      {user.name}
    </UserName>,
    <LogOutLink key="log-out" />,
  ].filter(navItem => navItem != null);

  return (
    <ClientPortalHeaderContainer>
      <LogoLink to={getClientPortalHomePageUrl(user)}>
        <Logo src={logoUrl} />
      </LogoLink>
      <HeaderItems>
        {windowWidth > 1000 ? <>{navItems}</> : <DropdownNavList label="Menu" items={navItems} />}
      </HeaderItems>
    </ClientPortalHeaderContainer>
  );
};

const ClientPortalHeaderContainer = styled(HeaderContainer)`
  @media (max-width: ${pageHorizontalPaddingBreakpoint}) {
    padding: 0 ${clientPortalSmallHorizontalPadding};
  }
`;

const HeaderItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UserName = styled(StyledNavLink)`
  margin-right: ${wide};
`;

const LogOutLink = () => (
  <AuthenticationContext.Consumer>
    {({ logout }) => (
      <LogOutNavLink as="a" onClick={logout}>
        Log Out
      </LogOutNavLink>
    )}
  </AuthenticationContext.Consumer>
);

const LogOutNavLink = styled(StyledNavLink)`
  font-weight: ${regularWeight};
  font-size: ${navLinkFontSize};
`;
