import { Omit } from '@reach/router';
import * as request from 'superagent';
import { ProgressEvent, SuperAgentRequest } from 'superagent';

export type HttpMethod = 'delete' | 'get' | 'post' | 'put';
export type ApiResponse<T> = Omit<request.Response, 'body'> & { body: T };

export type AbortablePromise<T> = Promise<T> & { abort: () => void };
export interface ProgressTrackingPromise<T> extends Promise<T> {
  on(name: 'progress', handler: (event: ProgressEvent) => void): this;
}

export type ApiRequest<T> = SuperAgentRequest & Promise<ApiResponse<T>>;
export type AuthenticatedApiRequest<T> = (
  accessToken: string | null,
) => SuperAgentRequest & Promise<ApiResponse<T>>;

const oneMinuteInMilliseconds = 60 * 1000;
const requestTimeout = oneMinuteInMilliseconds;
export const fileUploadTimeout = 30 * oneMinuteInMilliseconds; // Enough to upload a 500MB file on slow internet
export const sendInvoicesTimeout = 5 * oneMinuteInMilliseconds;
export const downloadFinanceActivityReportTimeout = 5 * oneMinuteInMilliseconds;
export const downloadUnsortedCollectionsReportTimeout = 5 * oneMinuteInMilliseconds;

export const from = (url: string, method: HttpMethod): SuperAgentRequest =>
  request[method](`/api/${url}`).timeout(requestTimeout);

export const fetchJson = <T>(req: SuperAgentRequest): ApiRequest<T> =>
  req
    .set('Accept', 'application/json')
    .set('Cache-Control', 'no-cache')
    .set('Pragma', 'no-cache');

// We use a type of ArrayBuffer here instead of Blob, since this allows us to read the content
// as a JSON string in the case of an error
export const fetchFile = (req: SuperAgentRequest): ApiRequest<ArrayBuffer> =>
  req
    .set('Cache-Control', 'no-cache')
    .set('Pragma', 'no-cache')
    .responseType('arraybuffer');

export const withAuthorizationHeader = <T>(accessToken: string) => (
  req: SuperAgentRequest,
): ApiRequest<T> => req.set('Authorization', `Bearer ${accessToken}`);

export const fetchSecureJson = <T>(req: SuperAgentRequest): AuthenticatedApiRequest<T> => (
  accessToken: string | null,
): ApiRequest<T> =>
  fetchJson(accessToken != null ? withAuthorizationHeader(accessToken)(req) : req);

export const fetchSecureFile = (req: SuperAgentRequest): AuthenticatedApiRequest<ArrayBuffer> => (
  accessToken: string | null,
): ApiRequest<ArrayBuffer> =>
  fetchFile(accessToken != null ? withAuthorizationHeader(accessToken)(req) : req);

export const withJsonBody = <T extends string | object>(body: T) => (
  req: SuperAgentRequest,
): ApiRequest<T> => req.set('Content-Type', 'application/json').send(body);

export const withFileBody = <T extends string | object>(file: File, name: string = 'file') => (
  req: SuperAgentRequest,
): ApiRequest<T> => {
  const body = new FormData();
  body.append(name, file);
  return req.send(body);
};

export const withTimeout = <T>(timeout: number) => (req: SuperAgentRequest): ApiRequest<T> =>
  req.timeout(timeout);

export type HttpError<T> = Error & {
  status: number;
  response: ApiResponse<T>;
};

export type ApiErrorResponse = {
  message: string;
  userVisibleMessage: string | null;
};

export type ApiError = HttpError<ApiErrorResponse>;

const isHttpError = (error: Error): error is HttpError<unknown> =>
  (error as HttpError<unknown>).status != null && (error as HttpError<unknown>).response != null;

const isApiJsonError = (error: Error): error is ApiError =>
  isHttpError(error) &&
  error.response.body != null &&
  (error.response.body as ApiErrorResponse).message != null;

const isApiFileDownloadError = (error: Error): error is HttpError<ArrayBuffer> =>
  isHttpError(error) && error.response.body != null && error.response.body instanceof ArrayBuffer;

const parseFileDownloadError = (error: HttpError<ArrayBuffer>): ApiErrorResponse =>
  JSON.parse(new TextDecoder().decode(error.response.body));

export const getUserVisibleMessage = (error: Error): string | null =>
  isApiJsonError(error)
    ? error.response.body.userVisibleMessage || null
    : isApiFileDownloadError(error)
    ? parseFileDownloadError(error).userVisibleMessage || null
    : null;

export const isRequestAbortedError = (error: Error): boolean => error.message === 'Aborted';
