import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as CaretDown } from '../../../images/icons/caret-down-solid.svg';
import { ReactComponent as CaretUp } from '../../../images/icons/caret-up-solid.svg';
import { white } from '../../../styling/colours';
import { narrow } from '../../../styling/spacing';
import { StyledNavLink } from './NavLink';

type DropdownNavListProps = { label: string; items: Array<React.ReactNode> };

export const DropdownNavList = ({ label, items }: DropdownNavListProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const close = () => setIsOpen(false);
      window.addEventListener('click', close);
      return () => window.removeEventListener('click', close);
    }
    return;
  });

  return (
    <DropdownContainer>
      <DropdownToggle as="div" onClick={() => setIsOpen(value => !value)}>
        <DropdownLabelText>{label}</DropdownLabelText>
        {isOpen ? <CaretUp /> : <CaretDown />}
      </DropdownToggle>
      {isOpen && (
        <ExpandedDropdownList>
          {items.map((item, index) => (
            <NavItemContainer key={index}>{item}</NavItemContainer>
          ))}
        </ExpandedDropdownList>
      )}
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownToggle = styled(StyledNavLink)`
  display: flex;
  align-items: center;
  margin-right: 0;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 0;
  }

  svg {
    height: 25px;
  }
`;

const DropdownLabelText = styled.span`
  margin-right: ${narrow};
`;

const ExpandedDropdownList = styled.div`
  position: absolute;
  right: 0;
  margin-top: 5px;
  z-index: 10;
  min-width: 200px;
  max-width: 80vw;
  padding: 0 ${narrow};
  background: ${white};
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
`;

const NavItemContainer = styled.div`
  padding: ${narrow};
`;
