import * as React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../../../shared/buttons/Button';
import { medium } from '../../../styling/spacing';
import { navigate } from '../../../utils/routing';
import {
  clientPortalConvertForecastJobToLiveUrl,
  clientPortalMailingBriefJobUrl,
} from '../clientPortalUrls';
import { ClientPortalJobResponse } from './ClientPortalViewJob';

type Props = {
  job: ClientPortalJobResponse;
};

export const ClientPortalJobHeader = ({ job }: Props) => (
  <ButtonContainer>
    <PrimaryButton onClick={() => navigate(clientPortalMailingBriefJobUrl(job.jobId))}>
      Edit
    </PrimaryButton>
    <PrimaryButton onClick={() => navigate(clientPortalConvertForecastJobToLiveUrl(job.jobId))}>
      Convert to Live
    </PrimaryButton>
  </ButtonContainer>
);

export const ButtonContainer = styled.div`
  *:not(:last-child) {
    margin-right: ${medium};
  }
`;
