import { Formik } from 'formik';
import * as queryString from 'query-string';
import * as React from 'react';
import { useState } from 'react';
import { Id } from '../../../models/id';
import {
  AttachmentDetails,
  AttachmentDownloadResponse,
} from '../../../shared/files/AttachmentDetails';
import { Form } from '../../../shared/form/Form';
import { FormErrorBox } from '../../../shared/form/FormErrorBox';
import { FormSubmit } from '../../../shared/form/FormSubmit';
import { PasswordField } from '../../../shared/form/inputs/PasswordField';
import {
  ApiRequestProps,
  withApiRequest,
} from '../../../shared/higher-order-components/withApiRequest';
import { SectionHeader } from '../../../shared/layout/Headers';
import { AuthenticatedApiRequest, fetchJson, fetchSecureJson, from } from '../../../utils/api';
import { assertNotNull } from '../../../utils/assertNotNull';

type OwnProps = { accessCode: string };

type ListAttachmentsRequestProps = ApiRequestProps<
  ListAttachmentsQuery,
  ListAttachmentsResponse,
  'listAttachmentsRequest'
>;

type Props = OwnProps & ListAttachmentsRequestProps;

export const MailingHouseDataAttachmentsDownloadComponent = (props: Props) => {
  const [worksheetReference, setWorksheetReference] = useState<number | null>(null);
  const [password, setPassword] = useState<string | null>(null);

  return (
    <>
      {!props.listAttachmentsRequest.response ? (
        <>
          <SectionHeader>
            Please enter the password sent to you in the email separate to the link email
          </SectionHeader>
          <Formik<MailingHouseDataAttachmentsPasswordValidationModel>
            initialValues={{
              password: '',
            }}
            onSubmit={async (formModel, { setSubmitting, setStatus }) => {
              const result = await props.listAttachmentsRequest.sendRequest({
                accessCode: assertNotNull(props.accessCode),
                password: formModel.password,
              });

              if (result.success) {
                setPassword(formModel.password);
                setWorksheetReference(result.body.worksheetReference);
              }
              setSubmitting(false);
            }}
          >
            {formikProps => (
              <Form {...formikProps}>
                <PasswordField label="Password" name="password" />
                <FormSubmit
                  errors={formikProps.errors}
                  isSubmitting={formikProps.isSubmitting}
                  submitButtonText="Submit"
                />
                {props.listAttachmentsRequest && props.listAttachmentsRequest.error && (
                  <FormErrorBox error={props.listAttachmentsRequest.error} />
                )}
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <>
          <SectionHeader>Data Files for Worksheet {worksheetReference}</SectionHeader>
          {assertNotNull(props.listAttachmentsRequest.response.attachments).map(attachment => (
            <AttachmentDetails
              key={attachment.worksheetAttachmentId}
              fileName={attachment.fileName}
              sizeInBytes={attachment.sizeInBytes}
              unauthenticated={true}
              fetchDownloadUrl={() =>
                getDownloadLink({
                  accessCode: assertNotNull(props.accessCode),
                  worksheetAttachmentId: attachment.worksheetAttachmentId,
                  password: assertNotNull(password),
                })
              }
            />
          ))}
          {assertNotNull(props.listAttachmentsRequest.response.attachments).length === 0 && (
            <div>There are no data files for this worksheet.</div>
          )}
        </>
      )}
    </>
  );
};

type MailingHouseDataAttachmentsPasswordValidationModel = {
  password: string;
};

type ListAttachmentsQuery = {
  accessCode: string;
  password: string;
};

export type ListAttachmentsResponse = {
  worksheetReference: number;
  attachments: listAttachments;
};

type listAttachments = Array<{ worksheetAttachmentId: Id; fileName: string; sizeInBytes: number }>;

const fetchAttachments = ({ accessCode, password }: ListAttachmentsQuery) =>
  fetchSecureJson<ListAttachmentsResponse>(
    from(
      `client-portal/mailing-house-data-download/?${queryString.stringify({
        accessCode,
        password,
      })}`,
      'get',
    ),
  );

const listAttachmentsRequestEnhancer = withApiRequest<
  OwnProps,
  ListAttachmentsQuery,
  ListAttachmentsResponse,
  'listAttachmentsRequest'
>(props => fetchAttachments, 'listAttachmentsRequest', () => ({ unauthenticatedRequest: true }));

type GetDownloadLinkQuery = { accessCode: string; worksheetAttachmentId: Id; password: string };

const getDownloadLink = ({
  accessCode,
  worksheetAttachmentId,
  password,
}: GetDownloadLinkQuery): AuthenticatedApiRequest<AttachmentDownloadResponse> => () =>
  fetchJson<AttachmentDownloadResponse>(
    from(
      `client-portal/mailing-house-data-download/${worksheetAttachmentId}/?${queryString.stringify({
        accessCode,
        password,
      })}`,
      'get',
    ),
  );

export const MailingHouseDataAttachmentsDownload = listAttachmentsRequestEnhancer(
  MailingHouseDataAttachmentsDownloadComponent,
);
