import * as React from 'react';
import { formatDateStamp } from '../../../../models/dateStamp';
import { Dd, DdVerticallyCentered, Dl, DlItem, Dt } from '../../../../shared/DefinitionList';
import { ExpandableSection } from '../../../../shared/ExpandableSection';
import { SectionHeader } from '../../../../shared/layout/Headers';
import { Table, TBody, Td, Tr } from '../../../../shared/table/Table';
import { TickOrCross } from '../../../../shared/TickOrCross';
import {
  unsortedCollectionPresentationDisplayNames,
  unsortedCollectionTypeDisplayNames,
  unsortedProcessingCentreDisplayNames,
  unsortedProcessingDateOptionDisplayNames,
} from '../../../worksheets/unsorted/unsortedWorksheet';
import {
  WorksheetDetailsSection,
  WorksheetPrimaryDetailsPanel,
  WorksheetPrimaryDetailsSection,
  WorksheetPrimaryDetailsSectionLeft,
  WorksheetPrimaryDetailsSectionRight,
} from '../../../worksheets/WorksheetDetails';
import { ClientPortalWorksheetDetailsHeader } from '../ClientPortalWorksheetDetailsHeader';
import { ClientPortalUnsortedCellCollectionsTable } from './ClientPortalUnsortedCellCollectionsTable';
import { ClientPortalUnsortedWorksheet } from './ClientPortalUnsortedWorksheet';
import { ClientPortalUnsortedWorksheetContactsTable } from './ClientPortalUnsortedWorksheetContactsTable';
import { ClientPortalUnsortedWorksheetMailingHouseDetails } from './ClientPortalUnsortedWorksheetMailingHouseDetails';
import { ClientPortalViewUnsortedCellsTable } from './ClientPortalViewUnsortedCellsTable';

type Props = {
  unsortedWorksheet: ClientPortalUnsortedWorksheet;
};

export const ClientPortalUnsortedWorksheetDetails = ({ unsortedWorksheet }: Props) => (
  <>
    <ClientPortalWorksheetDetailsHeader worksheet={unsortedWorksheet} />

    <WorksheetPrimaryDetailsSection>
      <WorksheetPrimaryDetailsSectionLeft>
        <WorksheetPrimaryDetailsPanel>
          <Dl>
            <DlItem horizontal={true}>
              <Dt>Collect From Customer?</Dt>
              <DdVerticallyCentered>
                <TickOrCross value={unsortedWorksheet.collectFromCustomer} centered={true} />
              </DdVerticallyCentered>
            </DlItem>

            <DlItem horizontal={true}>
              <Dt>Collection Presentation</Dt>
              <Dd>
                {
                  unsortedCollectionPresentationDisplayNames[
                    unsortedWorksheet.collectionPresentation
                  ]
                }
              </Dd>
            </DlItem>

            <DlItem horizontal={true}>
              <Dt>Collection</Dt>
              <Dd>{unsortedCollectionTypeDisplayNames[unsortedWorksheet.collectionType]}</Dd>
            </DlItem>

            {unsortedWorksheet.collectionType === 'ExternalTransport' && (
              <DlItem horizontal={true}>
                <Dt>External Transport Supplier</Dt>
                <Dd>
                  {unsortedWorksheet.externalTransportSupplier &&
                    `${unsortedWorksheet.externalTransportSupplier.code} - ${unsortedWorksheet.externalTransportSupplier.name}`}
                </Dd>
              </DlItem>
            )}

            <DlItem horizontal={true}>
              <Dt>Processing Date</Dt>
              <Dd>
                {unsortedProcessingDateOptionDisplayNames[unsortedWorksheet.processingDateOption]}
              </Dd>
            </DlItem>

            <DlItem horizontal={true}>
              <Dt>Processing Centre</Dt>
              <Dd>{unsortedProcessingCentreDisplayNames[unsortedWorksheet.processingCentre]}</Dd>
            </DlItem>

            <DlItem horizontal={false}>
              <Dt>Custom Collection Email Content</Dt>
              <Dd multiline={true}>{unsortedWorksheet.customCollectionEmailContent}</Dd>
            </DlItem>
          </Dl>
        </WorksheetPrimaryDetailsPanel>
      </WorksheetPrimaryDetailsSectionLeft>

      <WorksheetPrimaryDetailsSectionRight withPaddingTop={false}>
        <Table>
          <TBody>
            <Tr>
              <Td>
                {unsortedWorksheet.collectionSchedule === 'Daily'
                  ? 'First Collection'
                  : 'Collection Date'}
              </Td>
              <Td>{formatDateStamp(unsortedWorksheet.firstCollectionDate)}</Td>
            </Tr>
            {unsortedWorksheet.collectionSchedule === 'Daily' && (
              <Tr>
                <Td>Last Collection</Td>
                <Td>{formatDateStamp(unsortedWorksheet.lastCollectionDate)}</Td>
              </Tr>
            )}
          </TBody>
        </Table>
      </WorksheetPrimaryDetailsSectionRight>
    </WorksheetPrimaryDetailsSection>

    <WorksheetDetailsSection>
      <SectionHeader>Cells</SectionHeader>
      <ClientPortalViewUnsortedCellsTable
        cells={unsortedWorksheet.cells}
        collectionSchedule={unsortedWorksheet.collectionSchedule}
      />
    </WorksheetDetailsSection>

    {unsortedWorksheet.collectionSchedule === 'Daily' && (
      <ExpandableSection title="Volumes" initiallyExpanded={false}>
        <ClientPortalUnsortedCellCollectionsTable worksheet={unsortedWorksheet} />
      </ExpandableSection>
    )}

    {unsortedWorksheet.mailingHouse && (
      <WorksheetDetailsSection>
        <SectionHeader>Mailing House</SectionHeader>
        <ClientPortalUnsortedWorksheetMailingHouseDetails unsortedWorksheet={unsortedWorksheet} />
      </WorksheetDetailsSection>
    )}

    <WorksheetDetailsSection>
      <SectionHeader>Customer Contacts</SectionHeader>
      <ClientPortalUnsortedWorksheetContactsTable contacts={unsortedWorksheet.customerContacts} />
    </WorksheetDetailsSection>
  </>
);
