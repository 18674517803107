import { includes } from 'lodash';
import {
  ValidationResultWithTopLevelValidation,
  Validator,
  ValidatorWithTopLevelValidator,
} from './validation';

export const required = <T>(
  message: string = 'Please enter a value',
): Validator<T | null | undefined> => (value: T | null | undefined): string | null =>
  value == null ? message : null;

export const requiredSelect = <T>(message: string = 'Please select a value') =>
  required<T>(message);

export const noValidate = () => null;

export const oneOf = <T>(
  validOptions: Array<T>,
  message: string,
): Validator<T | null | undefined> => (value: T | null | undefined): string | null =>
  !includes(validOptions, value) ? message : null;

export const validateIfModified = <T>(
  initialValue: T,
  validator: Validator<T | null | undefined>,
): Validator<T | null | undefined> => (value: T | null | undefined) =>
  value === initialValue ? null : validator(value);

export const validateIfPresent = <T>(
  validator: Validator<T> | ValidatorWithTopLevelValidator<T>,
): Validator<T | null | undefined> => (
  value: T | null | undefined,
): ValidationResultWithTopLevelValidation<T> | null => (value != null ? validator(value) : null);
