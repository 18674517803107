export const red = '#C91139';
export const redHoverAccent = '#AF0F32';

export const green = '#1b7136';

export const blue = '#194492';
export const blueHoverAccent = '#1A6AFF';

export const black = '#00050F';

export const white = '#FFFFFF';

export const fontGrey = '#464646';
export const darkGrey = '#8B8C8D';
export const greyHoverAccent = '#464646';
export const lightGrey = '#EAEAEA';
export const lightGreyHoverAccent = '#CACACA';
export const greyBorder = '#d7d7d7';

export const successText = green;
export const successAccent = '#E1FAEA';

export const warningText = '#a05b00';
export const warningAccent = '#FFF3D9';

export const alertText = '#b93e28';
export const alertAccent = '#F7E4E1';

export const formInvalid = alertText;
export const formInvalidAccent = '#F4DAD6';

export const formSuccess = successText;
export const formSuccessAccent = '#D0E6D8';

export const categoryRed = red;
export const categoryRedLight = '#fde8ec';
export const categoryBlue = blue;
export const categoryBlueLight = '#e9f0fb';
export const categoryGreen = successText;
export const categoryGreenLight = '#e9fbf0';
export const categoryYellow = warningText;
export const categoryYellowLight = '#fff7e6';
export const categoryGrey = fontGrey;
export const categoryGreyLight = '#f2f2f2';
export const categoryBlack = black;
export const categoryBlackLight = '#f2f2f2';
export const categoryPurple = '#8735a2';
export const categoryPurpleLight = '#eadeef';
export const categoryOrange = '#ff7500';
export const categoryOrangeLight = '#ffebda';
export const categoryCyan = '#00b2a5';
export const categoryCyanLight = '#e2f8f1';
export const categoryPink = '#d000c8';
export const categoryPinkLight = '#ffe7fa';
export const categoryTeal = '#00b2a5';
export const categoryTealLight = '#b6f1f1';

export const infoText = categoryBlue;
export const infoAccent = categoryBlueLight;

export const tdgLightGrey = '#EAEAEA';
export const tdgDarkBlue = '#1F2C3E';
export const tdgBlueHoverAccent = '#2e4460';
export const tdgGreen = '#628036';
