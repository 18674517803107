import * as React from 'react';
import { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { ReactComponent as CaretDown } from '../images/icons/caret-down-solid.svg';
import { narrow } from '../styling/spacing';
import { ItemHeader, SectionHeader } from './layout/Headers';
import { Section } from './layout/Section';

type Props = {
  title: string;
  children?: React.ReactNode;
  initiallyExpanded?: boolean;
  className?: string;
};

export const ExpandableSection = ({ title, children, initiallyExpanded, className }: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(
    initiallyExpanded != null && initiallyExpanded,
  );

  return (
    <Section className={className}>
      <SectionTitle onClick={() => setIsExpanded(!isExpanded)}>
        <Toggle expanded={isExpanded} />
        <div>{title}</div>
      </SectionTitle>
      <div>{isExpanded ? children : null}</div>
    </Section>
  );
};

export const ExpandableItem = ({ title, children, initiallyExpanded, className }: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(
    initiallyExpanded != null && initiallyExpanded,
  );

  return (
    <Section className={className}>
      <ItemTitle onClick={() => setIsExpanded(!isExpanded)}>
        <Toggle expanded={isExpanded} />
        <div>{title}</div>
      </ItemTitle>
      <div>{isExpanded ? children : null}</div>
    </Section>
  );
};

const Toggle = styled(({ expanded, ...props }) => <CaretDown {...props} />)<{ expanded: boolean }>`
  margin-right: ${narrow};
  height: 20px;
  transform: rotate(${props => (props.expanded ? 0 : -90)}deg);
  transition: transform 0.2s ease;
`;

const titleStyles = css`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
`;

const SectionTitle = styled(SectionHeader)`
  ${titleStyles};
`;

const ItemTitle = styled(ItemHeader)`
  ${titleStyles};
`;
