import { UserManager } from 'oidc-client';
import { useEffect } from 'react';
import { RouteComponentProps } from '../../../utils/RouteComponentProps';
import { authenticationConfig } from './ClientPortalAuthentication';

export const ClientPortalSilentTokenRefresh = (props: RouteComponentProps) => {
  useEffect(() => {
    console.log('Handling silent token refresh callback'); // tslint:disable-line:no-console
    const userManager = new UserManager(authenticationConfig);
    userManager.signinSilentCallback();
  });

  return null;
};
