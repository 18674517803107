import { DateStamp } from '../../../../models/dateStamp';
import { Id } from '../../../../models/id';

export const unsortedCellReferenceMaxLength = 200;

export type UnsortedCell = {
  unsortedCellId: Id;
  displayOrder: number;
  cellReference: string;
  service: UnsortedCellServiceType;
  format: UnsortedCellFormat;
  processingType: UnsortedCellProcessingType;
  variant: UnsortedCellVariant;
  quantity: number;
  itemWeightInGrams: number;
  isPpiPrinted: boolean;
  isReturnAddressPrinting: boolean;
  isOverlabel: boolean;
  isSignedFor: boolean;
  isTracked: boolean;
  collections: { [collectionDate in DateStamp]: UnsortedCellCollection };
};

export type UnsortedCellCollection = {
  collectionDate: DateStamp;
  quantity: number | null;
};

export type UnsortedCellServiceType = 'Ocr' | 'Mailmark' | 'Standard' | 'Overseas';
export const unsortedCellServiceTypeDisplayNames: {
  [schedule in UnsortedCellServiceType]: string;
} = {
  Ocr: 'Ocr',
  Mailmark: 'Mailmark',
  Standard: 'Standard',
  Overseas: 'Overseas',
};
export const unsortedCellServiceTypes = Object.keys(unsortedCellServiceTypeDisplayNames) as Array<
  UnsortedCellServiceType
>;

export type UnsortedCellFormat = 'Letter' | 'LargeLetter' | 'Packet' | 'Parcel';
export const unsortedCellFormatDisplayNames: { [schedule in UnsortedCellFormat]: string } = {
  Letter: 'Letter',
  LargeLetter: 'Large Letter',
  Packet: 'Packet',
  Parcel: 'Parcel',
};
export const unsortedCellFormats = Object.keys(unsortedCellFormatDisplayNames) as Array<
  UnsortedCellFormat
>;

export type UnsortedCellProcessingType = 'Machine' | 'Manual';
export const unsortedCellProcessingTypeDisplayNames: {
  [schedule in UnsortedCellProcessingType]: string;
} = {
  Machine: 'Machine',
  Manual: 'Manual',
};
export const unsortedCellProcessingTypes = Object.keys(
  unsortedCellProcessingTypeDisplayNames,
) as Array<UnsortedCellProcessingType>;

export type UnsortedCellVariant = 'GeneralMail' | 'BusinessMail' | 'AdvertisingMail';
export const unsortedCellVariantDisplayNames: { [schedule in UnsortedCellVariant]: string } = {
  GeneralMail: 'General Mail',
  BusinessMail: 'Business Mail',
  AdvertisingMail: 'Advertising Mail',
};
export const unsortedCellVariants = Object.keys(unsortedCellVariantDisplayNames) as Array<
  UnsortedCellVariant
>;

export type UnsortedCellValidationMetadata = {
  serviceTypes: { [format in UnsortedCellServiceType]: UnsortedCellServiceTypeFormats };
};

export type UnsortedCellServiceTypeFormats = {
  serviceType: UnsortedCellServiceType;
  formatRules: { [format in UnsortedCellFormat]?: UnsortedCellServiceFormatRules };
};

export type UnsortedCellServiceFormatRules = {
  allowedProcessingTypes: Array<UnsortedCellProcessingType>;
  allowedVariants: Array<UnsortedCellVariant>;
  minWeightInKg: number;
  maxWeightInKg: number;
  isPpiPrintedAvailable: boolean;
  isReturnAddressPrintingAvailable: boolean;
  isOverlabelAvailable: boolean;
  isSignedForAvailable: boolean;
  isTrackedAvailable: boolean;
};
