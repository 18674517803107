import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { regularWeight } from '../styling/fonts';
import { medium, narrow } from '../styling/spacing';

export const Dl = styled.dl`
  margin: 0;
`;

export const DlItem = styled.div<{ horizontal?: boolean; isChildItem?: boolean }>`
  &:not(:last-child) {
    margin-bottom: ${props => (props.isChildItem ? narrow : medium)};
  }

  ${props =>
    props.horizontal &&
    css`
      display: flex;
      flex-flow: row wrap;

      dt {
        margin-right: ${narrow};
      }

      dd:not(:last-child) {
        margin-right: ${medium};
      }
    `};
`;

export const Dt = (props: { className?: string; children: React.ReactNode }) => (
  <StyledDt className={props.className}>{props.children}:</StyledDt>
);

const StyledDt = styled.dt`
  font-weight: bold;
`;

export const SubDt = styled(Dt)`
  font-weight: ${regularWeight};
`;

type DdProps = {
  children?: React.ReactNode;
  multiline?: boolean;
  className?: string;
};

export const Dd = (props: DdProps) => (
  <StyledDd multiline={props.multiline} className={props.className}>
    {!props.children ? <span>&mdash;</span> : props.children}
  </StyledDd>
);

const StyledDd = styled.dd<{
  multiline?: boolean;
}>`
  padding: 0;
  margin: 0;
  white-space: ${props => (!props.multiline ? 'normal' : 'pre-wrap')};
  min-width: 0; // required so that overflow-wrap works within flex container
  overflow-wrap: break-word;
`;

export const DdVerticallyCentered = styled(Dd)`
  display: flex;
  align-items: center;
`;
