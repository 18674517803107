import { Id } from '../../models/id';
import { SelectOptions } from '../../shared/form/inputs/Select';
import { AuthenticatedApiRequest, fetchSecureJson, from } from '../../utils/api';
import { SelectableUser } from '../authentication/user';
import { IncludeOnDataCommunicationCode } from '../mailing-houses/contacts/mailingHouseContact';
import { ConsumableLimitationCode } from '../mailing-houses/mailingHouse';

export type DataForJobForm = {
  mailingHousesById: { [mailingHouseId: number]: MailingHouseForJobForm };
  internationalUsers: Array<SelectableUser>;
  suppliers: Array<SupplierForJobForm>;
};

export type MailingHouseForJobForm = {
  mailingHouseId: Id;
  referenceNumber: number;
  name: string;
  notes: string;
  contactsById: { [mailingHouseContactId: number]: MailingHouseContactForJobForm };
  consumableLimitationCode: ConsumableLimitationCode;
};

export type MailingHouseContactForJobForm = {
  mailingHouseContactId: Id;
  name: string;
  jobTitle: string;
  includeOnDataCommunication: IncludeOnDataCommunicationCode;
  includeOnLabelsCommunication: boolean;
  includeOnCollectionsCommunication: boolean;
  includeOnConsumablesCommunication: boolean;
};

export type SupplierForJobForm = {
  supplierId: Id;
  name: string;
  code: string;
};

export const securedMailPostageSupplierCode = 'SEC012';

export const fetchDataForJobForm = (): AuthenticatedApiRequest<DataForJobForm> =>
  fetchSecureJson(from('jobs/dataForJobForm', 'get'));

export const getSupplierOptions = (suppliers: Array<SupplierForJobForm>): SelectOptions<Id> =>
  suppliers.map(supplier => ({
    text: supplier.code + ' - ' + supplier.name,
    value: supplier.supplierId,
  }));
