import * as React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { blue } from '../styling/colours';

export const loadingSpinnerTestId = 'loading-spinner';
export const loadingSpinnerHiddenId = 'loading-spinner-hidden';

type LoadingSpinnerProps = {
  color?: string;
  className?: string;
  visible?: boolean;
};

export const LoadingSpinner = (props: LoadingSpinnerProps) => (
  <CubeContainer
    data-testid={
      props.visible == null || props.visible ? loadingSpinnerTestId : loadingSpinnerHiddenId
    }
    className={props.className}
  >
    <Cube initialRotation={0} delay={0} color={props.color} />
    <Cube initialRotation={90} delay={0.3} color={props.color} />
    <Cube initialRotation={270} delay={0.9} color={props.color} />
    <Cube initialRotation={180} delay={0.6} color={props.color} />
  </CubeContainer>
);

const cubeAnimation = keyframes`
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  }
`;

const CubeContainer = styled.div<LoadingSpinnerProps>`
  margin: 20px;
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(45deg);
  opacity: ${props => (props.visible == null || props.visible ? 1 : 0)};
`;

type CubeProps = { initialRotation: number; delay: number; color?: string };

const Cube = styled.div<CubeProps>`
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1) rotate(${props => props.initialRotation}deg);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.color || blue};
    animation: ${cubeAnimation} 2.4s infinite linear both;
    transform-origin: 100% 100%;
    animation-delay: ${props => props.delay}s;
  }
`;
