import { getSelectOptions } from '../shared/form/inputs/Select';

export type CarrierCode = 'SecuredMail' | 'Cms';

export const carrierCodes: Array<CarrierCode> = ['SecuredMail', 'Cms'];

export const carrierDisplayNamesByCode: { [code in CarrierCode]: string } = {
  SecuredMail: 'Secured Mail',
  Cms: 'CMS',
};

export const carrierSelectOptions = getSelectOptions(carrierCodes, carrierDisplayNamesByCode);

export const carrierSupportsUcidsAndScids = (carrier: CarrierCode): boolean => carrier !== 'Cms';
