export const narrowPixels = 8;
export const narrow = `${narrowPixels}px`;
export const mediumPixels = 14;
export const medium = `${mediumPixels}px`;
export const widePixels = 24;
export const wide = `${widePixels}px`;
export const wider = '40px';

export const pageHorizontalPadding = '75px';
export const pageSmallHorizontalPadding = '50px';
export const clientPortalSmallHorizontalPadding = '25px';
export const pageHorizontalPaddingBreakpoint = '1400px';
