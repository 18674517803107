import { FieldProps } from 'formik';
import * as React from 'react';
import { FormField, InlineFormField } from '../FormField';
import {
  defaultMapValueToKey,
  isNonNullValueToKeyMapper,
  Select,
  SelectOptions,
  ValueToKeyMapper,
} from './Select';

type SelectFieldProps<TValue> = (
  | { label: string; inline?: false }
  | { label?: undefined; inline: true }) & {
  name: string;
  options: SelectOptions<TValue>;
  excludeNullOption?: boolean;
  nullOptionText?: string;
  mapValueToKey?: ValueToKeyMapper<TValue>;
  optional?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (previousValue: TValue | null, newValue: TValue | null) => void;
  onBlur?: () => void;
} & (TValue extends string | number
    ? { mapValueToKey?: ValueToKeyMapper<TValue> }
    : { mapValueToKey: ValueToKeyMapper<TValue> });

export class SelectField<TValue> extends React.Component<SelectFieldProps<TValue>> {
  onChange = ({ form, field }: FieldProps) => (newValue: TValue | null) => {
    const { onChange } = this.props;
    const previousValue: TValue | null = field.value;

    form.setFieldValue(field.name, newValue);
    if (onChange) {
      onChange(previousValue, newValue);
    }
  };

  render() {
    const {
      name,
      label,
      optional,
      disabled,
      excludeNullOption,
      nullOptionText,
      options,
      inline,
      onBlur,
    } = this.props;

    const SelectFormField = inline ? InlineFormField : FormField;

    return (
      <SelectFormField
        name={name}
        label={label == null ? '' : label}
        optional={optional}
        disabled={disabled}
      >
        {({ field, form, valid, invalid, showWarning }) => {
          const mapValueToKey = isNonNullValueToKeyMapper<TValue>(this.props.mapValueToKey)
            ? this.props.mapValueToKey
            : defaultMapValueToKey;

          return (
            <Select
              options={options}
              mapValueToKey={mapValueToKey}
              value={field.value}
              name={name}
              onChange={this.onChange({ field, form })}
              onBlur={onBlur ? onBlur : field.onBlur}
              disabled={disabled || form.isSubmitting}
              invalid={invalid}
              showWarning={showWarning}
              valid={valid}
              inline={inline}
              excludeNullOption={excludeNullOption}
              nullOptionText={nullOptionText}
            />
          );
        }}
      </SelectFormField>
    );
  }
}
