import * as React from 'react';
import styled from 'styled-components/macro';
import { alertText, warningText } from '../../styling/colours';
import { bold } from '../../styling/fonts';

type FieldErrorProps = {
  children?: React.ReactNode;
  className?: string;
  for: string;
  showError: boolean;
  showWarning: boolean;
};

export const fieldErrorValidTestId = (fieldName: string) => `${fieldName}-field-error-valid`;
export const fieldErrorInvalidTestId = (fieldName: string) => `${fieldName}-field-error-invalid`;
export const fieldErrorWarningTestId = (fieldName: string) => `${fieldName}-field-error-warning`;

export const FieldError = (props: FieldErrorProps) => (
  <FieldErrorContainer
    showError={props.showError}
    showWarning={props.showWarning}
    data-testid={
      props.showError
        ? fieldErrorInvalidTestId(props.for)
        : props.showWarning
        ? fieldErrorWarningTestId(props.for)
        : fieldErrorValidTestId(props.for)
    }
    className={props.className}
  >
    {props.children}
  </FieldErrorContainer>
);

const FieldErrorContainer = styled.div<{ showError: boolean; showWarning: boolean }>`
  cursor: default;
  color: ${props => (props.showError ? alertText : warningText)};
  font-weight: ${bold};
  opacity: ${props => (props.showError || props.showWarning ? 1 : 0)};
`;
