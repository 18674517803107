import * as React from 'react';
import { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import {
  ApiRequestProps,
  withApiRequest,
} from '../../../shared/higher-order-components/withApiRequest';
import {
  clientPortalDataUploadsUrl,
  clientServicesManageWorksheetsUrl,
  connectManageWorksheetsUrl,
  customersUrl,
  internationalManageWorksheetsUrl,
  mailingHousesUrl,
  managerApprovalUrl,
  manageScidsUrl,
  manageUcidsUrl,
  operationsManageWorksheetsUrl,
  reportsUrl,
} from '../../../urls';
import { fetchSecureJson, from, isRequestAbortedError } from '../../../utils/api';
import { useIntervalWhileWindowActive } from '../../../utils/useInterval';
import { useWindowWidth } from '../../../utils/useWindowWidth';
import { UserContext } from '../../authentication/LogIn';
import { Permission, permissions, reportPermissions } from '../../authentication/permissions';
import { userHasOneOfPermissions, userHasPermission } from '../../authentication/user';
import {
  confirmCollectionsUrl,
  sortedWorksheetCollectionEmailsUrl,
  submitWorksheetsToFinanceUrl,
} from '../../collections/collectionsUrls';
import {
  orderConsumablesUrl,
  worksheetCarrierResponsesUrl,
} from '../../consumables/consumablesUrls';
import {
  dataProcessingTaskListUrl,
  prioritiseDataProcessingWorksheetsUrl,
} from '../../data-processing/dataProcessingUrls';
import {
  creditCheckUrl,
  financeManageWorksheetsUrl,
  sendInvoicesUrl,
  worksheetsOnCreditHoldUrl,
} from '../../finance/financeUrls';
import { internationalWorksheetCollectionEmailsUrl } from '../../worksheets/international/internationalUrls';
import { unsortedWorksheetCollectionEmailsUrl } from '../../worksheets/unsorted/unsortedUrls';
import { DropdownNavList } from './DropdownNavList';
import { NavLink } from './NavLink';

export type TaskCounters = {
  numberOfJobsAwaitingManagerApproval: number | null;
  numberOfJobsAwaitingCreditCheck: number | null;
  numberOfJobsAwaitingInvoices: number | null;
  numberOfWorksheetsInDataProcessing: number | null;
  numberOfWorksheetsNeedingConsumablesOrdering: number | null;
  numberOfSortedWorksheetsNeedingCollectionsEmails: number | null;
  numberOfUnsortedWorksheetsNeedingCollectionsEmails: number | null;
  numberOfInternationalWorksheetsNeedingCollectionsEmails: number | null;
  numberOfCollectionsAwaitingConfirmation: number | null;
  numberOfUnresolvedClientPortalDataUploads: number | null;
};

type TaskCountersProps = ApiRequestProps<{}, TaskCounters, 'taskCounters'>;

export type NavItem = {
  text: string;
  path: string;
  permission: Permission | null;
  oneOfPermissions?: Array<Permission>;
  taskCount?: number | null;
};

const NavLinksComponent = (props: TaskCountersProps) => {
  const user = useContext(UserContext);

  useEffect(() => {
    loadTaskCounters();
  }, []);

  useIntervalWhileWindowActive(() => {
    loadTaskCounters();
  }, 30 * 1000);

  const loadTaskCounters = () => {
    props.taskCounters.sendRequest({}).then(response => {
      if (response.success === false && !isRequestAbortedError(response.error)) {
        // if loading task counters fails, we don't want to notify the user as it's not important
        // enough to warrant breaking the site. We do log an error though to allow us to pick it up.
        // tslint:disable-next-line:no-console
        console.error('Failed to load task counters', response.error);
      }
    });
  };

  const taskCountersResponse = props.taskCounters.response;

  const navItems: Array<NavItem> = [
    {
      text: 'Manage Worksheets (Client Services)',
      path: clientServicesManageWorksheetsUrl(),
      permission: permissions.ViewClientServicesManageWorksheetsScreen,
    },
    {
      text: 'Manage Worksheets (International)',
      path: internationalManageWorksheetsUrl(),
      permission: permissions.ViewInternationalManageWorksheetsScreen,
    },
    {
      text: 'Manage Worksheets (Operations)',
      path: operationsManageWorksheetsUrl(),
      permission: permissions.ViewOperationsManageWorksheetsScreen,
    },
    {
      text: 'Manage Worksheets (Finance)',
      path: financeManageWorksheetsUrl(),
      permission: permissions.ViewFinanceManageWorksheetsScreen,
    },
    {
      text: 'Manage Worksheets (Connect)',
      path: connectManageWorksheetsUrl(),
      permission: permissions.ViewConnectManageWorksheetsScreen,
    },
    {
      text: 'Prioritise DP Worksheets',
      path: prioritiseDataProcessingWorksheetsUrl(),
      permission: permissions.PrioritiseDataProcessingWorksheets,
    },
    {
      text: 'DP Task List',
      path: dataProcessingTaskListUrl(),
      permission: permissions.ViewDataProcessingTaskList,
      taskCount: taskCountersResponse && taskCountersResponse.numberOfWorksheetsInDataProcessing,
    },
    {
      text: 'Manager Approval Task List',
      path: managerApprovalUrl(),
      permission: permissions.ReviewManagerApproval,
      taskCount: taskCountersResponse && taskCountersResponse.numberOfJobsAwaitingManagerApproval,
    },
    {
      text: 'Credit Check',
      path: creditCheckUrl(),
      permission: permissions.ApproveCreditCheck,
      taskCount: taskCountersResponse && taskCountersResponse.numberOfJobsAwaitingCreditCheck,
    },
    {
      text: 'Send Invoices',
      path: sendInvoicesUrl(),
      permission: permissions.SendInvoicesToDimensions,
      taskCount: taskCountersResponse && taskCountersResponse.numberOfJobsAwaitingInvoices,
    },
    {
      text: 'On Hold',
      path: worksheetsOnCreditHoldUrl(),
      permission: permissions.ViewWorksheetsOnCreditHoldScreen,
    },
    {
      text: 'Customers',
      path: customersUrl(),
      permission: permissions.ViewCustomer,
    },
    {
      text: 'Customer File Uploads',
      path: clientPortalDataUploadsUrl(),
      permission: permissions.ViewAndResolveClientPortalDataUploads,
      taskCount:
        taskCountersResponse && taskCountersResponse.numberOfUnresolvedClientPortalDataUploads,
    },
    {
      text: 'Mailing Houses',
      path: mailingHousesUrl(),
      permission: permissions.ViewMailingHouse,
    },
    {
      text: 'Manage SCIDs',
      path: manageScidsUrl(),
      permission: permissions.ViewManageScidsScreen,
    },
    {
      text: 'Manage UCIDs',
      path: manageUcidsUrl(),
      permission: permissions.ViewManageUcidsScreen,
    },
    {
      text: 'Send Collections Emails (Sorted)',
      path: sortedWorksheetCollectionEmailsUrl(),
      permission: permissions.SendSortedWorksheetCollectionEmails,
      taskCount:
        taskCountersResponse &&
        taskCountersResponse.numberOfSortedWorksheetsNeedingCollectionsEmails,
    },
    {
      text: 'Confirm Collections',
      path: confirmCollectionsUrl(),
      permission: permissions.ConfirmCollections,
      taskCount:
        taskCountersResponse && taskCountersResponse.numberOfCollectionsAwaitingConfirmation,
    },
    {
      text: 'Send Collections Emails (Unsorted)',
      path: unsortedWorksheetCollectionEmailsUrl(),
      permission: permissions.SendUnsortedWorksheetCollectionEmails,
      taskCount:
        taskCountersResponse &&
        taskCountersResponse.numberOfUnsortedWorksheetsNeedingCollectionsEmails,
    },
    {
      text: 'Send Collections Emails (International)',
      path: internationalWorksheetCollectionEmailsUrl(),
      permission: permissions.SendInternationalWorksheetCollectionEmails,
      taskCount:
        taskCountersResponse &&
        taskCountersResponse.numberOfInternationalWorksheetsNeedingCollectionsEmails,
    },
    {
      text: 'Submit Worksheets to Finance',
      path: submitWorksheetsToFinanceUrl(),
      permission: permissions.SubmitSortedWorksheetsToFinance,
    },
    {
      text: 'Order Consumables',
      path: orderConsumablesUrl(),
      permission: permissions.OrderConsumables,
      taskCount:
        taskCountersResponse && taskCountersResponse.numberOfWorksheetsNeedingConsumablesOrdering,
    },
    {
      text: 'Consumables Order Responses',
      path: worksheetCarrierResponsesUrl(),
      permission: permissions.EditWorksheetCarrierResponses,
    },
    {
      text: 'Reports',
      path: reportsUrl(),
      permission: null,
      oneOfPermissions: reportPermissions,
    },
  ].filter(
    navItem =>
      (navItem.permission == null || userHasPermission(user, navItem.permission)) &&
      (navItem.oneOfPermissions == null || userHasOneOfPermissions(user, navItem.oneOfPermissions)),
  );

  const windowWidth = useWindowWidth();

  const maxNavItemsToShow =
    windowWidth >= 2300
      ? 10
      : windowWidth >= 2100
      ? 9
      : windowWidth >= 2000
      ? 8
      : windowWidth > 1800
      ? 7
      : windowWidth > 1600
      ? 6
      : windowWidth > 1500
      ? 5
      : windowWidth > 970
      ? 4
      : 2;

  const navItemsToShow =
    navItems.length > maxNavItemsToShow ? navItems.slice(0, maxNavItemsToShow - 2) : navItems;
  const navItemsInDropdown = navItems.slice(navItemsToShow.length);

  return (
    <NavLinksContainer>
      {navItemsToShow.map(navItem => (
        <NavLink key={navItem.path} to={navItem.path} taskCount={navItem.taskCount}>
          {navItem.text}
        </NavLink>
      ))}
      {navItemsInDropdown.length > 0 && (
        <DropdownNavList
          label="More"
          items={navItemsInDropdown.map(item => (
            <NavLink
              key={item.path}
              to={item.path}
              taskCount={item.taskCount}
              inlineTaskCount={true}
            >
              {item.text}
            </NavLink>
          ))}
        />
      )}
    </NavLinksContainer>
  );
};

const fetchTaskCounters = () => fetchSecureJson<TaskCounters>(from('tasks', 'get'));

export const NavLinks = withApiRequest(() => () => fetchTaskCounters(), 'taskCounters')(
  NavLinksComponent,
);

export const NavLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 90px;
`;
