import React from 'react';
import { DataForMailingBriefForm } from './dataForMailingBriefForm';

export const DataForMailingBriefFormContext = React.createContext<
  DataForMailingBriefForm | undefined
>(undefined);

export const useDataForMailingBriefForm = () => {
  const dataForMailingBriefForm = React.useContext(DataForMailingBriefFormContext);

  if (dataForMailingBriefForm == null) {
    throw new Error('Data For Mailing Brief Form context is not initialised');
  }

  return dataForMailingBriefForm;
};
