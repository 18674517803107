import { FieldProps } from 'formik';
import * as React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { DateStamp } from '../../../models/dateStamp';
import { FormField, InlineFormField } from '../FormField';
import { DatePicker } from './DatePicker';

type Props = ({ label: string; inline?: false } | { label?: undefined; inline: true }) & {
  name: string;
  optional?: boolean;
  disabled?: boolean;
  minDate?: DateStamp;
  maxDate?: DateStamp;
  onChange?: (newDate: DateStamp | null) => void;
  excludeNonWorkingDays?: boolean;
  excludeSundays?: boolean;
};

export class DateField extends React.PureComponent<Props> {
  onChangeDate = ({ form, field }: FieldProps) => (newValue: DateStamp | null) => {
    form.setFieldValue(field.name, newValue);

    const { onChange } = this.props;
    if (onChange != null) {
      onChange(newValue);
    }
  };

  onBlur = ({ form, field }: FieldProps) => () => {
    form.setFieldTouched(field.name, true);
  };

  render() {
    const props = this.props;

    const DateFormField = props.inline ? InlineFormField : FormField;

    return (
      <DateFormField
        name={props.name}
        label={props.label == null ? '' : props.label}
        optional={props.optional}
        disabled={props.disabled}
      >
        {({ form, field, valid, invalid, showWarning }) => {
          return (
            <DatePicker
              name={props.name}
              value={field.value}
              inline={props.inline}
              valid={valid}
              invalid={invalid}
              showWarning={showWarning}
              disabled={form.isSubmitting || props.disabled}
              onChange={this.onChangeDate({ form, field })}
              onBlur={this.onBlur({ form, field })}
              minDate={props.minDate}
              maxDate={props.maxDate}
              excludeNonWorkingDays={props.excludeNonWorkingDays}
              excludeSundays={props.excludeSundays}
            />
          );
        }}
      </DateFormField>
    );
  }
}
