import { flowRight } from 'lodash';
import * as React from 'react';
import { Component, ComponentType } from 'react';
import { AbortablePromise, ApiResponse } from '../../utils/api';
import { getComponentDisplayName } from '../../utils/getComponentDisplayName';
import { ErrorBox } from '../ErrorBox';
import { LoadingIndicator } from '../LoadingIndicator';
import { dispatchOnPropsChange } from './dispatchOnPropsChange';
import { ApiRequestProps, withApiRequest } from './withApiRequest';

export type ApiFetchParameter<TResponse> = { response: TResponse; refresh: () => void };

export type ApiFetchProps<TResponse, TPropName extends string> = {
  [propName in TPropName]: ApiFetchParameter<TResponse>;
};

export type ApiFetchEnhancer<TOwnProps, TResponse, TPropName extends string> = (
  WrappedComponent: ComponentType<TOwnProps & ApiFetchProps<TResponse, TPropName>>,
) => ComponentType<TOwnProps>;

export const fetchFromApi = <TOwnProps, TParams, TResponse, TPropName extends string>(
  mapPropsToParams: (props: TOwnProps) => TParams,
  mapParamsToRequest: (
    params: TParams,
  ) => (accessToken: string | null) => AbortablePromise<ApiResponse<TResponse>>,
  propName: TPropName,
  mapPropsToOptions?: (props: TOwnProps) => { unauthenticatedRequest?: boolean },
): ApiFetchEnhancer<TOwnProps, TResponse, TPropName> => (
  WrappedComponent: ComponentType<TOwnProps & ApiFetchProps<TResponse, TPropName>>,
): ComponentType<TOwnProps> => {
  class ApiFetchWrapper extends Component<
    TOwnProps & ApiRequestProps<TParams, TResponse, TPropName>
  > {
    static displayName = `fetchFromApi(${getComponentDisplayName(WrappedComponent)})`;

    render() {
      const async = this.props[propName];
      if (async.inProgress) {
        return <LoadingIndicator />;
      } else if (async.error) {
        return <ErrorBox error={async.error} />;
      } else if (async.response) {
        // We have to cast here since Typescript isn't clever enough to handle building up the dynamic prop name
        const fetchProps = {
          [propName]: {
            response: async.response,
            refresh: () => async.sendRequest(mapPropsToParams(this.props)),
          },
        } as ApiFetchProps<TResponse, TPropName>;
        return <WrappedComponent {...this.props} {...fetchProps} />;
      } else {
        return <LoadingIndicator />;
      }
    }
  }

  const enhance = flowRight(
    withApiRequest<TOwnProps, TParams, TResponse, TPropName>(
      () => mapParamsToRequest,
      propName,
      mapPropsToOptions,
    ),
    dispatchOnPropsChange<TOwnProps & ApiRequestProps<TParams, TResponse, TPropName>, TParams>(
      props => params => props[propName].sendRequest(params).catch(error => ({})),
      mapPropsToParams,
    ),
  );
  return enhance(ApiFetchWrapper);
};
