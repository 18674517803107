import * as React from 'react';
import { Dd, Dl, DlItem, Dt } from '../../../shared/DefinitionList';
import { ItemHeader } from '../../../shared/layout/Headers';
import { Link } from '../../../shared/navigation/Link';
import { formatAddress } from '../../mailing-houses/mailingHouse';
import { viewMailingHouseUrl } from '../../mailing-houses/mailingHouseUrls';
import { ClientPortalWorksheetMailingHouse } from './ClientPortalWorksheet';

type Props = {
  mailingHouse: ClientPortalWorksheetMailingHouse;
};

export const ClientPortalWorksheetMailingHouseDetails = ({ mailingHouse }: Props) => (
  <>
    <ItemHeader>
      <Link to={viewMailingHouseUrl(mailingHouse.mailingHouseId)}>
        {mailingHouse.referenceNumber} - {mailingHouse.name}
      </Link>
    </ItemHeader>
    <Dl>
      <DlItem horizontal={false}>
        <Dt>Address</Dt>
        <Dd multiline={true}>{formatAddress(mailingHouse.address)}</Dd>
      </DlItem>
    </Dl>
  </>
);
