import { FieldArray, FieldArrayRenderProps, FormikProps } from 'formik';
import { find, findIndex, isEmpty } from 'lodash';
import * as React from 'react';
import styled from 'styled-components/macro';
import { CheckboxesField, CheckboxesFieldItem } from '../../../shared/form/inputs/CheckboxesField';
import { InputField } from '../../../shared/form/inputs/InputField';
import { SelectField } from '../../../shared/form/inputs/SelectField';
import { TextAreaField } from '../../../shared/form/inputs/TextAreaField';
import { SectionHeader } from '../../../shared/layout/Headers';
import {
  getNewSelectedTabIndexAfterClosingTab,
  TabContent,
  TabHeading,
  TabHeadings,
} from '../../../shared/Tabs';
import { WarningBox } from '../../../shared/WarningBox';
import { greyBorder } from '../../../styling/colours';
import { medium, wide } from '../../../styling/spacing';
import { assertNotNull } from '../../../utils/assertNotNull';
import { ValidationResult } from '../../../utils/validation/validation';
import { IncludeOnJobsCode, includeOnJobsOptions } from '../../jobs/includeOnJobsCode';
import { MailingHouseContact } from './mailingHouseContact';
import { MailingHouseContactFormModel } from './mailingHouseContactFormModel';

type Props<TFormModel> = {
  formProps: FormikProps<TFormModel>;
  originalContacts: Array<MailingHouseContact>;
};

type State = { selectedContactIndex: number | null };

type BaseFormModel = { contacts: Array<MailingHouseContactFormModel> };

export class MailingHouseContactsFieldArray<
  TFormModel extends BaseFormModel
> extends React.Component<Props<TFormModel>, State> {
  state: State = {
    selectedContactIndex: this.props.formProps.values.contacts.length > 0 ? 0 : null,
  };

  componentDidUpdate(prevProps: Props<TFormModel>) {
    const { isSubmitting, isValidating, errors } = prevProps.formProps;
    const contactsErrors = (errors.contacts as unknown) as Array<
      ValidationResult<MailingHouseContactFormModel>
    >;

    if (contactsErrors != null && isSubmitting && !isValidating) {
      this.switchToContactWithError(contactsErrors);
    }
  }

  switchToContactWithError = (
    contactsErrors: Array<ValidationResult<MailingHouseContactFormModel>>,
  ) => {
    const { selectedContactIndex } = this.state;

    // Don't tab away from the currently selected tab if it has errors
    if (selectedContactIndex != null && !isEmpty(contactsErrors[selectedContactIndex])) {
      return;
    }

    const indexOfFirstTabWithError = findIndex(
      contactsErrors,
      validationResult => !isEmpty(validationResult),
    );
    this.setState({ selectedContactIndex: indexOfFirstTabWithError });
  };

  setSelectedContactIndex = (index: number) => this.setState({ selectedContactIndex: index });

  addContact = () => {
    const currentContacts = this.props.formProps.values.contacts;
    const newContact: MailingHouseContactFormModel = {
      mailingHouseContactId: null,
      isNew: true,
      firstName: '',
      lastName: '',
      landline: '',
      mobile: '',
      email: '',
      jobTitle: '',
      includeOnJobsCode: 'Optionally',
      includeOnCarbonCopyCommunication: false,
      includeOnDataCommunication: false,
      includeOnLabelsCommunication: false,
      includeOnCollectionsCommunication: false,
      includeOnConsumablesCommunication: false,
      notes: '',
    };
    this.props.formProps.setFieldValue('contacts', [...currentContacts, newContact]);
    this.setState({ selectedContactIndex: currentContacts.length });
  };

  removeContact = (arrayHelpers: FieldArrayRenderProps, index: number) => {
    const { contacts } = this.props.formProps.values;
    this.setState(
      state => ({
        selectedContactIndex: getNewSelectedTabIndexAfterClosingTab(
          index,
          state.selectedContactIndex,
          contacts.length,
        ),
      }),
      () => arrayHelpers.remove(index),
    );
  };

  onChangeIncludeOnJobs = (
    oldSelection: IncludeOnJobsCode | null,
    newSelection: IncludeOnJobsCode | null,
  ) => {
    if (oldSelection === newSelection) {
      return;
    }

    const { setFieldValue } = this.props.formProps;
    const { selectedContactIndex } = this.state;

    const currentContactFieldName = `contacts.${selectedContactIndex}`;
    const includeOnCarbonCopyCommunication = `${currentContactFieldName}.includeOnCarbonCopyCommunication`;
    const includeOnDataCommunication = `${currentContactFieldName}.includeOnDataCommunication`;
    const includeOnLabelsCommunication = `${currentContactFieldName}.includeOnLabelsCommunication`;
    const includeOnCollectionsCommunication = `${currentContactFieldName}.includeOnCollectionsCommunication`;
    const includeOnConsumablesCommunication = `${currentContactFieldName}.includeOnConsumablesCommunication`;

    if (newSelection === 'Always') {
      // includeOnCarbonCopyCommunication is set to false because includeOnDataCommunication is set to true,
      // and these fields represent the same three-state variable so must be either opposite or both false
      setFieldValue(includeOnCarbonCopyCommunication, false);
      setFieldValue(includeOnDataCommunication, true);
      setFieldValue(includeOnLabelsCommunication, true);
      setFieldValue(includeOnCollectionsCommunication, true);
      setFieldValue(includeOnConsumablesCommunication, true);
    } else {
      setFieldValue(includeOnCarbonCopyCommunication, false);
      setFieldValue(includeOnDataCommunication, false);
      setFieldValue(includeOnLabelsCommunication, false);
      setFieldValue(includeOnCollectionsCommunication, false);
      setFieldValue(includeOnConsumablesCommunication, false);
    }
  };

  renderFieldArray = (arrayHelpers: FieldArrayRenderProps) => {
    const { selectedContactIndex } = this.state;
    const { originalContacts } = this.props;
    const { contacts } = this.props.formProps.values;

    const currentContactFieldName = `contacts.${selectedContactIndex}`;

    const selectedContact = selectedContactIndex == null ? null : contacts[selectedContactIndex];

    const originalContact =
      selectedContact != null && selectedContact.mailingHouseContactId != null
        ? assertNotNull(
            find(
              originalContacts,
              contact => contact.mailingHouseContactId === selectedContact.mailingHouseContactId,
            ),
          )
        : null;

    const showCommunicationOptions: boolean =
      contacts.length > 0 &&
      selectedContactIndex != null &&
      contacts[selectedContactIndex] != null &&
      contacts[selectedContactIndex].includeOnJobsCode === 'Always';

    return (
      <MailingHouseContactsContainer>
        <SectionHeader>Contacts</SectionHeader>
        <div>
          <TabHeadings>
            {contacts.map((contact, index) => (
              <TabHeading
                active={index === selectedContactIndex}
                title={
                  contact.isNew
                    ? `New Contact ${index + 1}`
                    : `${contact.firstName} ${contact.lastName}`
                }
                onSelect={() => this.setSelectedContactIndex(index)}
                onClose={contact.isNew ? () => this.removeContact(arrayHelpers, index) : undefined}
                key={index}
              />
            ))}
            <TabHeading active={false} title="+ Add Contact" onSelect={this.addContact} />
          </TabHeadings>
          <TabContent>
            {!contacts.length || !selectedContact ? (
              <p>You haven't added any contacts yet.</p>
            ) : (
              <>
                <InputField label="First Name" name={`${currentContactFieldName}.firstName`} />
                <InputField label="Last Name" name={`${currentContactFieldName}.lastName`} />

                <InputField label="Landline" name={`${currentContactFieldName}.landline`} />
                <InputField label="Mobile" name={`${currentContactFieldName}.mobile`} />

                <InputField label="Email" name={`${currentContactFieldName}.email`} />

                {originalContact != null &&
                  originalContact.invitedToClientPortalDateTime != null &&
                  selectedContact.email !== originalContact.email && (
                    <WarningBox message="Modifying the email means this contact will need to log into the Client Portal with the new email address. Please ensure the contact is aware of this." />
                  )}

                {originalContact != null &&
                  originalContact.invitedToClientPortalDateTime != null &&
                  selectedContact.email !== originalContact.email &&
                  (originalContact.hasClientPortalAccessForOtherCustomer ||
                    originalContact.hasClientPortalAccessForOtherMailingHouse) && (
                    <WarningBox message="This contact is also listed as a contact on another Customer or Mailing House, and has Client Portal access. Please update the email on the other Customers or Mailing Houses." />
                  )}

                <InputField
                  label="Job Title"
                  optional={true}
                  name={`${currentContactFieldName}.jobTitle`}
                />

                <SelectField<IncludeOnJobsCode>
                  label="Include on Jobs?"
                  options={includeOnJobsOptions}
                  excludeNullOption={true}
                  name={`${currentContactFieldName}.includeOnJobsCode`}
                  onChange={this.onChangeIncludeOnJobs}
                />

                {showCommunicationOptions && (
                  <CheckboxesField>
                    <DataEmailAccessOptions>
                      <CheckboxesFieldItem
                        label="CC"
                        name={`${currentContactFieldName}.includeOnCarbonCopyCommunication`}
                        onChange={(newValue: boolean) => {
                          if (newValue) {
                            this.props.formProps.setFieldValue(
                              `${currentContactFieldName}.includeOnDataCommunication`,
                              false,
                            );
                          }
                        }}
                      />
                      <CheckboxesFieldItem
                        label="Data"
                        name={`${currentContactFieldName}.includeOnDataCommunication`}
                        onChange={(newValue: boolean) => {
                          if (newValue) {
                            this.props.formProps.setFieldValue(
                              `${currentContactFieldName}.includeOnCarbonCopyCommunication`,
                              false,
                            );
                          }
                        }}
                      />
                    </DataEmailAccessOptions>
                    <CheckboxesFieldItem
                      label="Labels"
                      name={`${currentContactFieldName}.includeOnLabelsCommunication`}
                    />
                    <CheckboxesFieldItem
                      label="Collections"
                      name={`${currentContactFieldName}.includeOnCollectionsCommunication`}
                    />
                    <CheckboxesFieldItem
                      label="Consumables"
                      name={`${currentContactFieldName}.includeOnConsumablesCommunication`}
                    />
                  </CheckboxesField>
                )}

                <TextAreaField
                  label="Notes"
                  optional={true}
                  name={`${currentContactFieldName}.notes`}
                />
              </>
            )}
          </TabContent>
        </div>
      </MailingHouseContactsContainer>
    );
  };

  render() {
    return <FieldArray name="contacts" render={this.renderFieldArray} />;
  }
}

const MailingHouseContactsContainer = styled.div`
  margin-bottom: ${wide};

  ${TabHeadings} {
    margin-top: ${medium};
  }
`;

export const DataEmailAccessOptions = styled.div`
  outline: 1px solid ${greyBorder};
  outline-offset: 5px;
  display: flex;
  margin-right: ${medium};
  height: 19.5px;
`;
