import * as React from 'react';
import { useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components/macro';
import { ReactComponent as CloseIcon } from '../images/icons/cross-icon.svg';
import { lightGrey, lightGreyHoverAccent, white } from '../styling/colours';
import { getDocumentHtmlNode, modalIsOpenClassName } from '../styling/GlobalStyles';
import { medium, mediumPixels, wider } from '../styling/spacing';
import { usePrevious } from '../utils/usePrevious';
import { ButtonGroup } from './buttons/Button';
import { ErrorBox } from './ErrorBox';
import { formFieldMaxWidthPixels } from './form/FormField';
import { SectionHeader } from './layout/Headers';

type Props = {
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  wide?: boolean;
  disableClose?: boolean;
};

export const modalPortalRootElementId = 'modal-portal-root';
export const modalBackdropTestId = 'modal-backdrop';
export const closeModalButtonTestId = 'close-modal-button';

export const Modal = ({ children, isOpen, onClose, wide, disableClose }: Props) => {
  const handleKeydown = (event: Event) => {
    const keyboardEvent = (event as unknown) as React.KeyboardEvent;
    if (keyboardEvent.key === 'Escape') {
      event.preventDefault(); // Needed to prevent the sidebar from closing as well if it's open
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown, /* useCapture: */ true);
    return () => {
      document.removeEventListener('keydown', handleKeydown, /* useCapture: */ true);
    };
  }, []);

  const prevIsOpen = usePrevious(isOpen);

  useEffect(() => {
    const documentHtmlNode = getDocumentHtmlNode();

    if (!prevIsOpen && isOpen) {
      documentHtmlNode.classList.add(modalIsOpenClassName);
    } else if (prevIsOpen && !isOpen) {
      documentHtmlNode.classList.remove(modalIsOpenClassName);
    }

    return () => documentHtmlNode.classList.remove(modalIsOpenClassName);
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const modalPortalRoot = document.getElementById(modalPortalRootElementId);
  if (modalPortalRoot == null) {
    throw new Error('Could not find modal portal root');
  }

  return ReactDOM.createPortal(
    <ModalBackdrop data-testid={modalBackdropTestId}>
      <ModalContentContainer>
        <ModalContent role="dialog" aria-label="Modal." wide={wide}>
          <FocusAnchor tabIndex={-1} />
          {children}
          {!disableClose && (
            <CloseButton
              onClick={onClose}
              data-testid={closeModalButtonTestId}
              aria-label="Close modal button."
            >
              <CloseButtonIcon />
            </CloseButton>
          )}
        </ModalContent>
      </ModalContentContainer>
    </ModalBackdrop>,
    modalPortalRoot,
  );
};

export const ModalHeader = styled(SectionHeader)`
  margin-bottom: ${medium};
`;

export const ModalActionButtons = styled(ButtonGroup)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: ${medium};
  margin-bottom: 0;
`;

const backdropFadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const ModalBackdrop = styled.div`
  overflow-y: auto;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  animation: ${backdropFadeIn} 250ms ease forwards;
`;

const ModalContentContainer = styled.div`
  margin: auto;
`;

export const ModalContent = styled.div<{ wide?: boolean }>`
  background-color: ${white};
  padding: ${medium};
  margin: ${wider};
  width: ${props => (props.wide ? formFieldMaxWidthPixels + 2 * mediumPixels : 800)}px;
  position: relative;
`;

const FocusAnchor = styled.div`
  height: 0;
  width: 0;
`;

const closeButtonSize = 30;

const CloseButton = styled.button`
  position: absolute;
  top: -${closeButtonSize / 3}px;
  right: -${closeButtonSize / 3}px;
  border: none;
  border-radius: 100%;
  width: ${closeButtonSize}px;
  height: ${closeButtonSize}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${lightGrey};
  outline: none;
  cursor: pointer;
  transition: background-color 200ms ease;

  &:hover,
  &:focus {
    background-color: ${lightGreyHoverAccent};
  }
`;

const CloseButtonIcon = styled(CloseIcon)`
  height: 10px;
  width: 10px;
`;

export const ModalErrorBox = styled(ErrorBox)`
  margin-top: ${medium};
`;
