import styled from 'styled-components/macro';
import {
  alertAccent,
  alertText,
  darkGrey,
  infoAccent,
  infoText,
  successAccent,
  successText,
  warningAccent,
  warningText,
} from '../styling/colours';
import { medium, narrow } from '../styling/spacing';

export const Callout = styled.div`
  border: 1px solid ${darkGrey};
  padding: ${medium};
  margin-bottom: ${narrow};
`;

export const SuccessCallout = styled(Callout)`
  background-color: ${successAccent};
  color: ${successText};
  border-color: ${successText};
`;

export const WarningCallout = styled(Callout)`
  background-color: ${warningAccent};
  color: ${warningText};
  border-color: ${warningText};
`;

export const AlertCallout = styled(Callout)`
  background-color: ${alertAccent};
  color: ${alertText};
  border-color: ${alertText};
`;

export const InfoCallout = styled(Callout)`
  background-color: ${infoAccent};
  color: ${infoText};
  border-color: ${infoText};
`;

export const CalloutHeader = styled.h3`
  padding: 0;
  margin-top: 0;
  margin-bottom: ${medium};
`;
