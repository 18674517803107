import { keys, values } from 'lodash';
import { CarrierCode } from '../../../../models/carrier';
import { SortedCellMetadata } from '../../../../models/metadata';
import { ConsumableLimitationCode } from '../../../mailing-houses/mailingHouse';
import {
  printingTypeRequiresBundleLabelFormat,
  WorksheetPrintingType,
} from '../edit-form/sortedWorksheetFormModel';
import {
  CarrierPresentationConsumablesConfiguration,
  CarrierServiceFormatRules,
  PresentationAllowedConsumables,
  SortedCellBundleLabelFormat,
  SortedCellConsumable,
  SortedCellFormat,
  SortedCellMailmarkBarcode,
  SortedCellPresentation,
  SortedCellServiceType,
  SortedCellVariant,
} from './sortedCell';
import { SortedCellFormModel } from './sortedCellFormModel';

export type ValidOptionsForCell = {
  validServiceTypes: Array<SortedCellServiceType> | null;
  validFormats: Array<SortedCellFormat> | null;
  validVariants: Array<SortedCellVariant> | null;
  validPresentations: Array<SortedCellPresentation> | null;
  validBundleLabelFormats: Array<SortedCellBundleLabelFormat> | null;
  validMailmarkBarcodes: Array<SortedCellMailmarkBarcode> | null;
  minWeight: number | null;
  maxWeight: number | null;
  serviceFormatRules: CarrierServiceFormatRules | null;
  presentationConsumablesConfiguration: CarrierPresentationConsumablesConfiguration | null;
  presentationAllowedConsumables: PresentationAllowedConsumables | null;
  validConsumables: Array<SortedCellConsumable> | null;
  maximumMaxPresentationWeight: number | null;
};

type WarningsForCell = {
  nonDefaultConsumableIsSelected: boolean;
  nonDefaultConsumableSelectionWarning: string | null;
  presentationSelectionWarning: string | null;
};

export const getValidOptionsForCell = (
  cell: SortedCellFormModel,
  carrierCode: CarrierCode | null,
  consumableLimitationCode: ConsumableLimitationCode | null,
  printingType: WorksheetPrintingType | null,
  worksheetIsForecast: boolean,
  hasAgencyAgreement: boolean,
  isJobCreatedBeforeSortedCellServiceArchiveDate: boolean,
  isJobCreatedBeforeSortedCellVariantArchiveDate: boolean,
  metadata: SortedCellMetadata,
): ValidOptionsForCell => {
  const {
    carrierServiceFormatValidationConfiguration,
    presentationConsumablesValidationConfiguration,
  } = metadata.sortedCellValidation;

  const serviceTypeFormatsByServiceTypeCode =
    carrierCode == null
      ? null
      : carrierServiceFormatValidationConfiguration.serviceTypesByCarrierCode[carrierCode]
          .serviceTypesByCode;

  const isServiceTypeValid = (type: SortedCellServiceType) =>
    !metadata.sortedCellServiceTypes[type].isArchived ||
    isJobCreatedBeforeSortedCellServiceArchiveDate;

  const serviceTypeFormats =
    cell.serviceType == null || serviceTypeFormatsByServiceTypeCode == null
      ? null
      : serviceTypeFormatsByServiceTypeCode[cell.serviceType] || null;

  const serviceFormatRules =
    cell.format == null || serviceTypeFormats == null
      ? null
      : serviceTypeFormats.rulesByFormatCode[cell.format] || null;

  const isVariantCodeValid = (code: SortedCellVariant) =>
    !metadata.sortedCellVariants[code].isArchived || isJobCreatedBeforeSortedCellVariantArchiveDate;

  const validVariants =
    serviceFormatRules == null
      ? null
      : hasAgencyAgreement
      ? serviceFormatRules.allowedVariantCodesForAgencyAgreement.filter(isVariantCodeValid)
      : serviceFormatRules.allowedVariantCodesForNonAgencyAgreement.filter(isVariantCodeValid);

  const validPresentations =
    serviceFormatRules == null ? null : serviceFormatRules.allowedPresentationCodes;

  const minWeight = serviceFormatRules == null ? null : serviceFormatRules.minWeight;
  const maxWeight = serviceFormatRules == null ? null : serviceFormatRules.maxWeight;

  const presentationConsumablesConfiguration =
    carrierCode == null || cell.presentation == null
      ? null
      : presentationConsumablesValidationConfiguration
          .presentationConsumablesConfigurationByCarrierCode[carrierCode] || null;

  const presentationAllowedConsumables =
    cell.presentation == null || presentationConsumablesConfiguration == null
      ? null
      : presentationConsumablesConfiguration.allowedConsumablesByPresentationCode[
          cell.presentation
        ] || null;

  const validConsumablesForMailingHouse =
    presentationConsumablesConfiguration == null || presentationAllowedConsumables == null
      ? null
      : presentationAllowedConsumables.allowedConsumableCodes.filter(
          consumableCode =>
            consumableLimitationCode == null ||
            consumableLimitationCode === 'None' ||
            consumableCode === consumableLimitationCode,
        );

  const validConsumables =
    presentationConsumablesConfiguration == null || presentationAllowedConsumables == null
      ? null
      : validConsumablesForMailingHouse && validConsumablesForMailingHouse.length > 0
      ? validConsumablesForMailingHouse
      : presentationAllowedConsumables.allowedConsumableCodes;

  const maximumMaxPresentationWeight =
    cell.presentation == null
      ? null
      : metadata.sortedCellPresentations[cell.presentation].maximumMaxWeightKg;

  const validBundleLabelFormats =
    cell.presentation == null ||
    worksheetIsForecast ||
    (printingType && !printingTypeRequiresBundleLabelFormat(printingType))
      ? null
      : metadata.sortedCellPresentations[cell.presentation].validBundleLabelFormats;

  const validMailmarkBarcodes =
    cell.serviceType == null
      ? null
      : metadata.sortedCellServiceTypes[cell.serviceType].validMailmarkBarcodes;

  return {
    validServiceTypes:
      serviceTypeFormatsByServiceTypeCode &&
      (values(serviceTypeFormatsByServiceTypeCode)
        .filter(
          serviceTypeFormat =>
            serviceTypeFormat &&
            isServiceTypeValid(serviceTypeFormat.serviceTypeCode) &&
            (!hasAgencyAgreement || serviceTypeFormat.isAllowedOnAgencyWorksheets),
        )
        .map(serviceTypeFormat => serviceTypeFormat && serviceTypeFormat.serviceTypeCode) as Array<
        SortedCellServiceType
      >),
    validFormats:
      serviceTypeFormats && (keys(serviceTypeFormats.rulesByFormatCode) as Array<SortedCellFormat>),
    validVariants,
    validPresentations,
    validBundleLabelFormats,
    validMailmarkBarcodes,
    minWeight,
    maxWeight,
    serviceFormatRules,
    presentationConsumablesConfiguration,
    presentationAllowedConsumables,
    validConsumables,
    maximumMaxPresentationWeight,
  };
};

export const getWarningsForCell = (
  cell: SortedCellFormModel,
  {
    serviceFormatRules,
    presentationConsumablesConfiguration,
    presentationAllowedConsumables,
  }: ValidOptionsForCell,
): WarningsForCell => {
  const presentationSelectionWarning =
    serviceFormatRules == null || cell.presentation == null
      ? null
      : serviceFormatRules.selectionWarningsByPresentationCode[cell.presentation] || null;

  const nonDefaultConsumableSelectionWarning =
    presentationConsumablesConfiguration == null || cell.consumable == null
      ? null
      : presentationConsumablesConfiguration.nonDefaultSelectionWarning;

  const nonDefaultConsumableIsSelected =
    cell.presentation != null &&
    cell.consumable != null &&
    presentationAllowedConsumables != null &&
    presentationAllowedConsumables.defaultConsumableCode !== cell.consumable;

  return {
    nonDefaultConsumableIsSelected,
    nonDefaultConsumableSelectionWarning,
    presentationSelectionWarning,
  };
};
