import * as React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as DownloadIcon } from '../../images/icons/download.svg';
import { ReactComponent as UserIcon } from '../../images/icons/user-solid.svg';
import { DateTimeStamp, formatDateTimeStamp } from '../../models/dateTimeStamp';
import { fontGrey } from '../../styling/colours';
import { bold, regularFont, smallFont } from '../../styling/fonts';
import { narrow } from '../../styling/spacing';
import { fastTransitionDuration } from '../../styling/transitions';
import { AuthenticatedApiRequest } from '../../utils/api';
import { SmallBadge } from '../Badge';
import { ErrorBox } from '../ErrorBox';
import { ApiRequestProps, withApiRequest } from '../higher-order-components/withApiRequest';
import { Icon, LeftInlineIcon } from '../Icon';
import { LoadingSpinner } from '../LoadingSpinner';
import { Link } from '../navigation/Link';
import { Panel } from '../Panel';
import { formatFileSize } from './attachment';

export type AttachmentDownloadResponse = {
  downloadUrl: string;
};

type AttachmentOwnProps = {
  fileName: string;
  sizeInBytes: number;
  notes?: string;
  uploadedByUserName?: string;
  uploadedDateTime?: DateTimeStamp;
  fetchDownloadUrl: () => AuthenticatedApiRequest<AttachmentDownloadResponse>;
  disabled?: boolean;
  className?: string;
  unauthenticated?: boolean;
};

type DownloadAttachmentProps = ApiRequestProps<
  {},
  AttachmentDownloadResponse,
  'downloadAttachment'
>;

type AttachmentDetailsProps = AttachmentOwnProps & DownloadAttachmentProps;

export const AttachmentDetailsComponent = (props: AttachmentDetailsProps) => {
  const downloadAttachment = async () => {
    if (!props.disabled && !props.downloadAttachment.inProgress) {
      const result = await props.downloadAttachment.sendRequest({});
      if (result.success) {
        window.location.href = result.body.downloadUrl;
      }
    }
  };

  return (
    <AttachmentDetailsContainer className={props.className}>
      <FileDetails>
        {props.disabled ? (
          <DisabledFileName>{props.fileName}</DisabledFileName>
        ) : (
          <DownloadLink onClick={downloadAttachment} as="a" title={props.fileName}>
            <DownloadIconContainer size="font" visible={!props.downloadAttachment.inProgress}>
              <DownloadIcon />
            </DownloadIconContainer>
            {props.downloadAttachment.inProgress && (
              <SpinnerContainer>
                <DownloadSpinner />
              </SpinnerContainer>
            )}
            <FileName>{props.fileName}</FileName>
          </DownloadLink>
        )}
        <UploadDetails>
          {props.uploadedByUserName && (
            <InfoBadge foregroundColour={fontGrey} backgroundColour={infoBadgeBackgroundColour}>
              <LeftInlineIcon size="font">
                <UserIcon />
              </LeftInlineIcon>
              {props.uploadedByUserName}
            </InfoBadge>
          )}
          {props.uploadedDateTime && (
            <InfoBadge foregroundColour={fontGrey} backgroundColour={infoBadgeBackgroundColour}>
              {formatDateTimeStamp(props.uploadedDateTime)}
            </InfoBadge>
          )}
          <InfoBadge foregroundColour={fontGrey} backgroundColour={infoBadgeBackgroundColour}>
            {formatFileSize(props.sizeInBytes)}
          </InfoBadge>
        </UploadDetails>
      </FileDetails>
      {props.notes && <Notes>{props.notes}</Notes>}
      {props.downloadAttachment.error && (
        <ErrorBox
          inline={true}
          error={props.downloadAttachment.error}
          genericErrorMessage="There was an error downloading the attachment"
        />
      )}
    </AttachmentDetailsContainer>
  );
};

const enhance = withApiRequest<
  AttachmentOwnProps,
  {},
  AttachmentDownloadResponse,
  'downloadAttachment'
>(props => () => props.fetchDownloadUrl(), 'downloadAttachment', props => ({
  unauthenticatedRequest: props.unauthenticated,
}));

export const AttachmentDetails = enhance(AttachmentDetailsComponent);

export const Attachments = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const AttachmentDetailsContainer = styled(Panel)`
  flex: 1;
  margin-bottom: ${narrow};
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding: ${narrow};
`;

const FileDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const infoBadgeBackgroundColour = '#f5f5f5';

const InfoBadge = styled(SmallBadge)`
  display: flex;
  align-items: center;
  padding: ${narrow} ${narrow};
  white-space: nowrap;
  text-align: center;
  font-size: ${smallFont};
  margin-left: ${narrow};
  border-radius: 0;
`;

const DownloadLink = styled(Link)`
  font-weight: ${bold};
  display: flex;
  align-items: center;
  padding-right: ${narrow};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const DisabledFileName = styled.div`
  font-weight: ${bold};
  padding-right: ${narrow};
`;

const DownloadIconContainer = styled(Icon)<{ visible: boolean }>`
  margin-right: ${narrow};
  flex: 0 0 auto;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity ${fastTransitionDuration} ease;
`;

const DownloadSpinner = styled(LoadingSpinner)`
  margin: 0;
  height: 100%;
  width: 100%;
`;

const SpinnerContainer = styled.div`
  height: ${regularFont};
  width: ${regularFont};
  margin-right: ${narrow};
  position: absolute;
`;

const FileName = styled.span`
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const UploadDetails = styled.div`
  font-size: ${smallFont};
  display: flex;
`;

const Notes = styled.div`
  padding-top: ${narrow};
`;
