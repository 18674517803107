import { connect, FieldArray, FieldArrayRenderProps, FormikContext, getIn } from 'formik';
import * as React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as CrossIcon } from '../../../../images/icons/cross-icon.svg';
import { HollowButton } from '../../../../shared/buttons/Button';
import { IconButton } from '../../../../shared/buttons/IconButton';
import { standardVerticalFormFieldSpacing } from '../../../../shared/form/FormField';
import { InputField } from '../../../../shared/form/inputs/InputField';
import {
  FullWidthFixedTable,
  NoDataTableDataCell,
  TBody,
  Td,
  TFoot,
  Th,
  THead,
  Tr,
} from '../../../../shared/table/Table';
import { WarningBox } from '../../../../shared/WarningBox';
import { alertText, tdgGreen } from '../../../../styling/colours';
import { medium } from '../../../../styling/spacing';
import { MailingBriefFormModel } from '../mailingBriefFormModel';
import {
  getInitialMailingBriefInternationalCellFormModel,
  MailingBriefInternationalCellFormModel,
} from './mailingBriefInternationalCellFormModel';
import { MailingBriefInternationalWorksheetFormModel } from './mailingBriefInternationalWorksheetFormModel';

type OwnProps = {};

type Props = OwnProps & {
  formik: FormikContext<MailingBriefFormModel>;
};

export const deleteCellButtonTestId = (cellIndex: number) => `deleteCellButton${cellIndex}`;

const MailingBriefInternationalCellsFieldArrayComponent = (props: Props) => {
  const worksheetFieldName = 'internationalWorksheet';

  const { setFieldValue, values } = props.formik;
  const worksheetFormModel = values.internationalWorksheet as MailingBriefInternationalWorksheetFormModel;

  const addCell = () => {
    const currentCells = worksheetFormModel.cells;
    const newCell: MailingBriefInternationalCellFormModel = getInitialMailingBriefInternationalCellFormModel();
    setFieldValue(`${worksheetFieldName}.cells`, [...currentCells, newCell]);
  };

  const removeCell = (arrayHelpers: FieldArrayRenderProps, cellIndex: number) => {
    arrayHelpers.remove(cellIndex);
  };

  const renderFieldArray = (arrayHelpers: FieldArrayRenderProps) => {
    const cells = worksheetFormModel.cells;
    const shouldShowQuantityWarning = cells.some((cell, index) => {
      const currentCellQuantityTouched = getIn(
        props.formik.touched,
        `${worksheetFieldName}.cells[${index}].quantity`,
      );
      return currentCellQuantityTouched && Number(cell.quantity.trim()) < 100;
    });

    return (
      <div>
        <EditCellsTable>
          <THead>
            <Tr>
              <Th>Cell Reference</Th>
              <Th>Quantity</Th>
              <Th>Item Weight (g)</Th>
              <Th>Width (mm)</Th>
              <Th>Height (mm)</Th>
              <Th>Thickness (mm)</Th>
              <DeleteCellColumnHeader />
            </Tr>
          </THead>
          <TBody>
            {!cells.length ? (
              <Tr>
                <NoDataTableDataCell colSpan={6}>
                  -- You haven't added any cells yet --
                </NoDataTableDataCell>
              </Tr>
            ) : (
              cells.map((cell, cellIndex) => {
                const currentCellFieldName = `${worksheetFieldName}.cells.${cellIndex}`;

                return (
                  <StyledTr key={cellIndex}>
                    <Td>
                      <InputField inline={true} name={`${currentCellFieldName}.cellReference`} />
                    </Td>
                    <Td>
                      <InputField inline={true} name={`${currentCellFieldName}.quantity`} />
                    </Td>
                    <Td>
                      <InputField inline={true} name={`${currentCellFieldName}.weightInGrams`} />
                    </Td>
                    <Td>
                      <InputField
                        inline={true}
                        name={`${currentCellFieldName}.widthInMillimetres`}
                      />
                    </Td>
                    <Td>
                      <InputField
                        inline={true}
                        name={`${currentCellFieldName}.heightInMillimetres`}
                      />
                    </Td>
                    <Td>
                      <InputField
                        inline={true}
                        name={`${currentCellFieldName}.thicknessInMillimetres`}
                      />
                    </Td>
                    <Td>
                      <DeleteButton
                        onClick={() => removeCell(arrayHelpers, cellIndex)}
                        colour={alertText}
                        data-testid={deleteCellButtonTestId(cellIndex)}
                      >
                        <CrossIcon />
                      </DeleteButton>
                    </Td>
                  </StyledTr>
                );
              })
            )}
          </TBody>
          <TFoot>
            <Td colSpan={7}>
              <AddCellButton onClick={addCell}>+ Add Cell</AddCellButton>
            </Td>
          </TFoot>
        </EditCellsTable>
        {shouldShowQuantityWarning && (
          <WarningBox
            message={
              'For International  jobs of less than 100 items a minimum charge of £50 applies'
            }
          />
        )}
      </div>
    );
  };

  return <FieldArray name={`${worksheetFieldName}.cells`} render={renderFieldArray} />;
};

export const MailingBriefInternationalCellsFieldArray = connect<OwnProps, MailingBriefFormModel>(
  MailingBriefInternationalCellsFieldArrayComponent,
);

const AddCellButton = styled(HollowButton)`
  margin-bottom: ${medium};
  width: 100%;
  height: 100%;
  color: ${tdgGreen};
  border: none;
`;

const EditCellsTable = styled(FullWidthFixedTable)`
  margin-bottom: ${standardVerticalFormFieldSpacing};
`;

const DeleteCellColumnHeader = styled(Th)`
  width: 30px;
`;

const DeleteButton = styled(IconButton)`
  height: 20px;
  width: 20px;
  margin-top: 8px;
`;

// For some reason, when testing, the input fields do not remain aligned with
// each other when an error message is displayed. This is a workaround.
const StyledTr = styled(Tr)`
  vertical-align: baseline;
`;
