import { FormikProps } from 'formik';
import { map } from 'lodash';
import { useEffect, useMemo } from 'react';
import * as React from 'react';
import { Id } from '../../../../models/id';
import { FieldSet } from '../../../../shared/form/FieldSet';
import { RadioButtonsField } from '../../../../shared/form/inputs/RadioButtonsField';
import { SearchSelectField } from '../../../../shared/form/inputs/SearchSelectField';
import { SelectField } from '../../../../shared/form/inputs/SelectField';
import { WarningBox } from '../../../../shared/WarningBox';
import { assertNotNull } from '../../../../utils/assertNotNull';
import {
  printingTypeOptions,
  WorksheetPrintingType,
} from '../../../worksheets/sorted/edit-form/sortedWorksheetFormModel';
import { labelDeliveryServiceOptions } from '../../../worksheets/sorted/sortedWorksheet';
import { WorksheetLabelDeliveryService } from '../../../worksheets/worksheet';
import { MailingHouseForMailingBriefForm } from '../dataForMailingBriefForm';
import { MailingBriefFormModel } from '../mailingBriefFormModel';
import { fromMailingHouseContactForJobForm } from '../mailingBriefMailingHouseFormModel';
import { MailingBriefMailingHouseContactsFieldArray } from './MailingBriefMailingHouseContactsFieldArray';

export const MailingBriefMailingHouse = (
  props: {
    formikProps: FormikProps<MailingBriefFormModel>;
    mailingHouseResponses: { [mailingHouseId: number]: MailingHouseForMailingBriefForm };
    isConvertingForecastJobToLive: boolean;
    mailingHouseId: number | null;
  } & (
    | {
        worksheetDependentFieldNamePrefix: 'sortedWorksheets';
        sortedWorksheetIndex: number;
      }
    | {
        worksheetDependentFieldNamePrefix: 'unsortedWorksheet' | 'internationalWorksheet';
      }),
) => {
  const mailingHouseId = props.mailingHouseId;
  const { formikProps } = props;
  const { values: formValues } = formikProps;

  const mailingHouseFieldName =
    props.worksheetDependentFieldNamePrefix === 'sortedWorksheets'
      ? `sortedWorksheets[${props.sortedWorksheetIndex}].mailingHouse`
      : `${props.worksheetDependentFieldNamePrefix}.mailingHouse`;

  useEffect(() => {
    if (Object.keys(props.mailingHouseResponses).length === 1) {
      formikProps.setFieldValue(
        `${mailingHouseFieldName}.mailingHouseId`,
        Object.values(props.mailingHouseResponses)[0].mailingHouseId,
      );
    }
  }, []);

  useEffect(() => {
    const newValue = mailingHouseId
      ? Object.values(props.mailingHouseResponses[mailingHouseId].contactsById).map(contact =>
          fromMailingHouseContactForJobForm(contact),
        )
      : [];
    formikProps.setFieldValue(`${mailingHouseFieldName}.contacts`, newValue);
  }, [mailingHouseId]);

  const mailingHouseOptions = useMemo(
    () =>
      map(props.mailingHouseResponses, ({ mailingHouseId: id, referenceNumber, name }) => ({
        value: id,
        text: `${referenceNumber} - ${name}`,
      })),
    [mailingHouseId],
  );

  const hasMultipleMailingHouses = Object.keys(props.mailingHouseResponses).length > 1;
  const hasMailingHouseAvailable = Object.keys(props.mailingHouseResponses).length > 0;

  if (!hasMailingHouseAvailable) {
    return (
      <WarningBox message="You have no mailing houses assigned - please contact your ONEPOST account manager" />
    );
  }

  const isForecast = formValues.forecastJobOption === 'Forecast';

  return (
    <>
      {hasMultipleMailingHouses && (
        <SearchSelectField<Id>
          label="Mailing house"
          name={`${mailingHouseFieldName}.mailingHouseId`}
          options={mailingHouseOptions}
          placeholder="Select a mailing house"
        />
      )}
      {!!mailingHouseId && (!isForecast || props.isConvertingForecastJobToLive) && (
        <>
          {props.worksheetDependentFieldNamePrefix === 'sortedWorksheets' ? (
            <MailingBriefMailingHouseContactsFieldArray
              sortedWorksheetIndex={assertNotNull(props.sortedWorksheetIndex)}
              mailingHouse={props.mailingHouseResponses[mailingHouseId]}
              mailingBriefFormModel={formValues}
              formikProps={props.formikProps}
              worksheetDependentFieldNamePrefix="sortedWorksheets"
            />
          ) : (
            <MailingBriefMailingHouseContactsFieldArray
              mailingHouse={props.mailingHouseResponses[mailingHouseId]}
              mailingBriefFormModel={formValues}
              formikProps={props.formikProps}
              worksheetDependentFieldNamePrefix={props.worksheetDependentFieldNamePrefix}
            />
          )}
          {props.worksheetDependentFieldNamePrefix === 'sortedWorksheets' && (
            <FieldSet label={'Carrier Delivery'}>
              <RadioButtonsField<WorksheetPrintingType>
                name={`sortedWorksheets[${props.sortedWorksheetIndex}].printingType`}
                label="Printing Type"
                options={printingTypeOptions}
              />
              {formValues.sortedWorksheets[assertNotNull(props.sortedWorksheetIndex)]
                .printingType === 'OnepostPrint' && (
                <SelectField<WorksheetLabelDeliveryService>
                  label="Label Delivery Service"
                  name={`sortedWorksheets[${props.sortedWorksheetIndex}].labelDeliveryService`}
                  options={labelDeliveryServiceOptions}
                />
              )}
            </FieldSet>
          )}
        </>
      )}
    </>
  );
};
