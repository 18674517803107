import { endsWith, some } from 'lodash';
import { Validator } from './validation';

export const maxFileSizeInMb = (
  maxSizeInMb: number,
  message: string = `Please choose a file smaller than ${maxSizeInMb}MB`,
): Validator<File> => (file: File) => (file.size > maxSizeInMb * 1024 * 1024 ? message : null);

export const hasFileExtension = (validExtensions: Array<string>): Validator<File> => (
  file: File,
) => {
  const hasExtension = some(validExtensions, extension =>
    endsWith(file.name.toLowerCase(), `.${extension.toLowerCase()}`),
  );
  return hasExtension
    ? null
    : validExtensions.length === 1
    ? `Please choose a file of type ${validExtensions[0]}`
    : `Please choose a file of one of the following types: ${validExtensions.join(', ')}`;
};
