import { some } from 'lodash';
import { DateStamp } from '../../../../models/dateStamp';
import { attachmentValidator } from '../../../../shared/files/attachment';
import { onOrAfter, requiredDate, todayOrLater } from '../../../../utils/validation/dateValidators';
import { nonNegativeInteger } from '../../../../utils/validation/numberValidators';
import { maxLength, notEmpty } from '../../../../utils/validation/stringValidators';
import {
  combineValidators,
  createArrayValidator,
  createValidator,
  Validator,
} from '../../../../utils/validation/validation';
import { noValidate, requiredSelect } from '../../../../utils/validation/validators';
import { UnsortedCellValidationMetadata } from '../cells/unsortedCell';
import { unsortedCellValidator } from '../cells/unsortedCellValidator';
import { UnsortedWorksheetFormModel } from './unsortedWorksheetFormModel';
import { unsortedWorksheetMailingHouseValidator } from './unsortedWorksheetMailingHouseFormModel';

// These are the only fields the validator needs, we use this broader type to allow different types
// of Customer object to be passed in for different use-cases.
type CustomerLike = {
  hasAgencyAgreementWithSecuredMail: boolean;
};

export const getUnsortedWorksheetValidator = (
  formModel: UnsortedWorksheetFormModel,
  customer: CustomerLike,
  unsortedCellValidation: UnsortedCellValidationMetadata,
  validateCustomerContacts: boolean = true,
) =>
  createValidator<UnsortedWorksheetFormModel>({
    estimatedValueInPounds: combineValidators<string>(notEmpty(), nonNegativeInteger()),
    isAgencyAgreement: agencyAgreementValidator(formModel, customer),
    collectionSchedule: requiredSelect(),
    invoiceSchedule: formModel.collectionSchedule === 'Daily' ? requiredSelect() : noValidate,
    firstCollectionDate: combineValidators<DateStamp | null>(
      requiredDate(),
      formModel.isNew ? todayOrLater() : noValidate,
    ),
    lastCollectionDate:
      formModel.collectionSchedule === 'Daily'
        ? combineValidators<DateStamp | null>(
            requiredDate(),
            formModel.isNew ? todayOrLater() : noValidate,
            onOrAfter(
              formModel.firstCollectionDate,
              'Must be later than the First Collection Date',
            ),
          )
        : noValidate,
    collectionPresentation: requiredSelect(),
    collectionType: requiredSelect(),
    processingDateOption: requiredSelect(),
    processingCentre: requiredSelect(),
    notes: maxLength(2000),
    customCollectionEmailContent: maxLength(2000),
    mailingHouse: formModel.collectFromCustomer
      ? noValidate
      : unsortedWorksheetMailingHouseValidator,
    customerContacts: validateCustomerContacts
      ? customerContacts =>
          some(customerContacts, c => c.includeOnCommunications)
            ? null
            : 'At least one customer contact must be selected'
      : noValidate,
    cells: createArrayValidator(cell =>
      unsortedCellValidator(cell, formModel, unsortedCellValidation),
    ),
    attachments: createValidator({
      Unsorted: createArrayValidator(() => attachmentValidator),
    }),
  });

const agencyAgreementValidator = (
  formModel: UnsortedWorksheetFormModel,
  customer: CustomerLike,
): Validator<boolean> => (isAgencyAgreement: boolean) =>
  isAgencyAgreement && !customer.hasAgencyAgreementWithSecuredMail
    ? 'Customer does not have an agency agreement with Secured Mail'
    : null;
