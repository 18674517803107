import { Redirect, Router } from '@reach/router';
import * as React from 'react';
import { AppContainer } from '../../AppContainer';
import { PortalRoots } from '../../PortalRoots';
import { ClientPortalErrorBoundary } from '../../shared/ErrorBoundary';
import { NotificationsProvider } from '../../shared/notifications/NotificationsProvider';
import { assertNotNull } from '../../utils/assertNotNull';
import { RouteComponentProps } from '../../utils/RouteComponentProps';
import { AccessCodePageLayout } from '../layout/AccessCodePageLayout';
import { LoggedOut } from '../LoggedOut';
import { NotFound } from '../NotFound';
import { ClientPortalAuthentication } from './authentication/ClientPortalAuthentication';
import { ClientPortalLogIn } from './authentication/ClientPortalLogIn';
import { ClientPortalSilentTokenRefresh } from './authentication/ClientPortalSilentTokenRefresh';
import { ClientPortalLayout } from './ClientPortalLayout';
import { ClientPortalMetadataContextProvider } from './ClientPortalMetadataContextProvider';
import { clientPortalUrl } from './clientPortalUrls';
import { ClientPortalJob } from './jobs/ClientPortalViewJob';
import { ClientPortalJobs } from './jobs/ClientPortalViewJobs';
import { ClientPortalMailingBrief } from './mailing-brief/ClientPortalMailingBriefScreen';
import { MailingHouseDataAttachmentsDownload } from './mailing-house-data-download/MailingHouseDataAttachmentsDownload';
import { RedirectToClientPortalHomePage } from './RedirectToClientPortalHomePage';
import { UpdateClientPortalPassword } from './update-password/UpdateClientPortalPassword';

export const ClientPortalApp = () => (
  <>
    <AppContainer>
      <ClientPortalErrorBoundary>
        <Router primary={true}>
          <ClientPortalRoutes path="/portal/*" />
          <Redirect from="/*" to="/portal" noThrow={true} />
        </Router>
      </ClientPortalErrorBoundary>
    </AppContainer>
    <PortalRoots />
  </>
);

const ClientPortalRoutes = (props: RouteComponentProps) => (
  <Router primary={true}>
    <AuthenticatedRoutes path="/*" />
    <DataDownloadSection path="data-download/:accessCode" />
    <ClientPortalSilentTokenRefresh path="token-refresh" />
    <LoggedOut path="logged-out" returnPath={clientPortalUrl} />
  </Router>
);

const AuthenticatedRoutes = (props: RouteComponentProps) => (
  <ClientPortalAuthentication>
    {authentication => (
      <ClientPortalLogIn {...authentication}>
        <ClientPortalMetadataContextProvider>
          <NotificationsProvider>
            <ClientPortalLayout>
              <Router primary={false}>
                <RedirectToClientPortalHomePage path="/" />
                <ClientPortalJobs path="/jobs" />
                <ClientPortalJob path="/jobs/:jobId" />
                <ClientPortalMailingBrief
                  path="/mailing-brief"
                  isConvertingForecastJobToLive={false}
                />
                <ClientPortalMailingBrief
                  path="/mailing-brief/:jobId"
                  isConvertingForecastJobToLive={false}
                />
                <ClientPortalMailingBrief
                  path="/convert-forecast-job-to-live/:jobId"
                  isConvertingForecastJobToLive={true}
                />
                <UpdateClientPortalPassword path="/change-password" />
                <NotFound default={true} homeUrl={clientPortalUrl} />
              </Router>
            </ClientPortalLayout>
          </NotificationsProvider>
        </ClientPortalMetadataContextProvider>
      </ClientPortalLogIn>
    )}
  </ClientPortalAuthentication>
);

const DataDownloadSection = (props: RouteComponentProps<{ accessCode: string }>) => (
  <AccessCodePageLayout>
    <MailingHouseDataAttachmentsDownload accessCode={assertNotNull(props.accessCode)} />
  </AccessCodePageLayout>
);
