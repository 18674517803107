import { FormikErrors } from 'formik';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useContext } from 'react';
import { SubmitButton } from '../buttons/SubmitButton';
import { FormButtonGroup } from './Form';
import { FormErrorBox } from './FormErrorBox';
import { FormSubmissionContext } from './FormSubmissionContext';

type OwnProps = {
  errors: FormikErrors<unknown>;
  isSubmitting: boolean;
  submitButtonText: string;
};

type Props = OwnProps;

export const errorMessage =
  'Some fields on this form are not valid. Please correct the errors above before submitting.';

export const FormSubmit = (props: Props) => {
  const formSubmissionContext = useContext(FormSubmissionContext);

  return (
    <>
      {formSubmissionContext.hasBeenSubmitted && !isEmpty(props.errors) && (
        <FormErrorBox error={errorMessage} />
      )}
      <FormButtonGroup>
        <SubmitButton isSubmitting={props.isSubmitting}>{props.submitButtonText}</SubmitButton>
      </FormButtonGroup>
    </>
  );
};
