import Downshift, { GetInputPropsOptions } from 'downshift';
import { FieldProps } from 'formik';
import { debounce } from 'lodash';
import * as React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as CrossIcon } from '../../../images/icons/cross-icon.svg';
import { black, darkGrey, greyHoverAccent, white } from '../../../styling/colours';
import { narrow } from '../../../styling/spacing';
import { memoize } from '../../../utils/memoize/memoize';
import { FormField, InlineFormField } from '../FormField';
import { Input } from './InputField';
import {
  defaultMapValueToKey,
  getSelectOptionsByKey,
  isNonNullValueToKeyMapper,
  SelectOption,
  SelectOptions,
  ValueToKeyMapper,
} from './Select';

export type Props<TValue> = (
  | { label: string; inline?: false }
  | { label?: undefined; inline: true }) & {
  name: string;
  options: Array<SelectOption<TValue>>;
  optional?: boolean;
  disabled?: boolean;
  placeholder?: string;
  filterDebounceDurationMilliseconds?: number;
  onChange?: (oldValue: TValue | null, newValue: TValue | null) => void;
} & (TValue extends string | number
    ? { mapValueToKey?: ValueToKeyMapper<TValue> }
    : { mapValueToKey: ValueToKeyMapper<TValue> });

type State<TValue> = {
  inputValue: string;
  filteredOptions: Array<SelectOption<TValue>>;
};

export class SearchSelectField<TValue> extends React.Component<Props<TValue>, State<TValue>> {
  state = { inputValue: '', filteredOptions: this.props.options };

  componentWillUnmount() {
    this.debouncedSetFilteredOptions.cancel();
  }

  getOptionsByKey = memoize(
    (options: SelectOptions<TValue>, getKeyFromValue: ValueToKeyMapper<TValue>) =>
      getSelectOptionsByKey(options, getKeyFromValue),
  );

  setFilteredOptions = () =>
    this.setState(({ inputValue }) => ({
      filteredOptions: this.props.options.filter(
        option => !inputValue || option.text.toLowerCase().includes(inputValue.toLowerCase()),
      ),
    }));

  debouncedSetFilteredOptions = debounce(
    this.setFilteredOptions,
    this.props.filterDebounceDurationMilliseconds || 250,
  );

  setFieldValueToNull = ({ field, form }: FieldProps<TValue>): null => {
    const { onChange } = this.props;
    if (onChange != null) {
      onChange(field.value, null);
    }

    form.setFieldValue(this.props.name, null);

    return null;
  };

  onInputValueChange = (inputValue: string) =>
    this.setState({ inputValue }, this.debouncedSetFilteredOptions);

  onChange = ({ form, field }: FieldProps) => (newValue: TValue | null) => {
    const { onChange } = this.props;
    if (onChange != null) {
      onChange(field.value, newValue);
    }

    form.setFieldValue(this.props.name, newValue);
  };

  onBlur = (inputProps: GetInputPropsOptions, { field }: FieldProps) => (
    event: React.FocusEvent<HTMLInputElement>,
  ) => {
    if (inputProps.onBlur != null) {
      inputProps.onBlur(event);
    }
    field.onBlur(event);
  };

  render() {
    const { label, name, optional, disabled, placeholder, options, inline } = this.props;

    const mapValueToKey = isNonNullValueToKeyMapper<TValue>(this.props.mapValueToKey)
      ? this.props.mapValueToKey
      : defaultMapValueToKey;

    const optionsByKey = this.getOptionsByKey(options, mapValueToKey);

    const SearchSelectFormField = inline ? InlineFormField : FormField;

    return (
      <SearchSelectFormField
        name={name}
        label={label == null ? '' : label}
        optional={optional}
        disabled={disabled}
      >
        {({ field, form, valid, invalid, showWarning }) => (
          <Downshift
            itemToString={(item: TValue) =>
              item == null || optionsByKey[mapValueToKey(item)] == null
                ? ''
                : optionsByKey[mapValueToKey(item)].text
            }
            onChange={this.onChange({ field, form })}
            selectedItem={field.value}
            initialSelectedItem={
              field.value !== null && optionsByKey[mapValueToKey(field.value)] == null
                ? this.setFieldValueToNull({ field, form })
                : field.value
            }
            onInputValueChange={this.onInputValueChange}
          >
            {({
              getInputProps,
              getItemProps,
              getMenuProps,
              isOpen,
              inputValue,
              highlightedIndex,
              selectedItem,
              clearSelection,
            }) => {
              const filteredOptions = this.state.filteredOptions;

              return (
                <div>
                  <SearchSelectFieldContainer>
                    <SearchSelectFieldInput
                      {...field}
                      {...getInputProps()}
                      onBlur={this.onBlur(getInputProps(), { field, form })}
                      id={name}
                      disabled={disabled || form.isSubmitting}
                      valid={valid}
                      invalid={invalid}
                      showWarning={showWarning}
                      placeholder={placeholder}
                      data-testid={`${name}.searchSelectInput`}
                      inline={inline}
                    />
                    {isOpen && inputValue && (
                      <OptionsContainer {...getMenuProps()}>
                        {!filteredOptions.length ? (
                          <Option highlighted={false}>No results</Option>
                        ) : (
                          filteredOptions.map((option, index) => (
                            <Option
                              key={option.value}
                              highlighted={index === highlightedIndex}
                              {...getItemProps({
                                key: mapValueToKey(option.value),
                                index,
                                item: option.value,
                              })}
                              data-testid={searchSelectFieldOptionTestId(name, option.value)}
                            >
                              {option.text}
                            </Option>
                          ))
                        )}
                      </OptionsContainer>
                    )}
                    {selectedItem != null && !disabled && (
                      <ClearSelectionButton
                        onClick={() => clearSelection()}
                        offset={inline ? 8 : 14}
                        data-testid={searchSelectFieldClearButtonTestId(name)}
                      />
                    )}
                  </SearchSelectFieldContainer>
                </div>
              );
            }}
          </Downshift>
        )}
      </SearchSelectFormField>
    );
  }
}

export const searchSelectFieldClearButtonTestId = (fieldName: string) =>
  `searchSelectFieldCloseButton-${fieldName}`;

export const searchSelectFieldOptionTestId = <TValue extends unknown>(
  fieldName: string,
  value: TValue,
) => `${fieldName}.searchSelectOption.${JSON.stringify(value)}`;

const SearchSelectFieldContainer = styled.div`
  position: relative;
`;

const SearchSelectFieldInput = styled(Input)`
  padding-right: ${props => (props.inline ? 35 : 45)}px;
`;

const ClearSelectionButton = styled(CrossIcon)<{ offset: number }>`
  position: absolute;
  right: ${props => props.offset}px;
  top: ${props => props.offset}px;
  height: 22px;
  width: 22px;
  padding: 5px;
  cursor: pointer;
  color: ${darkGrey};

  &:hover {
    color: ${greyHoverAccent};
  }
`;

// These have been chosen to match the colours on the <select /> element in Chrome
const dropdownBorderColour = '#7A9CD3';
const dropdownOptionHighlightColour = '#1E90FF';

const OptionsContainer = styled.div`
  border: solid 1px ${dropdownBorderColour};
  position: absolute;
  z-index: 1;
  width: 100%;
  max-height: 250px;
  overflow-y: scroll;
  text-overflow: ellipsis;
`;

const Option = styled.div<{ highlighted: boolean }>`
  background-color: ${props => (props.highlighted ? dropdownOptionHighlightColour : white)};
  color: ${props => (props.highlighted ? white : black)};
  padding: 0 ${narrow};
`;
