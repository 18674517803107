import { SortedCellMetadata } from '../../../../models/metadata';
import { assertIsNumber } from '../../../../utils/assertIsNumber';
import { assertNotNull } from '../../../../utils/assertNotNull';
import { Validator } from '../../../../utils/validation/validation';
import { ConsumableLimitationCode } from '../../../mailing-houses/mailingHouse';
import { CreateUpdateSortedCellDto } from '../../../worksheets/sorted/cells/createUpdateSortedCellDto';
import {
  emptySortedCellFormModel,
  getCellValidatorWithOptions,
  SortedCellFormModel,
} from '../../../worksheets/sorted/cells/sortedCellFormModel';

export type MailingBriefSortedCellFormModel = SortedCellFormModel;

export const getInitialMailingBriefSortedCellFormModel = (): MailingBriefSortedCellFormModel => {
  return emptySortedCellFormModel;
};

export const getMailingBriefSortedCellValidator = (
  cell: MailingBriefSortedCellFormModel,
  consumableLimitationCode: ConsumableLimitationCode,
  worksheetIsForecast: boolean,
  hasAgencyAgreement: boolean,
  collectionDate: Date | null,
  metadata: SortedCellMetadata,
): Validator<MailingBriefSortedCellFormModel> => {
  return getCellValidatorWithOptions(
    cell,
    'SecuredMail',
    consumableLimitationCode,
    'OnepostPrint',
    worksheetIsForecast,
    hasAgencyAgreement,
    false,
    false,
    collectionDate,
    metadata,
  );
};

export const toCreateUpdateSortedCellDto = (
  cell: MailingBriefSortedCellFormModel,
  displayOrder: number,
): CreateUpdateSortedCellDto => ({
  sortedCellId: cell.sortedCellId,
  displayOrder,
  cellReference: cell.cellReference,
  serviceType: assertNotNull(cell.serviceType),
  format: assertNotNull(cell.format),
  variant: assertNotNull(cell.variant),
  quantity: assertIsNumber(cell.quantity),
  weightInGrams: assertIsNumber(cell.weightInGrams),
  presentation: assertNotNull(cell.presentation),
  consumable: assertNotNull(cell.consumable),
  maxPresentationWeightKg: assertIsNumber(cell.maxPresentationWeightKg),
  maxPresentationFill: assertIsNumber(cell.maxPresentationFill),
  bundleLabelFormat: cell.bundleLabelFormat,
  mailmarkBarcode: cell.mailmarkBarcode,
});
