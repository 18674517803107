import { isEqual } from 'lodash';
import * as React from 'react';
import { Component, ComponentClass, ComponentType } from 'react';
import { getComponentDisplayName } from '../../utils/getComponentDisplayName';

export type OnMountEnhancer<Props> = (
  wrappedComponent: ComponentType<Props>,
) => ComponentType<Props>;

export const dispatchOnPropsChange = <OwnProps, Params>(
  actionProducer: (props: OwnProps) => (params: Params) => void,
  mapPropsToParams: (props: OwnProps) => Params,
): OnMountEnhancer<OwnProps> => (
  WrappedComponent: ComponentType<OwnProps>,
): ComponentClass<OwnProps> =>
  class DispatchOnChangeWrapper extends Component<OwnProps> {
    static displayName = `dispatchOnPropsChange(${getComponentDisplayName(WrappedComponent)})`;

    componentDidMount() {
      actionProducer(this.props)(mapPropsToParams(this.props));
    }

    componentDidUpdate(prevProps: OwnProps) {
      const prevParams = mapPropsToParams(prevProps);
      const newParams = mapPropsToParams(this.props);
      if (!isEqual(prevParams, newParams)) {
        actionProducer(this.props)(newParams);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
