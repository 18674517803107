import { LocationProvider } from '@reach/router';
import * as React from 'react';
import { MatrixErrorBoundary } from './shared/ErrorBoundary';
import { GlobalStyles } from './styling/GlobalStyles';
import { appHistory } from './utils/routing';

export const AppContainer = (props: { children: React.ReactNode }) => (
  <React.StrictMode>
    <MatrixErrorBoundary>
      <GlobalStyles />
      <LocationProvider history={appHistory}>{props.children}</LocationProvider>
    </MatrixErrorBoundary>
  </React.StrictMode>
);
