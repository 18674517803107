// Note that this is perhaps better thought of as an 'IncludeOnWorksheetsCode'
import { getSelectOptions } from '../../shared/form/inputs/Select';

export type IncludeOnJobsCode = 'Optionally' | 'Never' | 'Always';

export const includeOnJobsCodes: Array<IncludeOnJobsCode> = ['Optionally', 'Never', 'Always'];

export const includeOnJobsDisplayNameByCode: { [code in IncludeOnJobsCode]: string } = {
  Optionally: 'Optionally',
  Never: 'Never',
  Always: 'Always',
};

export const includeOnJobsOptions = getSelectOptions(
  includeOnJobsCodes,
  includeOnJobsDisplayNameByCode,
);
