import { createGlobalStyle } from 'styled-components/macro';
import { assertNotNull } from '../utils/assertNotNull';
import { fontGrey } from './colours';
import { lightFontFamily, regularFont, regularFontFamily, regularWeight } from './fonts';
import { medium } from './spacing';

export const sidebarIsOpenClassName = 'sidebar-is-open';
export const modalIsOpenClassName = 'modal-is-open';

export const getDocumentHtmlNode = () =>
  assertNotNull(document.getElementsByTagName('html').item(0));

export const GlobalStyles = createGlobalStyle`
  html, body {
    box-sizing: border-box;
    font-family: ${regularFontFamily};
    font-size: ${regularFont};
    font-weight: ${regularWeight};
    line-height: 1.3;
    color: ${fontGrey};
    margin: 0;
  }

  html.${sidebarIsOpenClassName}, html.${modalIsOpenClassName} {
    overflow-y: hidden;
    
    body {
      overflow-y: scroll;
    }
  }

  html {
    height: 100%;
    overflow-y: scroll;
  }
  
  body, #root {
    min-height: 100%;
  }
    
  *, *:before, *:after {
    box-sizing: inherit;
  }
    
  h1, h2, h3, h4, h5, h6 {
    font-family: ${lightFontFamily};
    margin-bottom: ${medium};
    margin-top: 0;
    font-weight: ${regularWeight};
  }
`;
