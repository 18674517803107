import { isInteger } from 'lodash';
import { combineValidators, Validator } from './validation';

export const maxIntegerValue = 999999999;

const lessThanOrEqualToMaxValue = (): Validator<string | null | undefined> => (
  value,
): string | null => {
  if (value == null || value.trim() === '') {
    return null;
  }
  const valueAsNumber = Number(value.trim());

  if (valueAsNumber > maxIntegerValue) {
    return `Please enter a number less than or equal to ${maxIntegerValue.toLocaleString()}`;
  }

  return null;
};

const unboundedInteger = (): Validator<string | null | undefined> => (value): string | null => {
  if (value == null || value.trim() === '') {
    return null;
  }
  const valueAsNumber = Number(value.trim());

  if (!isInteger(valueAsNumber)) {
    return 'Please enter a whole number';
  }

  return null;
};

export const integer = () => combineValidators(unboundedInteger(), lessThanOrEqualToMaxValue());

export const numberInRange = (
  min: number,
  max: number,
  belowBoundaryMessage?: string,
  aboveBoundaryMessage?: string,
): Validator<string | null | undefined> => (value): string | null => {
  if (value == null || value.trim() === '') {
    return null;
  }

  const valueAsNumber = Number(value.trim());

  if (isNaN(valueAsNumber)) {
    return 'Please enter a number';
  }

  if (valueAsNumber < min) {
    return belowBoundaryMessage
      ? belowBoundaryMessage
      : `Please enter a number greater than or equal to ${min.toLocaleString()}`;
  }
  if (valueAsNumber > max) {
    return aboveBoundaryMessage
      ? aboveBoundaryMessage
      : `Please enter a number less than or equal to ${max.toLocaleString()}`;
  }

  return null;
};

export const numberGreaterThan = (min: number): Validator<string | null | undefined> => (
  value,
): string | null => {
  if (value == null || value.trim() === '') {
    return null;
  }

  const valueAsNumber = Number(value.trim());

  if (isNaN(valueAsNumber)) {
    return 'Please enter a number';
  }

  if (valueAsNumber <= min) {
    return `Please enter a number greater than ${min.toLocaleString()}`;
  }

  return null;
};

export const integerInRange = (min: number, max: number) =>
  combineValidators(numberInRange(min, max), unboundedInteger());

export const unboundedPositiveNumber = (): Validator<string | null | undefined> => (
  value,
): string | null => {
  if (value == null || value.trim() === '') {
    return null;
  }

  const valueAsNumber = Number(value.trim());

  if (isNaN(valueAsNumber)) {
    return 'Please enter a number';
  }

  if (valueAsNumber <= 0) {
    return 'Please enter a positive number';
  }

  return null;
};

export const positiveNumber = () =>
  combineValidators(unboundedPositiveNumber(), lessThanOrEqualToMaxValue());

export const positiveInteger = (): Validator<string | null | undefined> =>
  combineValidators(unboundedInteger(), unboundedPositiveNumber(), lessThanOrEqualToMaxValue());

export const nonNegativeInteger = (): Validator<string | null | undefined> =>
  integerInRange(0, maxIntegerValue);

export const negativeNumber = (): Validator<string | null | undefined> => (
  value,
): string | null => {
  if (value == null || value.trim() === '') {
    return null;
  }

  const valueAsNumber = Number(value.trim());

  if (isNaN(valueAsNumber)) {
    return 'Please enter a number';
  }

  if (valueAsNumber >= 0) {
    return 'Please enter a negative number';
  }

  return null;
};

export const currency = (
  message: string = 'Please enter a cost in pounds and pence',
): Validator<string | null | undefined> => decimal(2, message);

export const currencyInRange = (min: number, max: number): Validator<string | null | undefined> =>
  combineValidators(currency(), numberInRange(min, max));

const decimalRegEx = (precision: number) => new RegExp(`^(-)?([0-9])*(\\.[0-9]{0,${precision}})?$`);

export const decimal = (
  precision: number,
  message: string = `Please enter no more than ${precision} decimal digits`,
): Validator<string | null | undefined> => (value): string | null =>
  value != null && value.trim() !== '' && !decimalRegEx(precision).test(value) ? message : null;
