import * as React from 'react';
import styled from 'styled-components/macro';
import { Badge } from '../../../shared/Badge';
import { SectionHeader } from '../../../shared/layout/Headers';
import { categoryBlue, categoryBlueLight } from '../../../styling/colours';
import { bold, largeFont } from '../../../styling/fonts';
import { medium, wide, wider } from '../../../styling/spacing';
import { worksheetTypeDisplayNames } from '../../worksheets/worksheet';
import {
  ClientPortalWorksheet,
  ClientPortalWorksheetStatusDisplayNames,
} from './ClientPortalWorksheet';

type Props = {
  worksheet: ClientPortalWorksheet;
  sortedWorksheetIndex?: number;
};

export const ClientPortalWorksheetDetailsHeader = ({ worksheet }: Props) => (
  <>
    <WorksheetHeader>
      {worksheetTypeDisplayNames[worksheet.type] + ' Worksheet ' + worksheet.worksheetReference}
    </WorksheetHeader>
    <StatusBadge foregroundColour={categoryBlue} backgroundColour={categoryBlueLight}>
      {ClientPortalWorksheetStatusDisplayNames[worksheet.statusCode]}
    </StatusBadge>
  </>
);

const WorksheetHeader = styled(SectionHeader)`
  font-weight: ${bold};
  margin-top: ${wider};
  margin-bottom: ${wide};
  width: 100%;
`;

const StatusBadge = styled(Badge)`
  display: inline-block;
  margin-left: ${medium};
  margin-bottom: ${medium};
  font-size: ${largeFont};
`;
