import { Match } from '@reach/router';
import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { Link } from '../../../shared/navigation/Link';
import { categoryRed, categoryRedLight, fontGrey, red } from '../../../styling/colours';
import { bold, smallFont } from '../../../styling/fonts';
import { narrow, wider } from '../../../styling/spacing';

export const navLinkFontSize = '16px';

export const NavLink = ({
  to,
  inlineTaskCount,
  taskCount,
  children,
}: {
  to: string;
  inlineTaskCount?: boolean;
  taskCount?: number | null;
  children: React.ReactNode;
}) => (
  <Match path={to}>
    {({ match }) => (
      <StyledNavLink to={to} isActive={match != null}>
        {children}
        {taskCount != null && match == null && (
          <CounterBadge inline={inlineTaskCount}>{taskCount}</CounterBadge>
        )}
      </StyledNavLink>
    )}
  </Match>
);

// We need to strip out the isActive prop used for styling to avoid an error from Reach Router
const LinkWrapper = ({
  isActive,
  ...rest
}: {
  isActive?: boolean;
  to?: string;
  children: React.ReactNode;
  onClick?: () => void;
}) => <Link {...rest} />;

export const StyledNavLink = styled(LinkWrapper)<{ isActive?: boolean }>`
  &:not(:last-child) {
    margin-right: ${wider};
  }

  color: ${props => (props.isActive ? red : fontGrey)};
  font-weight: ${bold};
  font-size: ${navLinkFontSize};

  &:hover {
    color: ${red};
  }

  position: relative;
  cursor: pointer;
`;

export const CounterBadge = styled.span<{ inline: boolean | undefined }>`
  color: ${categoryRed};
  background: ${categoryRedLight};
  padding: 0 5px;

  border-radius: 12px;
  font-size: ${smallFont};

  ${props =>
    props.inline &&
    css`
      margin-left: ${narrow};
    `}

  ${props =>
    !props.inline &&
    css`
      position: absolute;
      top: -10px;
      left: calc(100% - 5px);
    `}
`;
