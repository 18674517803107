import * as React from 'react';
import styled from 'styled-components/macro';
import {
  blue,
  blueHoverAccent,
  darkGrey,
  greyHoverAccent,
  lightGrey,
  white,
} from '../../styling/colours';
import { smallFont } from '../../styling/fonts';
import { medium, wide } from '../../styling/spacing';
import { regularTransitionDuration } from '../../styling/transitions';
import { Link } from '../navigation/Link';

export type ButtonProps = {
  onClick?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  size?: 'normal' | 'small';
};

export const standardButtonHeight = '40px';
export const smallButtonHeight = '25px';

const BaseButton = (
  props: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
) => <button {...props} type={props.type || 'button'} />;

export const Button = styled(BaseButton)<ButtonProps>`
  position: relative;
  font-family: inherit;
  font-size: ${props => (props.size === 'small' ? smallFont : 'inherit')};
  letter-spacing: ${props => (props.size === 'small' ? '0.25px' : 'inherit')};
  line-height: inherit;

  border: none;

  height: ${props => (props.size === 'small' ? smallButtonHeight : standardButtonHeight)};
  padding: 0 ${props => (props.size === 'small' ? medium : wide)};
  transition: all ${regularTransitionDuration} ease;

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.4 : null)};
`;

export const PrimaryButton = styled(Button)`
  background-color: ${blue};
  color: ${white};

  &:hover,
  &:focus {
    background-color: ${props => !props.disabled && blueHoverAccent};
    outline: none;
  }
`;

export const SecondaryButton = styled(Button)`
  background-color: ${darkGrey};
  color: ${white};

  &:hover,
  &:focus {
    background-color: ${props => !props.disabled && greyHoverAccent};
    outline: none;
  }
`;

export const HollowButton = styled(Button)`
  background-color: transparent;
  color: ${blue};
  border: 1px solid ${blue};

  &:hover,
  &:focus {
    color: ${props => !props.disabled && blueHoverAccent};
    border-color: ${props => !props.disabled && blueHoverAccent};
    outline: none;
  }
`;

export const BorderlessHollowButton = styled(HollowButton)`
  border: none;

  &:hover,
  &:focus {
    background-color: ${props => !props.disabled && lightGrey};
  }
`;

export const ButtonGroup = styled.div`
  ${Button}, ${Link} {
    margin-right: ${medium};

    &:last-of-type {
      margin-right: 0;
    }
  }

  margin-bottom: ${wide};
`;
