import * as React from 'react';
import styled from 'styled-components/macro';
import { Dd, Dl, DlItem, Dt } from '../../../../shared/DefinitionList';
import { ExpandableSection } from '../../../../shared/ExpandableSection';
import { Panel } from '../../../../shared/Panel';
import { medium } from '../../../../styling/spacing';
import { ClientPortalWorksheetMailingHouseDetails } from '../ClientPortalWorksheetMailingHouseDetails';
import { ClientPortalUnsortedWorksheet } from './ClientPortalUnsortedWorksheet';
import { ClientPortalUnsortedWorksheetContactsTable } from './ClientPortalUnsortedWorksheetContactsTable';

type Props = {
  unsortedWorksheet: ClientPortalUnsortedWorksheet;
};

export const ClientPortalUnsortedWorksheetMailingHouseDetails = ({ unsortedWorksheet }: Props) => {
  const { mailingHouse, mailingHouseContacts } = unsortedWorksheet;

  if (mailingHouse === null) {
    return (
      <>
        <div>There are no mailing houses for this unsorted element.</div>
      </>
    );
  }

  const selectedContacts = mailingHouseContacts.filter(contact => contact.includeOnCommunications);
  const unselectedContacts = mailingHouseContacts.filter(
    contact => !contact.includeOnCommunications,
  );

  return (
    <Panel>
      <ClientPortalWorksheetMailingHouseDetails mailingHouse={mailingHouse} />
      <InstructionsDl>
        <DlItem horizontal={false}>
          <Dt>Instructions</Dt>
          <Dd multiline={true}>{mailingHouse.instructions}</Dd>
        </DlItem>
      </InstructionsDl>
      <ClientPortalUnsortedWorksheetContactsTable contacts={selectedContacts} />
      {unselectedContacts.length > 0 && (
        <UnselectedContacts title="Unselected Contacts" initiallyExpanded={false}>
          <ClientPortalUnsortedWorksheetContactsTable contacts={unselectedContacts} />
        </UnselectedContacts>
      )}
    </Panel>
  );
};

const InstructionsDl = styled(Dl)`
  margin-top: ${medium};
`;

const UnselectedContacts = styled(ExpandableSection)`
  margin-top: ${medium};
  margin-bottom: 0;
`;
