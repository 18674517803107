import * as React from 'react';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import { ReactComponent as CloseIcon } from '../../images/icons/cross-icon.svg';
import { MatrixErrorBoundary } from '../../shared/ErrorBoundary';
import { HeaderActionsDisplay } from '../../shared/header-actions/HeaderActionsDisplay';
import { HeaderActionsProvider } from '../../shared/header-actions/HeaderActionsProvider';
import { lightGreyHoverAccent } from '../../styling/colours';
import { getDocumentHtmlNode, sidebarIsOpenClassName } from '../../styling/GlobalStyles';
import { widePixels } from '../../styling/spacing';

export const sidebarAnimationDurationMilliseconds = 400;

export const sidebarBackdropTestId = 'sidebar-backdrop';
export const sidebarContainerTestId = 'sidebar-container';
export const closeSidebarButtonTestId = 'close-sidebar-button';

type SidebarProps = {
  sidebarIsOpen: boolean;
  closeSidebar: () => void;
  location?: string;
  children?: React.ReactNode;
};

export const sidebarPortalRootElementId = 'sidebar-portal-root';

export class Sidebar extends React.Component<SidebarProps> {
  documentHtmlNode = getDocumentHtmlNode();

  focusAnchorRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeydown);
  }

  componentDidUpdate(previousProps: SidebarProps) {
    const wasPreviouslyOpen = previousProps.sidebarIsOpen;
    const isNowOpen = this.props.sidebarIsOpen;
    const locationHasChanged = previousProps.location !== this.props.location;

    if (locationHasChanged) {
      this.props.closeSidebar();
    } else if (!wasPreviouslyOpen && isNowOpen) {
      this.addSidebarIsOpenClassNameToDocumentBody();
      this.focusOnFocusAnchor();
    } else if (wasPreviouslyOpen && !isNowOpen) {
      this.removeSidebarIsOpenClassNameFromDocumentBody();
    }
  }

  componentWillUnmount() {
    this.removeSidebarIsOpenClassNameFromDocumentBody();
    document.removeEventListener('keydown', this.handleKeydown);
  }

  handleKeydown = (event: Event) => {
    if (event.defaultPrevented) {
      return;
    }

    const keyboardEvent = (event as unknown) as React.KeyboardEvent;
    if (keyboardEvent.key === 'Escape' && this.props.sidebarIsOpen) {
      this.props.closeSidebar();
    }
  };

  addSidebarIsOpenClassNameToDocumentBody = () =>
    this.documentHtmlNode.classList.add(sidebarIsOpenClassName);

  removeSidebarIsOpenClassNameFromDocumentBody = () =>
    this.documentHtmlNode.classList.remove(sidebarIsOpenClassName);

  focusOnFocusAnchor = () => {
    const focusAnchor = this.focusAnchorRef.current;
    if (focusAnchor != null) {
      focusAnchor.focus();
    }
  };

  render() {
    const { sidebarIsOpen, closeSidebar, children } = this.props;

    const sidebarPortalRoot = document.getElementById(sidebarPortalRootElementId);
    if (sidebarPortalRoot == null) {
      throw new Error('Could not find sidebar portal root');
    }

    return ReactDOM.createPortal(
      <>
        <SidebarBackdrop
          sidebarIsOpen={sidebarIsOpen}
          onClick={closeSidebar}
          data-testid={sidebarBackdropTestId}
        />
        <HeaderActionsProvider>
          <SidebarContainer
            sidebarIsOpen={sidebarIsOpen}
            data-testid={sidebarContainerTestId}
            role="dialog"
            aria-label="Sidebar."
            id={sidebarContainerElementId}
          >
            <MatrixErrorBoundary>
              <FocusAnchor tabIndex={-1} ref={this.focusAnchorRef} />
              <ActionsContainer>
                <HeaderActionsDisplay />
                <CloseButton
                  onClick={closeSidebar}
                  data-testid={closeSidebarButtonTestId}
                  aria-label="Close sidebar button."
                >
                  <CloseButtonIcon />
                </CloseButton>
              </ActionsContainer>
              {children}
            </MatrixErrorBoundary>
          </SidebarContainer>
        </HeaderActionsProvider>
      </>,
      sidebarPortalRoot,
    );
  }
}

type SidebarBackdropProps = {
  sidebarIsOpen: boolean;
};

const SidebarBackdrop = styled.div<SidebarBackdropProps>`
  opacity: ${props => (props.sidebarIsOpen ? 1 : 0)};
  transition: opacity ${sidebarAnimationDurationMilliseconds}ms ease;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background: hsla(220, 100%, 3%, 50%);
`;

type SidebarContainerProps = {
  sidebarIsOpen: boolean;
};

const largeScreenMinWidth = '1600px';
const sidebarContainerWidthOnLargeScreen = '1275px';
export const sidebarContainerWidthOnNormalScreen = '1000px';

const sidebarContainerPaddingPixels = widePixels;

export const sidebarContainerElementId = 'sidebar-container';

const SidebarContainer = styled.div<SidebarContainerProps>`
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  padding: ${sidebarContainerPaddingPixels}px;
  background-color: white;
  transition: all ${sidebarAnimationDurationMilliseconds}ms ease;
  max-width: 100vw;

  width: ${sidebarContainerWidthOnNormalScreen};
  transform: translateX(
    ${props => (props.sidebarIsOpen ? '0' : `-${sidebarContainerWidthOnNormalScreen}`)}
  );

  @media (min-width: ${largeScreenMinWidth}) {
    width: ${sidebarContainerWidthOnLargeScreen};
    transform: translateX(
      ${props => (props.sidebarIsOpen ? '0' : `-${sidebarContainerWidthOnLargeScreen}`)}
    );
  }
`;

const FocusAnchor = styled.div`
  height: 0;
  width: 0;
`;

export const sidebarActionsContainerHeightPixels = 70;

const ActionsContainer = styled.div`
  position: sticky;
  top: ${-1 * sidebarContainerPaddingPixels}px;
  margin: ${-1 * sidebarContainerPaddingPixels}px;
  margin-bottom: ${sidebarContainerPaddingPixels}px;
  height: ${sidebarActionsContainerHeightPixels}px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 ${sidebarContainerPaddingPixels}px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 2px 0;
  background-color: white;
  z-index: 1;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color ${sidebarAnimationDurationMilliseconds}ms ease;
  z-index: 2;

  &:hover,
  &:focus {
    background-color: ${lightGreyHoverAccent};
  }
`;

const CloseButtonIcon = styled(CloseIcon)`
  height: 10px;
  width: 10px;
`;
