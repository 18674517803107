import { DateStamp } from '../../../../models/dateStamp';
import { Id } from '../../../../models/id';
import { assertNotNull } from '../../../../utils/assertNotNull';
import { CreateUpdateSortedCellDto } from '../../../worksheets/sorted/cells/createUpdateSortedCellDto';
import { ActiveWorksheetLabelDeliveryService } from '../../../worksheets/worksheet';
import { MailingBriefMailingHouseContactDto } from '../mailingBriefMailingHouseContactDto';
import { toMailingBriefMailingHouseContactDto } from '../mailingBriefMailingHouseFormModel';
import { toCreateUpdateSortedCellDto } from './mailingBriefSortedCellFormModel';
import { MailingBriefSortedWorksheetFormModel } from './mailingBriefSortedWorksheetFormModel';

export type MailingBriefCreateUpdateSortedWorksheetDto = {
  sortedWorksheetId: number | null;
  indexAmongNewWorksheets: number | null;
  royalMailHandoverDate: DateStamp;
  dataToOnepostDate: DateStamp;
  cells: Array<CreateUpdateSortedCellDto>;
  labelDeliveryService: ActiveWorksheetLabelDeliveryService | null;
  collectionDate: DateStamp;
  mailingHouse: {
    mailingHouseId: Id | null;
    mailingHouseContacts: Array<MailingBriefMailingHouseContactDto>;
  };
};

export const toMailingBriefCreateUpdateSortedWorksheetDtos = (
  formModels: Array<MailingBriefSortedWorksheetFormModel>,
): Array<MailingBriefCreateUpdateSortedWorksheetDto> =>
  formModels.map(toMailingBriefCreateUpdateSortedWorksheetDto);

const toMailingBriefCreateUpdateSortedWorksheetDto = (
  formModel: MailingBriefSortedWorksheetFormModel,
  index: number,
): MailingBriefCreateUpdateSortedWorksheetDto => {
  return {
    sortedWorksheetId: formModel.sortedWorksheetId ? formModel.sortedWorksheetId : null,
    indexAmongNewWorksheets: index,
    royalMailHandoverDate: assertNotNull(formModel.royalMailHandoverDate),
    dataToOnepostDate: assertNotNull(formModel.dataToOnepostDate),
    cells: formModel.cells.map(toCreateUpdateSortedCellDto),
    labelDeliveryService: formModel.labelDeliveryService,
    mailingHouse: {
      mailingHouseId: formModel.mailingHouse.mailingHouseId,
      mailingHouseContacts: formModel.mailingHouse.contacts.map(
        toMailingBriefMailingHouseContactDto,
      ),
    },
    collectionDate: assertNotNull(formModel.collectionDate),
  };
};
