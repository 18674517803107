import * as React from 'react';
import { useEffect } from 'react';
import { setApplicationInsightsUser } from '../../../applicationInsights';
import { PrimaryButton } from '../../../shared/buttons/Button';
import {
  ApiRequestProps,
  withApiRequest,
} from '../../../shared/higher-order-components/withApiRequest';
import { LoadingSpinner } from '../../../shared/LoadingSpinner';
import { AuthenticatedApiRequest, fetchSecureJson, from } from '../../../utils/api';
import { LogInContainer, LogInError } from '../../authentication/LogIn';
import { User } from '../../authentication/user';
import { AuthenticationProps } from './ClientPortalAuthentication';
import { ClientPortalUser, ClientPortalUserContext } from './clientPortalUser';

type OwnProps = AuthenticationProps & { children: React.ReactNode };
type UserRequestProps = ApiRequestProps<undefined, ClientPortalUser, 'userRequest'>;
export type LogInProps = OwnProps & UserRequestProps;

export const loginErrorTestId = 'login-error';

const ClientPortalLogInComponent = (props: LogInProps) => {
  const { isAuthenticated, isLoggingIn, isLoggingOut, authenticationError, userRequest } = props;

  useEffect(() => {
    if (
      isAuthenticated &&
      !isLoggingIn &&
      !isLoggingOut &&
      !authenticationError &&
      !props.userRequest.response &&
      !props.userRequest.inProgress &&
      !props.userRequest.error
    ) {
      userRequest
        .sendRequest(undefined)
        .then(
          response =>
            response.success && setApplicationInsightsUser(`client-portal-${response.body.userId}`),
        );
    }
  });

  const error = userRequest.error || authenticationError;
  const isLoading = userRequest.inProgress;

  if (!isLoggingIn && isAuthenticated && userRequest.response && !isLoading && !error) {
    return (
      <ClientPortalUserContext.Provider value={userRequest.response}>
        {props.children}
      </ClientPortalUserContext.Provider>
    );
  } else if (error || authenticationError) {
    return (
      <LogInContainer>
        <LogInError data-testid={loginErrorTestId}>
          Sorry, something went wrong while logging you in.
        </LogInError>
        <PrimaryButton onClick={props.redirectToLogin}>Try again</PrimaryButton>
      </LogInContainer>
    );
  } else {
    return (
      <LogInContainer>
        <LoadingSpinner />
        <div>{props.isLoggingOut ? 'Logging out' : 'Logging in'}</div>
      </LogInContainer>
    );
  }
};

const fetchCurrentUser = (): AuthenticatedApiRequest<ClientPortalUser> => {
  return fetchSecureJson<User>(from(`client-portal/user`, 'get'));
};

const enhance = withApiRequest<OwnProps, undefined, ClientPortalUser, 'userRequest'>(
  () => fetchCurrentUser,
  'userRequest',
);

export const ClientPortalLogIn = enhance(ClientPortalLogInComponent);
