import { css } from 'styled-components/macro';
import {
  darkGrey,
  fontGrey,
  formInvalid,
  formInvalidAccent,
  formSuccess,
  formSuccessAccent,
  greyBorder,
  lightGrey,
  warningAccent,
  warningText,
  white,
} from '../../styling/colours';
import { regularFont, smallFont } from '../../styling/fonts';
import { fastTransitionDuration } from '../../styling/transitions';

const formInputHeightPixels = 48;
export const formInputHeight = `${formInputHeightPixels}px`;

export const inlineFormInputHeightPixels = 35;
export const inlineFormInputHeight = `${inlineFormInputHeightPixels}px`;

const formInputPaddingPixels = 10;
export const formInputPadding = `${formInputPaddingPixels}px`;

const inlineFormInputPaddingPixels = 5;
export const inlineFormInputPadding = `${inlineFormInputPaddingPixels}px`;

export const formInputContentHeight = `${formInputHeightPixels - 2 * formInputPaddingPixels}px`;

export const formInputStyling = ({
  valid,
  invalid,
  showWarning,
  textTransform,
  inline,
}: {
  valid?: boolean;
  invalid?: boolean;
  showWarning?: boolean;
  textTransform?: string;
  inline?: boolean;
}) => css`
  border: 1px solid
    ${(valid && formSuccess) ||
      (invalid && formInvalid) ||
      (showWarning && warningText) ||
      greyBorder};

  background-color: ${(valid && formSuccessAccent) ||
    (invalid && formInvalidAccent) ||
    (showWarning && warningAccent) ||
    white};

  font-size: ${inline ? smallFont : regularFont};
  height: ${inline ? inlineFormInputHeight : formInputHeight};
  padding: ${inline ? inlineFormInputPadding : formInputPadding};
  color: ${fontGrey};
  font-family: inherit;
  width: 100%;
  transition: background-color ${fastTransitionDuration} ease;
  text-transform: ${textTransform};

  &:focus {
    border-color: ${darkGrey};
    background-color: ${white};
    outline: none;
  }

  &:disabled {
    background-color: ${lightGrey};
    cursor: not-allowed;
  }
`;
