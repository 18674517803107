import { Link as RouterLink } from '@reach/router';
import * as React from 'react';
import styled from 'styled-components/macro';
import { darkGrey } from '../../../styling/colours';
import { regularWeight } from '../../../styling/fonts';
import {
  pageHorizontalPadding,
  pageHorizontalPaddingBreakpoint,
  pageSmallHorizontalPadding,
  wide,
  wider,
} from '../../../styling/spacing';
import { homeUrl } from '../../../urls';
import { AuthenticationContext } from '../../authentication/authenticationContext';
import { UserContext } from '../../authentication/LogIn';
import logoUrl from '../onepostLogo.svg';
import { navLinkFontSize, StyledNavLink } from './NavLink';
import { NavLinks } from './NavLinks';

export const Header = () => (
  <HeaderContainer>
    <LogoLink to={homeUrl()}>
      <Logo src={logoUrl} />
    </LogoLink>
    <HeaderItems>
      <NavLinks />
      <UserDetails />
    </HeaderItems>
  </HeaderContainer>
);

export const HeaderContainer = styled.header`
  height: 70px;
  padding: 0 ${pageHorizontalPadding};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);

  @media (max-width: ${pageHorizontalPaddingBreakpoint}) {
    padding: 0 ${pageSmallHorizontalPadding};
  }
`;

export const LogoLink = styled(RouterLink)`
  height: 100%;
`;

export const Logo = styled.img`
  height: 100%;
  padding-right: ${wider};
  padding-top: 12px;
  padding-bottom: 12px;
`;

export const HeaderItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UserDetails = () => (
  <HeaderItems>
    <UserContext.Consumer>{user => <UserName>{user.userName}</UserName>}</UserContext.Consumer>
    <LogOutLink />
  </HeaderItems>
);

const UserName = styled.div`
  color: ${darkGrey};
  margin-right: ${wide};
  font-size: ${navLinkFontSize};
`;

const LogOutLink = () => (
  <AuthenticationContext.Consumer>
    {({ logout }) => (
      <LogOutNavLink as="a" onClick={logout}>
        Log Out
      </LogOutNavLink>
    )}
  </AuthenticationContext.Consumer>
);

const LogOutNavLink = styled(StyledNavLink)`
  font-weight: ${regularWeight};
  font-size: ${navLinkFontSize};
`;
