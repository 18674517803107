import * as React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as CrossIcon } from '../../images/icons/cross-icon.svg';
import { ReactComponent as UndoIcon } from '../../images/icons/undo-solid.svg';
import { alertText, fontGrey } from '../../styling/colours';
import { Th } from '../table/Table';
import { IconButton } from './IconButton';

type DeleteButtonProps = {
  delete: () => void;
  testId?: string;
};

export const DeleteButton = (props: DeleteButtonProps) => (
  <ButtonContainer
    colour={alertText}
    onClick={() => props.delete()}
    data-testid={props.testId || deleteButtonTestId}
  >
    <CrossIcon />
  </ButtonContainer>
);

type DeleteOrUndeleteButtonProps = {
  isDeleted: boolean;
  delete: () => void;
  undelete: () => void;
  disabled?: boolean;
  testId?: string;
};

export const DeleteOrUndeleteButton = (props: DeleteOrUndeleteButtonProps) => (
  <ButtonContainer
    disabled={props.disabled}
    colour={props.isDeleted ? fontGrey : alertText}
    onClick={() => {
      props.isDeleted ? props.undelete() : props.delete();
    }}
    data-testid={props.testId || (props.isDeleted ? undeleteButtonTestId : deleteButtonTestId)}
    title={props.isDeleted ? 'Undo' : 'Remove'}
  >
    {props.isDeleted ? <UndoIcon /> : <CrossIcon />}
  </ButtonContainer>
);

const ButtonContainer = styled(IconButton)`
  width: 25px;
  height: 25px;
`;

export const DeleteButtonTableHeader = styled(Th)`
  width: 45px;
`;

export const deleteButtonTestId = 'delete-button';
export const undeleteButtonTestId = 'undelete-button';
