import * as React from 'react';
import styled from 'styled-components/macro';
import { greyBorder, white } from '../../styling/colours';
import { medium, narrow } from '../../styling/spacing';
import {
  FieldLabelContainer,
  fieldLabelMarginRight,
  formFieldMaxWidth,
  standardVerticalFormFieldSpacing,
} from './FormField';

type Props = {
  label: string;
  children?: React.ReactNode;
  className?: string;
};

export const FieldSet = (props: Props) => (
  <FieldSetContainer className={props.className}>
    <FieldSetLabel>{props.label}</FieldSetLabel>
    {props.children}
  </FieldSetContainer>
);

const horizontalPadding = medium;

const FieldSetContainer = styled.div`
  border: solid 1px ${greyBorder};
  padding: ${medium} ${horizontalPadding} 0 ${horizontalPadding};
  position: relative;
  max-width: ${formFieldMaxWidth};

  margin-bottom: ${standardVerticalFormFieldSpacing};

  & ${FieldLabelContainer} {
    margin-right: calc(${fieldLabelMarginRight} - ${horizontalPadding});
  }
`;

const FieldSetLabel = styled.span`
  position: absolute;
  top: -${narrow};
  left: ${narrow};
  background-color: ${white};
  padding: 0 ${narrow};
`;
