import { CarrierCode } from '../../../../models/carrier';
import { Id } from '../../../../models/id';
import { SortedCellMetadata } from '../../../../models/metadata';
import { assertIsNumber } from '../../../../utils/assertIsNumber';
import { assertNotNull } from '../../../../utils/assertNotNull';
import {
  integerInRange,
  numberGreaterThan,
  numberInRange,
  positiveInteger,
} from '../../../../utils/validation/numberValidators';
import { maxLength, notEmpty } from '../../../../utils/validation/stringValidators';
import {
  combineValidators,
  createValidator,
  Validator,
} from '../../../../utils/validation/validation';
import { noValidate, oneOf, requiredSelect } from '../../../../utils/validation/validators';
import { ConsumableLimitationCode } from '../../../mailing-houses/mailingHouse';
import { WorksheetPrintingType } from '../edit-form/sortedWorksheetFormModel';
import { CreateUpdateSortedCellDto } from './createUpdateSortedCellDto';
import {
  SortedCell,
  SortedCellBundleLabelFormat,
  SortedCellConsumable,
  SortedCellFormat,
  SortedCellMailmarkBarcode,
  SortedCellPresentation,
  sortedCellReferenceMaxLength,
  SortedCellServiceType,
  SortedCellVariant,
} from './sortedCell';
import { getValidOptionsForCell, ValidOptionsForCell } from './sortedCellValidation';

export type SortedCellFormModel = {
  sortedCellId: Id | null;
  cellReference: string;
  serviceType: SortedCellServiceType | null;
  format: SortedCellFormat | null;
  variant: SortedCellVariant | null;
  quantity: string;
  weightInGrams: string;
  presentation: SortedCellPresentation | null;
  consumable: SortedCellConsumable | null;
  maxPresentationWeightKg: string;
  maxPresentationFill: string;
  bundleLabelFormat: SortedCellBundleLabelFormat | null;
  mailmarkBarcode: SortedCellMailmarkBarcode | null;
};

export const emptySortedCellFormModel: SortedCellFormModel = {
  sortedCellId: null,
  cellReference: '',
  serviceType: null,
  format: null,
  variant: null,
  quantity: '',
  weightInGrams: '',
  presentation: null,
  consumable: null,
  maxPresentationWeightKg: '',
  maxPresentationFill: '',
  bundleLabelFormat: null,
  mailmarkBarcode: null,
};

const requiredInputErrorMessage = 'Enter a value';
const requiredSelectErrorMessage = 'Select a value';

export const maxPresentationFillValidator = (
  cell: SortedCellFormModel,
): Validator<string | null | undefined> => (value): string | null => {
  if (
    value == null ||
    value.trim() === '' ||
    cell.weightInGrams == null ||
    cell.weightInGrams.trim() === '' ||
    cell.maxPresentationWeightKg == null ||
    cell.maxPresentationWeightKg.trim() === ''
  ) {
    return null;
  }
  const valueAsNumber = Number(value.trim());
  const weightInGramsAsNumber = Number(cell.weightInGrams.trim());
  const maxPresentationWeightKgAsNumber = Number(cell.maxPresentationWeightKg.trim());

  const epsilon = 0.000001;

  return !isNaN(valueAsNumber) &&
    !isNaN(weightInGramsAsNumber) &&
    !isNaN(maxPresentationWeightKgAsNumber) &&
    valueAsNumber * weightInGramsAsNumber > maxPresentationWeightKgAsNumber * 1000 + epsilon
    ? 'The product of weight and fill must be less than max weight'
    : null;
};

export const getCellValidatorWithOptions = (
  cell: SortedCellFormModel,
  carrierCode: CarrierCode | null,
  consumableLimitationCode: ConsumableLimitationCode | null,
  printingType: WorksheetPrintingType | null,
  worksheetIsForecast: boolean,
  isAgencyAgreement: boolean,
  isJobCreatedBeforeSortedCellServiceArchiveDate: boolean,
  isJobCreatedBeforeSortedCellVariantArchiveDate: boolean,
  collectionDate: Date | null,
  metadata: SortedCellMetadata,
): Validator<SortedCellFormModel> => {
  const validOptionsForCell = getValidOptionsForCell(
    cell,
    carrierCode,
    consumableLimitationCode,
    printingType,
    worksheetIsForecast,
    isAgencyAgreement,
    isJobCreatedBeforeSortedCellServiceArchiveDate,
    isJobCreatedBeforeSortedCellVariantArchiveDate,
    metadata,
  );

  return getCellValidator(cell, validOptionsForCell, collectionDate);
};

export const getCellValidator = (
  cell: SortedCellFormModel,
  validOptionsForCell: ValidOptionsForCell | null = null,
  collectionDate: Date | null,
) =>
  createValidator<SortedCellFormModel>({
    cellReference: combineValidators(
      notEmpty(requiredInputErrorMessage),
      maxLength(sortedCellReferenceMaxLength),
    ),
    serviceType: combineValidators(
      requiredSelect<SortedCellServiceType>(requiredSelectErrorMessage),
      validOptionsForCell == null || validOptionsForCell.validServiceTypes == null
        ? null
        : oneOf<SortedCellServiceType>(
            validOptionsForCell.validServiceTypes,
            'Invalid service type',
          ),
      serviceType =>
        (serviceType === 'SeventyMailmarkCatalogue' ||
          serviceType === 'SeventyMailmarkCatalogueEconomy') &&
        !!collectionDate &&
        collectionDate < new Date('2024-07-01')
          ? 'Service type only available if collection date is 1st July 2024 or later'
          : null,
    ),
    format: combineValidators(
      requiredSelect<SortedCellFormat>(requiredSelectErrorMessage),
      validOptionsForCell == null || validOptionsForCell.validFormats == null
        ? null
        : oneOf<SortedCellFormat>(validOptionsForCell.validFormats, 'Invalid format'),
    ),
    variant: combineValidators(
      requiredSelect<SortedCellVariant>(requiredSelectErrorMessage),
      validOptionsForCell == null || validOptionsForCell.validVariants == null
        ? null
        : oneOf<SortedCellVariant>(validOptionsForCell.validVariants, 'Invalid variant'),
    ),
    quantity: combineValidators(notEmpty(requiredInputErrorMessage), positiveInteger()),
    weightInGrams: combineValidators(
      notEmpty(requiredInputErrorMessage),
      validOptionsForCell == null ||
        validOptionsForCell.minWeight == null ||
        validOptionsForCell.maxWeight == null
        ? null
        : integerInRange(validOptionsForCell.minWeight, validOptionsForCell.maxWeight),
    ),
    presentation: combineValidators(
      requiredSelect<SortedCellPresentation>(requiredSelectErrorMessage),
      validOptionsForCell == null || validOptionsForCell.validPresentations == null
        ? noValidate
        : oneOf<SortedCellPresentation>(
            validOptionsForCell.validPresentations,
            'Invalid presentation',
          ),
    ),
    consumable: combineValidators(
      requiredSelect<SortedCellConsumable>(requiredSelectErrorMessage),
      validOptionsForCell == null || validOptionsForCell.validConsumables == null
        ? noValidate
        : oneOf<SortedCellConsumable>(validOptionsForCell.validConsumables, 'Invalid consumable'),
    ),
    maxPresentationWeightKg:
      validOptionsForCell == null || validOptionsForCell.maximumMaxPresentationWeight == null
        ? notEmpty(requiredInputErrorMessage)
        : combineValidators(
            notEmpty(requiredInputErrorMessage),
            cell.presentation === 'Tray'
              ? numberInRange(
                  0,
                  validOptionsForCell.maximumMaxPresentationWeight,
                  `Please enter a higher max tray fill as the max weight cannot be less than 0`,
                  `Please enter a lower max tray fill as the max weight cannot exceed ${validOptionsForCell.maximumMaxPresentationWeight}`,
                )
              : numberInRange(0, validOptionsForCell.maximumMaxPresentationWeight),
          ),
    maxPresentationFill: combineValidators(
      integerInRange(0, 999),
      maxPresentationFillValidator(cell),
      cell.presentation === 'Tray' ? notEmpty(requiredInputErrorMessage) : noValidate,
      cell.presentation === 'Tray' ? positiveInteger() : noValidate,
    ),
    bundleLabelFormat:
      validOptionsForCell == null || validOptionsForCell.validBundleLabelFormats == null
        ? noValidate
        : requiredSelect<SortedCellBundleLabelFormat>(requiredSelectErrorMessage),
    mailmarkBarcode:
      validOptionsForCell == null || validOptionsForCell.validMailmarkBarcodes == null
        ? noValidate
        : requiredSelect<SortedCellMailmarkBarcode>(requiredSelectErrorMessage),
  });

export const toCreateUpdateSortedCellDto = (
  formModel: SortedCellFormModel,
  displayOrder: number,
): CreateUpdateSortedCellDto => ({
  sortedCellId: formModel.sortedCellId,
  displayOrder,
  cellReference: formModel.cellReference,
  serviceType: assertNotNull(formModel.serviceType),
  format: assertNotNull(formModel.format),
  variant: assertNotNull(formModel.variant),
  quantity: assertIsNumber(formModel.quantity),
  weightInGrams: assertIsNumber(formModel.weightInGrams),
  presentation: assertNotNull(formModel.presentation),
  consumable: assertNotNull(formModel.consumable),
  maxPresentationWeightKg: assertIsNumber(formModel.maxPresentationWeightKg),
  maxPresentationFill: formModel.maxPresentationFill.trim()
    ? assertIsNumber(formModel.maxPresentationFill)
    : null,
  bundleLabelFormat: formModel.bundleLabelFormat,
  mailmarkBarcode: formModel.mailmarkBarcode,
});

export const fromSortedCell = (
  cell: SortedCell | CreateUpdateSortedCellDto,
): SortedCellFormModel => ({
  sortedCellId: cell.sortedCellId,
  cellReference: cell.cellReference,
  serviceType: cell.serviceType,
  format: cell.format,
  variant: cell.variant,
  quantity: cell.quantity.toString(),
  weightInGrams: cell.weightInGrams.toString(),
  presentation: cell.presentation,
  consumable: cell.consumable,
  maxPresentationWeightKg: cell.maxPresentationWeightKg.toString(),
  maxPresentationFill: cell.maxPresentationFill ? cell.maxPresentationFill.toString() : '',
  bundleLabelFormat: cell.bundleLabelFormat,
  mailmarkBarcode: cell.mailmarkBarcode,
});
