import { map } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as CrossIcon } from '../images/icons/cross-icon.svg';
import { darkGrey, red, tdgBlueHoverAccent, tdgDarkBlue, white } from '../styling/colours';
import { medium, wide } from '../styling/spacing';
import { fastTransitionDuration } from '../styling/transitions';

export const getNewSelectedTabIndexAfterClosingTab = (
  indexOfTabBeingClosed: number,
  currentlySelectedTabIndex: number | null,
  numberOfTabsBeforeClosingTab: number,
): number | null => {
  if (currentlySelectedTabIndex == null) {
    return null;
  } else if (indexOfTabBeingClosed < currentlySelectedTabIndex) {
    return currentlySelectedTabIndex - 1;
  } else {
    const numberOfTabsAfterClosingTab = numberOfTabsBeforeClosingTab - 1;
    if (numberOfTabsAfterClosingTab === 0) {
      return null;
    }
    const greatestPossibleIndexAfterClosingTab = numberOfTabsAfterClosingTab - 1;
    return Math.min(currentlySelectedTabIndex, greatestPossibleIndexAfterClosingTab);
  }
};

export const TabContent = styled.div`
  padding: ${wide};
  border: solid 1px ${darkGrey};
  background-color: ${white};
  margin-bottom: ${medium};
`;

export const TabHeadings = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

type TabHeadingProps = {
  active: boolean;
  title: string;
  onSelect: () => void;
  onClose?: () => void;
  testId?: string;
};

export const closeTabButtonTestId = (tabTitle: string) => `Close ${tabTitle}`;
export const activeTabHeaderTestId = `active-tab-header`;

export class TabHeading extends React.Component<TabHeadingProps> {
  onClickCloseButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Prevent the click event from bubbling through to avoid onSelectTab being called
    event.preventDefault();
    const { onClose } = this.props;
    if (onClose != null) {
      onClose();
    }
  };

  onClick = (event: React.MouseEvent) => {
    if (!event.defaultPrevented) {
      this.props.onSelect();
    }
  };

  render() {
    const { active, title, onClose } = this.props;
    return (
      <TabHeadingContainer
        active={active}
        onClick={this.onClick}
        data-testid={active ? activeTabHeaderTestId : this.props.testId || null}
      >
        <TabTitle>{title}</TabTitle>
        {onClose && (
          <CloseButton
            type="button"
            onClick={this.onClickCloseButton}
            data-testid={closeTabButtonTestId(title)}
          >
            <CloseButtonIcon />
          </CloseButton>
        )}
      </TabHeadingContainer>
    );
  }
}

type TabsProps = { tabs: Array<{ title: string; content: React.ReactNode }> };

export const Tabs = ({ tabs }: TabsProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const selectedTab = tabs[selectedTabIndex];
  if (!selectedTab) {
    return null;
  }

  return (
    <>
      <TabHeadings>
        {map(tabs, (tab, tabIndex) => (
          <TabHeading
            active={tabIndex === selectedTabIndex}
            title={tab.title}
            onSelect={() => setSelectedTabIndex(tabIndex)}
            key={tabIndex}
          />
        ))}
      </TabHeadings>
      <TabContent>{selectedTab.content}</TabContent>
    </>
  );
};

const TabHeadingContainer = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0 ${medium};
  border: solid 1px ${darkGrey};
  color: ${props => (props.active ? white : red)};
  background-color: ${props => (props.active ? tdgDarkBlue : white)};
  cursor: pointer;
  transition: background-color ${fastTransitionDuration} ease, color ${fastTransitionDuration} ease;
  user-select: none;
  margin-right: -1px;
  margin-bottom: -1px;

  &:hover {
    background-color: ${tdgBlueHoverAccent};
    color: ${white};
  }
`;

const TabTitle = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  color: inherit;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-left: ${medium};
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
  transition: background-color ${fastTransitionDuration} ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
`;

const CloseButtonIcon = styled(CrossIcon)`
  height: 85%;
  width: 85%;
`;
