import { Formik, FormikActions } from 'formik';
import { flowRight } from 'lodash';
import * as React from 'react';
import { Form } from '../../../shared/form/Form';
import { FormErrorBox } from '../../../shared/form/FormErrorBox';
import { FormSubmit } from '../../../shared/form/FormSubmit';
import { InputField } from '../../../shared/form/inputs/InputField';
import { TextAreaField } from '../../../shared/form/inputs/TextAreaField';
import {
  ApiRequestProps,
  withApiRequest,
} from '../../../shared/higher-order-components/withApiRequest';
import { ItemHeader } from '../../../shared/layout/Headers';
import { Section } from '../../../shared/layout/Section';
import { AuthenticatedApiRequest, fetchSecureJson, from, withJsonBody } from '../../../utils/api';
import { navigate } from '../../../utils/routing';
import { ConvertForecastJobToLiveCommand } from '../../jobs/convert-forecast-job-to-live/ConvertForecastJobToLive';
import { useClientPortalMetadata } from '../clientPortalMetadata';
import { CollectionDate } from './collectionDate/CollectionDate';
import { JobLevelCollectionDate } from './collectionDate/JobLevelCollectionDate';
import { CustomerAndSubCustomer } from './CustomerAndSubCustomer';
import { CustomerContacts } from './CustomerContacts';
import { DataForMailingBriefForm } from './dataForMailingBriefForm';
import { MailingBriefInternationalWorksheetFormFields } from './international/MailingBriefInternationalWorksheetFormFields';
import { JobTypeSelection } from './JobTypeSelection';
import { getMailingBriefFormInitialValues } from './mailingBriefFormInitialValues';
import {
  anyWorksheetSelected,
  ClientPortalConvertForecastJobToLiveCommand,
  ConvertForecastJobToLiveResponse,
  getMailingBriefFormModelValidator,
  hasAtMostOneSortedWorksheet,
  hasWorksheetRequiringCustomerContactsAndMailingHouse,
  MailingBriefFormModel,
  SubmitMailingBriefCommand,
  toConvertForecastJobToLiveCommand,
  toMailingBriefCommand,
} from './mailingBriefFormModel';
import { JobLevelMailingBriefMailingHouse } from './mailingHouse/JobLevelMailingBriefMailingHouse';
import { MailingBriefSortedWorksheetFormFields } from './sorted/MailingBriefSortedWorksheetFormFields';
import { MailingBriefSplitJobSortedWorksheetFormFields } from './sorted/MailingBriefSplitJobSortedWorksheetFormFields';
import { MailingBriefUnsortedWorksheetFormFields } from './unsorted/MailingBriefUnsortedWorksheetFormFields';

type OwnProps = {
  dataForMailingBriefForm: DataForMailingBriefForm;
  isConvertingForecastJobToLive: boolean;
  onSuccess: () => void;
};

type MailingBriefRequestProps = ApiRequestProps<
  SubmitMailingBriefCommand,
  {},
  'mailingBriefRequest'
>;

type ConvertForecastToJobRequestProps = ApiRequestProps<
  ClientPortalConvertForecastJobToLiveCommand,
  ConvertForecastJobToLiveResponse,
  'convertForecastJobToLiveRequest'
>;

type Props = OwnProps & MailingBriefRequestProps & ConvertForecastToJobRequestProps;

const MailingBriefFormComponent = ({
  dataForMailingBriefForm,
  onSuccess,
  isConvertingForecastJobToLive,
  mailingBriefRequest,
  convertForecastJobToLiveRequest,
}: Props) => {
  const metadata = useClientPortalMetadata();
  const {
    customersByCustomerId: customers,
    subCustomersByCustomerId: subCustomers,
    mailingHousesById: mailingHouses,
    suppliers,
    mailingBriefForecastJob,
  } = dataForMailingBriefForm;

  const onSubmit = (
    formModel: MailingBriefFormModel,
    { setSubmitting }: FormikActions<MailingBriefFormModel>,
  ) => {
    if (isConvertingForecastJobToLive) {
      if (mailingBriefForecastJob == null) {
        throw new Error('Forecast job does not exist');
      }
      convertForecastJobToLiveRequest
        .sendRequest(toConvertForecastJobToLiveCommand(formModel, mailingBriefForecastJob.jobId))
        .then(response => {
          if (response.success) {
            onRequestSuccess();
          } else {
            setSubmitting(false);
          }
        });
    } else {
      mailingBriefRequest
        .sendRequest(
          toMailingBriefCommand(
            formModel,
            mailingBriefForecastJob ? mailingBriefForecastJob.jobId : null,
          ),
        )
        .then(response => {
          if (response.success) {
            onRequestSuccess();
          } else {
            setSubmitting(false);
          }
        });
    }
  };

  const onRequestSuccess = () => {
    onSuccess();
    setTimeout(() => {
      if (mailingBriefForecastJob == null) {
        navigate('/portal/jobs', { suppressUserConfirmation: true });
      } else {
        navigate(`/portal/jobs/${mailingBriefForecastJob.jobId}`, {
          suppressUserConfirmation: true,
        });
      }
    }, 3000);
  };

  return (
    <Formik<MailingBriefFormModel>
      initialValues={getMailingBriefFormInitialValues(customers, mailingBriefForecastJob)}
      onSubmit={onSubmit}
      validate={formModel =>
        getMailingBriefFormModelValidator(
          formModel,
          customers,
          mailingHouses,
          metadata,
          isConvertingForecastJobToLive,
        )
      }
    >
      {formikProps => {
        const { values: formValues } = formikProps;
        return (
          <Form {...formikProps}>
            <ItemHeader>
              {isConvertingForecastJobToLive ? 'Forecast Job' : 'Mailing brief'}
            </ItemHeader>
            {!isConvertingForecastJobToLive && (
              <>
                <CustomerAndSubCustomer
                  customers={customers}
                  subCustomers={subCustomers}
                  formikProps={formikProps}
                  hasExistingForecastJob={!!mailingBriefForecastJob}
                />
                <InputField label="Campaign name" name="campaignName" />
                <InputField label="Customer reference" name="customerReference" />
                <InputField label="Purchase Order number (optional)" name="purchaseOrderNumber" />
              </>
            )}
            <JobTypeSelection
              isConvertingForecastJobToLive={isConvertingForecastJobToLive}
              hasExistingForecastJob={!!mailingBriefForecastJob}
              suppliers={suppliers}
            />
            {(anyWorksheetSelected(formValues) || isConvertingForecastJobToLive) &&
              hasAtMostOneSortedWorksheet(formValues, isConvertingForecastJobToLive) && (
                <JobLevelCollectionDate formikProps={formikProps} />
              )}
            {hasWorksheetRequiringCustomerContactsAndMailingHouse(formValues) && (
              <>
                {(hasAtMostOneSortedWorksheet(formValues, isConvertingForecastJobToLive) ||
                  formValues.splitJobOption === 'Split') && (
                  <CustomerContacts
                    formikProps={formikProps}
                    customerResponses={customers}
                    isConvertingForecastJobToLive={isConvertingForecastJobToLive}
                  />
                )}
                {hasAtMostOneSortedWorksheet(formValues, isConvertingForecastJobToLive) && (
                  <JobLevelMailingBriefMailingHouse
                    formikProps={formikProps}
                    mailingHouseResponses={mailingHouses}
                    isConvertingForecastJobToLive={isConvertingForecastJobToLive}
                  />
                )}
              </>
            )}
            {formValues.sortedWorksheets.length > 0 && (
              <>
                {formValues.splitJobOption === 'Split' ? (
                  <MailingBriefSplitJobSortedWorksheetFormFields
                    mailingHouses={mailingHouses}
                    customers={customers}
                    isConvertingForecastJobToLive={isConvertingForecastJobToLive}
                    formikProps={formikProps}
                  />
                ) : (formValues.splitJobOption === 'NotSplit' ||
                    formValues.forecastJobOption === 'Forecast') &&
                  formValues.sortedWorksheets[0].collectionDate &&
                  formValues.sortedWorksheets[0].mailingHouse.mailingHouseId ? (
                  <Section>
                    <ItemHeader>Sorted element</ItemHeader>
                    <MailingBriefSortedWorksheetFormFields
                      sortedWorksheetIndex={0}
                      mailingHouses={mailingHouses}
                      customers={customers}
                      isConvertingForecastJobToLive={isConvertingForecastJobToLive}
                    />
                  </Section>
                ) : null}
              </>
            )}
            {formValues.unsortedWorksheet &&
              (formValues.splitJobOption === 'Split' ||
                (formValues.unsortedWorksheet.mailingHouse.mailingHouseId &&
                  formValues.unsortedWorksheet.collectionDate)) && (
                <Section>
                  <ItemHeader>Unsorted element</ItemHeader>
                  <MailingBriefUnsortedWorksheetFormFields
                    formikProps={formikProps}
                    isConvertingForecastJobToLive={isConvertingForecastJobToLive}
                    isSplitJob={formValues.splitJobOption === 'Split'}
                    mailingHouseResponses={mailingHouses}
                  />
                </Section>
              )}
            {formValues.internationalWorksheet &&
              (formValues.splitJobOption === 'Split' ||
                (formValues.internationalWorksheet.mailingHouse.mailingHouseId &&
                  formValues.internationalWorksheet.collectionDate)) && (
                <Section>
                  <ItemHeader>International element</ItemHeader>
                  <MailingBriefInternationalWorksheetFormFields
                    formikProps={formikProps}
                    isConvertingForecastJobToLive={isConvertingForecastJobToLive}
                    isSplitJob={formValues.splitJobOption === 'Split'}
                    mailingHouseResponses={mailingHouses}
                  />
                </Section>
              )}
            {formValues.dataCleansingWorksheet && formValues.splitJobOption === 'Split' && (
              <Section>
                <ItemHeader>Data cleansing element</ItemHeader>
                <CollectionDate
                  formikProps={formikProps}
                  worksheetDependentFieldNamePrefix="dataCleansingWorksheet"
                />
              </Section>
            )}
            {anyWorksheetSelected(formValues) && (
              <Section>
                <ItemHeader>Additional Instructions</ItemHeader>
                <TextAreaField label="Additional instructions" name="additionalInstructions" />
              </Section>
            )}
            <FormSubmit
              errors={formikProps.errors}
              isSubmitting={formikProps.isSubmitting}
              submitButtonText="Submit"
            />
            {mailingBriefRequest.error && <FormErrorBox error={mailingBriefRequest.error} />}
          </Form>
        );
      }}
    </Formik>
  );
};

const submitMailingBrief = (command: SubmitMailingBriefCommand): AuthenticatedApiRequest<{}> =>
  fetchSecureJson<{}>(withJsonBody(command)(from('client-portal/mailing-brief', 'post')));

const mailingBriefRequestEnhancer = withApiRequest<
  OwnProps,
  SubmitMailingBriefCommand,
  {},
  'mailingBriefRequest'
>(() => submitMailingBrief, 'mailingBriefRequest');

const createLiveJobFromForecastJob = (command: ConvertForecastJobToLiveCommand) =>
  fetchSecureJson<ConvertForecastJobToLiveResponse>(
    withJsonBody<ConvertForecastJobToLiveCommand>(command)(
      from(`client-portal/${command.jobId}/create-live-job-from-forecast-job`, 'post'),
    ),
  );

const convertForecastJobToLiveRequestEnhancer = withApiRequest<
  OwnProps,
  ConvertForecastJobToLiveCommand,
  void,
  'convertForecastJobToLiveRequest'
>(() => createLiveJobFromForecastJob, 'convertForecastJobToLiveRequest');

const enhance = flowRight(
  mailingBriefRequestEnhancer,
  convertForecastJobToLiveRequestEnhancer,
);

export const MailingBriefForm = enhance(MailingBriefFormComponent);
