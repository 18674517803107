import { Link as RouterLink } from '@reach/router';
import styled from 'styled-components/macro';
import { blue, blueHoverAccent } from '../../styling/colours';

export const Link = styled(RouterLink)`
  cursor: pointer;
  color: ${blue};
  text-decoration: none;

  &:hover {
    color: ${blueHoverAccent};
  }
`;
