import { CustomerForMailingBriefForm, MailingBriefForecastJob } from './dataForMailingBriefForm';
import { MailingBriefFormModel } from './mailingBriefFormModel';
import {
  getInitialMailingBriefSortedWorksheetFormModel,
  getInitialMailingBriefSortedWorksheetFormModelFromWorksheet,
} from './sorted/mailingBriefSortedWorksheetFormModel';

export const getMailingBriefFormInitialValues = (
  customers: { [id: number]: CustomerForMailingBriefForm },
  forecastJob: MailingBriefForecastJob | null,
): MailingBriefFormModel =>
  forecastJob
    ? {
        campaignName: forecastJob.campaignName,
        customerId: forecastJob.customerId,
        customerReference: forecastJob.customerReference,
        purchaseOrderNumber: forecastJob.purchaseOrderNumber,
        subCustomerId: forecastJob.subCustomerId,
        dataSortationRequired: 'Required',
        forecastJobOption: 'Forecast',
        splitJobOption: 'NotSplit',
        customerContacts: [],
        unsortedWorksheet: null,
        sortedWorksheets: forecastJob.sortedWorksheet
          ? [
              getInitialMailingBriefSortedWorksheetFormModelFromWorksheet(
                forecastJob.sortedWorksheet,
              ),
            ]
          : getInitialMailingBriefSortedWorksheetFormModel(),
        internationalWorksheet: null,
        dataCleansingWorksheet: null,
        additionalInstructions: forecastJob.additionalInstructions,
      }
    : {
        campaignName: '',
        customerId:
          Object.keys(customers).length === 1 ? Object.values(customers)[0].customerId : null,
        customerReference: '',
        purchaseOrderNumber: '',
        subCustomerId: null,
        dataSortationRequired: null,
        forecastJobOption: null,
        splitJobOption: null,
        customerContacts: [],
        sortedWorksheets: getInitialMailingBriefSortedWorksheetFormModel(),
        unsortedWorksheet: null,
        internationalWorksheet: null,
        dataCleansingWorksheet: null,
        additionalInstructions: '',
      };
