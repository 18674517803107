import { flowRight, some } from 'lodash';
import { useContext } from 'react';
import * as React from 'react';
import styled from 'styled-components/macro';
import { formatDateStamp } from '../../../../models/dateStamp';
import { PrimaryButton } from '../../../../shared/buttons/Button';
import { Dd, DdVerticallyCentered, Dl, DlItem, Dt } from '../../../../shared/DefinitionList';
import { withModal, WithModalProps } from '../../../../shared/higher-order-components/withModal';
import { SectionHeader } from '../../../../shared/layout/Headers';
import { NotificationsContext } from '../../../../shared/notifications/NotificationsProvider';
import { Table, TBody, Td, Tr } from '../../../../shared/table/Table';
import { TickOrCross } from '../../../../shared/TickOrCross';
import { medium } from '../../../../styling/spacing';
import { SortedCell } from '../../../worksheets/sorted/cells/sortedCell';
import { WorksheetDetailsSection } from '../../../worksheets/WorksheetDetails';
import { WorksheetScidsOverrideWarning } from '../../../worksheets/WorksheetScidsOverrideWarning';
import { useClientPortalMetadata } from '../../clientPortalMetadata';
import { ClientPortalJobResponse } from '../../jobs/ClientPortalViewJob';
import { CreateForecastJobsFromExistingJobModal } from '../../jobs/CreateForecastJobFromExistingJobModal';
import { ClientPortalWorksheetDetailsHeader } from '../ClientPortalWorksheetDetailsHeader';
import { ClientPortalSortedWorksheet } from './ClientPortalSortedWorksheet';
import { ClientPortalSortedWorksheetCustomerContactsTable } from './ClientPortalSortedWorksheetCustomerContactsTable';
import { ClientPortalSortedWorksheetMailingHouseDetails } from './ClientPortalSortedWorksheetMailingHouseDetails';
import { ViewClientPortalSortedCellsTable } from './ViewClientPortalSortedCellsTable';

export type CreateForecastJobsFromExistingJobModalParams = {
  onSuccess: (createdJobReferences: Array<number>) => void;
};

type OwnProps = {
  sortedWorksheet: ClientPortalSortedWorksheet;
  sortedWorksheetIndex: number;
  job: ClientPortalJobResponse;
};

type CreateForecastJobsFromExistingJobModalProps = WithModalProps<
  CreateForecastJobsFromExistingJobModalParams,
  'createForecastJobsFromExistingJobModal'
>;

type Props = OwnProps & CreateForecastJobsFromExistingJobModalProps;

const withCreateForecastJobsFromExistingJobModal = withModal<
  OwnProps,
  CreateForecastJobsFromExistingJobModalParams,
  'createForecastJobsFromExistingJobModal'
>('createForecastJobsFromExistingJobModal', ({ ownProps, params, closeModal }) => {
  const sortedWorksheet = ownProps.sortedWorksheet;
  return (
    <CreateForecastJobsFromExistingJobModal
      sortedWorksheetId={sortedWorksheet.sortedWorksheetId}
      originalSchedule={{
        dataToOnepostDate: sortedWorksheet.dataToOnepostDate,
        consumablesDate: sortedWorksheet.consumablesDate,
        collectionDate: sortedWorksheet.collectionDate,
        campaignName: ownProps.job.campaignName,
      }}
      disableQuantityField={sortedWorksheet.cells.length !== 1}
      onSuccess={params.onSuccess}
      onClose={closeModal}
      sortedWorksheetReference={sortedWorksheet.worksheetReference}
    />
  );
});

export const ClientPortalSortedWorksheetDetailsComponent = ({
  sortedWorksheet,
  sortedWorksheetIndex,
  createForecastJobsFromExistingJobModal,
}: Props) => {
  const metadata = useClientPortalMetadata();
  const { sendNotification } = useContext(NotificationsContext);

  return (
    <>
      <SortedWorksheetHeaderAndButtonContainer>
        <ClientPortalWorksheetDetailsHeader
          sortedWorksheetIndex={sortedWorksheetIndex}
          worksheet={sortedWorksheet}
        />
        <ModalButton
          onClick={() =>
            createForecastJobsFromExistingJobModal.open({
              onSuccess: (createdJobReferences: Array<number>) =>
                sendNotification({
                  type: 'Success',
                  message:
                    createdJobReferences.length === 1
                      ? `Forecast job ${createdJobReferences[0]} was created.`
                      : `Forecast jobs ${createdJobReferences.join(', ')} were created.`,
                }),
            })
          }
        >
          Duplicate Sorted Worksheet {sortedWorksheet.worksheetReference} to Forecast
        </ModalButton>
      </SortedWorksheetHeaderAndButtonContainer>
      <WorksheetDetailsSection>
        <Table>
          <TBody>
            <Tr>
              <Td>Royal Mail Handover Date</Td>
              <Td>{formatDateStamp(sortedWorksheet.royalMailHandoverDate)}</Td>
            </Tr>
            <Tr>
              <Td>Collection Date</Td>
              <Td>{formatDateStamp(sortedWorksheet.collectionDate)}</Td>
            </Tr>
          </TBody>
        </Table>
      </WorksheetDetailsSection>
      <WorksheetDetailsSection>
        <SectionHeader>Cells</SectionHeader>
        <ViewClientPortalSortedCellsTable cells={sortedWorksheet.cells} />
      </WorksheetDetailsSection>
      {sortedWorksheet.statusCode !== 'Forecast' && (
        <WorksheetDetailsSection>
          <SectionHeader>UCIDs</SectionHeader>
          <Dl>
            <DlItem horizontal={true}>
              <Dt>Zonal UCID</Dt>
              <Dd>{sortedWorksheet.zonalUcidCode || 'No matching UCID'}</Dd>
            </DlItem>
            <DlItem horizontal={true}>
              <Dt>National UCID</Dt>
              <Dd>{sortedWorksheet.nationalUcidCode || 'No matching UCID'}</Dd>
            </DlItem>
          </Dl>
        </WorksheetDetailsSection>
      )}
      {sortedWorksheet.statusCode !== 'Forecast' &&
        some(
          sortedWorksheet.cells,
          (cell: SortedCell) => metadata.sortedCellServiceTypes[cell.serviceType].supportsScids,
        ) && (
          <WorksheetDetailsSection>
            <SectionHeader>SCIDs</SectionHeader>
            {sortedWorksheet.overrideScids && <WorksheetScidsOverrideWarning />}
            <Dl>
              <DlItem horizontal={true}>
                <Dt>Zonal SCID</Dt>
                <Dd>{sortedWorksheet.zonalScidCode || 'No matching SCID'}</Dd>
              </DlItem>
              <DlItem horizontal={true}>
                <Dt>National SCID</Dt>
                <Dd>{sortedWorksheet.nationalScidCode || 'No matching SCID'}</Dd>
              </DlItem>
              <DlItem horizontal={true}>
                <Dt>Override SCIDs</Dt>
                <DdVerticallyCentered>
                  <TickOrCross value={sortedWorksheet.overrideScids} />
                </DdVerticallyCentered>
              </DlItem>
              {sortedWorksheet.overrideScids && (
                <>
                  <DlItem horizontal={true}>
                    <Dt>Zonal SCID (override)</Dt>
                    <Dd>{sortedWorksheet.zonalScidCodeOverriddenValue}</Dd>
                  </DlItem>
                  <DlItem horizontal={true}>
                    <Dt>National SCID (override)</Dt>
                    <Dd>{sortedWorksheet.nationalScidCodeOverriddenValue}</Dd>
                  </DlItem>
                </>
              )}
            </Dl>
          </WorksheetDetailsSection>
        )}
      <WorksheetDetailsSection>
        <SectionHeader>Mailing House</SectionHeader>
        <ClientPortalSortedWorksheetMailingHouseDetails sortedWorksheet={sortedWorksheet} />
      </WorksheetDetailsSection>
      <WorksheetDetailsSection>
        <SectionHeader>Customer Contacts</SectionHeader>
        <ClientPortalSortedWorksheetCustomerContactsTable
          contacts={sortedWorksheet.customerContacts}
        />
      </WorksheetDetailsSection>
    </>
  );
};

const SortedWorksheetHeaderAndButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ModalButton = styled(PrimaryButton)`
  margin-left: ${medium};
  height: 42px;
`;

const enhance = flowRight(withCreateForecastJobsFromExistingJobModal);

export const ClientPortalSortedWorksheetDetails = enhance(
  ClientPortalSortedWorksheetDetailsComponent,
);
