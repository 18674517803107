import { connect, FormikContext } from 'formik';
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import {
  formatDateStamp,
  getTodayAsDateStamp,
  nextWorkingDay,
  workingDaysBefore,
} from '../../../../models/dateStamp';
import { InfoCallout } from '../../../../shared/Callout';
import { DateField } from '../../../../shared/form/inputs/DateField';
import { assertNotNull } from '../../../../utils/assertNotNull';
import { SortedWorksheetCustomerContactFormModel } from '../../../worksheets/sorted/edit-form/sortedWorksheetCustomerContactFormModel';
import { ClientPortalMetadata, useClientPortalMetadata } from '../../clientPortalMetadata';
import {
  CustomerForMailingBriefForm,
  MailingHouseForMailingBriefForm,
} from '../dataForMailingBriefForm';
import { includedOnSortedJob } from '../mailingBriefContactBaseFormModel';
import { MailingBriefCustomerContactFormModel } from '../mailingBriefCustomerContactFormModel';
import { MailingBriefFormModel } from '../mailingBriefFormModel';
import { MailingBriefSortedCellsFieldArray } from './MailingBriefSortedCellsFieldArray';

type OwnProps = {
  customers: { [customerId: number]: CustomerForMailingBriefForm };
  mailingHouses: { [mailingHouseId: number]: MailingHouseForMailingBriefForm };
  isConvertingForecastJobToLive: boolean;
  sortedWorksheetIndex: number;
};

type Props = OwnProps & { formik: FormikContext<MailingBriefFormModel> };

export const mailingBriefSortedWorksheetFormFieldsTestId =
  'mailing-brief-sorted-worksheet-form-fields';

const MailingBriefSortedWorksheetFormFieldsComponent = (props: Props) => {
  const formikContext = props.formik;
  const formModel = formikContext.values;
  const sortedFormModel =
    formikContext.values.sortedWorksheets[assertNotNull(props.sortedWorksheetIndex)];
  const metadata = useClientPortalMetadata();

  useSyncCustomerContactsFromJob(props.sortedWorksheetIndex, formikContext);
  useSyncCollectionDateAndDataToOnepostDateFromJob(
    props.sortedWorksheetIndex,
    formikContext,
    metadata,
  );

  const formModelCollectionDate = sortedFormModel.collectionDate;
  const formModelMailingHouseId = sortedFormModel.mailingHouse.mailingHouseId;

  const requiredFields = [formModelCollectionDate, formModelMailingHouseId, formModel.customerId];

  if (requiredFields.some(field => field == null)) {
    return null;
  }

  const collectionDate = assertNotNull(formModelCollectionDate);

  const earliestRoyalMailHandoverDate = nextWorkingDay(
    assertNotNull(formModelCollectionDate),
    metadata.bankHolidays,
  );

  const latestDataToOnepostDate = workingDaysBefore(collectionDate, 2, metadata.bankHolidays);

  return (
    <div data-testid={mailingBriefSortedWorksheetFormFieldsTestId}>
      {formikContext.values.forecastJobOption === 'Forecast' && (
        <DateField
          label="Data to Onepost"
          name={`sortedWorksheets[${props.sortedWorksheetIndex}].dataToOnepostDate`}
          minDate={getTodayAsDateStamp()}
          maxDate={latestDataToOnepostDate}
        />
      )}
      <DateField
        label="Royal Mail Handover Date"
        name={`sortedWorksheets[${props.sortedWorksheetIndex}].royalMailHandoverDate`}
        minDate={earliestRoyalMailHandoverDate}
        excludeSundays={true}
      />
      <RoyalMailInfoCallout>
        {`Mailing items can expect to start door dropping from
        ${
          sortedFormModel.royalMailHandoverDate == null
            ? 'the next working day'
            : formatDateStamp(
                nextWorkingDay(sortedFormModel.royalMailHandoverDate, metadata.bankHolidays),
              )
        }`}
      </RoyalMailInfoCallout>

      <MailingBriefSortedCellsFieldArray
        worksheetFieldName={`sortedWorksheets[${props.sortedWorksheetIndex}]`}
        worksheetFormModel={sortedFormModel}
        formProps={formikContext}
        consumableLimitationCode={
          props.mailingHouses[assertNotNull(formModelMailingHouseId)].consumableLimitationCode
        }
        printingType={sortedFormModel.printingType}
        isAgencyAgreement={
          props.customers[assertNotNull(formikContext.values.customerId)]
            .hasAgencyAgreementWithSecuredMail
        }
      />
    </div>
  );
};

const useSyncCollectionDateAndDataToOnepostDateFromJob = (
  sortedWorksheetIndex: number,
  formikContext: FormikContext<MailingBriefFormModel>,
  metadata: ClientPortalMetadata,
) => {
  const collectionDate = formikContext.values.sortedWorksheets[sortedWorksheetIndex].collectionDate;

  useEffect(() => {
    if (!!collectionDate) {
      formikContext.setFieldValue(
        `sortedWorksheets[${sortedWorksheetIndex}].royalMailHandoverDate`,
        nextWorkingDay(collectionDate, metadata.bankHolidays),
      );
    }
  }, [collectionDate]);

  useEffect(() => {
    if (formikContext.values.forecastJobOption === 'NotForecast') {
      formikContext.setFieldValue(
        `sortedWorksheets[${sortedWorksheetIndex}].dataToOnepostDate`,
        getTodayAsDateStamp(),
      );
    }
  }, [formikContext.values.forecastJobOption]);
};

// The form model for the Sorted Worksheet requires Customer Contacts to be specified.
// These actually get set at a higher level in the form model, so we have to sync them down from the
// top-level details into the Sorted Worksheet details.
const useSyncCustomerContactsFromJob = (
  sortedWorksheetIndex: number,
  formikContext: FormikContext<MailingBriefFormModel>,
) => {
  const { values: formModel, setFieldValue } = formikContext;

  const jobLevelCustomerContacts = formModel.customerContacts;

  // We generate a string that will change whenever the selected Contacts change, so that we only
  // fire the effect below when we need to (if we used an array it would change every render).
  const jobLevelCustomerContactIdsSelectedForSortedCommunications = jobLevelCustomerContacts
    .filter(includedOnSortedJob)
    .map(c => c.customerContactId.toString())
    .join(',');

  useEffect(() => {
    setFieldValue(
      `sortedWorksheets[${sortedWorksheetIndex}].customerContacts`,
      jobLevelCustomerContacts.map(mapFromJobLevelCustomerContact),
    );
  }, [jobLevelCustomerContactIdsSelectedForSortedCommunications]);
};

const mapFromJobLevelCustomerContact = (
  jobLevelCustomerContact: MailingBriefCustomerContactFormModel,
): SortedWorksheetCustomerContactFormModel => ({
  customerContactId: jobLevelCustomerContact.customerContactId,
  includeOnCarbonCopyCommunication: jobLevelCustomerContact.includeOnCarbonCopyCommunication,
  includeOnDataCommunication: jobLevelCustomerContact.includeOnDataCommunication,
  includeOnLabelsCommunication: jobLevelCustomerContact.includeOnLabelsCommunication,
  includeOnCollectionsCommunication: jobLevelCustomerContact.includeOnCollectionsCommunication,
  includeOnConsumablesCommunication: jobLevelCustomerContact.includeOnConsumablesCommunication,
  includeOnConfirmationCommunication: jobLevelCustomerContact.includeOnConfirmationCommunication,
});

export const MailingBriefSortedWorksheetFormFields = connect<OwnProps, MailingBriefFormModel>(
  MailingBriefSortedWorksheetFormFieldsComponent,
);

const RoyalMailInfoCallout = styled(InfoCallout)`
  margin-bottom: 8px;
`;
