import styled from 'styled-components/macro';
import { bold, headerFont, largeFont } from '../../styling/fonts';
import { narrow } from '../../styling/spacing';

export const PageHeader = styled.h2`
  font-size: ${headerFont};
`;

export const SectionHeader = styled.h3`
  font-size: ${headerFont};
  margin-bottom: ${narrow};
`;

export const ItemHeader = styled.h4`
  font-size: ${largeFont};
  font-weight: ${bold};
  margin-bottom: ${narrow};
`;
