import { DateStamp } from '../../../../models/dateStamp';
import { assertNotNull } from '../../../../utils/assertNotNull';
import { MailingBriefDataCleansingWorksheetFormModel } from './mailingBriefDataCleansingWorksheetFormModel';

export type MailingBriefCreateUpdateDataCleansingWorksheetDto = {
  processingDate: DateStamp;
};

export const toMailingBriefCreateUpdateDataCleansingWorksheetDto = (
  formModel: MailingBriefDataCleansingWorksheetFormModel,
): MailingBriefCreateUpdateDataCleansingWorksheetDto => ({
  processingDate: assertNotNull(formModel.processingDate),
});

export const getInitialMailingBriefDataCleansingWorksheetFormModel = (
  jobLevelCollectionDate?: DateStamp | null,
) => ({
  processingDate: jobLevelCollectionDate ? jobLevelCollectionDate : null,
});
