import { Field, FieldProps, Form, FormikProps, getIn, withFormik } from 'formik';
import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as SearchIcon } from '../images/icons/search-solid.svg';
import {
  blue,
  blueHoverAccent,
  formInvalid,
  formInvalidAccent,
  greyBorder,
  warningAccent,
  warningText,
  white,
} from '../styling/colours';
import { narrow } from '../styling/spacing';
import { maxLength, minLengthIfNotEmpty } from '../utils/validation/stringValidators';
import { combineValidators, createValidator } from '../utils/validation/validation';
import { IconButton } from './buttons/IconButton';
import { FieldError } from './form/FieldError';
import { formInputHeight } from './form/formInputStyling';
import { FormOptionsContext } from './form/FormOptionsContext';
import { Input } from './form/inputs/InputField';

type FilterQuery = {
  query: string;
};

export type FilterProps = {
  value: string;
  onSubmitFilterQuery: (filterQuery: string) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
};

type Props = FilterProps & FormikProps<FilterQuery>;

const maxFilterLength = 200;
const minFilterLength = 3;
export const filterTestId = 'filter';
export const filterInputFieldTestId = 'filter-input-field';
export const filterButtonTestId = 'filter-button';

const FilterComponent = (props: Props) => (
  <Form className={props.className} data-testid={filterTestId}>
    <FilterInputField placeholder={props.placeholder} disabled={props.disabled} name="query" />
  </Form>
);

const filterQueryValidator = createValidator<FilterQuery>({
  query: combineValidators(maxLength(maxFilterLength), minLengthIfNotEmpty(minFilterLength)),
});

const withFormikEnhancer = withFormik<FilterProps, FilterQuery>({
  mapPropsToValues: props => ({ query: props.value }),
  handleSubmit: (values, { props, setSubmitting, setFieldTouched, setFieldValue }) => {
    const filterQuery = values.query.trim();
    props.onSubmitFilterQuery(filterQuery);
    setSubmitting(false);
    setFieldTouched('query', false);
    setFieldValue('query', filterQuery);
  },
  validate: filterQueryValidator,
});

export const Filter = withFormikEnhancer(FilterComponent);

type FilterInputFieldProps = {
  name: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
};

const FilterInputField = (props: FilterInputFieldProps) => {
  const formOptions = useContext(FormOptionsContext);
  return (
    <Field name={props.name} type={props.type} className={props.className}>
      {(fieldProps: FieldProps) => {
        const { field, form } = fieldProps;
        const touched = getIn(form.touched, props.name);
        const error = getIn(form.errors, props.name);
        const valid = touched && !error;
        const invalid = touched && !!error;
        const showWarning = formOptions.showWarningsInitially && !!error;
        return (
          <FilterContentContainer>
            <FilterContainer>
              <FilterInput
                id={props.name}
                onChange={field.onChange}
                {...field}
                disabled={props.disabled || form.isSubmitting}
                placeholder={props.placeholder}
                valid={valid}
                invalid={invalid}
                showWarning={showWarning}
                data-testid={filterInputFieldTestId}
              />
              <FilterButton
                type="submit"
                disabled={props.disabled}
                data-testid={filterButtonTestId}
              >
                <SearchIcon />
              </FilterButton>
            </FilterContainer>

            <FilterFieldError showError={invalid} showWarning={showWarning} for={props.name}>
              {error || 'error placeholder'}
            </FilterFieldError>
          </FilterContentContainer>
        );
      }}
    </Field>
  );
};

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const FilterContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FilterFieldError = styled(FieldError)`
  margin-top: ${narrow};
  margin-left: ${narrow};
  margin-bottom: ${narrow};
`;

const FilterInput = styled(Input)`
  border: 1px solid
    ${props => (props.invalid && formInvalid) || (props.showWarning && warningText) || greyBorder};
  border-right: none;
  border-radius: 24px 0 0 24px;
  background-color: ${props =>
    (props.invalid && formInvalidAccent) || (props.showWarning && warningAccent) || white};
  flex-grow: 1;
  margin: 0;
  padding-left: 20px;
`;

const FilterButton = styled(IconButton)`
  color: ${white};
  background-color: ${blue};
  height: ${formInputHeight};
  border-radius: 0 24px 24px 0;

  &:hover {
    background-color: ${props => !props.disabled && blueHoverAccent};
  }
`;
