import * as React from 'react';
import styled from 'styled-components/macro';
import { lightGrey } from '../../styling/colours';
import { Button, ButtonProps, standardButtonHeight } from './Button';

type IconButtonProps = ButtonProps &
  IconContainerProps & { type?: 'submit' | 'reset' | 'button'; title?: string };

export const IconButton = (props: IconButtonProps) => (
  <IconButtonContainer className={props.className} type={props.type || 'button'} {...props}>
    <IconContainer colour={props.colour}>{props.children}</IconContainer>
  </IconButtonContainer>
);

const IconButtonContainer = styled(Button)`
  background-color: transparent;
  height: ${standardButtonHeight};
  width: ${standardButtonHeight};
  border-radius: 50%;
  padding: 0;

  &:hover {
    background-color: ${props => !props.disabled && lightGrey};
  }

  &:hover,
  &:focus {
    outline: none;
  }
`;

type IconContainerProps = {
  colour?: string;
};

const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  svg {
    height: 50%;
    width: 50%;
    color: ${props => props.colour};
  }
`;
