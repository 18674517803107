import * as React from 'react';
import styled from 'styled-components/macro';
import {
  pageHorizontalPadding,
  pageHorizontalPaddingBreakpoint,
  pageSmallHorizontalPadding,
  wide,
} from '../../styling/spacing';
import { HeaderContainer, Logo } from './Header/Header';
import logoUrl from './onepostLogo.svg';

type Props = {
  children?: React.ReactNode;
};

export const AccessCodePageLayout = (props: Props) => (
  <>
    <HeaderContainer>
      <Logo src={logoUrl} />
    </HeaderContainer>
    <PageContent>{props.children}</PageContent>
  </>
);

const PageContent = styled.div`
  margin-top: ${wide};
  padding: 0 ${pageHorizontalPadding};

  @media (max-width: ${pageHorizontalPaddingBreakpoint}) {
    padding: 0 ${pageSmallHorizontalPadding};
  }
`;
