import {
  DateStamp,
  formatDateStamp,
  getTodayAsDateStamp,
  getTomorrowAsDateStamp,
  isDateStampAfter,
  isDateStampBefore,
  isWorkingDay,
  toDate,
} from '../../models/dateStamp';
import { DateTimeStamp, getNowAsDateTimeStamp } from '../../models/dateTimeStamp';
import { Validator } from './validation';
import { required } from './validators';

export const requiredDate = (
  message: string = 'Please select a date',
): Validator<DateStamp | null | undefined> => (value): string | null =>
  required<DateStamp | null | undefined>(message)(value);

export const requiredDateTime = (
  message: string = 'Please select a date and time',
): Validator<DateTimeStamp | null | undefined> => (value): string | null =>
  required<DateTimeStamp | null | undefined>(message)(value);

export const requiredTime = (
  message: string = 'Please select a time',
): Validator<DateTimeStamp | null | undefined> => (value): string | null =>
  required<DateTimeStamp | null | undefined>(message)(value);

export const onOrAfter = (
  dateStampToCompare: DateStamp | null,
  message: string = dateStampToCompare == null
    ? ''
    : `Cannot be earlier than ${formatDateStamp(dateStampToCompare)}`,
): Validator<DateStamp | null | undefined> => (value): string | null =>
  value != null && dateStampToCompare != null && isDateStampBefore(value, dateStampToCompare)
    ? message
    : null;

export const isAWorkingDay = (
  bankHolidays: Array<DateStamp>,
  message: string = `Cannot be a non-working day`,
): Validator<DateStamp | null | undefined> => (value): string | null => {
  if (value != null) {
    const date = toDate(value);
    if (!isWorkingDay(date, bankHolidays)) {
      return message;
    }
    return null;
  }
  return null;
};

export const isNotASunday = (
  message: string = `Cannot be a Sunday`,
): Validator<DateStamp | null | undefined> => (value): string | null => {
  if (value != null) {
    const date = toDate(value);
    if (date.getDay() === 0) {
      return message;
    }
    return null;
  }
  return null;
};

export const after = (
  dateStampToCompare: DateStamp | null,
  message: string = dateStampToCompare == null
    ? ''
    : `Must be after ${formatDateStamp(dateStampToCompare)}`,
): Validator<DateStamp | null | undefined> => (value): string | null =>
  value != null && dateStampToCompare != null && !isDateStampAfter(value, dateStampToCompare)
    ? message
    : null;

export const onOrBefore = (
  dateStampToCompare: DateStamp | null,
  message: string = dateStampToCompare == null
    ? ''
    : `Cannot be later than ${formatDateStamp(dateStampToCompare)}`,
): Validator<DateStamp | null | undefined> => (value): string | null =>
  value != null && dateStampToCompare != null && isDateStampAfter(value, dateStampToCompare)
    ? message
    : null;

export const before = (
  dateStampToCompare: DateStamp | null,
  message: string = dateStampToCompare == null
    ? ''
    : `Must be before ${formatDateStamp(dateStampToCompare)}`,
): Validator<DateStamp | null | undefined> => (value): string | null =>
  value != null && dateStampToCompare != null && !isDateStampBefore(value, dateStampToCompare)
    ? message
    : null;

export const inTheFuture = (
  message: string = 'Must be in the future',
): Validator<DateStamp | null | undefined> => (value): string | null =>
  onOrAfter(getTomorrowAsDateStamp(), message)(value);

export const dateTimeInTheFuture = (
  message: string = 'Must be in the future',
): Validator<DateTimeStamp | null | undefined> => (value): string | null =>
  onOrAfter(getNowAsDateTimeStamp(), message)(value);

export const todayOrLater = (
  message: string = 'Must be today or later',
): Validator<DateStamp | null | undefined> => (value): string | null =>
  onOrAfter(getTodayAsDateStamp(), message)(value);
