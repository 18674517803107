import { flowRight } from 'lodash';
import * as React from 'react';
import styled from 'styled-components/macro';
import { setApplicationInsightsUser } from '../../applicationInsights';
import { Metadata, MetadataContext } from '../../models/metadata';
import { PrimaryButton } from '../../shared/buttons/Button';
import {
  ApiRequestProps,
  withApiRequest,
} from '../../shared/higher-order-components/withApiRequest';
import { LoadingSpinner } from '../../shared/LoadingSpinner';
import { lightGrey } from '../../styling/colours';
import { fadeIn } from '../../styling/fadeIn';
import { wide, wider } from '../../styling/spacing';
import { AuthenticatedApiRequest, fetchSecureJson, from } from '../../utils/api';
import { AuthenticationProps } from './AzureAdAuthentication';
import { User } from './user';

export const UserContext = React.createContext<User>((null as unknown) as User);

type OwnProps = AuthenticationProps;
type UserRequestProps = ApiRequestProps<undefined, User, 'userRequest'>;
type MetadataRequestProps = ApiRequestProps<undefined, Metadata, 'metadataRequest'>;
export type LogInProps = OwnProps & UserRequestProps & MetadataRequestProps;

export const loginErrorTestId = 'login-error';
export const noPermissionsErrorTestId = 'no-permissions-error';

export class LogInComponent extends React.Component<LogInProps> {
  componentDidMount() {
    const {
      isAuthenticated,
      isLoggingIn,
      isLoggingOut,
      authenticationError,
      redirectToLogin,
      userRequest,
      metadataRequest,
    } = this.props;
    if (!isAuthenticated && !isLoggingIn && !isLoggingOut && !authenticationError) {
      redirectToLogin();
    } else if (isAuthenticated && !isLoggingIn) {
      userRequest
        .sendRequest(undefined)
        .then(response => response.success && setApplicationInsightsUser(response.body.userId));

      metadataRequest.sendRequest(undefined);
    }
  }

  render() {
    const {
      userRequest,
      metadataRequest,
      isAuthenticated,
      isLoggingIn,
      authenticationError,
    } = this.props;
    const error = userRequest.error || metadataRequest.error || authenticationError;
    const isLoading = userRequest.inProgress || metadataRequest.inProgress;

    if (
      !isLoggingIn &&
      isAuthenticated &&
      userRequest.response &&
      metadataRequest.response &&
      !isLoading &&
      !error
    ) {
      const user = userRequest.response;

      return user.permissions.length > 1 ? (
        <UserContext.Provider value={user}>
          <MetadataContext.Provider value={metadataRequest.response}>
            {this.props.children}
          </MetadataContext.Provider>
        </UserContext.Provider>
      ) : (
        <LogInContainer>
          <LogInError data-testid={noPermissionsErrorTestId}>
            You do not have permission to access MATRIX. Please contact the IT department to be
            granted access.
          </LogInError>
          <PrimaryButton onClick={this.props.logout}>Log Out</PrimaryButton>
        </LogInContainer>
      );
    } else if (error || authenticationError) {
      return (
        <LogInContainer>
          <LogInError data-testid={loginErrorTestId}>
            Sorry, something went wrong while logging you in.
          </LogInError>
          <PrimaryButton onClick={this.props.redirectToLogin}>Try again</PrimaryButton>
        </LogInContainer>
      );
    } else {
      return (
        <LogInContainer>
          <LoadingSpinner />
          <div>{this.props.isLoggingOut ? 'Logging out' : 'Logging in'}</div>
        </LogInContainer>
      );
    }
  }
}

const fetchMetadata = (): AuthenticatedApiRequest<Metadata> =>
  fetchSecureJson<Metadata>(from('metadata', 'get'));

const fetchCurrentUser = (): AuthenticatedApiRequest<User> =>
  fetchSecureJson<User>(from(`user`, 'get'));

const enhance = flowRight(
  withApiRequest<OwnProps, undefined, User, 'userRequest'>(() => fetchCurrentUser, 'userRequest'),
  withApiRequest<OwnProps & UserRequestProps, undefined, Metadata, 'metadataRequest'>(
    () => fetchMetadata,
    'metadataRequest',
  ),
);
export const LogIn = enhance(LogInComponent);

export const LogInContainer = styled.div`
  background: ${lightGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  padding: ${wider};
  animation: ${fadeIn} 1s ease;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  opacity: 0;
`;

export const LogInError = styled.div`
  margin-bottom: ${wide};
`;
