import * as React from 'react';
import styled from 'styled-components/macro';
import { FormField } from '../FormField';
import { formInputStyling } from '../formInputStyling';

type TextAreaFieldProps = {
  label: string;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  optional?: boolean;
};

type TextAreaProps = {
  valid?: boolean;
  invalid?: boolean;
  showWarning?: boolean;
};

const TextArea = styled.textarea<TextAreaProps>`
  ${({ valid, invalid, showWarning }) => formInputStyling({ valid, invalid, showWarning })};

  height: 96px;
  resize: vertical;
`;

export const TextAreaField = React.forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
  (props, ref) => (
    <FormField
      name={props.name}
      label={props.label}
      optional={props.optional}
      disabled={props.disabled}
    >
      {({ field, form, valid, invalid, showWarning }) => (
        <TextArea
          id={props.name}
          onChange={field.onChange}
          {...field}
          disabled={props.disabled || form.isSubmitting}
          placeholder={props.placeholder}
          invalid={invalid}
          showWarning={showWarning}
          valid={valid}
          ref={ref}
        />
      )}
    </FormField>
  ),
);
