import { DateTimeStamp } from '../../models/dateTimeStamp';
import { Id } from '../../models/id';
import { WorksheetStatus } from '../../models/metadata';
import { AttachmentFormModel } from '../../shared/files/attachment';
import { Permission, permissions } from '../authentication/permissions';
import { User } from '../authentication/user';
import { canEdit } from './sorted/sortedWorksheet';

export type WorksheetAttachment = {
  worksheetAttachmentId: Id;
  worksheetId: Id;
  fileId: Id;
  fileName: string;
  sizeInBytes: number;
  category: WorksheetAttachmentCategory;
  notes: string;
  isDisplayedOnMailingHouseDataDownloadPage: boolean;
  canBeDisplayedOnMailingHouseDataDownloadPage: boolean;
  isExpiredPersonalData: boolean;
  uploadedByUserName: string;
  uploadedDateTime: DateTimeStamp;
};

export type WorksheetAttachmentCategory =
  | SortedWorksheetAttachmentCategory
  | UnsortedWorksheetAttachmentCategory
  | InternationalWorksheetAttachmentCategory
  | OtherWorksheetAttachmentCategory;

export type SortedWorksheetAttachmentCategory =
  | 'RawData'
  | 'InputData'
  | 'SortedData'
  | 'MultisortOutputs'
  | 'Proof'
  | 'Seed';

export const emptySortedWorksheetAttachments: {
  [category in SortedWorksheetAttachmentCategory]: Array<AttachmentFormModel>;
} = {
  InputData: [],
  SortedData: [],
  MultisortOutputs: [],
  RawData: [],
  Proof: [],
  Seed: [],
};

export type UnsortedWorksheetAttachmentCategory = 'Unsorted';

export type InternationalWorksheetAttachmentCategory = 'International';

export type OtherWorksheetAttachmentCategory = 'Other';

export const sortedWorksheetAttachmentCategoryDisplayNames: {
  [category in SortedWorksheetAttachmentCategory]: string;
} = {
  RawData: 'Raw Data',
  InputData: 'Input Data',
  SortedData: 'Sorted Data',
  MultisortOutputs: 'MULTISORT Outputs',
  Proof: 'Proof',
  Seed: 'Seeds',
};

export const sortedWorksheetAttachmentCategories = Object.keys(
  sortedWorksheetAttachmentCategoryDisplayNames,
) as Array<SortedWorksheetAttachmentCategory>;

export const worksheetAttachmentCategoryDisplayNames: {
  [category in WorksheetAttachmentCategory]: string;
} = {
  ...sortedWorksheetAttachmentCategoryDisplayNames,
  Unsorted: 'Attachments',
  International: 'Attachments',
  Other: 'Attachments',
};

export const viewableSortedWorksheetAttachmentCategoriesByPermissions = (
  userPermissions: Array<Permission>,
): Array<SortedWorksheetAttachmentCategory> => {
  const categories: Array<SortedWorksheetAttachmentCategory> = [];

  if (userPermissions.includes(permissions.ViewRawDataWorksheetAttachment)) {
    categories.push('InputData', 'RawData');
  }

  if (userPermissions.includes(permissions.ViewSortedDataWorksheetAttachment)) {
    categories.push('SortedData');
  }

  if (userPermissions.includes(permissions.ViewMultisortOutputWorksheetAttachment)) {
    categories.push('MultisortOutputs');
  }

  if (userPermissions.includes(permissions.ViewOtherWorksheetAttachment)) {
    categories.push('Proof', 'Seed');
  }

  return categories;
};

export type CreateUpdateWorksheetAttachment = {
  worksheetAttachmentId: Id | null;
  fileId: Id;
  category: WorksheetAttachmentCategory;
  notes: string;
};

export const toAttachmentFormModel = (attachment: WorksheetAttachment): AttachmentFormModel => ({
  attachmentId: attachment.worksheetAttachmentId,
  fileId: attachment.fileId,
  notes: attachment.notes,
  sizeInBytes: attachment.sizeInBytes,
  fileName: attachment.fileName,
  uploadedByUserName: attachment.uploadedByUserName,
  uploadedDateTime: attachment.uploadedDateTime,
  isDeleted: false,
});

export const toCreateUpdateWorksheetAttachment = (
  formModel: AttachmentFormModel,
  category: WorksheetAttachmentCategory,
): CreateUpdateWorksheetAttachment => ({
  worksheetAttachmentId: formModel.attachmentId,
  fileId: formModel.fileId,
  category,
  notes: formModel.notes,
});

export const editableSortedWorksheetAttachmentCategoriesByStatus = (
  worksheetStatus: WorksheetStatus,
  dataProcessingAssigneeUserId: string | null,
  user: User,
): Array<SortedWorksheetAttachmentCategory> => {
  if (
    !canEdit(worksheetStatus.editSortedWorksheetAttachments, dataProcessingAssigneeUserId, user)
  ) {
    return [];
  }

  const categories: Array<SortedWorksheetAttachmentCategory> = ['SortedData', 'MultisortOutputs'];

  if (canEdit(worksheetStatus.editRawDataAttachments, dataProcessingAssigneeUserId, user)) {
    categories.push('RawData');
  }
  if (canEdit(worksheetStatus.editInputDataAttachments, dataProcessingAssigneeUserId, user)) {
    categories.push('InputData');
  }
  if (canEdit(worksheetStatus.editSeedAttachments, dataProcessingAssigneeUserId, user)) {
    categories.push('Seed');
  }
  if (canEdit(worksheetStatus.editProofAttachments, dataProcessingAssigneeUserId, user)) {
    categories.push('Proof');
  }

  return categories;
};

export const editableSortedWorksheetAttachmentCategoriesByPermissions = (
  userPermissions: Array<Permission>,
): Array<SortedWorksheetAttachmentCategory> => {
  const categories: Array<SortedWorksheetAttachmentCategory> = [];

  if (userPermissions.includes(permissions.EditRawDataWorksheetAttachment)) {
    categories.push('InputData', 'RawData');
  }

  if (userPermissions.includes(permissions.EditSortedDataWorksheetAttachment)) {
    categories.push('SortedData');
  }

  if (userPermissions.includes(permissions.EditMultisortOutputWorksheetAttachment)) {
    categories.push('MultisortOutputs');
  }

  if (userPermissions.includes(permissions.EditOtherWorksheetAttachment)) {
    categories.push('Proof', 'Seed');
  }

  return categories;
};
