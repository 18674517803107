import { some } from 'lodash';
import { DateStamp } from '../../../../models/dateStamp';
import { Id } from '../../../../models/id';
import { assertNotNull } from '../../../../utils/assertNotNull';
import {
  isAWorkingDay,
  onOrAfter,
  requiredDate,
} from '../../../../utils/validation/dateValidators';
import {
  combineValidators,
  createArrayValidator,
  createValidator,
  createValidatorWithTopLevelValidator,
  Validator,
} from '../../../../utils/validation/validation';
import { noValidate, required } from '../../../../utils/validation/validators';
import { internationalCellValidator } from '../../../worksheets/international/cells/internationalCellFormModel';
import { InternationalWorksheetFormModel } from '../../../worksheets/international/edit-form/internationalWorksheetFormModel';
import { InternationalWorksheetMailingHouseFormModel } from '../../../worksheets/international/edit-form/internationalWorksheetMailingHouseFormModel';
import { ClientPortalMetadata } from '../../clientPortalMetadata';
import {
  getEarliestCollectionDateForMailingBriefJob,
  hasWorksheetRequiringCustomerContactsAndMailingHouse,
  MailingBriefFormModel,
} from '../mailingBriefFormModel';
import {
  MailingBriefMailingHouseContactFormModel,
  MailingBriefMailingHouseFormModel,
  toMailingBriefMailingHouseContactDto,
} from '../mailingBriefMailingHouseFormModel';
import { MailingBriefCreateUpdateInternationalWorksheetDto } from './mailingBriefCreateUpdateInternationalWorksheetDto';
import {
  getInitialMailingBriefInternationalCellFormModel,
  MailingBriefInternationalCellFormModel,
  toCreateUpdateInternationalCellDto,
} from './mailingBriefInternationalCellFormModel';

export type MailingBriefInternationalWorksheetFormModel = InternationalWorksheetFormModel & {
  worksheetId: null;
  internationalWorksheetId: null;
  statusCode: 'Draft';
  assigneeUserId: null;
  notes: '';
  customCollectionEmailContent: '';
  labelsPrepared: false;
  labelsSentDate: null;
  manifestPrepared: false;
  manifestSentDate: null;
  cells: Array<MailingBriefInternationalCellFormModel>;
  mailingHouse: MailingBriefMailingHouseFormModel;
  collectionDate: DateStamp | null;
};

export const getInitialMailingBriefInternationalWorksheetFormModel = (
  jobLevelMailingHouse?: MailingBriefMailingHouseFormModel | null,
  jobLevelCollectionDate?: DateStamp | null,
): MailingBriefInternationalWorksheetFormModel => ({
  type: 'International',
  statusCode: 'Draft',
  worksheetId: null,
  internationalWorksheetId: null,
  worksheetReference: null,
  isNew: true,
  isOnCreditHold: false,
  isUrgent: false,
  collectionBy: null,
  carrier: null,
  estimatedValueInPounds: '',
  collectionDate: jobLevelCollectionDate ? jobLevelCollectionDate : null,
  assigneeUserId: null,
  mailingHouse: jobLevelMailingHouse
    ? {
        mailingHouseId: jobLevelMailingHouse.mailingHouseId,
        contacts: jobLevelMailingHouse.contacts.map(contact => ({
          ...contact,
          includeOnCommunications: false,
        })),
      }
    : {
        mailingHouseId: null,
        contacts: [],
      },
  customerContacts: [],
  notes: '',
  customCollectionEmailContent: '',
  labelsPrepared: false,
  labelsSentDate: null,
  manifestPrepared: false,
  manifestSentDate: null,
  cells: [getInitialMailingBriefInternationalCellFormModel()],
  attachments: {
    International: [],
  },
});

export const toMailingBriefCreateUpdateInternationalWorksheetDto = (
  formModel: MailingBriefInternationalWorksheetFormModel,
): MailingBriefCreateUpdateInternationalWorksheetDto => ({
  cells: formModel.cells.map(toCreateUpdateInternationalCellDto),
  mailingHouse: {
    mailingHouseId: formModel.mailingHouse.mailingHouseId,
    mailingHouseContacts: formModel.mailingHouse.contacts.map(toMailingBriefMailingHouseContactDto),
  },
  collectionDate: assertNotNull(formModel.collectionDate),
});

export const mailingBriefInternationalWorksheetValidator = (
  metadata: ClientPortalMetadata,
  formModel: MailingBriefFormModel,
  isForecast: boolean,
  isConvertingForecastJobToLive: boolean,
) => {
  if (!formModel.internationalWorksheet) {
    return noValidate;
  }

  const earliestCollectionDate = getEarliestCollectionDateForMailingBriefJob(metadata.bankHolidays);

  return createValidator<MailingBriefInternationalWorksheetFormModel>({
    cells: createArrayValidator(() => internationalCellValidator),
    mailingHouse:
      isForecast && !isConvertingForecastJobToLive
        ? internationalWorksheetMailingHouseValidator
        : internationalWorksheetMailingHouseWithContactsValidator(
            formModel,
            isConvertingForecastJobToLive,
          ),
    collectionDate: combineValidators(
      requiredDate('Please select a collection date'),
      onOrAfter(earliestCollectionDate),
      isAWorkingDay(metadata.bankHolidays),
    ),
  }) as Validator<MailingBriefInternationalWorksheetFormModel | null>;
};

const internationalWorksheetMailingHouseValidator = createValidator<
  InternationalWorksheetMailingHouseFormModel & MailingBriefMailingHouseFormModel
>({
  mailingHouseId: required<Id>(),
});

const internationalWorksheetMailingHouseWithContactsValidator = (
  mailingBriefFormModel: MailingBriefFormModel,
  isConvertingForecastJobToLive: boolean,
) => {
  if (!hasWorksheetRequiringCustomerContactsAndMailingHouse(mailingBriefFormModel)) {
    return noValidate;
  }

  return createValidatorWithTopLevelValidator<
    InternationalWorksheetMailingHouseFormModel & MailingBriefMailingHouseFormModel
  >(
    {
      mailingHouseId: required<Id>(),
    },
    ({ contacts }) => {
      if (contacts.length === 0) {
        return null;
      }

      if (
        mailingBriefFormModel.forecastJobOption === 'Forecast' &&
        !isConvertingForecastJobToLive
      ) {
        return null;
      }

      const internationalContactSelected = some(
        contacts,
        (contact: MailingBriefMailingHouseContactFormModel) =>
          contact.includeOnInternationalCommunication,
      );
      if (mailingBriefFormModel.internationalWorksheet) {
        if (!internationalContactSelected) {
          return 'At least one contact must be selected for international communication';
        }
      }
      return null;
    },
  );
};
