import * as React from 'react';
import styled from 'styled-components/macro';
import { NoDataTableDataCell, TBody, Td, Th, THead, Tr } from '../../../../shared/table/Table';
import { TickOrCross } from '../../../../shared/TickOrCross';
import {
  WorksheetContactDetailsTableData,
  WorksheetContactsTable,
} from '../../../worksheets/contacts/WorksheetContactsTable';
import { ClientPortalWorksheetContactDetails } from '../ClientPortalWorksheetContactDetails';
import { ClientPortalSortedWorksheetCustomerContact } from './ClientPortalSortedWorksheet';

type Props = {
  contacts: Array<ClientPortalSortedWorksheetCustomerContact>;
};

export const ClientPortalSortedWorksheetCustomerContactsTable = (props: Props) => {
  const { contacts } = props;

  return (
    <WorksheetContactsTable>
      <THead>
        <Tr>
          <Th>Contact Details</Th>
          <CarbonCopyTableHeader>CC</CarbonCopyTableHeader>
          <DataTableHeader>Data</DataTableHeader>
          <LabelsTableHeader>Labels</LabelsTableHeader>
          <CollectionsTableHeader>Collections</CollectionsTableHeader>
          <ConsumablesTableHeader>Consumables</ConsumablesTableHeader>
          <ConfirmationTableHeader>Confirmation</ConfirmationTableHeader>
        </Tr>
      </THead>
      <TBody>
        {contacts.length === 0 && (
          <Tr>
            <NoDataTableDataCell colSpan={7}>
              -- No contacts have been configured on this worksheet --
            </NoDataTableDataCell>
          </Tr>
        )}
        {contacts.map((contact, index) => (
          <Tr key={index}>
            <WorksheetContactDetailsTableData>
              <ClientPortalWorksheetContactDetails contact={contact} />
            </WorksheetContactDetailsTableData>
            <Td>
              <TickOrCross
                centered={true}
                value={contact.includeOnDataCommunication === 'CarbonCopy'}
              />
            </Td>
            <Td>
              <TickOrCross
                centered={true}
                value={contact.includeOnDataCommunication === 'LinkAndPassword'}
              />
            </Td>
            <Td>
              <TickOrCross centered={true} value={contact.includeOnLabelsCommunication} />
            </Td>
            <Td>
              <TickOrCross centered={true} value={contact.includeOnCollectionsCommunication} />
            </Td>
            <Td>
              <TickOrCross centered={true} value={contact.includeOnConsumablesCommunication} />
            </Td>
            <Td>
              <TickOrCross centered={true} value={contact.includeOnConfirmationCommunication} />
            </Td>
          </Tr>
        ))}
      </TBody>
    </WorksheetContactsTable>
  );
};

const IncludeOnCommunicationTableHeader = styled(Th)`
  text-align: center;
`;

const LabelsTableHeader = styled(IncludeOnCommunicationTableHeader)`
  width: 90px;
`;

const CarbonCopyTableHeader = styled(IncludeOnCommunicationTableHeader)`
  width: 90px;
`;

const DataTableHeader = styled(IncludeOnCommunicationTableHeader)`
  width: 90px;
`;

const CollectionsTableHeader = styled(IncludeOnCommunicationTableHeader)`
  width: 120px;
`;

const ConsumablesTableHeader = styled(IncludeOnCommunicationTableHeader)`
  width: 120px;
`;

const ConfirmationTableHeader = styled(IncludeOnCommunicationTableHeader)`
  width: 120px;
`;
