export const smallFont = '13px';
export const regularFontPixels = 15;
export const regularFont = `${regularFontPixels}px`;
export const largeFont = '19px';
export const headerFont = '26px';

export const regularWeight = 500;
export const bold = 600;

export const regularFontFamily = '"Calibri", sans-serif';
export const lightFontFamily = '"Calibri Light", sans-serif';
