import { Id } from '../../models/id';
import { dataProcessingUrl } from '../../urls';

export const prioritiseDataProcessingWorksheetsUrl = () =>
  `${dataProcessingUrl()}/prioritise-worksheets`;

export const dataProcessingTaskListUrl = () => `${dataProcessingUrl()}/task-list`;

export const viewWorksheetForDataProcessingUrl = (sortedWorksheetId: Id) =>
  `${dataProcessingUrl()}/${sortedWorksheetId}`;
