import { FormikProps } from 'formik';
import { map } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { Id } from '../../../../models/id';
import { FieldSet } from '../../../../shared/form/FieldSet';
import { RadioButtonsField } from '../../../../shared/form/inputs/RadioButtonsField';
import { SearchSelectField } from '../../../../shared/form/inputs/SearchSelectField';
import { SelectField } from '../../../../shared/form/inputs/SelectField';
import { WarningBox } from '../../../../shared/WarningBox';
import {
  printingTypeOptions,
  WorksheetPrintingType,
} from '../../../worksheets/sorted/edit-form/sortedWorksheetFormModel';
import { labelDeliveryServiceOptions } from '../../../worksheets/sorted/sortedWorksheet';
import { WorksheetLabelDeliveryService } from '../../../worksheets/worksheet';
import { MailingHouseForMailingBriefForm } from '../dataForMailingBriefForm';
import { MailingBriefFormModel } from '../mailingBriefFormModel';
import { fromMailingHouseContactForJobForm } from '../mailingBriefMailingHouseFormModel';
import { JobLevelMailingBriefMailingHouseContactsFieldArray } from './JobLevelMailingBriefMailingHouseContactsFieldArray';

// This component is used in non-split jobs, where the mailing house (and its contacts) should be the same for all worksheets.
// There will be at most one sorted worksheet. When there is a sorted worksheet,
// we take the first and only element in the sorted worksheets array.
// We need to set mailing house on each of the worksheets, so we first find the mailing house ID if it exists by going
// through all the possible worksheets.
// Then we set the mailing house ID and contacts on each of the worksheets.
// Some of the form fields require a name to bind to, so we arbitrarily choose the first worksheet to bind to.
export const JobLevelMailingBriefMailingHouse = (props: {
  formikProps: FormikProps<MailingBriefFormModel>;
  mailingHouseResponses: { [mailingHouseId: number]: MailingHouseForMailingBriefForm };
  isConvertingForecastJobToLive: boolean;
}) => {
  const { formikProps } = props;
  const { values: formValues } = formikProps;
  const [mailingHouseId, setMailingHouseId] = useState(
    formValues.sortedWorksheets.length > 0
      ? formValues.sortedWorksheets[0].mailingHouse.mailingHouseId
      : formValues.unsortedWorksheet
      ? formValues.unsortedWorksheet.mailingHouse.mailingHouseId
      : formValues.internationalWorksheet
      ? formValues.internationalWorksheet.mailingHouse.mailingHouseId
      : null,
  );

  const mailingHouseFieldNames = [
    formValues.sortedWorksheets.length > 0 ? `sortedWorksheets[0].mailingHouse` : null,
    formValues.unsortedWorksheet ? 'unsortedWorksheet.mailingHouse' : null,
    formValues.internationalWorksheet ? 'internationalWorksheet.mailingHouse' : null,
  ].filter(name => !!name);

  useEffect(() => {
    if (Object.keys(props.mailingHouseResponses).length === 1) {
      mailingHouseFieldNames.map(mailingHouseFieldName => {
        const onlyMailingHouseId = Object.values(props.mailingHouseResponses)[0].mailingHouseId;
        props.formikProps.setFieldValue(
          `${mailingHouseFieldName}.mailingHouseId`,
          onlyMailingHouseId,
        );
        setMailingHouseId(onlyMailingHouseId);
      });
    } else {
      setMailingHouseId(
        formValues.sortedWorksheets.length > 0
          ? formValues.sortedWorksheets[0].mailingHouse.mailingHouseId
          : formValues.unsortedWorksheet
          ? formValues.unsortedWorksheet.mailingHouse.mailingHouseId
          : formValues.internationalWorksheet
          ? formValues.internationalWorksheet.mailingHouse.mailingHouseId
          : null,
      );
    }
  }, [
    formValues.sortedWorksheets,
    formValues.unsortedWorksheet,
    formValues.internationalWorksheet,
  ]);

  useEffect(() => {
    if (mailingHouseId === null) {
      mailingHouseFieldNames.map(mailingHouseFieldName => {
        formikProps.setFieldValue(`${mailingHouseFieldName}.mailingHouseId`, null);
        formikProps.setFieldValue(`${mailingHouseFieldName}.contacts`, []);
      });
    } else {
      const mailingHouse = props.mailingHouseResponses[mailingHouseId];
      mailingHouseFieldNames.map(mailingHouseFieldName => {
        formikProps.setFieldValue(`${mailingHouseFieldName}.mailingHouseId`, mailingHouseId);
        formikProps.setFieldValue(
          `${mailingHouseFieldName}.contacts`,
          Object.values(mailingHouse.contactsById).map(contact =>
            fromMailingHouseContactForJobForm(contact),
          ),
        );
      });
    }
  }, [mailingHouseId]);

  const mailingHouseOptions = useMemo(
    () =>
      map(props.mailingHouseResponses, ({ mailingHouseId: id, referenceNumber, name }) => ({
        value: id,
        text: `${referenceNumber} - ${name}`,
      })),
    [mailingHouseId],
  );

  const hasMultipleMailingHouses = Object.keys(props.mailingHouseResponses).length > 1;
  const hasMailingHouseAvailable = Object.keys(props.mailingHouseResponses).length > 0;

  if (!hasMailingHouseAvailable) {
    return (
      <WarningBox message="You have no mailing houses assigned - please contact your ONEPOST account manager" />
    );
  }

  const isForecast = formValues.forecastJobOption === 'Forecast';

  return (
    <>
      {hasMultipleMailingHouses && (
        <SearchSelectField<Id>
          label="Mailing house"
          name={`${mailingHouseFieldNames[0]}.mailingHouseId`}
          options={mailingHouseOptions}
          placeholder="Select a mailing house"
        />
      )}
      {!!mailingHouseId && (!isForecast || props.isConvertingForecastJobToLive) && (
        <>
          <JobLevelMailingBriefMailingHouseContactsFieldArray
            mailingHouse={props.mailingHouseResponses[mailingHouseId]}
            mailingBriefFormModel={formValues}
            formikProps={props.formikProps}
          />
          {formValues.sortedWorksheets.length > 0 && (
            <FieldSet label="Carrier Delivery">
              <RadioButtonsField<WorksheetPrintingType>
                name="sortedWorksheets[0].printingType"
                label="Printing Type"
                options={printingTypeOptions}
              />
              {formValues.sortedWorksheets[0].printingType === 'OnepostPrint' && (
                <SelectField<WorksheetLabelDeliveryService>
                  label="Label Delivery Service"
                  name="sortedWorksheets[0].labelDeliveryService"
                  options={labelDeliveryServiceOptions}
                />
              )}
            </FieldSet>
          )}
        </>
      )}
    </>
  );
};
