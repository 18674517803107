import { DateStamp } from '../../models/dateStamp';
import { Id } from '../../models/id';
import { SelectOptions } from '../../shared/form/inputs/Select';
import {
  PaginatedTableListResponse,
  PaginatedTableQuery,
} from '../../shared/table/ControlledPaginatedTable';
import { PaginationUrlState } from '../../shared/table/PaginatedTableWithUrlState';
import { digitsOnly, maxLength, notEmpty } from '../../utils/validation/stringValidators';
import { combineValidators } from '../../utils/validation/validation';

export type Scid = {
  scidCode: string;
  customerId: Id;
  customerName: string;
  subCustomerName: string | null;
  mailingHouseName: string;
  contractTypeDisplayName: string;
  mailmarkBarcodeType: ScidMailmarkBarcodeType;
  lastUsed: DateStamp | null;
  nextAvailableItemNumber: number;
};

export type ScidMailmarkBarcodeType = 'FourState' | 'TwoState';

export const scidMailmarkBarcodeTypeDisplayNames: { [type in ScidMailmarkBarcodeType]: string } = {
  TwoState: '2-State',
  FourState: '4-State',
};

export const scidMailmarkBarcodeTypes = Object.keys(scidMailmarkBarcodeTypeDisplayNames) as Array<
  ScidMailmarkBarcodeType
>;

export const scidMailmarkBarcodeSelectOptions: SelectOptions<
  ScidMailmarkBarcodeType
> = scidMailmarkBarcodeTypes.map(barcodeType => ({
  text: scidMailmarkBarcodeTypeDisplayNames[barcodeType],
  value: barcodeType,
}));

export type ManageScidsSortColumn =
  | 'ScidCode'
  | 'CustomerName'
  | 'SubCustomerName'
  | 'MailingHouseName'
  | 'ContractType'
  | 'LastUsed';

export type ScidListPaginationQuery = PaginatedTableQuery<ManageScidsSortColumn>;

export type ScidListPaginationState = PaginationUrlState<ManageScidsSortColumn>;

export type PaginatedScidListResponse = PaginatedTableListResponse<ManageScidsSortColumn> & {
  scids: Array<Scid>;
};

export type SubCustomerForScidForm = {
  subCustomerId: Id;
  name: string;
};

export type CustomerForCreateScidForm = {
  customerId: Id;
  name: string;
  subCustomersById: { [subCustomerId: number]: SubCustomerForScidForm };
};

export type MailingHouseForCreateScidForm = {
  mailingHouseId: Id;
  name: string;
};

export type CustomersAndMailingHousesForCreateScidForm = {
  customersById: { [customerId: number]: CustomerForCreateScidForm };
  mailingHousesById: { [mailingHouseId: number]: MailingHouseForCreateScidForm };
};

export type GetMatchingScidsRequest = {
  customerId: Id;
  subCustomerId: Id | null;
  mailingHouseId: Id;
  mailmarkBarcodeType: ScidMailmarkBarcodeType;
};

export type GetMatchingScidsResponse = {
  zonalScidCode: string | null;
  nationalScidCode: string | null;
};

export const validScidCode = combineValidators(digitsOnly(), maxLength(7));
export const requiredScidCode = combineValidators(notEmpty(), validScidCode);
