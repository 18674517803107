import * as React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as RadioCheckedIcon } from '../../../images/icons/radio-checked.svg';
import { ReactComponent as RadioUncheckedIcon } from '../../../images/icons/radio-unchecked.svg';
import {
  alertText,
  blue,
  blueHoverAccent,
  darkGrey,
  fontGrey,
  lightGrey,
  successText,
  warningText,
} from '../../../styling/colours';
import { regularFont } from '../../../styling/fonts';
import { narrow } from '../../../styling/spacing';
import { fastTransitionDuration } from '../../../styling/transitions';

export const radioButtonInputTestId = 'checkbox-input';
export const radioButtonIconContainerTestId = 'checkbox-icon-container';

type RadioButtonProps<TValue extends string> = {
  name: string;
  label?: string;
  selected: boolean;
  value: TValue;
  onChange: (changeEvent: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  disabled?: boolean;
  valid?: boolean;
  invalid?: boolean;
  showWarning?: boolean;
};

type RadioButtonState = {
  focused: boolean;
};

export class RadioButton<TValue extends string> extends React.Component<
  RadioButtonProps<TValue>,
  RadioButtonState
> {
  state = { focused: false };

  onFocus = () => this.setState({ focused: true });

  onBlur = () => this.setState({ focused: false }, () => this.props.onBlur && this.props.onBlur());

  render() {
    return (
      <RadioButtonContainer>
        <RadioButtonLabel
          disabled={this.props.disabled}
          valid={this.props.valid}
          invalid={this.props.invalid}
          showWarning={this.props.showWarning}
        >
          <RadioButtonInput
            type="radio"
            id={this.props.name}
            name={this.props.name}
            disabled={this.props.disabled}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            data-testid={radioButtonInputTestId}
            value={this.props.value}
            onChange={this.props.onChange}
          />
          <RadioButtonIconContainer
            selected={this.props.selected}
            disabled={this.props.disabled}
            focused={this.state.focused}
            data-testid={radioButtonIconContainerTestId}
          >
            {!this.props.selected && <RadioUncheckedIcon />}
            {this.props.selected && <RadioCheckedIcon />}
          </RadioButtonIconContainer>
          {this.props.label && <RadioButtonLabelText>{this.props.label}</RadioButtonLabelText>}
        </RadioButtonLabel>
      </RadioButtonContainer>
    );
  }
}

const RadioButtonContainer = styled.div`
  display: inline-block;
`;

const RadioButtonInput = styled.input`
  margin: 0;
  height: ${regularFont};
  width: ${regularFont};
  z-index: 1;
  opacity: 0;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;

type RadioButtonLabelProps = {
  disabled?: boolean;
  valid?: boolean;
  invalid?: boolean;
  showWarning?: boolean;
};

const RadioButtonLabel = styled.label<RadioButtonLabelProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${props =>
    (props.valid && successText) ||
    (props.invalid && alertText) ||
    (props.showWarning && warningText) ||
    (props.disabled && darkGrey) ||
    fontGrey};
  user-select: none;
`;

type RadioButtonIconContainerProps = {
  selected: boolean;
  disabled?: boolean;
  focused: boolean;
};

const RadioButtonIconContainer = styled.div<RadioButtonIconContainerProps>`
  height: ${regularFont};
  width: ${regularFont};
  position: absolute;
  color: ${props => (props.focused && blueHoverAccent) || (props.selected && blue) || lightGrey};
  border: 1px solid ${props => (props.focused && darkGrey) || 'transparent'};
  transition: all ${fastTransitionDuration} ease;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  ${RadioButtonLabel}:hover & {
    color: ${props => (props.disabled ? (props.selected && blue) || lightGrey : blueHoverAccent)};
  }
`;

const RadioButtonLabelText = styled.span`
  margin-left: ${narrow};
`;
