import { every, some } from 'lodash';
import { DateStamp, getTodayAsDateStamp, workingDaysFrom } from '../../../models/dateStamp';
import { Id } from '../../../models/id';
import { RadioButtonOptions } from '../../../shared/form/inputs/RadioButtonsField';
import { assertNotNull } from '../../../utils/assertNotNull';
import { maxLength, notEmpty } from '../../../utils/validation/stringValidators';
import {
  combineValidators,
  createArrayValidator,
  createValidator,
} from '../../../utils/validation/validation';
import { noValidate, required, requiredSelect } from '../../../utils/validation/validators';
import { SubCustomerResponse } from '../../customers/subCustomerResponse';
import { ClientPortalMetadata } from '../clientPortalMetadata';
import {
  MailingBriefCreateUpdateDataCleansingWorksheetDto,
  toMailingBriefCreateUpdateDataCleansingWorksheetDto,
} from './dataCleansing/MailingBriefCreateUpdateDataCleansingWorksheetDto';
import {
  MailingBriefDataCleansingWorksheetFormModel,
  mailingBriefDataCleansingWorksheetValidator,
} from './dataCleansing/mailingBriefDataCleansingWorksheetFormModel';
import {
  CustomerForMailingBriefForm,
  MailingHouseForMailingBriefForm,
} from './dataForMailingBriefForm';
import { MailingBriefCreateUpdateInternationalWorksheetDto } from './international/mailingBriefCreateUpdateInternationalWorksheetDto';
import {
  MailingBriefInternationalWorksheetFormModel,
  mailingBriefInternationalWorksheetValidator,
  toMailingBriefCreateUpdateInternationalWorksheetDto,
} from './international/mailingBriefInternationalWorksheetFormModel';
import { MailingBriefCustomerContactDto } from './mailingBriefCustomerContactDto';
import {
  MailingBriefCustomerContactFormModel,
  toCreateUpdateMailingBriefCustomerContactDto,
} from './mailingBriefCustomerContactFormModel';
import {
  MailingBriefCreateUpdateSortedWorksheetDto,
  toMailingBriefCreateUpdateSortedWorksheetDtos,
} from './sorted/mailingBriefCreateUpdateSortedWorksheetDto';
import {
  MailingBriefSortedWorksheetFormModel,
  mailingBriefSortedWorksheetsValidator,
} from './sorted/mailingBriefSortedWorksheetFormModel';
import {
  MailingBriefCreateUpdateUnsortedWorksheetDto,
  toMailingBriefCreateUpdateUnsortedWorksheetDto,
} from './unsorted/mailingBriefCreateUpdateUnsortedWorksheetDto';
import {
  MailingBriefUnsortedWorksheetFormModel,
  mailingBriefUnsortedWorksheetValidator,
} from './unsorted/mailingBriefUnsortedWorksheetFormModel';

export type DataSortationOption = 'Required' | 'NotRequired';
export type ForecastJobOption = 'Forecast' | 'NotForecast';
export type SplitJobOption = 'Split' | 'NotSplit';

export const MinimumMaxPresentationFillValueThatTriggersReversionCharges = 25;

export type MailingBriefFormModel = {
  dataSortationRequired: DataSortationOption | null;
  forecastJobOption: ForecastJobOption | null;
  splitJobOption: SplitJobOption | null;
  campaignName: string;
  customerId: Id | null;
  customerReference: string;
  purchaseOrderNumber: string;
  subCustomerId: number | null;
  customerContacts: Array<MailingBriefCustomerContactFormModel>;
  sortedWorksheets: Array<MailingBriefSortedWorksheetFormModel>;
  unsortedWorksheet: MailingBriefUnsortedWorksheetFormModel | null;
  internationalWorksheet: MailingBriefInternationalWorksheetFormModel | null;
  dataCleansingWorksheet: MailingBriefDataCleansingWorksheetFormModel | null;
  additionalInstructions: string;
};

export const getEarliestCollectionDateForMailingBriefJob = (bankHolidays: Array<DateStamp>) =>
  workingDaysFrom(getTodayAsDateStamp(), 3, bankHolidays);

export const getMailingBriefFormModelValidator = (
  formModel: MailingBriefFormModel,
  customers: { [customerId: number]: CustomerForMailingBriefForm },
  mailingHouses: { [mailingHouseId: number]: MailingHouseForMailingBriefForm },
  metadata: ClientPortalMetadata,
  isConvertingForecastJobToLive: boolean,
) => {
  const isForecast = formModel.forecastJobOption === 'Forecast';

  return createValidator<MailingBriefFormModel>({
    customerId: combineValidators(required('Please select a customer')),
    campaignName: combineValidators(notEmpty('Please enter a campaign name'), maxLength(100)),
    customerReference: combineValidators(notEmpty('Please enter a reference'), maxLength(100)),
    purchaseOrderNumber: maxLength(20),
    splitJobOption: isConvertingForecastJobToLive ? requiredSelect() : noValidate,
    customerContacts:
      isForecast && !isConvertingForecastJobToLive
        ? noValidate
        : getMailingBriefCustomerContactValidator(
            customers,
            formModel,
            isConvertingForecastJobToLive,
          ),
    sortedWorksheets:
      formModel.sortedWorksheets.length === 0
        ? () =>
            !formModel.unsortedWorksheet &&
            !formModel.internationalWorksheet &&
            !formModel.dataCleansingWorksheet
              ? 'Please select at least one worksheet type'
              : null
        : mailingBriefSortedWorksheetsValidator(
            formModel,
            mailingHouses,
            metadata,
            isForecast,
            isConvertingForecastJobToLive,
          ),
    unsortedWorksheet: mailingBriefUnsortedWorksheetValidator(
      formModel,
      metadata.unsortedCellValidation,
      metadata,
      isForecast,
      isConvertingForecastJobToLive,
    ),
    internationalWorksheet: mailingBriefInternationalWorksheetValidator(
      metadata,
      formModel,
      isForecast,
      isConvertingForecastJobToLive,
    ),
    dataCleansingWorksheet: mailingBriefDataCleansingWorksheetValidator(formModel, metadata),
    additionalInstructions: maxLength(2000),
  })(formModel);
};

export type SubmitMailingBriefCommand = {
  customerId: Id;
  campaignName: string;
  customerReference: string;
  purchaseOrderNumber: string;
  subCustomerId: number | null;
  customerContacts: Array<MailingBriefCustomerContactDto>;
  isForecast: boolean;
  unsortedWorksheet: MailingBriefCreateUpdateUnsortedWorksheetDto | null;
  sortedWorksheets: Array<MailingBriefCreateUpdateSortedWorksheetDto> | null;
  internationalWorksheet: MailingBriefCreateUpdateInternationalWorksheetDto | null;
  dataCleansingWorksheet: MailingBriefCreateUpdateDataCleansingWorksheetDto | null;
  additionalInstructions: string;
  jobId: number | null;
};

export const dataSortationRadioOptions: RadioButtonOptions<DataSortationOption> = [
  { text: 'Required', value: 'Required' },
  { text: 'Not Required', value: 'NotRequired' },
];
export const forecastJobRadioOptions: RadioButtonOptions<ForecastJobOption> = [
  { text: 'Forecast', value: 'Forecast' },
  { text: 'Live', value: 'NotForecast' },
];

export const splitJobRadioOptions: RadioButtonOptions<SplitJobOption> = [
  { text: 'Standard', value: 'NotSplit' },
  { text: 'Split', value: 'Split' },
];

export const toMailingBriefCommand = (
  formModel: MailingBriefFormModel,
  jobId: number | null,
): SubmitMailingBriefCommand => ({
  customerId: assertNotNull(formModel.customerId),
  campaignName: formModel.campaignName,
  customerReference: formModel.customerReference,
  purchaseOrderNumber: formModel.purchaseOrderNumber,
  subCustomerId: formModel.subCustomerId,
  customerContacts: formModel.customerContacts.map(toCreateUpdateMailingBriefCustomerContactDto),
  isForecast: formModel.forecastJobOption === 'Forecast',
  sortedWorksheets:
    formModel.sortedWorksheets.length > 0
      ? toMailingBriefCreateUpdateSortedWorksheetDtos(formModel.sortedWorksheets)
      : null,
  unsortedWorksheet: formModel.unsortedWorksheet
    ? toMailingBriefCreateUpdateUnsortedWorksheetDto(assertNotNull(formModel.unsortedWorksheet))
    : null,
  internationalWorksheet: formModel.internationalWorksheet
    ? toMailingBriefCreateUpdateInternationalWorksheetDto(
        assertNotNull(formModel.internationalWorksheet),
      )
    : null,
  dataCleansingWorksheet: formModel.dataCleansingWorksheet
    ? toMailingBriefCreateUpdateDataCleansingWorksheetDto(
        assertNotNull(formModel.dataCleansingWorksheet),
      )
    : null,
  additionalInstructions: formModel.additionalInstructions,
  jobId,
});

export type ClientPortalConvertForecastJobToLiveCommand = {
  jobId: Id;
  sortedWorksheets: Array<MailingBriefCreateUpdateSortedWorksheetDto>;
  unsortedWorksheet: MailingBriefCreateUpdateUnsortedWorksheetDto | null;
  internationalWorksheet: MailingBriefCreateUpdateInternationalWorksheetDto | null;
  dataCleansingWorksheet: MailingBriefDataCleansingWorksheetFormModel | null;
  additionalInstructions: string;
  customerContacts: Array<MailingBriefCustomerContactDto>;
};

export type ConvertForecastJobToLiveResponse = {
  createdJobReference: number;
};

export const toConvertForecastJobToLiveCommand = (
  formModel: MailingBriefFormModel,
  jobId: number,
): ClientPortalConvertForecastJobToLiveCommand => ({
  sortedWorksheets: toMailingBriefCreateUpdateSortedWorksheetDtos(formModel.sortedWorksheets),
  unsortedWorksheet: formModel.unsortedWorksheet
    ? toMailingBriefCreateUpdateUnsortedWorksheetDto(assertNotNull(formModel.unsortedWorksheet))
    : null,
  internationalWorksheet: formModel.internationalWorksheet
    ? toMailingBriefCreateUpdateInternationalWorksheetDto(
        assertNotNull(formModel.internationalWorksheet),
      )
    : null,
  dataCleansingWorksheet: formModel.dataCleansingWorksheet
    ? toMailingBriefCreateUpdateDataCleansingWorksheetDto(
        assertNotNull(formModel.dataCleansingWorksheet),
      )
    : null,
  customerContacts: formModel.customerContacts.map(toCreateUpdateMailingBriefCustomerContactDto),
  additionalInstructions: formModel.additionalInstructions,
  jobId,
});

const getMailingBriefCustomerContactValidator = (
  customers: { [customerId: number]: CustomerForMailingBriefForm },
  mailingBrief: MailingBriefFormModel,
  isConvertingForecastJobToLive: boolean,
) => {
  if (
    mailingBrief.customerId === null ||
    (mailingBrief.forecastJobOption === 'Forecast' && !isConvertingForecastJobToLive)
  ) {
    return noValidate;
  }

  const customer = customers[mailingBrief.customerId];
  return createArrayValidator<MailingBriefCustomerContactFormModel>(
    () => () => ({}),
    (contacts: Array<MailingBriefCustomerContactFormModel>) => {
      if (mailingBrief.sortedWorksheets.length > 0) {
        if (
          some(
            customer.customerContacts,
            c => c.includeOnJobsCode === 'Optionally' || c.includeOnConfirmationCommunication,
          ) &&
          every(contacts, contact => !contact.includeOnConfirmationCommunication)
        ) {
          return 'At least one Confirmation contact must be selected';
        }

        if (
          every(contacts, contact => !contact.includeOnDataCommunication) &&
          every(mailingBrief.customerContacts, contact => !contact.includeOnDataCommunication) &&
          every(mailingBrief.sortedWorksheets, sortedWorksheet =>
            sortedWorksheet.mailingHouse.contacts.every(
              contact => !contact.includeOnDataCommunication,
            ),
          )
        ) {
          return 'At least one Mailing House or Customer contact must be selected to receive Data communications';
        }
      }

      if (
        mailingBrief.unsortedWorksheet &&
        !some(mailingBrief.customerContacts, contact => contact.includeOnUnsortedCommunication)
      ) {
        return 'At least one Customer contact must be selected to receive Unsorted communications';
      }

      if (
        mailingBrief.internationalWorksheet &&
        !some(mailingBrief.customerContacts, contact => contact.includeOnInternationalCommunication)
      ) {
        return 'At least one Customer contact must be selected to receive International communications';
      }

      return null;
    },
  );
};

export const customerHasSubCustomers = (
  subCustomersByCustomerId: { [customerId: number]: Array<SubCustomerResponse> },
  customerId: number,
) => subCustomersByCustomerId[customerId] && subCustomersByCustomerId[customerId].length > 0;

export const anyWorksheetSelected = (formModel: MailingBriefFormModel) =>
  formModel.sortedWorksheets.length > 0 ||
  !!formModel.unsortedWorksheet ||
  !!formModel.internationalWorksheet ||
  !!formModel.dataCleansingWorksheet;
export const hasWorksheetRequiringCustomerContactsAndMailingHouse = (
  formModel: MailingBriefFormModel,
) =>
  formModel.sortedWorksheets.length > 0 ||
  !!formModel.unsortedWorksheet ||
  !!formModel.internationalWorksheet;

export const hasAtMostOneSortedWorksheet = (
  formModel: MailingBriefFormModel,
  isConvertingForecastJobToLive: boolean,
) =>
  formModel.sortedWorksheets.length === 0 ||
  formModel.splitJobOption === 'NotSplit' ||
  (formModel.forecastJobOption === 'Forecast' && !isConvertingForecastJobToLive);
