import * as React from 'react';
import styled from 'styled-components/macro';
import { Link } from '../shared/navigation/Link';
import { lightGrey } from '../styling/colours';
import { medium, wider } from '../styling/spacing';
import { RouteComponentProps } from '../utils/RouteComponentProps';

export const LoggedOut = (props: RouteComponentProps & { returnPath: string }) => (
  <LogOutContainer>
    <LoggedOutText>You have been logged out.</LoggedOutText>
    <Link to={props.returnPath}>Return to site</Link>
  </LogOutContainer>
);

const LogOutContainer = styled.div`
  background: ${lightGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  padding: ${wider};
`;

const LoggedOutText = styled.div`
  margin-bottom: ${medium};
`;
