import * as React from 'react';
import { useState } from 'react';

export type FileUploadContextValue = {
  filesAreUploading: boolean;
  setFileUploading: (fileUploadId: string) => void;
  setFileNotUploading: (fileUploadId: string) => void;
};

const contextNotSetError = 'FileUpload context not set (are you rendering within a Form?)';

export const FileUploadContext = React.createContext<FileUploadContextValue>({
  filesAreUploading: false,
  setFileUploading: () => {
    throw new Error(contextNotSetError);
  },
  setFileNotUploading: () => {
    throw new Error(contextNotSetError);
  },
});

export const FileUploadContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [uploadingFileIds, setUploadingFileIds] = useState<Array<string>>([]);

  const setFileUploading = (fileUploadId: string) => {
    setUploadingFileIds(fileIds => [...fileIds, fileUploadId]);
  };

  const setFileNotUploading = (fileUploadId: string) => {
    setUploadingFileIds(fileIds => fileIds.filter(fileId => fileId !== fileUploadId));
  };

  return (
    <FileUploadContext.Provider
      value={{
        filesAreUploading: uploadingFileIds.length > 0,
        setFileUploading,
        setFileNotUploading,
      }}
    >
      {children}
    </FileUploadContext.Provider>
  );
};
