import { flowRight, isEqual } from 'lodash';
import queryString from 'query-string';
import * as React from 'react';
import styled from 'styled-components';
import { UrlStateProps, withUrlState } from 'with-url-state';
import { DateStamp, formatDateStamp } from '../../../models/dateStamp';
import { Id } from '../../../models/id';
import { ErrorBox } from '../../../shared/ErrorBox';
import { formInputHeight } from '../../../shared/form/formInputStyling';
import { Checkbox } from '../../../shared/form/inputs/Checkbox';
import {
  ApiRequestProps,
  withApiRequest,
} from '../../../shared/higher-order-components/withApiRequest';
import { SectionHeader } from '../../../shared/layout/Headers';
import { Link } from '../../../shared/navigation/Link';
import {
  ControlledPaginatedTable,
  getInitialTableState,
  getPaginationQuery,
  PaginatedTableListResponse,
  PaginatedTableQuery,
  PaginationState,
} from '../../../shared/table/ControlledPaginatedTable';
import {
  paginatedTableStateToPaginatedTableUrlState,
  paginatedTableUrlStateToPaginatedTableState,
  PaginationUrlState,
} from '../../../shared/table/PaginatedTableWithUrlState';
import {
  FullWidthTable,
  SortableTableHeaderCell,
  SortDirection,
  TableHeaderCell,
  TBody,
  Td,
  THead,
  Tr,
} from '../../../shared/table/Table';
import { AuthenticatedApiRequest, fetchSecureJson, from } from '../../../utils/api';
import { RouteComponentProps } from '../../../utils/RouteComponentProps';
import { BoolString, boolToString, stringToBool } from '../../../utils/urlStateHelpers';
import { WorksheetType } from '../../worksheets/worksheet';
import { clientPortalJobUrl } from '../clientPortalUrls';
import { ClientPortalJobStatus, clientPortalJobStatusDisplayNames } from './clientPortalJobStatus';
import { ClientPortalJobTableColumn } from './ClientPortalViewJob';

type OwnProps = RouteComponentProps;

type RequestProps = ApiRequestProps<ClientPortalJobQuery, ClientPortalJobsResponse, 'jobsRequest'>;

export type ClientPortalJobQuery = PaginatedTableQuery<ClientPortalJobTableColumn> & CheckboxState;
type ClientPortalJobUrlState = PaginationUrlState<ClientPortalJobTableColumn> & CheckboxUrlState;

export type JobResponse = {
  jobId: Id;
  jobReference: number;
  campaignName: string;
  customerName: string;
  customerReference: string;
  worksheetType: WorksheetType | null;
  totalVolume: number;
  collectionDate: DateStamp;
  status: ClientPortalJobStatus;
};

type CheckboxState = {
  includeCompletedJobs: boolean;
};

type CheckboxUrlState = {
  includeCompletedJobs: BoolString;
};

export type ClientPortalJobsResponse = {
  nonForecastJobs: Array<JobResponse>;
  forecastJobs: Array<JobResponse>;
} & PaginatedTableListResponse<ClientPortalJobTableColumn>;

type Props = OwnProps & RequestProps & UrlStateProps<ClientPortalJobUrlState>;

type PaginatedTableProps = {
  sortByColumn: ClientPortalJobTableColumn;
  sortByColumnSortDirection: SortDirection;
  onSortColumn: (sortByColumn: ClientPortalJobTableColumn, sortDirection: SortDirection) => void;
};

type JobsTableProps = {
  tableTitle: string;
  jobs: Array<JobResponse>;
  sortableHeaderProps?: PaginatedTableProps;
};

const ClientPortalJobsTable = (props: JobsTableProps) => {
  const { jobs, sortableHeaderProps } = props;

  return (
    <>
      <SectionHeader>{props.tableTitle}</SectionHeader>
      <FullWidthTable>
        <THead>
          {sortableHeaderProps ? (
            <Tr>
              <SortableTableHeaderCell<ClientPortalJobTableColumn>
                columnName="JobReference"
                {...sortableHeaderProps}
              >
                Job Reference
              </SortableTableHeaderCell>
              <SortableTableHeaderCell<ClientPortalJobTableColumn>
                columnName="CustomerName"
                {...sortableHeaderProps}
              >
                Customer Name
              </SortableTableHeaderCell>
              <SortableTableHeaderCell<ClientPortalJobTableColumn>
                columnName="CampaignName"
                {...sortableHeaderProps}
              >
                Campaign Name
              </SortableTableHeaderCell>
              <SortableTableHeaderCell<ClientPortalJobTableColumn>
                columnName="CustomerReference"
                {...sortableHeaderProps}
              >
                Customer Reference
              </SortableTableHeaderCell>
              <TableHeaderCell>Type</TableHeaderCell>
              <TableHeaderCell>Total Volume</TableHeaderCell>
              <SortableTableHeaderCell<ClientPortalJobTableColumn>
                columnName="CollectionDate"
                {...sortableHeaderProps}
              >
                Collection Date
              </SortableTableHeaderCell>
              <TableHeaderCell>Job status</TableHeaderCell>
            </Tr>
          ) : (
            <Tr>
              <TableHeaderCell>Job Reference</TableHeaderCell>
              <TableHeaderCell>Customer Name</TableHeaderCell>
              <TableHeaderCell>Campaign Name</TableHeaderCell>
              <TableHeaderCell>Customer Reference</TableHeaderCell>
              <TableHeaderCell>Collection Date</TableHeaderCell>
              <TableHeaderCell>Job status</TableHeaderCell>
            </Tr>
          )}
        </THead>
        <TBody>
          {jobs.length === 0 && (
            <Tr>
              <NoJobsRow colSpan={5}>There are no {props.tableTitle.toLowerCase()}.</NoJobsRow>
            </Tr>
          )}
          {jobs.map(job => (
            <Tr key={job.jobId}>
              <Td>
                <Link to={clientPortalJobUrl(job.jobId)}>{job.jobReference}</Link>
              </Td>
              <Td>{job.customerName}</Td>
              <Td>{job.campaignName}</Td>
              <Td>{job.customerReference}</Td>
              {sortableHeaderProps && <Td>{job.worksheetType ? job.worksheetType : 'Mixed'}</Td>}
              {sortableHeaderProps && <Td>{job.totalVolume ? job.totalVolume : null}</Td>}
              <Td>{formatDateStamp(job.collectionDate)}</Td>
              <Td>{clientPortalJobStatusDisplayNames[job.status]}</Td>
            </Tr>
          ))}
        </TBody>
      </FullWidthTable>
    </>
  );
};

const ClientPortalAllJobsComponent = (props: Props) => {
  const { jobsRequest, urlState, setUrlState } = props;
  const { response } = jobsRequest;

  const nonForecastJobs = jobsRequest.response ? jobsRequest.response.nonForecastJobs : [];
  const forecastJobs = jobsRequest.response ? jobsRequest.response.forecastJobs : [];

  React.useEffect(() => {
    sendClientPortalJobsRequest(urlState);
  }, []);

  const checkboxUrlStateToCheckboxState = (filterUrlState: CheckboxUrlState): CheckboxState => ({
    includeCompletedJobs: stringToBool(filterUrlState.includeCompletedJobs),
  });

  const checkboxStateToCheckboxUrlState = (filterState: CheckboxState): CheckboxUrlState => ({
    includeCompletedJobs: boolToString(filterState.includeCompletedJobs),
  });

  const sendClientPortalJobsRequest = (state: ClientPortalJobUrlState) => {
    jobsRequest.sendRequest({
      ...getPaginationQuery(paginatedTableUrlStateToPaginatedTableState(state)),
      ...checkboxUrlStateToCheckboxState(state),
    });
  };

  const setUrlStateAndSendRequest = (newUrlState: ClientPortalJobUrlState) => {
    if (!isEqual(newUrlState, urlState)) {
      setUrlState(newUrlState);
      sendClientPortalJobsRequest(newUrlState);
    }
  };

  const setFilterState = (filterState: CheckboxState) =>
    setUrlStateAndSendRequest({
      ...urlState,
      ...checkboxStateToCheckboxUrlState(filterState),
      pageNumber: '1',
    });

  const setTableState = (newTableState: PaginationState<ClientPortalJobTableColumn>) =>
    setUrlStateAndSendRequest({
      ...urlState,
      ...paginatedTableStateToPaginatedTableUrlState(newTableState),
    });

  return (
    <>
      {jobsRequest.error ? (
        <ErrorBox error={jobsRequest.error} />
      ) : (
        <>
          <ControlledPaginatedTable<ClientPortalJobTableColumn>
            totalPages={(response != null && response.totalPages) || 1}
            isLoading={jobsRequest.inProgress}
            hasData={response != null}
            tableState={paginatedTableUrlStateToPaginatedTableState(urlState)}
            setTableState={setTableState}
            actions={
              <IncludeCompletedJobsCheckboxContainer>
                <Checkbox
                  name="includeCompletedJobs"
                  label="Include completed jobs"
                  value={stringToBool(urlState.includeCompletedJobs)}
                  onChange={newValue => setFilterState({ includeCompletedJobs: newValue })}
                />
              </IncludeCompletedJobsCheckboxContainer>
            }
          >
            {(sortByColumn, sortDirection, onSortColumn) => {
              const sortableHeaderProps = {
                sortByColumn,
                sortByColumnSortDirection: sortDirection,
                onSortColumn,
              };

              return (
                <ClientPortalJobsTable
                  tableTitle={'Non-Forecast Jobs'}
                  jobs={nonForecastJobs}
                  sortableHeaderProps={sortableHeaderProps}
                />
              );
            }}
          </ControlledPaginatedTable>
          {!jobsRequest.inProgress && (
            <ClientPortalJobsTable tableTitle={'Forecast Jobs'} jobs={forecastJobs} />
          )}
        </>
      )}
    </>
  );
};

const clientPortalJobsApiUrl = (query: ClientPortalJobQuery): string =>
  `client-portal/jobs?${queryString.stringify(query)}`;

const fetchJobs = (
  query: ClientPortalJobQuery,
): AuthenticatedApiRequest<ClientPortalJobsResponse> =>
  fetchSecureJson<ClientPortalJobsResponse>(from(clientPortalJobsApiUrl(query), 'get'));

const withApiRequestEnhancer = withApiRequest<
  OwnProps,
  ClientPortalJobQuery,
  ClientPortalJobsResponse,
  'jobsRequest'
>(() => (query: ClientPortalJobQuery) => fetchJobs(query), 'jobsRequest');

const withUrlStateEnhancer = withUrlState<ClientPortalJobUrlState, OwnProps & RequestProps>(() => ({
  ...paginatedTableStateToPaginatedTableUrlState(
    getInitialTableState<ClientPortalJobTableColumn>({
      initialSortByColumn: 'JobReference',
      initialSortDirection: 'Asc',
    }),
  ),
  includeCompletedJobs: 'false',
}));

const enhance = flowRight(
  withApiRequestEnhancer,
  withUrlStateEnhancer,
);

export const ClientPortalJobs = enhance(ClientPortalAllJobsComponent);

const NoJobsRow = styled(Td)`
  text-align: center;
`;

const IncludeCompletedJobsCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  height: ${formInputHeight};
`;
