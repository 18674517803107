import * as React from 'react';
import styled from 'styled-components/macro';
import { medium, narrow } from '../../styling/spacing';
import { ItemHeader } from '../layout/Headers';

type Props = {
  name: string;
  jobTitle: string;
};

export const ContactCardHeader = ({ name, jobTitle }: Props) => (
  <HeaderContainer>
    <ContactName>{name}</ContactName>
    {jobTitle && <JobTitle>- {jobTitle}</JobTitle>}
  </HeaderContainer>
);

const HeaderContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: ${medium};
`;

const ContactName = styled(ItemHeader)`
  font-size: 18px;
  margin-bottom: 0;
`;

const JobTitle = styled.span`
  margin-left: ${narrow};
`;
