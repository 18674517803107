import { FormikProps } from 'formik';
import { useEffect } from 'react';
import * as React from 'react';
import { DateField } from '../../../../shared/form/inputs/DateField';
import { assertNotNull } from '../../../../utils/assertNotNull';
import { StyledWarningCallout } from '../../../worksheets/WorksheetWarnings';
import { useClientPortalMetadata } from '../../clientPortalMetadata';
import {
  getEarliestCollectionDateForMailingBriefJob,
  MailingBriefFormModel,
} from '../mailingBriefFormModel';

// This component is used in non-split jobs, where the collection date should be the same for all worksheets.
// There will be at most one sorted worksheet. When there is a sorted worksheet,
// we take the first and only element in the sorted worksheets array.
// We need to set mailing house on each of the worksheets, so we first find the collection date if it exists by going
// through all the possible worksheets.
// Then we set the collection date on each of the worksheets.
// Date form fields require a name to bind to, so we arbitrarily choose the first worksheet to bind to.
export const JobLevelCollectionDate = (props: {
  formikProps: FormikProps<MailingBriefFormModel>;
}) => {
  const { values: formValues } = props.formikProps;

  const { bankHolidays } = useClientPortalMetadata();

  const isCollectionDateLabel =
    formValues.sortedWorksheets.length > 0 ||
    formValues.unsortedWorksheet ||
    formValues.internationalWorksheet;
  const isProcessingDateLabel = formValues.dataCleansingWorksheet;
  const isCollectionProcessingDateLabel = isCollectionDateLabel && isProcessingDateLabel;

  const label = isCollectionProcessingDateLabel
    ? 'Collection / Processing Date'
    : isProcessingDateLabel
    ? 'Processing Date'
    : 'Collection Date';

  const earliestCollectionDate = getEarliestCollectionDateForMailingBriefJob(bankHolidays);

  const collectionDate =
    formValues.sortedWorksheets.length > 0
      ? formValues.sortedWorksheets[0].collectionDate
      : formValues.unsortedWorksheet
      ? formValues.unsortedWorksheet.collectionDate
      : formValues.internationalWorksheet
      ? formValues.internationalWorksheet.collectionDate
      : formValues.dataCleansingWorksheet
      ? formValues.dataCleansingWorksheet.processingDate
      : null;

  const collectionDateFieldNames = [
    formValues.sortedWorksheets.length > 0 ? 'sortedWorksheets[0].collectionDate' : null,
    formValues.unsortedWorksheet ? 'unsortedWorksheet.collectionDate' : null,
    formValues.internationalWorksheet ? 'internationalWorksheet.collectionDate' : null,
    formValues.dataCleansingWorksheet ? 'dataCleansingWorksheet.processingDate' : null,
  ].filter(name => !!name);

  const showWarning = collectionDate != null && collectionDate === earliestCollectionDate;

  useEffect(() => {
    collectionDateFieldNames.map(collectionDateFieldName =>
      props.formikProps.setFieldValue(assertNotNull(collectionDateFieldName), collectionDate),
    );
  }, [collectionDate]);

  return (
    <>
      <DateField
        label={label}
        name={collectionDateFieldNames[0] as string}
        minDate={earliestCollectionDate}
        excludeNonWorkingDays={true}
      />
      {showWarning && (
        <StyledWarningCallout>
          If an earlier collection is required, please contact your account manager after job
          submission.
        </StyledWarningCallout>
      )}
    </>
  );
};
