import { uniqueId } from 'lodash';
import { assertNotNull } from '../../../../utils/assertNotNull';
import {
  combineValidators,
  createValidator,
  Validator,
} from '../../../../utils/validation/validation';
import { oneOf } from '../../../../utils/validation/validators';
import {
  UnsortedCellFormat,
  UnsortedCellProcessingType,
  UnsortedCellServiceType,
  UnsortedCellValidationMetadata,
} from '../../../worksheets/unsorted/cells/unsortedCell';
import { UnsortedCellFormModel } from '../../../worksheets/unsorted/cells/unsortedCellFormModel';
import { unsortedCellValidator } from '../../../worksheets/unsorted/cells/unsortedCellValidator';
import { MailingBriefUnsortedWorksheetFormModel } from './mailingBriefUnsortedWorksheetFormModel';

// If any of the cells on the Sorted part of the job are "Mailmark", and the Customer has the "Bill
// Unsorted as Mailmark" flag set to yes, the service for any "Mech" processing jobs should be
// "Mailmark". Otherwise, the service should be "Standard".
export type MailingBriefUnsortedCellServiceType = UnsortedCellServiceType &
  ('Mailmark' | 'Standard');

// We only allow users to select 'Letter' or 'Large Letter' on the Mailing Brief screen.
export type MailingBriefUnsortedCellFormat = UnsortedCellFormat & ('Letter' | 'LargeLetter');

export type MailingBriefUnsortedCellFormModel = UnsortedCellFormModel & {
  service: MailingBriefUnsortedCellServiceType | null;
  format: MailingBriefUnsortedCellFormat | null;
  variant: 'BusinessMail';
};

export const getInitialMailingBriefUnsortedCellFormModel = (): MailingBriefUnsortedCellFormModel => {
  return {
    unsortedCellId: null,
    uniqueFormId: uniqueId(),
    cellReference: '',
    service: null,
    format: null,
    processingType: null,
    variant: 'BusinessMail',
    quantity: '',
    itemWeightInGrams: '',
    isPpiPrinted: false,
    isReturnAddressPrinting: false,
    isOverlabel: false,
    isSignedFor: false,
    isTracked: false,
    collectionQuantitiesByDate: {},
  };
};

export const mailingBriefUnsortedCellValidator = (
  cell: MailingBriefUnsortedCellFormModel,
  worksheetFormModel: MailingBriefUnsortedWorksheetFormModel,
  unsortedCellValidation: UnsortedCellValidationMetadata,
): Validator<MailingBriefUnsortedCellFormModel> => {
  const standardValidator = unsortedCellValidator(
    cell,
    worksheetFormModel,
    unsortedCellValidation,
  ) as Validator<MailingBriefUnsortedCellFormModel>;

  const mailingBriefValidator = createValidator<MailingBriefUnsortedCellFormModel>({
    format: oneOf(
      cell.processingType == null ? [] : getAllowedFormatsFromProcessingType(cell.processingType),
      'Invalid format for selected service',
    ),
  });

  return combineValidators(standardValidator, mailingBriefValidator);
};

const allowedFormatsByProcessingType: {
  [processingType in UnsortedCellProcessingType]: Array<MailingBriefUnsortedCellFormat>;
} = {
  Machine: ['Letter'],
  Manual: ['Letter', 'LargeLetter'],
};

export const getAllowedFormatsFromProcessingType = (
  processingType: UnsortedCellProcessingType | null,
): Array<MailingBriefUnsortedCellFormat> =>
  processingType == null
    ? ['Letter', 'LargeLetter']
    : allowedFormatsByProcessingType[assertNotNull(processingType)];

export const getServiceTypeFromProcessingType = (options: {
  processingType: UnsortedCellProcessingType | null;
  someSortedCellsAreMailmark: boolean;
  customerHasBillUnsortedAsMailmark: boolean;
}): MailingBriefUnsortedCellServiceType | null => {
  const { processingType, someSortedCellsAreMailmark, customerHasBillUnsortedAsMailmark } = options;

  if (processingType == null) {
    return null;
  }

  if (
    someSortedCellsAreMailmark &&
    customerHasBillUnsortedAsMailmark &&
    processingType === 'Machine'
  ) {
    return 'Mailmark';
  }

  return 'Standard';
};
