import { FieldProps } from 'formik';
import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { FieldLabel, FieldLabelProps } from '../FieldLabel';
import { FormField, InlineFormField } from '../FormField';
import { Checkbox } from './Checkbox';

type CheckboxFieldProps = (
  | { label: string; inline?: false }
  | { label?: undefined; inline: true }) & {
  name: string;
  disabled?: boolean;
  onChange?: (previousValue: boolean, newValue: boolean) => void;
  className?: string;
};

export class CheckboxField extends React.PureComponent<CheckboxFieldProps> {
  onBlur = ({ form, field }: FieldProps) => () => {
    form.setFieldTouched(field.name);
  };

  onChange = ({ form, field }: FieldProps) => (newValue: boolean) => {
    const previousValue = field.value;
    form.setFieldTouched(field.name);
    form.setFieldValue(field.name, newValue);

    if (this.props.onChange) {
      this.props.onChange(previousValue, newValue);
    }
  };

  render() {
    const props = this.props;
    const CheckboxFormField = props.inline ? InlineFormField : FormField;

    return (
      <CheckboxFormField
        name={props.name}
        label={props.label == null ? '' : props.label}
        renderLabel={renderCheckboxLabel}
        disabled={props.disabled}
        optional={false}
        className={props.className}
      >
        {({ field, form, valid, invalid, showWarning }) => (
          <Checkbox
            name={field.name}
            value={field.value}
            onChange={this.onChange({ field, form })}
            onBlur={this.onBlur({ field, form })}
            valid={valid}
            invalid={invalid}
            showWarning={showWarning}
            disabled={form.isSubmitting || props.disabled}
          />
        )}
      </CheckboxFormField>
    );
  }
}

const CheckboxFieldLabel = styled(FieldLabel)`
  ${props =>
    !props.disabled &&
    css`
      cursor: pointer;
    `};
`;

const renderCheckboxLabel = (props: FieldLabelProps) => <CheckboxFieldLabel {...props} />;
