import { isEmpty, orderBy } from 'lodash';
import React from 'react';
import {
  FullWidthTable,
  NoDataTableDataCell,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from '../../../../shared/table/Table';
import { ClientPortalInternationalCell } from './ClientPortalInternationalCell';

type Props = {
  internationalCells: Array<ClientPortalInternationalCell>;
};

export const ViewClientPortalInternationalCellsTable = ({ internationalCells }: Props) => (
  <FullWidthTable>
    <THead>
      <Tr>
        <Th>Cell Reference</Th>
        <Th>Quantity</Th>
        <Th>Item Weight (kg)</Th>
        <Th>Width (mm)</Th>
        <Th>Height (mm)</Th>
        <Th>Thickness (mm)</Th>
      </Tr>
    </THead>
    <TBody>
      {isEmpty(internationalCells) ? (
        <Tr>
          <NoDataTableDataCell colSpan={6}>
            -- This worksheet does not have any cells --
          </NoDataTableDataCell>
        </Tr>
      ) : (
        orderBy(internationalCells, internationalCell => internationalCell.displayOrder).map(
          internationalCell => (
            <Tr key={internationalCell.internationalCellId}>
              <Td>{internationalCell.cellReference}</Td>
              <Td>{internationalCell.quantity.toLocaleString()}</Td>
              <Td>{internationalCell.weightInGrams}</Td>
              <Td>{internationalCell.widthInMillimetres}</Td>
              <Td>{internationalCell.heightInMillimetres}</Td>
              <Td>{internationalCell.thicknessInMillimetres}</Td>
            </Tr>
          ),
        )
      )}
    </TBody>
  </FullWidthTable>
);
