import { Id } from '../../models/id';
import { TimeStamp } from '../../models/timeStamp';
import { getSelectOptions } from '../../shared/form/inputs/Select';
import { assertIsNumber } from '../../utils/assertIsNumber';
import { assertNotNull } from '../../utils/assertNotNull';
import { MailingHouseContact } from './contacts/mailingHouseContact';

export type ConsumableLimitationCode = 'None' | 'Yorks' | 'Cages' | 'Magnums';

export const consumableLimitationDisplayNameByCode: {
  [code in ConsumableLimitationCode]: string;
} = {
  None: 'None',
  Yorks: 'Yorks only',
  Cages: 'Cages only',
  Magnums: 'Magnums only',
};

export const consumableLimitationCodes = Object.keys(
  consumableLimitationDisplayNameByCode,
) as Array<ConsumableLimitationCode>;

export const consumableLimitationOptions = getSelectOptions(
  consumableLimitationCodes,
  consumableLimitationDisplayNameByCode,
);

export type MailingHouse = {
  mailingHouseId: Id;
  referenceNumber: number;
  name: string;
  address: MailingHouseAddress;
  notes: string;
  vehicleRestrictions: VehicleRestrictions;
  consumableLimitationCode: ConsumableLimitationCode;
  securedMailDetails: SecuredMailDetails;
  unsortedWorksheetInstructions: string | null;
  internationalWorksheetInstructions: string | null;
  contacts: Array<MailingHouseContact>;
};

export type MailingHouses = {
  mailingHouses: Array<MailingHouse>;
};

export type MailingHouseAddress = {
  streetAddress: string;
  town: string;
  county: string;
  postcode: string;
};

export const formatAddress = (address: MailingHouseAddress) =>
  [address.streetAddress, address.town, address.county, address.postcode]
    .filter(line => line != null && line.trim() !== '')
    .join('\n');

export type CreateUpdateMailingHouseAddress = {
  streetAddress: string;
  town: string;
  county: string;
  postcode: string;
};

export type VehicleRestrictionsMaxSizeCode = 'None' | 'SevenFiveT' | 'EighteenT';

export const vehicleRestrictionsMaxSizeDisplayNameByCode: {
  [code in VehicleRestrictionsMaxSizeCode]: string;
} = {
  None: 'None',
  SevenFiveT: '7.5t',
  EighteenT: '18t',
};

export const vehicleRestrictionsMaxSizeCodes = Object.keys(
  vehicleRestrictionsMaxSizeDisplayNameByCode,
) as Array<VehicleRestrictionsMaxSizeCode>;

export const vehicleRestrictionsMaxSizeOptions = getSelectOptions(
  vehicleRestrictionsMaxSizeCodes,
  vehicleRestrictionsMaxSizeDisplayNameByCode,
);

export type VehicleRestrictions = {
  maxSizeCode: VehicleRestrictionsMaxSizeCode;
  needsTailLift: boolean;
  other: string;
};

export type CreateUpdateVehicleRestrictions = {
  maxSizeCode: VehicleRestrictionsMaxSizeCode;
  needsTailLift: boolean;
  other: string;
};

export type SecuredMailDetails = {
  depot: string;
  isOutOfArea: boolean;
  outOfAreaCollectionCharge: number | null;
  collectionTime: TimeStamp;
};

export type SecuredMailDetailsFormModel = {
  depot: string;
  isOutOfArea: boolean;
  outOfAreaCollectionCharge: string | null;
  collectionTime: TimeStamp | null;
};

export type CreateUpdateSecuredMailDetails = {
  depot: string;
  isOutOfArea: boolean;
  outOfAreaCollectionCharge: number | null;
  collectionTime: TimeStamp;
};

export const getSecuredMailDetailsFromFormModel = (
  securedMailDetails: SecuredMailDetailsFormModel,
): CreateUpdateSecuredMailDetails => ({
  ...securedMailDetails,
  outOfAreaCollectionCharge: securedMailDetails.isOutOfArea
    ? assertIsNumber(securedMailDetails.outOfAreaCollectionCharge)
    : null,
  collectionTime: assertNotNull(securedMailDetails.collectionTime),
});
