import { DateStamp } from '../../../../models/dateStamp';
import { Id } from '../../../../models/id';
import { assertNotNull } from '../../../../utils/assertNotNull';
import { UnsortedCollectionPresentation } from '../../../worksheets/unsorted/unsortedWorksheet';
import { MailingBriefMailingHouseContactDto } from '../mailingBriefMailingHouseContactDto';
import { toMailingBriefMailingHouseContactDto } from '../mailingBriefMailingHouseFormModel';
import {
  MailingBriefCreateUpdateUnsortedCellDto,
  toMailingBriefCreateUpdateUnsortedCellDto,
} from './mailingBriefCreateUpdateUnsortedCellDto';
import { MailingBriefUnsortedCellFormModel } from './mailingBriefUnsortedCellFormModel';
import { MailingBriefUnsortedWorksheetFormModel } from './mailingBriefUnsortedWorksheetFormModel';

export type MailingBriefCreateUpdateUnsortedWorksheetDto = {
  unsortedWorksheetId: number | null;
  indexAmongNewWorksheets: number | null;
  collectFromCustomer: boolean;
  collectionPresentation: UnsortedCollectionPresentation;
  cells: Array<MailingBriefCreateUpdateUnsortedCellDto>;
  collectionDate: DateStamp;
  mailingHouse: {
    mailingHouseId: Id | null;
    mailingHouseContacts: Array<MailingBriefMailingHouseContactDto>;
  };
};

export const toMailingBriefCreateUpdateUnsortedWorksheetDto = (
  formModel: MailingBriefUnsortedWorksheetFormModel,
): MailingBriefCreateUpdateUnsortedWorksheetDto => ({
  unsortedWorksheetId: formModel.unsortedWorksheetId,
  indexAmongNewWorksheets: 0, // There is only ever a single Unsorted Worksheet
  collectFromCustomer: formModel.collectFromCustomer,
  collectionPresentation: assertNotNull(formModel.collectionPresentation),
  cells: formModel.cells.map((cell, index) =>
    toMailingBriefCreateUpdateUnsortedCellDto(cell as MailingBriefUnsortedCellFormModel, index),
  ),
  mailingHouse: {
    mailingHouseId: formModel.mailingHouse.mailingHouseId,
    mailingHouseContacts: formModel.mailingHouse.contacts.map(toMailingBriefMailingHouseContactDto),
  },
  collectionDate: assertNotNull(formModel.collectionDate),
});
