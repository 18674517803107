import { includes, orderBy, some } from 'lodash';
import { DateTimeStamp } from '../../models/dateTimeStamp';
import { Id } from '../../models/id';
import { Metadata } from '../../models/metadata';
import { AuthenticatedApiRequest, fetchSecureJson, from } from '../../utils/api';
import { Permission, permissions } from '../authentication/permissions';
import { User } from '../authentication/user';
import {
  InternationalWorksheet,
  isInternationalWorksheet,
} from '../worksheets/international/internationalWorksheet';
import {
  isOtherWorksheet,
  OtherWorksheet,
  otherWorksheetEditPermissions,
} from '../worksheets/other/otherWorksheet';
import {
  canEdit,
  isSortedWorksheet,
  isSortedWorksheetMissingRawDataAttachments,
  SortedWorksheet,
  validatePresenceOfScidsAndUcids,
} from '../worksheets/sorted/sortedWorksheet';
import { isUnsortedWorksheet, UnsortedWorksheet } from '../worksheets/unsorted/unsortedWorksheet';
import {
  isActiveWorksheet,
  isInSubmittableStatus,
  isInvoiced,
  isNotCancelledWorksheet,
  Worksheet,
} from '../worksheets/worksheet';
import { JobComment } from './comments/jobComment';
import { JobAttachment } from './jobAttachment';

export type Job = {
  jobId: Id;
  jobReference: number;
  customerId: Id;
  customerName: string;
  customerNotes: string;
  customerIsMissingAccountOrBusinessManager: boolean;
  subCustomerId: Id | null;
  subCustomerName: string | null;
  splitFromJob: SplitJobDetails | null;
  childSplitJobs: Array<SplitJobDetails>;
  campaignName: string;
  customerReference: string;
  estimatedJobValuePounds: number;
  purchaseOrderNumber: string | null;
  createdBy: string;
  creationDateTime: DateTimeStamp;
  customerIsInactive: boolean;
  customerIsOnCreditHold: boolean;
  customerNeedsTlc: boolean;
  customerRequiresPurchaseOrder: boolean;
  customerShouldShowContainerChargeWarningFlag: boolean;
  wasCreatedAsForecast: boolean;
  sortedWorksheets: Array<SortedWorksheet>;
  unsortedWorksheets: Array<UnsortedWorksheet>;
  internationalWorksheets: Array<InternationalWorksheet>;
  otherWorksheets: Array<OtherWorksheet>;
  attachments: Array<JobAttachment>;
  comments: Array<JobComment>;
  history: Array<JobHistory>;
};

export type SplitJobDetails = {
  jobId: Id;
  jobReference: number;
};

export type JobHistory = {
  jobHistoryId: number;
  message: string;
  changedByUserName: string;
  changeDateTime: DateTimeStamp;
};

export type JobFlags = {
  worksheetIsUrgent: boolean;
  isFirstJob: boolean;
  hasComplaint: boolean;
  customerIsOnCreditHold: boolean;
  worksheetIsOnCreditHold: boolean;
  isMissingPurchaseOrder: boolean;
  isMissingPurchaseOrderWithImminentCollection: boolean;
  customerNeedsTlc: boolean;
  customerIsProForma: boolean;
  isSplitJob: boolean;
  isForecast: boolean;
  consumablesHaveBeenOrdered: boolean;
};

export const fetchJob = (jobId: Id): AuthenticatedApiRequest<Job> =>
  fetchSecureJson<Job>(from(`jobs/${jobId}`, 'get'));

export const isForecastJob = (job: Job) =>
  job.sortedWorksheets.some(sortedWorksheet => sortedWorksheet.statusCode === 'Forecast');

export const jobIsMissingUcidsOrScids = (job: Job, metadata: Metadata) =>
  !isForecastJob(job) &&
  some(
    getActiveSortedWorksheets(job, metadata).map(worksheet =>
      validatePresenceOfScidsAndUcids(worksheet, metadata),
    ),
    validationResult =>
      (validationResult.isMissingNationalUcid && validationResult.isMissingZonalUcid) ||
      (validationResult.isMissingNationalScid && validationResult.isMissingZonalScid) ||
      validationResult.isMissingZonalOrNationalUcidAndScidPair,
  );

export const jobHasSortedWorksheetWithoutCells = (job: Job, metadata: Metadata) =>
  some(getActiveSortedWorksheets(job, metadata), worksheet => worksheet.cells.length === 0);

export const jobHasSortedWorksheetWithoutRawDataAttachment = (job: Job, metadata: Metadata) =>
  some(getActiveSortedWorksheets(job, metadata), isSortedWorksheetMissingRawDataAttachments);

export const jobIsMissingPurchaseOrder = (job: Job) =>
  job.customerRequiresPurchaseOrder &&
  !isForecastJob(job) &&
  (job.purchaseOrderNumber == null || job.purchaseOrderNumber.trim() === '');

export const getWorksheets = (job: Job): Array<Worksheet> =>
  orderBy(
    [
      ...job.sortedWorksheets,
      ...job.unsortedWorksheets,
      ...job.internationalWorksheets,
      ...job.otherWorksheets,
    ],
    worksheet => worksheet.displayOrder,
  );

export const getActiveWorksheets = (job: Job, metadata: Metadata): Array<Worksheet> =>
  getWorksheets(job).filter(worksheet => isActiveWorksheet(worksheet, metadata));

export const getInvoicedWorksheets = (job: Job): Array<Worksheet> =>
  getWorksheets(job).filter(worksheet => isInvoiced(worksheet));

export const getWorksheetsUserHasPermissionToCreateAndEdit = (
  job: Job,
  userPermissions: Array<Permission>,
) =>
  getWorksheets(job).filter(worksheet => {
    if (isSortedWorksheet(worksheet)) {
      return includes(userPermissions, permissions.CreateAndEditSortedWorksheets);
    }
    if (isUnsortedWorksheet(worksheet)) {
      return includes(userPermissions, permissions.CreateAndEditUnsortedWorksheets);
    }
    if (isInternationalWorksheet(worksheet)) {
      return includes(userPermissions, permissions.CreateAndEditInternationalWorksheets);
    }
    if (isOtherWorksheet(worksheet)) {
      return includes(userPermissions, otherWorksheetEditPermissions[worksheet.type]);
    }

    throw new Error('Could not determine type of worksheet');
  });

export const getActiveSortedWorksheets = (job: Job, metadata: Metadata): Array<SortedWorksheet> =>
  job.sortedWorksheets.filter(worksheet => isActiveWorksheet(worksheet, metadata));

export const getNotCancelledSortedWorksheets = (job: Job): Array<SortedWorksheet> =>
  job.sortedWorksheets.filter(worksheet => isNotCancelledWorksheet(worksheet));

export const getNotCancelledWorksheets = (job: Job): Array<Worksheet> =>
  getWorksheets(job).filter(worksheet => isNotCancelledWorksheet(worksheet));

export type JobScrollAnchor = 'worksheets' | 'collections' | 'consumables';

export const jobHasWorksheetsInSubmittableStatus = (job: Job, metadata: Metadata): boolean =>
  some(getWorksheets(job), worksheet => isInSubmittableStatus(worksheet, metadata));

/* We use all worksheets, not just editable worksheets to determine if job is editable -
so that job details cannot be edited if worksheet is completed */
export const canEditJobDetails = (job: Job, metadata: Metadata, user: User) =>
  job.sortedWorksheets.every(worksheet =>
    canEdit(
      metadata.worksheetStatuses[worksheet.statusCode].editSortedJobDetails,
      worksheet.dataProcessingAssigneeUserId,
      user,
    ),
  );

export const canEditPoNumber = (job: Job, metadata: Metadata, user: User) =>
  job.sortedWorksheets.every(worksheet =>
    canEdit(
      metadata.worksheetStatuses[worksheet.statusCode].editSortedPoNumber,
      worksheet.dataProcessingAssigneeUserId,
      user,
    ),
  );

export const canEditJobAttachments = (job: Job, metadata: Metadata, user: User) =>
  job.sortedWorksheets.every(worksheet =>
    canEdit(
      metadata.worksheetStatuses[worksheet.statusCode].editSortedJobAttachments,
      worksheet.dataProcessingAssigneeUserId,
      user,
    ),
  );

export const worksheetsAreMissingConfirmationContacts = (job: Job, metadata: Metadata) =>
  some(getActiveWorksheets(job, metadata), isWorksheetMissingConfirmationContacts);

export const isWorksheetMissingConfirmationContacts = (worksheet: Worksheet) => {
  if (isSortedWorksheet(worksheet)) {
    return (
      worksheet.customerContacts.filter(contact => contact.includeOnConfirmationCommunication)
        .length === 0
    );
  }
  if (isUnsortedWorksheet(worksheet)) {
    return (
      worksheet.customerContacts.filter(contact => contact.includeOnCommunications).length === 0
    );
  }
  if (isInternationalWorksheet(worksheet)) {
    return (
      worksheet.customerContacts.filter(contact => contact.includeOnCommunications).length === 0
    );
  }
  if (isOtherWorksheet(worksheet)) {
    return false; // Other worksheets do not have contacts
  }
  throw new Error('Could not determine type of worksheet');
};
