import styled from 'styled-components/macro';
import { CheckboxesField } from '../../shared/form/inputs/CheckboxesField';
import { medium } from '../../styling/spacing';

export const WorksheetCheckboxesField = styled(CheckboxesField)`
  margin-bottom: ${medium};

  &:last-of-type {
    margin-bottom: 0;
  }
`;
