import * as React from 'react';
import styled from 'styled-components/macro';
import { PageHeader } from '../shared/layout/Headers';
import { Link } from '../shared/navigation/Link';
import { medium } from '../styling/spacing';
import { homeUrl } from '../urls';
import { RouteComponentProps } from '../utils/RouteComponentProps';

export const NotFound = (props: RouteComponentProps & { homeUrl?: string }) => (
  <>
    <PageHeader>Page not found</PageHeader>
    <InfoText>Sorry, we couldn't find the page you were looking for.</InfoText>
    <Link to={props.homeUrl || homeUrl()}>Return to home page</Link>
  </>
);

const InfoText = styled.div`
  margin-bottom: ${medium};
`;
