import { keys, map, pickBy } from 'lodash';
import * as React from 'react';
import { useReducer } from 'react';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import { medium } from '../../styling/spacing';
import { ContextProps } from '../higher-order-components/withContext';
import { MatrixNotification } from './notification';
import { NotificationCard, notificationCardWidth } from './NotificationCard';

export const notificationsPortalRootElementId = 'notifications-portal-root';

export type NotificationsContextValue = {
  sendNotification: (notification: MatrixNotification) => void;
};

export const NotificationsContext = React.createContext<NotificationsContextValue>({
  sendNotification: () => {
    throw new Error('Notifications context not initialised');
  },
});

export type NotificationsContextProps = ContextProps<
  NotificationsContextValue,
  'notificationsContext'
>;

type Props = {
  children?: React.ReactNode;
};

export const NotificationsProvider = (props: Props) => {
  const [state, dispatch] = useReducer(notificationsReducer, initialNotificationsState);

  const addNotification = (notification: MatrixNotification) => {
    const action: NotificationAction = { type: 'AddNotification', payload: notification };
    dispatch(action);
  };

  const dismissNotification = (notificationId: number) => {
    const action: NotificationAction = { type: 'DismissNotification', payload: { notificationId } };
    dispatch(action);
  };

  const { notificationsById } = state;
  const notificationIds = keys(state.notificationsById);

  const notificationsPortalRoot = document.getElementById(notificationsPortalRootElementId);
  if (notificationsPortalRoot == null) {
    throw new Error('Could not find notifications portal root');
  }

  return (
    <NotificationsContext.Provider
      value={{ sendNotification: notification => addNotification(notification) }}
    >
      {props.children}
      {ReactDOM.createPortal(
        <NotificationCardsContainer>
          {map(notificationIds, (notificationId, index) => {
            const notification = notificationsById[Number(notificationId)];
            return (
              <NotificationCard
                key={notificationId}
                id={Number(notificationId)}
                notification={notification}
                dismiss={() => dismissNotification(Number(notificationId))}
                position={notificationIds.length - index - 1}
              />
            );
          })}
        </NotificationCardsContainer>,
        notificationsPortalRoot,
      )}
    </NotificationsContext.Provider>
  );
};

const NotificationCardsContainer = styled.div`
  position: fixed;
  top: 0;
  right: ${medium};
  width: ${notificationCardWidth};
`;

type NotificationAction =
  | { type: 'AddNotification'; payload: MatrixNotification }
  | { type: 'DismissNotification'; payload: { notificationId: number } };

type NotificationsState = {
  notificationsById: { [notificationId: number]: MatrixNotification };
  nextNotificationId: number;
};

const initialNotificationsState: NotificationsState = {
  notificationsById: {},
  nextNotificationId: 0,
};

const notificationsReducer = (
  state: NotificationsState,
  action: NotificationAction,
): NotificationsState => {
  switch (action.type) {
    case 'AddNotification':
      return {
        notificationsById: {
          ...state.notificationsById,
          [state.nextNotificationId]: action.payload,
        },
        nextNotificationId: state.nextNotificationId + 1,
      };
    case 'DismissNotification':
      return {
        ...state,
        notificationsById: pickBy(
          state.notificationsById,
          (_, id) => id.toString() !== action.payload.notificationId.toString(),
        ) as { [notificationId: number]: MatrixNotification },
      };
    default:
      throw new Error(`Unrecognised action: ${JSON.stringify(action, null, 2)}`);
  }
};
