import styled from 'styled-components/macro';
import { Section } from '../../shared/layout/Section';
import { Panel } from '../../shared/Panel';
import { medium, wide } from '../../styling/spacing';

export const WorksheetPrimaryDetailsSection = styled(Section)`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: ${medium};
`;

export const WorksheetPrimaryDetailsSectionLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 600px;
  margin-right: ${wide};
`;

export const WorksheetPrimaryDetailsPanel = styled(Panel)`
  flex: 1;
`;

export const WorksheetPrimaryDetailsSectionRight = styled.div<{ withPaddingTop: boolean }>`
  flex: 0 0 auto;
`;

export const WorksheetDetailsSection = styled(Section)`
  margin-bottom: ${medium};

  &:last-child {
    margin-bottom: 0;
  }
`;
