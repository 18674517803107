import { includes, keys, orderBy } from 'lodash';
import { DateStamp } from '../../../models/dateStamp';
import { Id } from '../../../models/id';
import { Metadata, WorksheetStatusCode, WorksheetTransitionName } from '../../../models/metadata';
import { SelectOptions } from '../../../shared/form/inputs/Select';
import { fetchSecureJson, from, withJsonBody } from '../../../utils/api';
import { assertNotNull } from '../../../utils/assertNotNull';
import { Job } from '../../jobs/job';
import {
  Worksheet,
  WorksheetCustomerContact,
  WorksheetMailingHouseContact,
  WorksheetMailingHouseDetails,
} from '../worksheet';
import { InternationalCell } from './cells/internationalCell';
import {
  InternationalBuyingPrice,
  InternationalCommissionItem,
  InternationalSellingPrice,
} from './prices/internationalPrices';

export type InternationalWorksheet = Worksheet & {
  internationalWorksheetId: Id;
  isUrgent: boolean;
  collectionBy: InternationalWorksheetCollectionByOption | null;
  carrier: InternationalWorksheetCarrier | null;
  assigneeId: string | null;
  assigneeName: string | null;
  notes: string | null;
  customCollectionEmailContent: string | null;
  labelsPrepared: boolean;
  labelsSentDate: DateStamp | null;
  manifestPrepared: boolean;
  manifestSentDate: DateStamp | null;
  mailingHouse: InternationalWorksheetMailingHouseDetails;
  mailingHouseContacts: Array<InternationalWorksheetMailingHouseContact>;
  customerContacts: Array<InternationalWorksheetCustomerContact>;
  cells: Array<InternationalCell>;
  buyingPrices: Array<InternationalBuyingPrice> | null;
  sellingPrices: Array<InternationalSellingPrice> | null;
  commissionItems: Array<InternationalCommissionItem> | null;
};

export type InternationalWorksheetMailingHouseDetails = WorksheetMailingHouseDetails & {
  instructions: string | null;
};

export type InternationalWorksheetMailingHouseContact = WorksheetMailingHouseContact & {
  includeOnCommunications: boolean;
};

export type InternationalWorksheetCustomerContact = WorksheetCustomerContact & {
  includeOnCommunications: boolean;
};

export const isInternationalWorksheet = (
  worksheet: Worksheet,
): worksheet is InternationalWorksheet => worksheet.type === 'International';

export type InternationalWorksheetCollectionByOption =
  | 'DeliveredIn'
  | 'AccFreight'
  | 'CitySprint'
  | 'CourierExchange'
  | 'DeutschePost'
  | 'DhlExpress'
  | 'Jetmail'
  | 'LaserInternational'
  | 'LesSmithHaulage'
  | 'MailHandlingInternational'
  | 'Palletways'
  | 'Spring'
  | 'SecuredMail'
  | 'Other';

export const internationalWorksheetCollectionByOptionDisplayNames: {
  [option in InternationalWorksheetCollectionByOption]: string;
} = {
  DeliveredIn: 'Delivered In',
  AccFreight: 'ACC Freight',
  CitySprint: 'CitySprint',
  CourierExchange: 'Courier Exchange',
  DeutschePost: 'Deutsche Post',
  DhlExpress: 'DHL Express',
  Jetmail: 'Jetmail',
  LaserInternational: 'Laser International',
  LesSmithHaulage: 'Les Smith Haulage',
  MailHandlingInternational: 'MHI',
  Palletways: 'Palletways',
  Spring: 'Spring (TNT Express)',
  SecuredMail: 'Secured Mail',
  Other: 'Other',
};

export const internationalWorksheetCollectionByOptions: Array<
  InternationalWorksheetCollectionByOption
> = keys(internationalWorksheetCollectionByOptionDisplayNames) as Array<
  InternationalWorksheetCollectionByOption
>;

export const internationalWorksheetCollectionBySelectOptions: SelectOptions<
  InternationalWorksheetCollectionByOption
> = internationalWorksheetCollectionByOptions.map(optionCode => ({
  text: internationalWorksheetCollectionByOptionDisplayNames[optionCode],
  value: optionCode,
}));

export type InternationalWorksheetCarrier =
  | 'Asendia'
  | 'BelgiumPost'
  | 'DeutschePost'
  | 'DirectLink'
  | 'Ibex'
  | 'Jetmail'
  | 'Lettershop'
  | 'MailAlliance'
  | 'MailHandlingInternational'
  | 'Postcon'
  | 'SecuredMail'
  | 'Spring'
  | 'UkPostings'
  | 'Other';

// Simple transitions are ones for which buttons are shown
// on the worksheet details panel and the manage worksheets table

export type InternationalWorksheetSimpleTransition = {
  displayOrder: number;
  transitionName: WorksheetTransitionName;
  endpoint: string;
};

type InternationalWorksheetSimpleTransitions = Array<InternationalWorksheetSimpleTransition>;

export const applyInternationalSimpleTransition = (
  internationalWorksheetId: Id,
  simpleTransition: InternationalWorksheetSimpleTransition,
) =>
  fetchSecureJson<Job>(
    withJsonBody({ internationalWorksheetId })(
      from(
        `international-worksheets/${internationalWorksheetId}/${simpleTransition.endpoint}`,
        'put',
      ),
    ),
  );

export const internationalWorksheetSimpleTransitions: InternationalWorksheetSimpleTransitions = [
  {
    displayOrder: 0,
    transitionName: 'CompleteInternationalPricing',
    endpoint: 'complete-international-pricing',
  },
  {
    displayOrder: 1,
    transitionName: 'RevertToAwaitingPricing',
    endpoint: 'revert-to-awaiting-pricing',
  },
  {
    displayOrder: 2,
    transitionName: 'BookCollection',
    endpoint: 'book-collection',
  },
  {
    displayOrder: 3,
    transitionName: 'RevertToInProgress',
    endpoint: 'revert-to-in-progress',
  },
  {
    displayOrder: 4,
    transitionName: 'ReceiveCollection',
    endpoint: 'receive-collection',
  },
  {
    displayOrder: 5,
    transitionName: 'RevertToCollectionBooked',
    endpoint: 'revert-to-collection-booked',
  },
  {
    displayOrder: 6,
    transitionName: 'SubmitToFinance',
    endpoint: 'submit-to-finance',
  },
];

export const getApplicableInternationalSimpleTransitions = (
  statusCode: WorksheetStatusCode,
  metadata: Metadata,
) =>
  orderBy(
    internationalWorksheetSimpleTransitions.filter(
      (simpleTransition: InternationalWorksheetSimpleTransition) => {
        const transitionStatuses = assertNotNull(
          metadata.worksheetTransitions[simpleTransition.transitionName].statusesByWorksheetType[
            'International' // tslint:disable-line no-string-literal
          ],
        );
        return includes(transitionStatuses.fromStatuses, statusCode);
      },
    ),
    simpleTransition => simpleTransition.displayOrder,
  );
