import { Id } from '../../../models/id';
import { MailingHouseContactForJobForm } from '../../jobs/dataForJobForm';
import { MailingBriefContactBaseFormModel } from './mailingBriefContactBaseFormModel';
import { MailingBriefMailingHouseContactDto } from './mailingBriefMailingHouseContactDto';

export type MailingBriefMailingHouseFormModel = {
  mailingHouseId: Id | null;
  contacts: Array<MailingBriefMailingHouseContactFormModel>;
};

export type MailingBriefMailingHouseContactFormModel = MailingBriefContactBaseFormModel & {
  mailingHouseContactId: Id;
};

export const fromMailingHouseContactForJobForm = (
  contact: MailingHouseContactForJobForm,
): MailingBriefMailingHouseContactFormModel => ({
  mailingHouseContactId: contact.mailingHouseContactId,
  includeOnCarbonCopyCommunication: contact.includeOnDataCommunication === 'CarbonCopy',
  includeOnDataCommunication: contact.includeOnDataCommunication === 'LinkAndPassword',
  includeOnLabelsCommunication: contact.includeOnLabelsCommunication,
  includeOnCollectionsCommunication: contact.includeOnCollectionsCommunication,
  includeOnConsumablesCommunication: contact.includeOnConsumablesCommunication,
  includeOnUnsortedCommunication: false,
  includeOnInternationalCommunication: false,
});

export const toMailingBriefMailingHouseContactDto = (
  formModel: MailingBriefMailingHouseContactFormModel,
): MailingBriefMailingHouseContactDto => ({
  mailingHouseContactId: formModel.mailingHouseContactId,
  includeOnDataCommunication: formModel.includeOnDataCommunication
    ? 'LinkAndPassword'
    : formModel.includeOnCarbonCopyCommunication
    ? 'CarbonCopy'
    : 'Never',
  includeOnLabelsCommunication: formModel.includeOnLabelsCommunication,
  includeOnCollectionsCommunication: formModel.includeOnCollectionsCommunication,
  includeOnConsumablesCommunication: formModel.includeOnConsumablesCommunication,
  includeOnUnsortedCommunication: formModel.includeOnUnsortedCommunication,
  includeOnInternationalCommunication: formModel.includeOnInternationalCommunication,
});

export const fromMailingBriefMailingHouseContactDto = (
  contact: MailingBriefMailingHouseContactDto,
): MailingBriefMailingHouseContactFormModel => ({
  mailingHouseContactId: contact.mailingHouseContactId,
  includeOnCarbonCopyCommunication: contact.includeOnDataCommunication === 'CarbonCopy',
  includeOnDataCommunication: contact.includeOnDataCommunication === 'LinkAndPassword',
  includeOnLabelsCommunication: contact.includeOnLabelsCommunication,
  includeOnCollectionsCommunication: contact.includeOnCollectionsCommunication,
  includeOnConsumablesCommunication: contact.includeOnConsumablesCommunication,
  includeOnUnsortedCommunication: contact.includeOnUnsortedCommunication,
  includeOnInternationalCommunication: contact.includeOnInternationalCommunication,
});
