import * as React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as EmailIcon } from '../../images/icons/email.svg';
import { medium, narrow } from '../../styling/spacing';
import { Icon } from '../Icon';

type Props = {
  email: string;
};

export const ContactCardEmailAddress = ({ email }: Props) =>
  !email ? null : (
    <ContactMethod>
      <ContactIcon size="font">
        <EmailIcon />
      </ContactIcon>
      {email}
    </ContactMethod>
  );

export const ContactMethod = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${medium};
`;

const ContactIcon = styled(Icon)`
  margin-right: ${narrow};
`;
