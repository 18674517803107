import { FieldArray, FieldArrayRenderProps, FormikProps, withFormik } from 'formik';
import { flowRight } from 'lodash';
import * as React from 'react';
import { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components/macro';
import {
  DateStamp,
  dayAfter,
  formatDateStamp,
  getTomorrowAsDateStamp,
  workingDaysBefore,
  workingDaysBetween,
  workingDaysFrom,
} from '../../../models/dateStamp';
import { Id } from '../../../models/id';
import { HollowButton, SecondaryButton } from '../../../shared/buttons/Button';
import {
  DeleteButton,
  DeleteButtonTableHeader,
} from '../../../shared/buttons/DeleteOrUndeleteButton';
import { SubmitButton } from '../../../shared/buttons/SubmitButton';
import { Form } from '../../../shared/form/Form';
import { DateField } from '../../../shared/form/inputs/DateField';
import { InputField } from '../../../shared/form/inputs/InputField';
import {
  ApiRequestProps,
  withApiRequest,
} from '../../../shared/higher-order-components/withApiRequest';
import { ItemHeader } from '../../../shared/layout/Headers';
import { Section } from '../../../shared/layout/Section';
import { ModalActionButtons, ModalErrorBox, ModalHeader } from '../../../shared/Modal';
import { FullWidthFixedTable, TBody, Td, Th, THead, Tr } from '../../../shared/table/Table';
import { WarningBox } from '../../../shared/WarningBox';
import { medium } from '../../../styling/spacing';
import { fetchSecureJson, from, withJsonBody } from '../../../utils/api';
import { assertNotNull } from '../../../utils/assertNotNull';
import { after, inTheFuture, requiredDate } from '../../../utils/validation/dateValidators';
import { notEmpty } from '../../../utils/validation/stringValidators';
import {
  combineValidators,
  createArrayValidator,
  createValidator,
} from '../../../utils/validation/validation';
import { noValidate } from '../../../utils/validation/validators';
import { useClientPortalMetadata } from '../clientPortalMetadata';

type OwnProps = {
  sortedWorksheetId: Id;
  originalSchedule: {
    campaignName: string;
    dataToOnepostDate: DateStamp;
    consumablesDate: DateStamp;
    collectionDate: DateStamp;
  };
  disableQuantityField: boolean;
  onSuccess: (createdJobReferences: Array<number>) => void;
  onClose: () => void;
  sortedWorksheetReference: number;
};

type RequestProps = ApiRequestProps<
  CreateForecastJobsFromForecastWorksheetCommand,
  CreateForecastJobsFromForecastWorksheetResponse,
  'request'
>;

export type CreateForecastJobsFromSortedWorksheetFormModel = {
  schedules: Array<ScheduleFormModel>;
  sortedWorksheetReference: number;
};

type ScheduleFormModel = {
  dataToOnepostDate: DateStamp | null;
  consumablesDate: DateStamp | null;
  collectionDate: DateStamp | null;
  campaignName: string | null;
  quantity: number | null;
  itemWeightInGrams: number | null;
};

type FormProps = FormikProps<CreateForecastJobsFromSortedWorksheetFormModel>;

type Props = OwnProps & RequestProps & FormProps;

const CreateForecastJobsFromExistingJobModalComponent = (props: Props) => {
  const { schedules } = props.values;
  const previousSchedules = useRef(schedules);

  const { originalSchedule, setFieldValue, request, disableQuantityField } = props;

  const metadata = useClientPortalMetadata();

  const dateDifferences = useMemo(() => {
    const { dataToOnepostDate, consumablesDate, collectionDate } = originalSchedule;

    const workingDaysBetweenDataToOnepostDateAndConsumablesDate = Math.max(
      1,
      workingDaysBetween(dataToOnepostDate, consumablesDate, metadata.bankHolidays).length - 1,
    );

    const workingDaysBetweenConsumablesDateAndCollectionDate = Math.max(
      1,
      workingDaysBetween(consumablesDate, collectionDate, metadata.bankHolidays).length - 1,
    );

    return {
      workingDaysBetweenDataToOnepostDateAndConsumablesDate,
      workingDaysBetweenConsumablesDateAndCollectionDate,
      workingDaysBetweenDataToOnepostDateAndCollectionDate:
        workingDaysBetweenDataToOnepostDateAndConsumablesDate +
        workingDaysBetweenConsumablesDateAndCollectionDate,
    };
  }, [originalSchedule]);

  useEffect(() => {
    const previouslyUntouchedScheduleIndexes = previousSchedules.current
      .map((schedule, index) => ({
        isUntouched:
          schedule.dataToOnepostDate == null &&
          schedule.consumablesDate == null &&
          schedule.collectionDate == null,
        index,
      }))
      .filter(schedule => schedule.isUntouched)
      .map(schedule => schedule.index);

    for (const index of previouslyUntouchedScheduleIndexes) {
      const previouslyUntouchedSchedule = schedules[index];

      if (!previouslyUntouchedSchedule) {
        continue;
      }

      if (previouslyUntouchedSchedule.dataToOnepostDate != null) {
        setFieldValue(
          `schedules.${index}.consumablesDate`,
          workingDaysFrom(
            previouslyUntouchedSchedule.dataToOnepostDate,
            dateDifferences.workingDaysBetweenDataToOnepostDateAndConsumablesDate,
            metadata.bankHolidays,
          ),
        );
        setFieldValue(
          `schedules.${index}.collectionDate`,
          workingDaysFrom(
            previouslyUntouchedSchedule.dataToOnepostDate,
            dateDifferences.workingDaysBetweenDataToOnepostDateAndCollectionDate,
            metadata.bankHolidays,
          ),
        );
      } else if (previouslyUntouchedSchedule.consumablesDate != null) {
        setFieldValue(
          `schedules.${index}.dataToOnepostDate`,
          workingDaysBefore(
            previouslyUntouchedSchedule.consumablesDate,
            -1 * dateDifferences.workingDaysBetweenDataToOnepostDateAndConsumablesDate,
            metadata.bankHolidays,
          ),
        );
        setFieldValue(
          `schedules.${index}.collectionDate`,
          workingDaysFrom(
            previouslyUntouchedSchedule.consumablesDate,
            dateDifferences.workingDaysBetweenConsumablesDateAndCollectionDate,
            metadata.bankHolidays,
          ),
        );
      } else if (previouslyUntouchedSchedule.collectionDate != null) {
        setFieldValue(
          `schedules.${index}.dataToOnepostDate`,
          workingDaysBefore(
            previouslyUntouchedSchedule.collectionDate,
            -1 * dateDifferences.workingDaysBetweenDataToOnepostDateAndCollectionDate,
            metadata.bankHolidays,
          ),
        );
        setFieldValue(
          `schedules.${index}.consumablesDate`,
          workingDaysBefore(
            previouslyUntouchedSchedule.collectionDate,
            -1 * dateDifferences.workingDaysBetweenConsumablesDateAndCollectionDate,
            metadata.bankHolidays,
          ),
        );
      }
    }
  }, [schedules]);

  useEffect(() => {
    previousSchedules.current = schedules;
  }, [schedules]);

  return (
    <FieldArray name="schedules">
      {(fieldArrayRenderProps: FieldArrayRenderProps) => (
        <Form {...props}>
          <ModalHeader>
            Duplicate Sorted Worksheet {props.values.sortedWorksheetReference} to Forecast
          </ModalHeader>
          <Section>
            <ItemHeader>Original Schedule</ItemHeader>
            <FullWidthFixedTable>
              <THead>
                <Tr>
                  <Th>Campaign name</Th>
                  <Th>Data to ONEPOST</Th>
                  <Th>Consumables Date</Th>
                  <Th>Collection Date</Th>
                  <DeleteButtonTableHeader />
                </Tr>
              </THead>
              <TBody>
                <Tr>
                  <Td>{originalSchedule.campaignName}</Td>
                  <Td>{formatDateStamp(originalSchedule.dataToOnepostDate)}</Td>
                  <Td>{formatDateStamp(originalSchedule.consumablesDate)}</Td>
                  <Td>{formatDateStamp(originalSchedule.collectionDate)}</Td>
                  <Td />
                </Tr>
              </TBody>
            </FullWidthFixedTable>
          </Section>
          <Section>
            <ItemHeader>New Schedules</ItemHeader>
            <AddScheduleButton
              onClick={() =>
                fieldArrayRenderProps.push({
                  campaignName: null,
                  dataToOnepostDate: null,
                  consumablesDate: null,
                  collectionDate: null,
                })
              }
            >
              + Add Schedule
            </AddScheduleButton>
            {disableQuantityField && (
              <WarningBox message="Quantity and item weight can only be set when duplicating from a worksheet with a single cell" />
            )}
            <FullWidthFixedTable>
              <THead>
                <Tr>
                  <Th>Campaign name & Cust. ref</Th>
                  <Th>Data to ONEPOST</Th>
                  <Th>Consumables Date</Th>
                  <Th>Collection Date</Th>
                  <Th>Quantity</Th>
                  <Th>Item weight (g)</Th>
                  <DeleteButtonTableHeader />
                </Tr>
              </THead>
              <TBody>
                {schedules.map((scheduleFormModel, index) => (
                  <Tr key={index}>
                    <Td>
                      <InputField inline={true} name={`schedules.${index}.campaignName`} />
                    </Td>
                    <Td>
                      <DateField inline={true} name={`schedules.${index}.dataToOnepostDate`} />
                    </Td>
                    <Td>
                      <DateField
                        inline={true}
                        name={`schedules.${index}.consumablesDate`}
                        minDate={
                          scheduleFormModel.dataToOnepostDate == null
                            ? getTomorrowAsDateStamp()
                            : dayAfter(scheduleFormModel.dataToOnepostDate)
                        }
                      />
                    </Td>
                    <Td>
                      <DateField
                        inline={true}
                        name={`schedules.${index}.collectionDate`}
                        minDate={
                          scheduleFormModel.consumablesDate == null
                            ? getTomorrowAsDateStamp()
                            : dayAfter(scheduleFormModel.consumablesDate)
                        }
                        excludeNonWorkingDays={true}
                      />
                    </Td>
                    <Td>
                      <InputField
                        inline={true}
                        name={`schedules.${index}.quantity`}
                        disabled={disableQuantityField}
                        placeholder="(Optional)"
                        hidePlaceholderOnFocus={true}
                      />
                    </Td>
                    <Td>
                      <InputField
                        inline={true}
                        name={`schedules.${index}.itemWeightInGrams`}
                        disabled={disableQuantityField}
                        placeholder="(Optional)"
                        hidePlaceholderOnFocus={true}
                      />
                    </Td>
                    <Td>
                      {index !== 0 && (
                        <DeleteButton
                          delete={() => fieldArrayRenderProps.remove(index)}
                          testId={deleteScheduleButtonTestId(index)}
                        />
                      )}
                    </Td>
                  </Tr>
                ))}
              </TBody>
            </FullWidthFixedTable>
          </Section>
          <ModalActionButtons>
            <SubmitButton isSubmitting={props.isSubmitting}>Save</SubmitButton>
            <SecondaryButton onClick={props.onClose}>Cancel</SecondaryButton>
          </ModalActionButtons>
          {request.error && <ModalErrorBox error={request.error} />}
        </Form>
      )}
    </FieldArray>
  );
};

export const deleteScheduleButtonTestId = (index: number) => `delete-schedule-${index}`;

const AddScheduleButton = styled(HollowButton)`
  margin-bottom: ${medium};
`;

type CreateForecastJobsFromForecastWorksheetCommand = {
  sortedWorksheetId: Id;
  schedules: Array<Schedule>;
};

export type CreateForecastJobsFromForecastWorksheetResponse = {
  createdJobReferences: Array<number>;
};

export type Schedule = {
  campaignName: string;
  dataToOnepostDate: DateStamp;
  consumablesDate: DateStamp;
  collectionDate: DateStamp;
  quantity: number | null;
  itemWeightInGrams: number | null;
};

const toSchedule = (formModel: ScheduleFormModel): Schedule => ({
  campaignName: assertNotNull(formModel.campaignName),
  dataToOnepostDate: assertNotNull(formModel.dataToOnepostDate),
  consumablesDate: assertNotNull(formModel.consumablesDate),
  collectionDate: assertNotNull(formModel.collectionDate),
  quantity: formModel.quantity,
  itemWeightInGrams: formModel.itemWeightInGrams,
});

const createForecastJobsFromForecastWorksheet = (
  command: CreateForecastJobsFromForecastWorksheetCommand,
) =>
  fetchSecureJson<CreateForecastJobsFromForecastWorksheetResponse>(
    withJsonBody<CreateForecastJobsFromForecastWorksheetCommand>(command)(
      from(
        `client-portal/${command.sortedWorksheetId}/create-forecast-jobs-from-sorted-worksheet`,
        'post',
      ),
    ),
  );

const withRequestEnhancer = withApiRequest<
  OwnProps,
  CreateForecastJobsFromForecastWorksheetCommand,
  void,
  'request'
>(() => createForecastJobsFromForecastWorksheet, 'request');

const getScheduleValidator = (formModel: ScheduleFormModel) =>
  createValidator<ScheduleFormModel>({
    campaignName: notEmpty('Please enter a campaign name'),
    dataToOnepostDate: requiredDate(),
    consumablesDate: combineValidators<DateStamp | null>(
      requiredDate(),
      inTheFuture(),
      formModel.dataToOnepostDate != null
        ? after(formModel.dataToOnepostDate, `Must be later than 'Data to ONEPOST'`)
        : noValidate,
    ),
    collectionDate: combineValidators<DateStamp | null>(
      requiredDate(),
      inTheFuture(),
      formModel.consumablesDate != null
        ? after(formModel.consumablesDate, `Must be later than 'Consumables date'`)
        : noValidate,
    ),
  });

const validator = createValidator<CreateForecastJobsFromSortedWorksheetFormModel>({
  schedules: createArrayValidator(getScheduleValidator),
});

const withFormEnhancer = withFormik<
  OwnProps & RequestProps,
  CreateForecastJobsFromSortedWorksheetFormModel
>({
  mapPropsToValues: props => ({
    sortedWorksheetReference: props.sortedWorksheetReference,
    schedules: [
      {
        campaignName: null,
        dataToOnepostDate: null,
        consumablesDate: null,
        collectionDate: null,
        quantity: null,
        itemWeightInGrams: null,
      },
    ],
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.request
      .sendRequest({
        sortedWorksheetId: props.sortedWorksheetId,
        schedules: values.schedules.map(toSchedule),
      })
      .then(result => {
        if (result.success) {
          props.onSuccess(result.body.createdJobReferences);
          props.onClose();
        } else {
          setSubmitting(false);
        }
      });
  },
  validate: validator,
});

const enhance = flowRight(
  withRequestEnhancer,
  withFormEnhancer,
);

export const CreateForecastJobsFromExistingJobModal = enhance(
  CreateForecastJobsFromExistingJobModalComponent,
);
