import { Validator } from './validation';

export const notEmpty = (
  message: string = 'Please enter a value',
): Validator<string | null | undefined> => (value): string | null =>
  value == null || value.trim().length === 0 ? message : null;

export const maxLength = (
  length: number,
  message: string = `Please enter no more than ${length} characters`,
): Validator<string | null | undefined> => (value): string | null =>
  value != null && value.length > length ? message : null;

export const minLengthIfNotEmpty = (
  length: number,
  message: string = `Please enter at least ${length} characters`,
): Validator<string | null | undefined> => (value): string | null =>
  value != null && value.length < length && value.length !== 0 ? message : null;

export const requiredLength = (
  length: number,
  message: string = `Please enter exactly ${length} characters`,
): Validator<string | null | undefined> => (value): string | null =>
  value != null && value.length !== length ? message : null;

const lettersOnlyRegEx = new RegExp('^[a-zA-Z]+$');

export const lettersOnly = (
  message: string = 'Please only enter letters',
): Validator<string | null | undefined> => (value): string | null =>
  value != null && !lettersOnlyRegEx.test(value) ? message : null;

const digitsOnlyRegEx = new RegExp('^[0-9]+$');

export const digitsOnly = (
  message: string = 'Please only enter digits (0-9)',
): Validator<string | null | undefined> => (value): string | null =>
  value != null && value !== '' && !digitsOnlyRegEx.test(value) ? message : null;

export const exactLength = (
  length: number,
  message: string = `Please enter exactly ${length} characters`,
): Validator<string | null | undefined> => (value): string | null =>
  value != null && value.length !== length ? message : null;

export const alphanumericRegEx = new RegExp('^[a-zA-Z0-9]+$');

export const alphanumeric = (
  message: string = 'Please only enter letters and digits',
): Validator<string | null | undefined> => (value): string | null =>
  value != null && value !== '' && !alphanumericRegEx.test(value) ? message : null;
