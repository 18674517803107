import * as React from 'react';
import styled from 'styled-components/macro';
import { white } from '../../styling/colours';
import { regularTransitionDuration } from '../../styling/transitions';
import { LoadingSpinner } from '../LoadingSpinner';
import { ButtonProps, PrimaryButton } from './Button';

type LoadingButtonProps = ButtonProps & { inProgress: boolean };

type ButtonContentContainerProps = {
  visible: boolean;
};

type SpinnerContainerProps = {
  visible: boolean;
  size?: 'normal' | 'small';
};

export const progressButtonTestId = 'progress-button';
export const progressButtonContentTestId = 'progress-button-content';

export const progressButtonVisibleSpinnerTestId = 'progress-button-spinner-visible';
export const progressButtonHiddenSpinnerTestId = 'progress-button-spinner-hidden';

export const ProgressButton = ({
  children,
  disabled,
  inProgress,
  size,
  ...rest
}: LoadingButtonProps) => (
  <PrimaryButton
    data-testid={progressButtonTestId}
    {...rest}
    disabled={disabled || inProgress}
    size={size}
  >
    <ButtonContentContainer visible={!inProgress} data-testid={progressButtonContentTestId}>
      {children}
    </ButtonContentContainer>
    <SpinnerContainer
      visible={inProgress}
      data-testid={
        inProgress ? progressButtonVisibleSpinnerTestId : progressButtonHiddenSpinnerTestId
      }
      size={size}
    >
      <ProgressButtonSpinner color={white} visible={inProgress} />
    </SpinnerContainer>
  </PrimaryButton>
);

const ButtonContentContainer = styled.div<ButtonContentContainerProps>`
  display: inline-block;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity ${regularTransitionDuration} ease;
`;

const SpinnerContainer = styled.div<SpinnerContainerProps>`
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity ${regularTransitionDuration} ease;
  position: absolute;
  height: ${props => (props.size === 'small' && '12px') || '18px'};
  width: ${props => (props.size === 'small' && '12px') || '18px'};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ProgressButtonSpinner = styled(LoadingSpinner)`
  margin: 0;
  height: 100%;
  width: 100%;
`;
