import * as React from 'react';
import { Context } from 'react';

export type AuthenticationContextValue = {
  getAccessToken: () => Promise<string>;
  logout: () => void;
};

// This needs to be in its own file, instead of in AzureAdAuthentication.tsx, to avoid circular references
export const AuthenticationContext: Context<AuthenticationContextValue> = React.createContext<
  AuthenticationContextValue
>({
  getAccessToken: () => Promise.reject('Authentication context not set'),
  logout: () => {
    throw new Error('Authentication context not set');
  },
});
