import { connect, FieldArray, FieldArrayRenderProps, FormikContext } from 'formik';
import * as React from 'react';
import styled from 'styled-components/macro';
import { HollowButton } from '../../../../shared/buttons/Button';
import { standardVerticalFormFieldSpacing } from '../../../../shared/form/FormField';
import {
  FullWidthFixedTable,
  NoDataTableDataCell,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from '../../../../shared/table/Table';
import { tdgGreen } from '../../../../styling/colours';
import { medium } from '../../../../styling/spacing';
import { assertNotNull } from '../../../../utils/assertNotNull';
import { MailingBriefFormModel } from '../mailingBriefFormModel';
import { MailingBriefUnsortedCellFields } from './MailingBriefUnsortedCellFields';
import {
  getInitialMailingBriefUnsortedCellFormModel,
  MailingBriefUnsortedCellFormModel,
} from './mailingBriefUnsortedCellFormModel';

type OwnProps = {};

type Props = OwnProps & {
  formik: FormikContext<MailingBriefFormModel>;
};

export const deleteCellButtonTestId = (cellIndex: number) => `deleteCellButton${cellIndex}`;

const MailingBriefUnsortedCellsFieldArrayComponent = (props: Props) => {
  const worksheetFieldName = 'unsortedWorksheet';

  const { setFieldValue, setFieldTouched, values } = props.formik;
  const worksheetFormModel = assertNotNull(values.unsortedWorksheet);

  const addCell = () => {
    const currentCells = worksheetFormModel.cells;
    const newCell: MailingBriefUnsortedCellFormModel = getInitialMailingBriefUnsortedCellFormModel();
    setFieldValue(`${worksheetFieldName}.cells`, [...currentCells, newCell]);
  };

  const removeCell = (arrayHelpers: FieldArrayRenderProps, cellIndex: number) => {
    arrayHelpers.remove(cellIndex);
  };

  const resetField = <T extends unknown>(
    cellIndex: number,
    fieldName: keyof MailingBriefUnsortedCellFormModel,
    value: T | null,
  ) => {
    const formFieldName = `${worksheetFieldName}.cells.${cellIndex}.${fieldName}`;
    setFieldValue(formFieldName, value);
    setFieldTouched(formFieldName, false);
  };

  const resetFormat = (cellIndex: number) => {
    resetField(cellIndex, 'format', null);
  };

  const resetWeight = (cellIndex: number) => {
    resetField(cellIndex, 'itemWeightInGrams', '');
  };

  const resetAddOns = (cellIndex: number) => {
    resetField(cellIndex, 'isPpiPrinted', false);
    resetField(cellIndex, 'isReturnAddressPrinting', false);
  };

  const onChangeFormat = (cellIndex: number) => {
    resetAddOns(cellIndex);
    resetWeight(cellIndex);
  };

  const onChangeProcessingType = (cellIndex: number) => {
    resetFormat(cellIndex);
    onChangeFormat(cellIndex);
  };

  const renderFieldArray = (arrayHelpers: FieldArrayRenderProps) => {
    const cells = worksheetFormModel.cells as Array<MailingBriefUnsortedCellFormModel>;

    return (
      <div>
        <EditCellsTable>
          <THead>
            <Tr>
              <Th>Cell Reference</Th>
              <Th>Service</Th>
              <Th>Format</Th>
              <Th>Quantity</Th>
              <Th>Item Weight (g)</Th>
              <Th>Return Address Printed</Th>
              <Th>PPI Printed?</Th>
              <DeleteCellColumnHeader />
            </Tr>
          </THead>
          <TBody>
            {!cells.length ? (
              <Tr>
                <NoDataTableDataCell colSpan={8}>
                  -- You haven't added any cells yet --
                </NoDataTableDataCell>
              </Tr>
            ) : (
              cells.map((cell, cellIndex) => {
                return (
                  <MailingBriefUnsortedCellFields
                    key={cell.uniqueFormId}
                    worksheetFieldName={worksheetFieldName}
                    cell={cell}
                    cellIndex={cellIndex}
                    onChangeFormat={() => onChangeFormat(cellIndex)}
                    onChangeProcessingType={() => onChangeProcessingType(cellIndex)}
                    removeCell={() => removeCell(arrayHelpers, cellIndex)}
                  />
                );
              })
            )}
            <Tr>
              <Td colSpan={8}>
                <AddCellButton onClick={addCell}>+ Add Cell</AddCellButton>
              </Td>
            </Tr>
          </TBody>
        </EditCellsTable>
      </div>
    );
  };

  return <FieldArray name={`${worksheetFieldName}.cells`} render={renderFieldArray} />;
};

export const MailingBriefUnsortedCellsFieldArray = connect<OwnProps, MailingBriefFormModel>(
  MailingBriefUnsortedCellsFieldArrayComponent,
);

const AddCellButton = styled(HollowButton)`
  margin-bottom: ${medium};
  width: 100%;
  height: 100%;
  color: ${tdgGreen};
  border: none;
`;

const EditCellsTable = styled(FullWidthFixedTable)`
  margin-bottom: ${standardVerticalFormFieldSpacing};
`;

const DeleteCellColumnHeader = styled(Th)`
  width: 30px;
`;
