import React from 'react';
import styled from 'styled-components/macro';
import { NoDataTableDataCell, TBody, Td, Th, THead, Tr } from '../../../../shared/table/Table';
import { TickOrCross } from '../../../../shared/TickOrCross';
import {
  WorksheetContactDetailsTableData,
  WorksheetContactsTable,
} from '../../../worksheets/contacts/WorksheetContactsTable';
import { ClientPortalWorksheetContactDetails } from '../../ClientPortalWorksheetContactDetails';
import { ClientPortalWorksheetContact } from '../ClientPortalWorksheet';

type InternationalWorksheetContact = ClientPortalWorksheetContact & {
  includeOnCommunications: boolean;
};

type Props<TContact extends InternationalWorksheetContact> = {
  contacts: Array<TContact>;
};

export class ClientPortalInternationalWorksheetContactsTable<
  TContact extends InternationalWorksheetContact
> extends React.Component<Props<TContact>> {
  render() {
    const { contacts } = this.props;

    return (
      <WorksheetContactsTable>
        <THead>
          <Tr>
            <Th>Contact Details</Th>
            <IncludeOnCommunicationTableHeader>
              Include on Communications
            </IncludeOnCommunicationTableHeader>
          </Tr>
        </THead>
        <TBody>
          {contacts.length === 0 && (
            <Tr>
              <NoDataTableDataCell colSpan={2}>
                -- No contacts have been configured on this international element --
              </NoDataTableDataCell>
            </Tr>
          )}
          {contacts.map((contact, index) => (
            <Tr key={index}>
              <WorksheetContactDetailsTableData>
                <ClientPortalWorksheetContactDetails contact={contact} />
              </WorksheetContactDetailsTableData>
              <Td>
                <TickOrCross value={contact.includeOnCommunications} />
              </Td>
            </Tr>
          ))}
        </TBody>
      </WorksheetContactsTable>
    );
  }
}

const IncludeOnCommunicationTableHeader = styled(Th)`
  width: 420px;
`;
