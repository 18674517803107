import { Id } from '../../../models/id';
import { CustomerContactForMailingBriefForm } from './dataForMailingBriefForm';
import { MailingBriefContactBaseFormModel } from './mailingBriefContactBaseFormModel';
import { MailingBriefCustomerContactDto } from './mailingBriefCustomerContactDto';

export type MailingBriefCustomerContactFormModel = MailingBriefContactBaseFormModel & {
  customerContactId: Id;
  includeOnConfirmationCommunication: boolean;
};

export const toCreateUpdateMailingBriefCustomerContactDto = ({
  includeOnDataCommunication,
  includeOnCarbonCopyCommunication,
  ...formModel
}: MailingBriefCustomerContactFormModel): MailingBriefCustomerContactDto => ({
  ...formModel,
  includeOnDataCommunication: includeOnDataCommunication
    ? 'LinkAndPassword'
    : includeOnCarbonCopyCommunication
    ? 'CarbonCopy'
    : 'Never',
});

export const fromCustomerContact = ({
  customerContactId,
  includeOnJobsCode,
  includeOnDataCommunication,
  includeOnLabelsCommunication,
  includeOnCollectionsCommunication,
  includeOnConsumablesCommunication,
  includeOnConfirmationCommunication,
}: CustomerContactForMailingBriefForm): MailingBriefCustomerContactFormModel => {
  const alwaysIncludeOnJobs = includeOnJobsCode === 'Always';

  return {
    customerContactId,
    includeOnDataCommunication:
      alwaysIncludeOnJobs && includeOnDataCommunication === 'LinkAndPassword',
    includeOnCarbonCopyCommunication:
      alwaysIncludeOnJobs && includeOnDataCommunication === 'CarbonCopy',
    includeOnLabelsCommunication: alwaysIncludeOnJobs && includeOnLabelsCommunication,
    includeOnCollectionsCommunication: alwaysIncludeOnJobs && includeOnCollectionsCommunication,
    includeOnConsumablesCommunication: alwaysIncludeOnJobs && includeOnConsumablesCommunication,
    includeOnConfirmationCommunication: alwaysIncludeOnJobs && includeOnConfirmationCommunication,
    includeOnUnsortedCommunication: false,
    includeOnInternationalCommunication: false,
  };
};
