import { FieldArray, FormikProps } from 'formik';
import { keyBy } from 'lodash';
import { useMemo } from 'react';
import * as React from 'react';
import styled from 'styled-components/macro';
import { CheckboxesFieldItem } from '../../../../shared/form/inputs/CheckboxesField';
import { TopLevelValidationError } from '../../../../shared/form/TopLevelValidationError';
import { darkGrey } from '../../../../styling/colours';
import { medium, narrow } from '../../../../styling/spacing';
import { assertNotNull } from '../../../../utils/assertNotNull';
import { DataEmailAccessOptions } from '../../../mailing-houses/contacts/MailingHouseContactsFieldArray';
import { WorksheetCheckboxesField } from '../../../worksheets/WorksheetCheckboxesField';
import { WorksheetFieldSet } from '../../../worksheets/WorksheetFieldSet';
import { MailingHouseForMailingBriefForm } from '../dataForMailingBriefForm';
import { MailingBriefFormModel } from '../mailingBriefFormModel';

type Props = {
  mailingHouse: MailingHouseForMailingBriefForm;
  mailingBriefFormModel: MailingBriefFormModel;
  formikProps: FormikProps<MailingBriefFormModel>;
};

// This component is used in non-split jobs, where the mailing house (and its contacts) should be the same for all worksheets.
// There will be at most one sorted worksheet. When there is a sorted worksheet,
// we take the first and only element in the sorted worksheets array.
// We need to set mailing house on each of the worksheets, so we first find the mailing house ID if it exists by going
// through all the possible worksheets.
// Then we set the mailing house ID and contacts on each of the worksheets.
// Some of the form fields require a name to bind to, so we arbitrarily choose the first worksheet to bind to.
export const JobLevelMailingBriefMailingHouseContactsFieldArray = (props: Props) => {
  const { mailingHouse, formikProps } = props;
  const { values: formValues } = formikProps;
  const mailingHouseContacts = useMemo(
    () => keyBy(Object.values(mailingHouse.contactsById), c => c.mailingHouseContactId),
    [mailingHouse],
  );

  const formMailingHouseContacts =
    formValues.sortedWorksheets.length > 0
      ? formValues.sortedWorksheets[0].mailingHouse.contacts
      : formValues.unsortedWorksheet
      ? formValues.unsortedWorksheet.mailingHouse.contacts
      : formValues.internationalWorksheet
      ? formValues.internationalWorksheet.mailingHouse.contacts
      : [];

  const mailingHouseFieldNames = [
    formValues.sortedWorksheets.length > 0 ? `sortedWorksheets[0].mailingHouse` : null,
    formValues.unsortedWorksheet ? `unsortedWorksheet.mailingHouse` : null,
    formValues.internationalWorksheet ? `internationalWorksheet.mailingHouse` : null,
  ].filter(mailingHouseField => !!mailingHouseField);

  const mailingHouseFieldName = mailingHouseFieldNames[0];

  return (
    <WorksheetFieldSet label="Mailing House Contacts">
      {formMailingHouseContacts.length > 0 ? (
        <FieldArray
          name={`${mailingHouseFieldName}.contacts`}
          render={() =>
            Object.values(mailingHouse.contactsById).map((mailingHouseContactResponse, index) => {
              const mailingHouseContactId = mailingHouseContactResponse.mailingHouseContactId;
              const mailingHouseContact = mailingHouseContacts[mailingHouseContactId];
              const { name, jobTitle } = mailingHouseContact;

              const mailingHouseContactFieldNames = [
                formValues.sortedWorksheets.length > 0
                  ? `sortedWorksheets[0].mailingHouse.contacts.${index}`
                  : null,
                formValues.unsortedWorksheet
                  ? `unsortedWorksheet.mailingHouse.contacts.${index}`
                  : null,
                formValues.internationalWorksheet
                  ? `internationalWorksheet.mailingHouse.contacts.${index}`
                  : null,
              ].filter(mailingHouseContactFieldName => !!mailingHouseContactFieldName);

              return (
                <WorksheetCheckboxesField
                  key={mailingHouseContactId}
                  renderLabel={() => (
                    <ContactNameAndJobTitleContainer>
                      <ContactName>{name}</ContactName>
                      <ContactJobTitle>- {jobTitle}</ContactJobTitle>
                    </ContactNameAndJobTitleContainer>
                  )}
                  label={undefined}
                >
                  {formValues.sortedWorksheets.length > 0 && (
                    <>
                      <DataEmailAccessOptions>
                        <CheckboxesFieldItem
                          name={`${mailingHouseContactFieldNames[0]}.includeOnCarbonCopyCommunication`}
                          label="CC"
                          onChange={(newValue: boolean) => {
                            mailingHouseContactFieldNames
                              .slice(1)
                              .map(mailingHouseContactFieldName =>
                                formikProps.setFieldValue(
                                  `${mailingHouseContactFieldName}.includeOnCarbonCopyCommunication`,
                                  newValue,
                                ),
                              );

                            if (newValue) {
                              mailingHouseContactFieldNames.map(mailingHouseContactFieldName =>
                                formikProps.setFieldValue(
                                  `${mailingHouseContactFieldName}.includeOnDataCommunication`,
                                  false,
                                ),
                              );
                            }
                          }}
                          disabled={
                            mailingHouseContact.includeOnDataCommunication === 'LinkAndPassword' ||
                            (mailingHouseContact.includeOnDataCommunication === 'CarbonCopy' &&
                              assertNotNull(
                                formMailingHouseContacts.find(
                                  contact =>
                                    contact.mailingHouseContactId === mailingHouseContactId,
                                ),
                              ).includeOnCarbonCopyCommunication)
                          }
                        />
                        <CheckboxesFieldItem
                          name={`${mailingHouseContactFieldNames[0]}.includeOnDataCommunication`}
                          label="Data"
                          onChange={(newValue: boolean) => {
                            mailingHouseContactFieldNames
                              .slice(1)
                              .map(mailingHouseContactFieldName => {
                                formikProps.setFieldValue(
                                  `${mailingHouseContactFieldName}.includeOnDataCommunication`,
                                  newValue,
                                );
                              });

                            if (newValue) {
                              mailingHouseContactFieldNames.map(mailingHouseContactFieldName => {
                                formikProps.setFieldValue(
                                  `${mailingHouseContactFieldName}.includeOnCarbonCopyCommunication`,
                                  false,
                                );
                              });
                            } else if (
                              // If the contact is set to 'Always' include on CarbonCopy communications, they can be upgraded
                              // to receive Data communications, but if Data is then unchecked, the CC checkbox must be
                              // re-checked to ensure they are still included on CarbonCopy communications
                              mailingHouseContact.includeOnDataCommunication === 'CarbonCopy'
                            ) {
                              mailingHouseContactFieldNames.map(mailingHouseContactFieldName =>
                                formikProps.setFieldValue(
                                  `${mailingHouseContactFieldName}.includeOnCarbonCopyCommunication`,
                                  true,
                                ),
                              );
                            }
                          }}
                          disabled={
                            mailingHouseContact.includeOnDataCommunication === 'LinkAndPassword'
                          }
                        />
                      </DataEmailAccessOptions>
                      <CheckboxesFieldItem
                        name={`${mailingHouseContactFieldNames[0]}.includeOnLabelsCommunication`}
                        label="Labels"
                        onChange={(newValue: boolean) =>
                          mailingHouseContactFieldNames
                            .slice(1)
                            .map(mailingHouseContactFieldName =>
                              formikProps.setFieldValue(
                                `${mailingHouseContactFieldName}.includeOnLabelsCommunication`,
                                newValue,
                              ),
                            )
                        }
                        disabled={mailingHouseContact.includeOnLabelsCommunication}
                      />
                      <CheckboxesFieldItem
                        name={`${mailingHouseContactFieldNames[0]}.includeOnCollectionsCommunication`}
                        label="Collections"
                        onChange={(newValue: boolean) =>
                          mailingHouseContactFieldNames
                            .slice(1)
                            .map(mailingHouseContactFieldName =>
                              formikProps.setFieldValue(
                                `${mailingHouseContactFieldName}.includeOnCollectionsCommunication`,
                                newValue,
                              ),
                            )
                        }
                        disabled={mailingHouseContact.includeOnCollectionsCommunication}
                      />
                      <CheckboxesFieldItem
                        name={`${mailingHouseContactFieldNames[0]}.includeOnConsumablesCommunication`}
                        label="Consumables"
                        onChange={(newValue: boolean) =>
                          mailingHouseContactFieldNames
                            .slice(1)
                            .map(mailingHouseContactFieldName =>
                              formikProps.setFieldValue(
                                `${mailingHouseContactFieldName}.includeOnConsumablesCommunication`,
                                newValue,
                              ),
                            )
                        }
                        disabled={mailingHouseContact.includeOnConsumablesCommunication}
                      />
                    </>
                  )}
                  {formValues.unsortedWorksheet && (
                    <CheckboxesFieldItem
                      name={`unsortedWorksheet.mailingHouse.contacts.${index}.includeOnUnsortedCommunication`}
                      label="Unsorted confirmation"
                    />
                  )}
                  {formValues.internationalWorksheet && (
                    <CheckboxesFieldItem
                      name={`internationalWorksheet.mailingHouse.contacts.${index}.includeOnInternationalCommunication`}
                      label="International confirmation"
                    />
                  )}
                </WorksheetCheckboxesField>
              );
            })
          }
        />
      ) : (
        <p>This customer does not have any contacts.</p>
      )}
      {mailingHouseFieldNames.map(name => (
        <MailingHouseContactsTopLevelValidationError
          key={assertNotNull(name)}
          fieldName={assertNotNull(name)}
          {...formikProps}
        />
      ))}
    </WorksheetFieldSet>
  );
};

const ContactNameAndJobTitleContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: ${medium};
`;

const ContactName = styled.span`
  margin-bottom: 0;
`;

const ContactJobTitle = styled.span`
  margin-left: ${narrow};
  color: ${darkGrey};
`;

const MailingHouseContactsTopLevelValidationError = styled(TopLevelValidationError)`
  margin-bottom: 0;
`;
