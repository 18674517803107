import { DateStamp } from '../../../models/dateStamp';
import { Id } from '../../../models/id';
import { MailingHouseAddress } from '../../mailing-houses/mailingHouse';
import { WorksheetType } from '../../worksheets/worksheet';

export type ClientPortalWorksheet = {
  statusCode: ClientPortalWorksheetStatusCode;
  type: WorksheetType;
  mailingHouse: ClientPortalWorksheetMailingHouse | null;
  collectionDate: DateStamp;
  worksheetReference: number;
};

export type ClientPortalWorksheetStatusCode =
  | 'Forecast'
  | 'Submitted'
  | 'InProgress'
  | 'Collected'
  | 'Completed'
  | 'Cancelled';

export const ClientPortalWorksheetStatusDisplayNames: {
  [clientPortalWorksheetStatusCode in ClientPortalWorksheetStatusCode]: string;
} = {
  Forecast: 'Forecast',
  Submitted: 'Submitted',
  InProgress: 'In Progress',
  Collected: 'Collected',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
};

export type ClientPortalWorksheetMailingHouse = {
  mailingHouseId: Id;
  referenceNumber: number;
  name: string;
  address: MailingHouseAddress;
};

export type ClientPortalWorksheetCustomerContact = {
  customerContactId: Id;
  name: string;
  jobTitle: string;
  landline: string;
  mobile: string;
  email: string;
};

export type ClientPortalWorksheetMailingHouseContact = {
  mailingHouseContactId: Id;
  name: string;
  jobTitle: string;
  landline: string;
  mobile: string;
  email: string;
};

export type ClientPortalWorksheetContact = {
  name: string;
  jobTitle: string;

  landline: string;
  mobile: string;
  email: string;
};
