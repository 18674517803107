import * as React from 'react';
import styled from 'styled-components/macro';
import { sidebarPortalRootElementId } from './features/layout/Sidebar';
import { modalPortalRootElementId } from './shared/Modal';
import { notificationsPortalRootElementId } from './shared/notifications/NotificationsProvider';

export const PortalRoots = () => (
  <>
    <SidebarPortalRoot id={sidebarPortalRootElementId} />
    <ModalPortalRoot id={modalPortalRootElementId} />
    <NotificationsPortalRoot id={notificationsPortalRootElementId} />
  </>
);

const SidebarPortalRoot = styled.div`
  position: absolute;
  z-index: 20;
`;

const ModalPortalRoot = styled.div`
  position: absolute;
  z-index: 30;
`;

const NotificationsPortalRoot = styled.div`
  position: absolute;
  z-index: 40;
`;
