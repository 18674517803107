import * as React from 'react';
import styled from 'styled-components/macro';
import { Dd, Dl, DlItem, Dt } from '../../../shared/DefinitionList';
import { PageHeader } from '../../../shared/layout/Headers';
import { Section } from '../../../shared/layout/Section';
import { Panel } from '../../../shared/Panel';
import { medium } from '../../../styling/spacing';
import { ClientPortalJob } from './ClientPortalJob';

type Props = {
  job: ClientPortalJob;
};

export const ClientPortalJobDetails = ({ job }: Props) => (
  <>
    <DetailsSection>
      <StyledPanel>
        <PageHeader>Job Details for {job.jobReference}</PageHeader>

        <Dl>
          <DlItem horizontal={true}>
            <Dt>Campaign Name</Dt>
            <Dd>{job.campaignName}</Dd>
          </DlItem>

          <DlItem horizontal={true}>
            <Dt>Customer</Dt>
            <Dd>{job.customerName}</Dd>
          </DlItem>

          <DlItem horizontal={true}>
            <Dt>Customer Reference</Dt>
            <Dd>{job.customerReference}</Dd>
          </DlItem>
        </Dl>
      </StyledPanel>
    </DetailsSection>
  </>
);

const DetailsSection = styled(Section)`
  display: flex;
  flex-flow: row wrap;
  margin: -${medium};
  margin-bottom: ${medium};
`;

const StyledPanel = styled(Panel)`
  flex: 2 1 auto;
  margin: ${medium};
  max-width: 1000px;
`;
