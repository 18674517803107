import { FieldProps } from 'formik';
import * as React from 'react';
import styled from 'styled-components/macro';
import { medium } from '../../../styling/spacing';
import { FieldLabel } from '../FieldLabel';
import { FormField } from '../FormField';
import { RadioButton } from './RadioButton';

export type RadioButtonOptions<TValue extends string> = Array<RadioButtonOption<TValue>>;

type RadioButtonOption<TValue extends string> = {
  text: string;
  value: TValue;
};

type RadioButtonsFieldProps<TValue extends string> = {
  name: string;
  label: string;
  options: RadioButtonOptions<TValue>;
  disabled?: boolean;
};

export class RadioButtonsField<TValue extends string> extends React.Component<
  RadioButtonsFieldProps<TValue>
> {
  onBlur = ({ form, field }: FieldProps) => () => {
    form.setFieldTouched(field.name);
  };

  render() {
    const { name, label, options, disabled } = this.props;
    return (
      <FormField
        name={name}
        label={label}
        disabled={disabled}
        optional={false}
        renderLabel={FieldLabel}
      >
        {({ field, form, valid, invalid, showWarning }) => (
          <OptionsContainer>
            {options.map(option => (
              <RadioButtonContainer key={option.value}>
                <RadioButton<TValue>
                  name={field.name}
                  label={option.text}
                  onChange={field.onChange}
                  onBlur={this.onBlur({ field, form })}
                  valid={valid}
                  invalid={invalid}
                  showWarning={showWarning}
                  disabled={form.isSubmitting || this.props.disabled}
                  value={option.value}
                  selected={option.value === field.value}
                />
              </RadioButtonContainer>
            ))}
          </OptionsContainer>
        )}
      </FormField>
    );
  }
}

const OptionsContainer = styled.div`
  display: flex;
  margin-top: -3px;
`;

const RadioButtonContainer = styled.div`
  :not(:last-child) {
    margin-right: ${medium};
  }
`;
