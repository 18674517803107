import { every, includes, some } from 'lodash';
import { SelectOptions } from '../../shared/form/inputs/Select';
import { Permission } from './permissions';

type Group =
  | 'Superuser'
  | 'Admin'
  | 'SeniorManagement'
  | 'BusinessManager'
  | 'BusinessManagerAdmin'
  | 'ClientServices'
  | 'ClientServicesAdmin'
  | 'Collections'
  | 'CollectionsAdmin'
  | 'Connect'
  | 'ConnectAdmin'
  | 'Consumables'
  | 'ConsumablesAdmin'
  | 'DataProcessing'
  | 'DataProcessingAdmin'
  | 'FinanceInvoicing'
  | 'FinanceSellingPrices'
  | 'FinanceAdmin'
  | 'International'
  | 'InternationalAdmin';

export type User = {
  userId: string;
  userName: string;
  permissions: Array<Permission>;
  groups: Array<Group>;
  canReceiveNotifications: boolean;
};

export const userIsInGroup = (user: User, group: Group) => includes(user.groups, group);

export const userIsInOneOfGroups = (user: User, groups: Array<Group>) =>
  some(groups, group => userIsInGroup(user, group));

export const userHasPermission = (user: User, permission: Permission | Array<Permission>) =>
  typeof permission === 'string'
    ? includes(user.permissions, permission)
    : every(permission, p => includes(user.permissions, p));

export const userHasOneOfPermissions = (user: User, permissions: Array<Permission>) =>
  some(permissions, permission => includes(user.permissions, permission));

export type SelectableUser = {
  userId: string;
  displayName: string;
};

export const userIdOptions = (users: Array<SelectableUser>): SelectOptions<string> =>
  users.map(user => ({
    value: user.userId,
    text: user.displayName,
  }));
