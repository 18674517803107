export const assertIsNumber = (value: string | null | undefined): number => {
  if (value == null) {
    throw new Error('Expected not to be null');
  }
  const numberValue = Number(value);
  if (isNaN(numberValue)) {
    throw new Error('Expected value to be a number');
  }
  return numberValue;
};

export const assertIsNumberOrNull = (value: string | null | undefined): number | null => {
  if (value == null) {
    return null;
  }

  return assertIsNumber(value);
};
