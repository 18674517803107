import { includes, orderBy } from 'lodash';
import { DateStamp } from '../../../models/dateStamp';
import { Id } from '../../../models/id';
import { Metadata, WorksheetStatusCode, WorksheetTransitionName } from '../../../models/metadata';
import { getSelectOptions, SelectOptions } from '../../../shared/form/inputs/Select';
import { fetchSecureJson, from, withJsonBody } from '../../../utils/api';
import { assertNotNull } from '../../../utils/assertNotNull';
import { Job } from '../../jobs/job';
import {
  Worksheet,
  WorksheetCustomerContact,
  WorksheetMailingHouseContact,
  WorksheetMailingHouseDetails,
} from '../worksheet';
import { UnsortedCell } from './cells/unsortedCell';
import {
  UnsortedBuyingPrice,
  UnsortedCommissionItem,
  UnsortedSellingPrice,
} from './prices/unsortedPrices';

export type UnsortedWorksheet = Worksheet & {
  unsortedWorksheetId: Id;
  isAgencyAgreement: boolean;
  collectionSchedule: UnsortedCollectionScheduleType;
  invoiceSchedule: UnsortedInvoiceSchedule | null;
  collectFromCustomer: boolean;
  firstCollectionDate: DateStamp;
  lastCollectionDate: DateStamp;
  collectionPresentation: UnsortedCollectionPresentation;
  collectionType: UnsortedCollectionType;
  externalTransportSupplier: UnsortedWorksheetExternalTransportSupplier | null;
  processingDateOption: UnsortedProcessingDateOption;
  processingCentre: UnsortedProcessingCentre;
  notes: string;
  customCollectionEmailContent: string | null;
  mailingHouse: UnsortedWorksheetMailingHouseDetails | null;
  mailingHouseContacts: Array<UnsortedWorksheetMailingHouseContact>;
  customerContacts: Array<UnsortedWorksheetCustomerContact>;
  cells: Array<UnsortedCell>;
  buyingPrices: Array<UnsortedBuyingPrice> | null;
  sellingPrices: Array<UnsortedSellingPrice> | null;
  commissionItems: Array<UnsortedCommissionItem> | null;
};

export type UnsortedWorksheetMailingHouseDetails = WorksheetMailingHouseDetails & {
  instructions: string | null;
};

export type UnsortedWorksheetMailingHouseContact = WorksheetMailingHouseContact & {
  includeOnCommunications: boolean;
};

export type UnsortedWorksheetCustomerContact = WorksheetCustomerContact & {
  includeOnCommunications: boolean;
};

export type UnsortedWorksheetExternalTransportSupplier = {
  supplierId: number;
  name: string;
  code: string;
};

export const isUnsortedWorksheet = (worksheet: Worksheet): worksheet is UnsortedWorksheet =>
  worksheet.type === 'Unsorted';

export type UnsortedCollectionScheduleType = 'AdHoc' | 'Daily';
export const unsortedCollectionScheduleDisplayNames: {
  [schedule in UnsortedCollectionScheduleType]: string;
} = {
  AdHoc: 'Ad-hoc',
  Daily: 'Daily',
};
export const unsortedCollectionScheduleTypes = Object.keys(
  unsortedCollectionScheduleDisplayNames,
) as Array<UnsortedCollectionScheduleType>;

export type UnsortedInvoiceSchedule = 'Weekly' | 'Monthly';
export const unsortedInvoiceScheduleDisplayNames: {
  [schedule in UnsortedInvoiceSchedule]: string;
} = {
  Weekly: 'Weekly',
  Monthly: 'Monthly',
};
export const unsortedInvoiceSchedules = Object.keys(unsortedInvoiceScheduleDisplayNames) as Array<
  UnsortedInvoiceSchedule
>;

export type UnsortedCollectionPresentation = 'Bags' | 'Trays' | 'Boxes' | 'Pallets';
export const unsortedCollectionPresentationDisplayNames: {
  [schedule in UnsortedCollectionPresentation]: string;
} = {
  Bags: 'Bags',
  Trays: 'Trays',
  Boxes: 'Boxes',
  Pallets: 'Pallets',
};
export const unsortedCollectionPresentations = Object.keys(
  unsortedCollectionPresentationDisplayNames,
) as Array<UnsortedCollectionPresentation>;

export type UnsortedCollectionType =
  | 'DeliveredInToProcessCentre'
  | 'ExternalTransport'
  | 'InternalTransport';
export const unsortedCollectionTypeDisplayNames: {
  [schedule in UnsortedCollectionType]: string;
} = {
  DeliveredInToProcessCentre: 'Delivered In To Processing Centre',
  ExternalTransport: 'External Transport',
  InternalTransport: 'Internal Transport',
};
export const unsortedCollectionTypes = Object.keys(unsortedCollectionTypeDisplayNames) as Array<
  UnsortedCollectionType
>;

export type UnsortedProcessingDateOption = 'DayOfCollection' | 'NextDay';
export const unsortedProcessingDateOptionDisplayNames: {
  [schedule in UnsortedProcessingDateOption]: string;
} = {
  DayOfCollection: 'Day of Collection',
  NextDay: 'Next Day',
};
export const unsortedProcessingDateOptions = Object.keys(
  unsortedProcessingDateOptionDisplayNames,
) as Array<UnsortedProcessingDateOption>;

export type UnsortedProcessingCentre =
  | 'Bristol'
  | 'WestsidePark'
  | 'Warrington'
  | 'Luton'
  | 'Maidstone'
  | 'AJT'
  | 'DcOffsite';
export const unsortedProcessingCentreDisplayNames: {
  [schedule in UnsortedProcessingCentre]: string;
} = {
  Bristol: 'Bristol',
  WestsidePark: 'Westside Park',
  Warrington: 'Warrington',
  Luton: 'Luton',
  Maidstone: 'Maidstone',
  AJT: 'AJ & T',
  DcOffsite: 'DC OffSite',
};
export const unsortedProcessingCentres = Object.keys(unsortedProcessingCentreDisplayNames) as Array<
  UnsortedProcessingCentre
>;
export const unsortedProcessingCentreSelectOptions: SelectOptions<
  UnsortedProcessingCentre
> = getSelectOptions(unsortedProcessingCentres, unsortedProcessingCentreDisplayNames);

// Simple transitions are ones for which buttons are shown
// on the worksheet details panel and the connect manage worksheets table

export type UnsortedWorksheetSimpleTransition = {
  displayOrder: number;
  transitionName: WorksheetTransitionName;
  endpoint: string;
};

type UnsortedWorksheetSimpleTransitions = Array<UnsortedWorksheetSimpleTransition>;

export const applyUnsortedSimpleTransition = (
  unsortedWorksheetId: Id,
  simpleTransition: UnsortedWorksheetSimpleTransition,
) =>
  fetchSecureJson<Job>(
    withJsonBody({ unsortedWorksheetId })(
      from(`unsorted-worksheets/${unsortedWorksheetId}/${simpleTransition.endpoint}`, 'put'),
    ),
  );

export const unsortedWorksheetSimpleTransitions: UnsortedWorksheetSimpleTransitions = [
  {
    displayOrder: 0,
    transitionName: 'MoveToInProgress',
    endpoint: 'move-to-in-progress',
  },
  {
    displayOrder: 1,
    transitionName: 'RevertToPendingCollection',
    endpoint: 'revert-to-pending-collection',
  },
  {
    displayOrder: 2,
    transitionName: 'SubmitToFinance',
    endpoint: 'submit-to-finance',
  },
];

export const getApplicableUnsortedSimpleTransitions = (
  statusCode: WorksheetStatusCode,
  metadata: Metadata,
) =>
  orderBy(
    unsortedWorksheetSimpleTransitions.filter(
      (simpleTransition: UnsortedWorksheetSimpleTransition) => {
        const transitionStatuses = assertNotNull(
          metadata.worksheetTransitions[simpleTransition.transitionName].statusesByWorksheetType[
            'Unsorted' // tslint:disable-line no-string-literal
          ],
        );
        return includes(transitionStatuses.fromStatuses, statusCode);
      },
    ),
    simpleTransition => simpleTransition.displayOrder,
  );
