import { DateTimeStamp } from '../../models/dateTimeStamp';
import { Id } from '../../models/id';
import { hasFileExtension, maxFileSizeInMb } from '../../utils/validation/fileValidators';
import { maxLength } from '../../utils/validation/stringValidators';
import { combineValidators, createValidator, Validator } from '../../utils/validation/validation';

export type AttachmentFormModel = {
  attachmentId: Id | null;
  fileId: Id;
  fileName: string;
  sizeInBytes: number;
  notes: string;
  isDeleted: boolean;
} & (
  | { attachmentId: Id; uploadedByUserName: string; uploadedDateTime: DateTimeStamp }
  | { attachmentId: null });

export const validAttachmentFileExtensions = [
  'csv',
  'dh',
  'doc',
  'docx',
  'gif',
  'html',
  'jpeg',
  'jpg',
  'osj',
  'pdf',
  'png',
  'psv',
  'sm',
  'smbag',
  'smmmm',
  'tab',
  'txt',
  'xls',
  'xlsx',
  'xml',
  'xml',
  'zip',
];

export const attachmentFileValidator: Validator<File> = combineValidators(
  maxFileSizeInMb(1000),
  hasFileExtension(validAttachmentFileExtensions),
);

export const attachmentValidator = createValidator<AttachmentFormModel>({
  notes: maxLength(4000),
});

export type FileUploadResponse = {
  fileId: Id;
};

export const formatFileSize = (sizeInBytes: number): string => {
  if (sizeInBytes < 1024) {
    return `${sizeInBytes} bytes`;
  } else if (sizeInBytes < 1024 * 1024) {
    const sizeInKb = (sizeInBytes / 1024).toFixed(0);
    return `${sizeInKb} KB`;
  } else {
    const sizeInMb = (sizeInBytes / 1024 / 1024).toFixed(0);
    return `${sizeInMb} MB`;
  }
};
