import styled, { css } from 'styled-components/macro';
import { narrow } from '../styling/spacing';

type IconSize = 'font' | 'smaller' | 'small' | 'medium' | 'large';

const iconSizeValues: { [size in IconSize]: string } = {
  font: '1em',
  smaller: '16px',
  small: '20px',
  medium: '30px',
  large: '70px',
};

type IconProps = {
  colour?: string;
  opacity?: string;
  size?: IconSize;
  centred?: boolean;
};

export const Icon = styled.div<IconProps>`
  height: ${props => iconSizeValues[props.size || 'font']};
  width: ${props => iconSizeValues[props.size || 'font']};

  svg {
    height: 100%;
    width: 100%;
    color: ${props => props.colour};
    opacity: ${props => props.opacity};
  }

  ${props =>
    props.centred &&
    css`
      margin-left: auto;
      margin-right: auto;
    `};
`;

export const LeftInlineIcon = styled(Icon)`
  margin-right: ${narrow};
`;

export const RightInlineIcon = styled(Icon)`
  margin-left: ${narrow};
`;
