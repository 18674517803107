export type ClientPortalJobStatus =
  | 'Forecast'
  | 'Submitted'
  | 'InProgress'
  | 'Collected'
  | 'Completed'
  | 'Cancelled';

export const clientPortalJobStatusDisplayNames: {
  [status in ClientPortalJobStatus]: string;
} = {
  Forecast: 'Forecast',
  Submitted: 'Submitted',
  InProgress: 'In progress',
  Collected: 'Collected',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
};
