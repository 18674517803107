import { assertIsNumber } from '../../../../utils/assertIsNumber';
import { assertNotNull } from '../../../../utils/assertNotNull';
import {
  UnsortedCellFormat,
  UnsortedCellProcessingType,
} from '../../../worksheets/unsorted/cells/unsortedCell';
import { MailingBriefUnsortedCellFormModel } from './mailingBriefUnsortedCellFormModel';

export type MailingBriefCreateUpdateUnsortedCellDto = {
  unsortedCellId: number | null;
  displayOrder: number;
  cellReference: string;
  format: UnsortedCellFormat;
  processingType: UnsortedCellProcessingType;
  quantity: number;
  itemWeightInGrams: number;
  isPpiPrinted: boolean;
  isReturnAddressPrinting: boolean;
};

export const toMailingBriefCreateUpdateUnsortedCellDto = (
  formModel: MailingBriefUnsortedCellFormModel,
  displayOrder: number,
): MailingBriefCreateUpdateUnsortedCellDto => ({
  unsortedCellId: formModel.unsortedCellId,
  displayOrder,
  cellReference: formModel.cellReference,
  format: assertNotNull(formModel.format),
  processingType: assertNotNull(formModel.processingType),
  quantity: assertIsNumber(formModel.quantity),
  itemWeightInGrams: assertIsNumber(formModel.itemWeightInGrams),
  isPpiPrinted: formModel.isPpiPrinted,
  isReturnAddressPrinting: formModel.isReturnAddressPrinting,
});
