import styled, { css } from 'styled-components/macro';
import { Table, Td, Th, Tr } from '../../../shared/table/Table';
import { fontGrey } from '../../../styling/colours';
import { bold } from '../../../styling/fonts';
import { narrow, wide } from '../../../styling/spacing';

export const SubTotalsRow = styled(Tr)`
  font-weight: ${bold};
  border-top: solid 2px ${fontGrey};
`;

export const TdFormField = styled(Td)``;

export const ItemCostTableHeader = styled(Th)<{ wide?: boolean }>`
  width: ${props => (props.wide ? '200px' : '140px')};
  text-align: right;
`;

export const QuantityTableHeader = styled(Th)<{ wide?: boolean }>`
  width: ${props => (props.wide ? '200px' : '140px')};
  text-align: right;
`;

export const PriceTableHeader = styled(Th)<{ wide?: boolean }>`
  width: ${props => (props.wide ? '200px' : '140px')};
  text-align: right;
`;

export const CellReferenceTableHeader = styled(Th)`
  width: 150px;
`;

export const WorksheetPricesTable = styled(Table)<{ fullWidth: boolean; fixed: boolean }>`
  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `};
  ${props =>
    props.fixed &&
    css`
      table-layout: fixed;
    `};
  margin-bottom: ${wide};
  min-width: 1000px;

  ${TdFormField} {
    padding: ${narrow} ${narrow};
    vertical-align: top;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;
