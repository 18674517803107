import * as React from 'react';
import styled from 'styled-components/macro';
import { white } from '../styling/colours';
import { loadingIndicationDelay, regularTransitionDuration } from '../styling/transitions';
import { LoadingSpinner } from './LoadingSpinner';

type Props = {
  isLoading: boolean;
  children: React.ReactNode;
};

export const LoadingOverlay = (props: Props) => (
  <LoadingOverlayContainer isLoading={props.isLoading}>
    {props.isLoading && <OverlaidLoadingSpinner />}
    {props.children}
  </LoadingOverlayContainer>
);

type LoadingOverlayContainerProps = {
  isLoading: boolean;
};

const LoadingOverlayContainer = styled.div<LoadingOverlayContainerProps>`
  position: relative;

  :after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${props => (props.isLoading ? 0.6 : 0)};
    transition: opacity ${regularTransitionDuration} ease;
    transition-delay: ${loadingIndicationDelay};
    pointer-events: ${props => (props.isLoading ? 'auto' : 'none')};
    cursor: ${props => (props.isLoading ? 'progress' : 'auto')};
    background-color: ${white};
  }
`;

const OverlaidLoadingSpinner = styled(LoadingSpinner)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10;
`;
