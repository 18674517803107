import * as React from 'react';
import styled from 'styled-components/macro';
import { wider } from '../styling/spacing';
import { LoadingSpinner } from './LoadingSpinner';

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: ${wider};
`;

export const loadingIndicatorTestId = 'loading-indicator';

export const LoadingIndicator = () => (
  <LoadingContainer data-testid={loadingIndicatorTestId}>
    <LoadingSpinner />
  </LoadingContainer>
);
