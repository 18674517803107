import { find, some } from 'lodash';
import { DateStamp, getDaysIntoTheFutureAsDateStamp } from '../../../../models/dateStamp';
import { Id } from '../../../../models/id';
import { assertNotNull } from '../../../../utils/assertNotNull';
import {
  isAWorkingDay,
  onOrAfter,
  requiredDate,
} from '../../../../utils/validation/dateValidators';
import {
  combineValidators,
  createArrayValidator,
  createValidator,
  createValidatorWithTopLevelValidator,
  Validator,
} from '../../../../utils/validation/validation';
import { noValidate, required } from '../../../../utils/validation/validators';
import { securedMailPostageSupplierCode, SupplierForJobForm } from '../../../jobs/dataForJobForm';
import { SortedCellPresentation } from '../../../worksheets/sorted/cells/sortedCell';
import { UnsortedCellValidationMetadata } from '../../../worksheets/unsorted/cells/unsortedCell';
import { UnsortedWorksheetFormModel } from '../../../worksheets/unsorted/edit-form/unsortedWorksheetFormModel';
import { UnsortedWorksheetMailingHouseFormModel } from '../../../worksheets/unsorted/edit-form/unsortedWorksheetMailingHouseFormModel';
import { getUnsortedWorksheetValidator } from '../../../worksheets/unsorted/edit-form/unsortedWorksheetValidator';
import {
  UnsortedCollectionPresentation,
  unsortedCollectionPresentationDisplayNames,
} from '../../../worksheets/unsorted/unsortedWorksheet';
import { ClientPortalMetadata } from '../../clientPortalMetadata';
import {
  getEarliestCollectionDateForMailingBriefJob,
  hasWorksheetRequiringCustomerContactsAndMailingHouse,
  MailingBriefFormModel,
} from '../mailingBriefFormModel';
import {
  MailingBriefMailingHouseContactFormModel,
  MailingBriefMailingHouseFormModel,
} from '../mailingBriefMailingHouseFormModel';
import {
  getInitialMailingBriefUnsortedCellFormModel,
  MailingBriefUnsortedCellFormModel,
  mailingBriefUnsortedCellValidator,
} from './mailingBriefUnsortedCellFormModel';

export type MailingBriefUnsortedWorksheetFormModel = UnsortedWorksheetFormModel & {
  collectionSchedule: 'AdHoc';
  collectionType: 'ExternalTransport'; // Collection defaults to "External Transport" via "SEC012"
  processingDateOption: 'NextDay';
  processingCentre: 'Warrington';
  cells: Array<MailingBriefUnsortedCellFormModel>;
  mailingHouse: MailingBriefMailingHouseFormModel;
  collectionDate: DateStamp | null;
};

export const getInitialMailingBriefUnsortedWorksheetFormModel = (
  suppliers: Array<SupplierForJobForm>,
  jobLevelMailingHouse?: MailingBriefMailingHouseFormModel | null,
  jobLevelCollectionDate?: DateStamp | null,
): MailingBriefUnsortedWorksheetFormModel => ({
  type: 'Unsorted',
  worksheetId: null,
  unsortedWorksheetId: null,
  worksheetReference: null,
  isNew: true,
  isOnCreditHold: false, // Set on back-end based on corresponding flag on the Customer
  estimatedValueInPounds: '0',
  isAgencyAgreement: false,
  collectionSchedule: 'AdHoc',
  invoiceSchedule: null,
  collectFromCustomer: false, // TODO: MCP-4
  firstCollectionDate: jobLevelCollectionDate ? jobLevelCollectionDate : null,
  lastCollectionDate: jobLevelCollectionDate ? jobLevelCollectionDate : null,
  collectionDate: jobLevelCollectionDate ? jobLevelCollectionDate : null,
  collectionPresentation: null,
  collectionType: 'ExternalTransport',
  // We default to "External Transport" via the "SECUREDP - Secured Mail Postage" Supplier
  externalTransportSupplierId: assertNotNull(
    find(suppliers, s => s.code === securedMailPostageSupplierCode),
  ).supplierId,
  processingDateOption: 'NextDay',
  processingCentre: 'Warrington',
  notes: '',
  customCollectionEmailContent: '',
  mailingHouse: jobLevelMailingHouse
    ? {
        mailingHouseId: jobLevelMailingHouse.mailingHouseId,
        contacts: jobLevelMailingHouse.contacts.map(contact => ({
          ...contact,
          includeOnCommunications: false,
        })),
      }
    : { mailingHouseId: null, contacts: [] },
  customerContacts: [],
  cells: [getInitialMailingBriefUnsortedCellFormModel()],
  attachments: { Unsorted: [] },
});

export const mailingBriefUnsortedWorksheetValidator = (
  formModel: MailingBriefFormModel,
  unsortedCellValidation: UnsortedCellValidationMetadata,
  metadata: ClientPortalMetadata,
  isForecast: boolean,
  isConvertingForecastJobToLive: boolean,
): Validator<MailingBriefUnsortedWorksheetFormModel | null> => {
  if (!formModel.unsortedWorksheet) {
    return noValidate;
  }

  // This field isn't relevant for the Mailing Brief, but the standard Unsorted Worksheet Validator
  // for the main MATRIX application requires it, so we just pass it through as false.
  const customer = { hasAgencyAgreementWithSecuredMail: false };

  const standardValidator = getUnsortedWorksheetValidator(
    assertNotNull(formModel.unsortedWorksheet),
    customer,
    unsortedCellValidation,
    false,
  ) as Validator<MailingBriefUnsortedWorksheetFormModel | null>;

  const earliestCollectionDate = getEarliestCollectionDateForMailingBriefJob(metadata.bankHolidays);

  const mailingBriefValidator = createValidator<MailingBriefUnsortedWorksheetFormModel>({
    cells: createArrayValidator(cell =>
      mailingBriefUnsortedCellValidator(
        cell,
        assertNotNull(formModel.unsortedWorksheet),
        unsortedCellValidation,
      ),
    ),
    mailingHouse:
      isForecast && !isConvertingForecastJobToLive
        ? unsortedWorksheetMailingHouseValidator
        : unsortedWorksheetMailingHouseWithContactsValidator(
            formModel,
            isConvertingForecastJobToLive,
          ),
    collectionDate: combineValidators(
      requiredDate('Please select a collection date'),
      onOrAfter(earliestCollectionDate),
      isAWorkingDay(metadata.bankHolidays),
    ),
  }) as Validator<MailingBriefUnsortedWorksheetFormModel | null>;

  return combineValidators(mailingBriefValidator, standardValidator);
};

const unsortedWorksheetMailingHouseValidator = createValidator<
  UnsortedWorksheetMailingHouseFormModel & MailingBriefMailingHouseFormModel
>({
  mailingHouseId: required<Id>(),
});

const unsortedWorksheetMailingHouseWithContactsValidator = (
  mailingBriefFormModel: MailingBriefFormModel,
  isConvertingForecastJobToLive: boolean,
) => {
  if (!hasWorksheetRequiringCustomerContactsAndMailingHouse(mailingBriefFormModel)) {
    return noValidate;
  }

  return createValidatorWithTopLevelValidator<
    UnsortedWorksheetMailingHouseFormModel & MailingBriefMailingHouseFormModel
  >(
    {
      mailingHouseId: required<Id>(),
    },
    ({ contacts }) => {
      if (contacts.length === 0) {
        return null;
      }

      if (
        mailingBriefFormModel.forecastJobOption === 'Forecast' &&
        !isConvertingForecastJobToLive
      ) {
        return null;
      }

      const unsortedContactSelected = some(
        contacts,
        (contact: MailingBriefMailingHouseContactFormModel) =>
          contact.includeOnUnsortedCommunication,
      );

      if (mailingBriefFormModel.unsortedWorksheet) {
        if (!unsortedContactSelected) {
          return 'At least one contact must be selected for unsorted communication';
        }
      }

      return null;
    },
  );
};

const unsortedCollectionPresentationBySortedCellPresentation: {
  [sortedCellPresentation in SortedCellPresentation]: UnsortedCollectionPresentation;
} = {
  Bag: 'Bags',
  Bundle: 'Bags', // TODO MCP-6, MCP-7: Confirm what "Bundle" should map to
  Tray: 'Trays',
  Pallets: 'Pallets',
};

const defaultPresentation: UnsortedCollectionPresentation = 'Trays';

export const getCollectionPresentationForMailingBriefUnsortedWorksheet = (
  collectionPresentationOfFirstSortedCell: SortedCellPresentation | null,
): UnsortedCollectionPresentation => {
  if (collectionPresentationOfFirstSortedCell != null) {
    return unsortedCollectionPresentationBySortedCellPresentation[
      collectionPresentationOfFirstSortedCell
    ];
  }

  return defaultPresentation;
};

export const getMailingBriefUnsortedWorksheetWarnings = (
  mailingBriefFormModel: MailingBriefFormModel,
): Array<string> => {
  const warnings: Array<string> = [];

  if (
    mailingBriefFormModel.unsortedWorksheet &&
    mailingBriefFormModel.unsortedWorksheet.cells.filter(
      // TODO MCP-37: Check this logic with ONEPOST
      c => c.isPpiPrinted || c.isReturnAddressPrinting,
    ).length > 0
  ) {
    warnings.push(
      'All unsorted mailings must contain a valid postal Indicia and also a return address, both of which must be laid out to comply with Royal Mail requirements.\n' +
        'Where you require these to be printed on your mail by TDG  an additional charge will apply as per your issued rate card.',
    );
  }

  if (mailingBriefFormModel.sortedWorksheets.length === 0) {
    warnings.push(
      `We are assuming you will deliver into us in ${unsortedCollectionPresentationDisplayNames[defaultPresentation]}. Please leave a note if you will be presenting in a different format.`,
    );
  }

  return warnings;
};
