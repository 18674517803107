import { connect, FormikContext, FormikProps } from 'formik';
import { useEffect } from 'react';
import * as React from 'react';
import styled from 'styled-components/macro';
import { WarningBox } from '../../../../shared/WarningBox';
import { assertNotNull } from '../../../../utils/assertNotNull';
import { UnsortedWorksheetCustomerContactFormModel } from '../../../worksheets/unsorted/edit-form/unsortedWorksheetCustomerContactFormModel';
import { CollectionDate } from '../collectionDate/CollectionDate';
import { MailingHouseForMailingBriefForm } from '../dataForMailingBriefForm';
import { useDataForMailingBriefForm } from '../DataForMailingBriefFormContext';
import { MailingBriefCustomerContactFormModel } from '../mailingBriefCustomerContactFormModel';
import { MailingBriefFormModel } from '../mailingBriefFormModel';
import { MailingBriefMailingHouse } from '../mailingHouse/MailingBriefMailingHouse';
import { MailingBriefUnsortedCellsFieldArray } from './MailingBriefUnsortedCellsFieldArray';
import {
  getCollectionPresentationForMailingBriefUnsortedWorksheet,
  getMailingBriefUnsortedWorksheetWarnings,
  MailingBriefUnsortedWorksheetFormModel,
} from './mailingBriefUnsortedWorksheetFormModel';

export type OwnProps = {
  isSplitJob: boolean;
  mailingHouseResponses: { [mailingHouseId: number]: MailingHouseForMailingBriefForm };
  isConvertingForecastJobToLive: boolean;
  formikProps: FormikProps<MailingBriefFormModel>;
};

type Props = OwnProps & {
  formik: FormikContext<MailingBriefFormModel>;
};

const MailingBriefUnsortedWorksheetFormFieldsComponent = (props: Props) => {
  const formikContext = props.formik;
  const unsortedWorksheet = assertNotNull(formikContext.values.unsortedWorksheet);
  const mailingHouseId = unsortedWorksheet.mailingHouse.mailingHouseId;

  useSyncCustomerContactsFromJob(formikContext);
  useSetAgencyAgreementFlagFromCustomer(formikContext);
  useSetCollectionPresentationToCalculatedValue(formikContext, unsortedWorksheet);

  return (
    <div data-testid={mailingBriefUnsortedWorksheetFormFieldsTestId}>
      {props.isSplitJob && (
        <>
          <CollectionDate
            formikProps={props.formikProps}
            worksheetDependentFieldNamePrefix="unsortedWorksheet"
          />
          <MailingBriefMailingHouse
            formikProps={props.formikProps}
            mailingHouseResponses={props.mailingHouseResponses}
            isConvertingForecastJobToLive={props.isConvertingForecastJobToLive}
            worksheetDependentFieldNamePrefix="unsortedWorksheet"
            mailingHouseId={mailingHouseId}
          />
        </>
      )}

      <MailingBriefUnsortedCellsFieldArray />
      {getMailingBriefUnsortedWorksheetWarnings(formikContext.values).map(warning => (
        <UnsortedWarningBox key={warning} message={warning} />
      ))}
    </div>
  );
};

export const mailingBriefUnsortedWorksheetFormFieldsTestId =
  'mailing-brief-unsorted-worksheet-form-fields';

const useSetAgencyAgreementFlagFromCustomer = (
  formikContext: FormikContext<MailingBriefFormModel>,
) => {
  const { values: formModel, setFieldValue } = formikContext;
  const dataForMailingBriefForm = useDataForMailingBriefForm();

  const selectedCustomerId = formModel.customerId;

  const selectedCustomer =
    selectedCustomerId == null
      ? null
      : assertNotNull(dataForMailingBriefForm.customersByCustomerId[selectedCustomerId]);

  const hasAgencyAgreementWithSecuredMail =
    selectedCustomer == null ? false : selectedCustomer.hasAgencyAgreementWithSecuredMail;

  useEffect(() => {
    // Note that we are assuming the Job will be using Secured Mail, since this is hard-coded into
    // the logic when creating the Worksheet.
    setFieldValue('sortedWorksheet.isAgencyAgreement', hasAgencyAgreementWithSecuredMail);
  }, [hasAgencyAgreementWithSecuredMail]);
};

const useSetCollectionPresentationToCalculatedValue = (
  formikContext: FormikContext<MailingBriefFormModel>,
  unsortedWorksheet: MailingBriefUnsortedWorksheetFormModel,
) => {
  const { setFieldValue } = formikContext;

  // TODO MCP-6: Populate this correctly once the Sorted element has been implemented
  const collectionPresentationOfFirstSortedCell = null;

  const calculatedCollectionPresentation = getCollectionPresentationForMailingBriefUnsortedWorksheet(
    collectionPresentationOfFirstSortedCell,
  );

  const currentCollectionPresentation = unsortedWorksheet.collectionPresentation;

  useEffect(() => {
    if (currentCollectionPresentation !== calculatedCollectionPresentation) {
      setFieldValue('unsortedWorksheet.collectionPresentation', calculatedCollectionPresentation);
    }
  }, [calculatedCollectionPresentation, currentCollectionPresentation]);
};

// The form model for the Unsorted Worksheet requires a Customer Contact to be specified.
// These actually get set at a higher level in the form model, so we have to sync them down from the
// top-level details into the Unsorted Worksheet details.
const useSyncCustomerContactsFromJob = (formikContext: FormikContext<MailingBriefFormModel>) => {
  const { values: formModel, setFieldValue } = formikContext;

  const jobLevelCustomerContacts = formModel.customerContacts;

  // We generate a string that will change whenever the selected Contacts change, so that we only
  // fire the effect below when we need to (if we used an array it would change every render).
  const jobLevelCustomerContactIdsSelectedForUnsortedCommunications = jobLevelCustomerContacts
    .filter(c => c.includeOnUnsortedCommunication)
    .map(c => c.customerContactId.toString())
    .join(',');

  useEffect(() => {
    setFieldValue(
      `unsortedWorksheet.customerContacts`,
      jobLevelCustomerContacts.map(mapFromJobLevelCustomerContact),
    );
  }, [jobLevelCustomerContactIdsSelectedForUnsortedCommunications]);
};

const mapFromJobLevelCustomerContact = (
  jobLevelCustomerContact: MailingBriefCustomerContactFormModel,
): UnsortedWorksheetCustomerContactFormModel => ({
  customerContactId: jobLevelCustomerContact.customerContactId,
  includeOnCommunications: jobLevelCustomerContact.includeOnUnsortedCommunication,
});

export const MailingBriefUnsortedWorksheetFormFields = connect<OwnProps, MailingBriefFormModel>(
  MailingBriefUnsortedWorksheetFormFieldsComponent,
);

const UnsortedWarningBox = styled(WarningBox)`
  white-space: pre-line;
`;
