export type MailingBriefContactBaseFormModel = {
  includeOnCarbonCopyCommunication: boolean;
  includeOnDataCommunication: boolean;
  includeOnLabelsCommunication: boolean;
  includeOnCollectionsCommunication: boolean;
  includeOnConsumablesCommunication: boolean;
  includeOnUnsortedCommunication: boolean;
  includeOnInternationalCommunication: boolean;
};

export const includedOnSortedJob = (contact: MailingBriefContactBaseFormModel) => {
  return (
    contact.includeOnCarbonCopyCommunication ||
    contact.includeOnDataCommunication ||
    contact.includeOnLabelsCommunication ||
    contact.includeOnCollectionsCommunication ||
    contact.includeOnConsumablesCommunication
  );
};
