import { Id } from '../../models/id';

export const clientPortalUrl = '/portal';
export const clientPortalJobsUrl = `${clientPortalUrl}/jobs`;
export const clientPortalJobUrl = (jobId: Id) => `${clientPortalJobsUrl}/${jobId}`;
export const clientPortalLoggedOutUrl = `${clientPortalUrl}/logged-out`;
export const clientPortalSigninRedirectUrl = `${clientPortalUrl}/signin-redirect`;
export const clientPortalSilentTokenRefreshUrl = `${clientPortalUrl}/token-refresh`;
export const clientPortalChangePasswordUrl = `${clientPortalUrl}/change-password`;

export const clientPortalMailingBriefUrl = `${clientPortalUrl}/mailing-brief`;
export const clientPortalMailingBriefJobUrl = (jobId: Id) =>
  `${clientPortalMailingBriefUrl}/${jobId}`;

export const clientPortalConvertForecastJobToLiveUrl = (jobId: Id) =>
  `${clientPortalUrl}/convert-forecast-job-to-live/${jobId}`;

// export const clientPortalDataUploadUrl = `${clientPortalUrl}/data-upload`;
