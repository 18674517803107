import * as React from 'react';
import { ContactCardEmailAddress } from '../../shared/contact-card/ContactCardEmailAddress';
import { ContactCardHeader } from '../../shared/contact-card/ContactCardHeader';
import { ContactCardPhoneNumbers } from '../../shared/contact-card/ContactCardPhoneNumbers';
import { ClientPortalWorksheetContact } from './worksheets/ClientPortalWorksheet';

type Props = {
  contact: ClientPortalWorksheetContact;
};

export const ClientPortalWorksheetContactDetails = ({ contact }: Props) => (
  <>
    <ContactCardHeader name={contact.name} jobTitle={contact.jobTitle} />
    <ContactCardPhoneNumbers landline={contact.landline} mobile={contact.mobile} />
    <ContactCardEmailAddress email={contact.email} />
  </>
);
