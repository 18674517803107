import { FormikProps } from 'formik';
import * as React from 'react';
import { DateField } from '../../../../shared/form/inputs/DateField';
import { assertNotNull } from '../../../../utils/assertNotNull';
import { StyledWarningCallout } from '../../../worksheets/WorksheetWarnings';
import { useClientPortalMetadata } from '../../clientPortalMetadata';
import {
  getEarliestCollectionDateForMailingBriefJob,
  MailingBriefFormModel,
} from '../mailingBriefFormModel';

export const CollectionDate = (
  props: {
    formikProps: FormikProps<MailingBriefFormModel>;
  } & (
    | {
        worksheetDependentFieldNamePrefix: 'sortedWorksheets';
        sortedWorksheetIndex: number;
      }
    | {
        worksheetDependentFieldNamePrefix:
          | 'unsortedWorksheet'
          | 'internationalWorksheet'
          | 'dataCleansingWorksheet';
      }),
) => {
  const { values: formValues } = props.formikProps;
  const { bankHolidays } = useClientPortalMetadata();

  const label =
    props.worksheetDependentFieldNamePrefix === 'dataCleansingWorksheet'
      ? 'Processing date'
      : 'Collection date';

  const earliestCollectionDate = getEarliestCollectionDateForMailingBriefJob(bankHolidays);

  const collectionDate =
    props.worksheetDependentFieldNamePrefix === 'sortedWorksheets'
      ? formValues.sortedWorksheets[assertNotNull(props.sortedWorksheetIndex)].collectionDate
      : props.worksheetDependentFieldNamePrefix === 'unsortedWorksheet'
      ? assertNotNull(formValues.unsortedWorksheet).collectionDate
      : props.worksheetDependentFieldNamePrefix === 'internationalWorksheet'
      ? assertNotNull(formValues.internationalWorksheet).collectionDate
      : assertNotNull(formValues.dataCleansingWorksheet).processingDate;

  const collectionDateFieldName =
    props.worksheetDependentFieldNamePrefix === 'sortedWorksheets'
      ? `sortedWorksheets[${props.sortedWorksheetIndex}].collectionDate`
      : props.worksheetDependentFieldNamePrefix === 'dataCleansingWorksheet'
      ? `${props.worksheetDependentFieldNamePrefix}.processingDate`
      : `${props.worksheetDependentFieldNamePrefix}.collectionDate`;

  const showWarning = collectionDate != null && collectionDate === earliestCollectionDate;

  return (
    <>
      <DateField
        label={label}
        name={collectionDateFieldName}
        minDate={earliestCollectionDate}
        excludeNonWorkingDays={true}
      />
      {showWarning && (
        <StyledWarningCallout>
          If an earlier collection is required, please contact your account manager after job
          submission.
        </StyledWarningCallout>
      )}
    </>
  );
};
