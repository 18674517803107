import { flatMap, some, values } from 'lodash';
import { DateStamp } from '../../../../models/dateStamp';
import { Id } from '../../../../models/id';
import { WorksheetStatusCode } from '../../../../models/metadata';
import { AttachmentFormModel } from '../../../../shared/files/attachment';
import { assertIsNumber } from '../../../../utils/assertIsNumber';
import { assertNotNull } from '../../../../utils/assertNotNull';
import {
  inTheFuture,
  requiredDate,
  todayOrLater,
} from '../../../../utils/validation/dateValidators';
import { nonNegativeInteger } from '../../../../utils/validation/numberValidators';
import { maxLength, notEmpty } from '../../../../utils/validation/stringValidators';
import {
  combineValidators,
  createArrayValidator,
  createValidator,
} from '../../../../utils/validation/validation';
import { noValidate } from '../../../../utils/validation/validators';
import { Customer } from '../../../customers/customer';
import { CustomerContact } from '../../../customers/customerContact';
import {
  MailingHouseContactForJobForm,
  MailingHouseForJobForm,
} from '../../../jobs/dataForJobForm';
import { mergeExistingContactsWithActualContacts } from '../../edit-form/worksheetFormModel';
import {
  InternationalWorksheetAttachmentCategory,
  toAttachmentFormModel,
  toCreateUpdateWorksheetAttachment,
  WorksheetAttachmentCategory,
} from '../../worksheetAttachment';
import {
  fromInternationalCell,
  InternationalCellFormModel,
  internationalCellValidator,
  toCreateUpdateInternationalCellDto,
} from '../cells/internationalCellFormModel';
import {
  InternationalWorksheet,
  InternationalWorksheetCarrier,
  InternationalWorksheetCollectionByOption,
  InternationalWorksheetCustomerContact,
  InternationalWorksheetMailingHouseContact,
} from '../internationalWorksheet';
import { CreateUpdateInternationalWorksheetDto } from './createUpdateInternationalWorksheetDto';
import {
  fromCustomerContact,
  fromInternationalWorksheetCustomerContact,
  InternationalWorksheetCustomerContactFormModel,
  toCreateUpdateInternationalWorksheetCustomerContactDto,
} from './internationalWorksheetCustomerContactFormModel';
import {
  fromInternationalWorksheetMailingHouseContact,
  fromMailingHouseContactForInternationalWorksheetForm,
  InternationalWorksheetMailingHouseContactFormModel,
  InternationalWorksheetMailingHouseFormModel,
  internationalWorksheetMailingHouseValidator,
  toCreateUpdateInternationalWorksheetMailingHouseContactDto,
} from './internationalWorksheetMailingHouseFormModel';

export type InternationalWorksheetFormModel = {
  type: 'International';
  statusCode: WorksheetStatusCode;
  worksheetId: Id | null;
  internationalWorksheetId: Id | null;
  worksheetReference: number | null;
  isNew: boolean;
  isOnCreditHold: boolean;
  isUrgent: boolean;
  collectionBy: InternationalWorksheetCollectionByOption | null;
  carrier: InternationalWorksheetCarrier | null;
  estimatedValueInPounds: string;
  collectionDate: DateStamp | null;
  assigneeUserId: string | null;
  mailingHouse: InternationalWorksheetMailingHouseFormModel;
  customerContacts: Array<InternationalWorksheetCustomerContactFormModel>;
  notes: string;
  customCollectionEmailContent: string;
  labelsPrepared: boolean;
  labelsSentDate: DateStamp | null;
  manifestPrepared: boolean;
  manifestSentDate: DateStamp | null;
  cells: Array<InternationalCellFormModel>;
  attachments: {
    [category in InternationalWorksheetAttachmentCategory]: Array<AttachmentFormModel>;
  };
};

export const toCreateUpdateInternationalWorksheetDto = (
  formModel: InternationalWorksheetFormModel,
  indexAmongNewWorksheets: number | null,
): CreateUpdateInternationalWorksheetDto => ({
  internationalWorksheetId: formModel.internationalWorksheetId,
  indexAmongNewWorksheets,
  isUrgent: formModel.isUrgent,
  estimatedValueInPounds: assertIsNumber(formModel.estimatedValueInPounds),
  collectionDate: assertNotNull(formModel.collectionDate),
  collectionBy: formModel.collectionBy,
  carrier: formModel.carrier,
  assigneeUserId: formModel.assigneeUserId,
  mailingHouseId: assertNotNull(formModel.mailingHouse.mailingHouseId),
  mailingHouseContacts: formModel.mailingHouse.contacts.map(
    toCreateUpdateInternationalWorksheetMailingHouseContactDto,
  ),
  customerContacts: formModel.customerContacts.map(
    toCreateUpdateInternationalWorksheetCustomerContactDto,
  ),
  notes: formModel.notes.trim() === '' ? null : formModel.notes,
  customCollectionEmailContent:
    formModel.customCollectionEmailContent.trim() === ''
      ? null
      : formModel.customCollectionEmailContent,
  labelsPrepared: formModel.labelsPrepared,
  labelsSentDate: formModel.labelsSentDate,
  manifestPrepared: formModel.manifestPrepared,
  manifestSentDate: formModel.manifestSentDate,
  cells: formModel.cells.map(toCreateUpdateInternationalCellDto),
  attachments: flatMap(formModel.attachments, (attachments, category) =>
    attachments
      .filter(attachment => !attachment.isDeleted)
      .map(attachment =>
        toCreateUpdateWorksheetAttachment(attachment, category as WorksheetAttachmentCategory),
      ),
  ),
});

export const fromInternationalWorksheet = (
  internationalWorksheet: InternationalWorksheet,
  mailingHouses: { [mailingHouseId: number]: MailingHouseForJobForm },
  customer: Customer,
): InternationalWorksheetFormModel => {
  const mailingHouseContacts = values(
    mailingHouses[internationalWorksheet.mailingHouse.mailingHouseId].contactsById,
  );

  const internationalWorksheetMailingHouseContacts = mergeExistingContactsWithActualContacts<
    MailingHouseContactForJobForm,
    InternationalWorksheetMailingHouseContact,
    InternationalWorksheetMailingHouseContactFormModel
  >(
    internationalWorksheet.mailingHouseContacts,
    mailingHouseContacts,
    c => c.mailingHouseContactId,
    fromInternationalWorksheetMailingHouseContact,
    fromMailingHouseContactForInternationalWorksheetForm,
    c => c.name,
  );

  const internationalWorksheetCustomerContacts = mergeExistingContactsWithActualContacts<
    CustomerContact,
    InternationalWorksheetCustomerContact,
    InternationalWorksheetCustomerContactFormModel
  >(
    internationalWorksheet.customerContacts,
    customer.contacts.filter(c => c.includeOnJobsCode !== 'Never'),
    c => c.customerContactId,
    fromInternationalWorksheetCustomerContact,
    fromCustomerContact,
    c => c.displayOrder,
  );

  return {
    type: 'International',
    statusCode: internationalWorksheet.statusCode,
    worksheetId: internationalWorksheet.worksheetId,
    internationalWorksheetId: internationalWorksheet.internationalWorksheetId,
    worksheetReference: internationalWorksheet.worksheetReference,
    isNew: false,
    isOnCreditHold: internationalWorksheet.isOnCreditHold,
    isUrgent: internationalWorksheet.isUrgent,
    collectionBy: internationalWorksheet.collectionBy,
    carrier: internationalWorksheet.carrier,
    estimatedValueInPounds: internationalWorksheet.estimatedValueInPounds.toString(),
    collectionDate: internationalWorksheet.collectionDate,
    assigneeUserId: internationalWorksheet.assigneeId,
    mailingHouse: {
      mailingHouseId: internationalWorksheet.mailingHouse.mailingHouseId,
      contacts: internationalWorksheetMailingHouseContacts,
    },
    customerContacts: internationalWorksheetCustomerContacts,
    notes: internationalWorksheet.notes || '',
    customCollectionEmailContent: internationalWorksheet.customCollectionEmailContent || '',
    labelsPrepared: internationalWorksheet.labelsPrepared,
    labelsSentDate: internationalWorksheet.labelsSentDate,
    manifestPrepared: internationalWorksheet.manifestPrepared,
    manifestSentDate: internationalWorksheet.manifestSentDate,
    cells: internationalWorksheet.cells.map(fromInternationalCell),
    attachments: {
      International: internationalWorksheet.attachments
        .filter(a => a.category === 'International')
        .map(toAttachmentFormModel),
    },
  };
};

export const getNewInternationalWorksheetFormModel = (
  internationalWorksheet: InternationalWorksheetFormModel,
): InternationalWorksheetFormModel => ({
  ...internationalWorksheet,

  internationalWorksheetId: null,
  statusCode: 'Draft',
  worksheetReference: null,
  isNew: true,
  isOnCreditHold: false,
  isUrgent: false,

  estimatedValueInPounds: '',
  collectionDate: null,
  labelsPrepared: false,
  labelsSentDate: null,
  manifestPrepared: false,
  manifestSentDate: null,

  cells: [],
  attachments: {
    International: [],
  },
});

export const getInternationalWorksheetValidator = (formModel: InternationalWorksheetFormModel) =>
  createValidator<InternationalWorksheetFormModel>({
    estimatedValueInPounds: combineValidators<string>(notEmpty(), nonNegativeInteger()),
    collectionDate: combineValidators<DateStamp | null>(
      requiredDate(),
      formModel.isNew
        ? formModel.isUrgent
          ? todayOrLater()
          : inTheFuture('Must be in the future. To set it to today, mark the worksheet as urgent')
        : noValidate,
    ),
    mailingHouse: internationalWorksheetMailingHouseValidator,
    customerContacts: customerContacts =>
      !some(customerContacts, c => c.includeOnCommunications)
        ? 'At least one customer contact must be selected'
        : null,
    notes: maxLength(2000),
    customCollectionEmailContent: maxLength(2000),
    cells: createArrayValidator(() => internationalCellValidator),
  });
