import { map } from 'lodash';
import * as React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as ChevronDownIcon } from '../../../images/icons/chevron-down-solid.svg';
import { fontGrey, white } from '../../../styling/colours';
import { medium, narrow, wide, wider } from '../../../styling/spacing';
import { memoize } from '../../../utils/memoize/memoize';
import { formInputStyling } from '../formInputStyling';

export const selectTestId = 'select';

export type SelectOption<TValue> = {
  value: TValue;
  text: string;
};

export type SelectOptions<TValue> = Array<SelectOption<TValue>>;

export const getSelectOptions = <TValue extends string>(
  values: Array<TValue>,
  valueToDisplayName?: { [value in TValue]: string },
): SelectOptions<TValue> =>
  values.map(value => ({
    value,
    text: valueToDisplayName ? valueToDisplayName[value] : value,
  }));

export type SelectOptionsByKey<TValue> = {
  [key: string]: SelectOption<TValue>;
};

export type ValueToKeyMapper<TValue> = (value: TValue) => string | number;

export const getSelectOptionsByKey = <TValue extends unknown>(
  options: SelectOptions<TValue>,
  getKeyFromValue: ValueToKeyMapper<TValue>,
): SelectOptionsByKey<TValue> => {
  const selectOptionsByKey: SelectOptionsByKey<TValue> = {};

  for (const selectOption of options) {
    const key = getKeyFromValue(selectOption.value);
    selectOptionsByKey[key] = selectOption;
  }

  return selectOptionsByKey;
};

export const defaultMapValueToKey = <TValue extends unknown>(value: TValue) => `${value}`;

export const isNonNullValueToKeyMapper = <TValue extends unknown>(
  mapValueToKey: ValueToKeyMapper<TValue> | undefined,
): mapValueToKey is ValueToKeyMapper<TValue> => mapValueToKey != null;

type SelectProps<TValue> = {
  options: SelectOptions<TValue>;
  value: TValue | null;
  name: string;
  onChange: (newValue: TValue | null) => void;
  onBlur?: (event: React.FocusEvent) => void;
  mapValueToKey?: ValueToKeyMapper<TValue>;
  disabled?: boolean;
  valid?: boolean;
  invalid?: boolean;
  showWarning?: boolean;
  inline?: boolean;
  excludeNullOption?: boolean;
  nullOptionText?: string;
};

export class Select<TValue> extends React.Component<SelectProps<TValue>> {
  render() {
    return (
      <SelectComponent
        {...this.props}
        selectChevronComponent={this.props.inline ? <InlineSelectChevron /> : <SelectChevron />}
      />
    );
  }
}

type SelectComponentProps<TValue> = SelectProps<TValue> & {
  selectChevronComponent: React.ReactNode;
};

class SelectComponent<TValue> extends React.Component<SelectComponentProps<TValue>> {
  // Does not re-run unless the inputs change
  getOptionsByKey = memoize(
    (options: SelectOptions<TValue>, getKeyFromValue: ValueToKeyMapper<TValue>) =>
      getSelectOptionsByKey(options, getKeyFromValue),
  );

  onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const key = event.target.value;
    const { onChange } = this.props;

    if (!key) {
      onChange(null);
    } else {
      const { options, mapValueToKey } = this.props;
      const optionsByKey = this.getOptionsByKey(
        options,
        isNonNullValueToKeyMapper<TValue>(mapValueToKey) ? mapValueToKey : defaultMapValueToKey,
      );
      const newValue = optionsByKey[key].value;
      onChange(newValue);
    }
  };

  render() {
    const {
      options,
      value,
      name,
      selectChevronComponent,
      disabled,
      valid,
      invalid,
      showWarning,
      inline,
      excludeNullOption,
      nullOptionText,
      onBlur,
    } = this.props;

    const mapValueToKey = isNonNullValueToKeyMapper<TValue>(this.props.mapValueToKey)
      ? this.props.mapValueToKey
      : defaultMapValueToKey;

    return (
      <SelectContainer disabled={disabled}>
        {selectChevronComponent}
        <SelectBox
          id={name}
          name={name}
          onChange={this.onChange}
          onBlur={onBlur}
          disabled={disabled}
          invalid={invalid}
          showWarning={showWarning}
          valid={valid}
          value={value == null ? '' : mapValueToKey(value)}
          inline={inline}
          data-testid={selectTestId}
        >
          {!excludeNullOption && <Option value="">{nullOptionText || '—'}</Option>}
          {map(options, option => {
            const key = mapValueToKey(option.value);
            return (
              <Option value={key} key={key}>
                {option.text}
              </Option>
            );
          })}
        </SelectBox>
      </SelectContainer>
    );
  }
}

const SelectContainer = styled.div<{ disabled?: boolean }>`
  position: relative;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const SelectChevron = styled(ChevronDownIcon)`
  position: absolute;
  width: 18px;
  height: 18px;
  top: ${medium};
  right: ${medium};
  color: ${fontGrey};
  padding: 2px;
  pointer-events: none;
`;

const InlineSelectChevron = styled(SelectChevron)`
  width: 15px;
  height: 15px;
  top: ${narrow};
  right: ${narrow};
  padding: 1px;
`;

type SelectBoxProps = {
  valid?: boolean;
  invalid?: boolean;
  showWarning?: boolean;
  inline?: boolean;
};

const SelectBox = styled.select<SelectBoxProps>`
  ${({ valid, invalid, showWarning, inline }) =>
    formInputStyling({ valid, invalid, showWarning, inline })};

  // Remove the default caret
  -webkit-appearance: none;
  -moz-appearance: none;

  cursor: pointer;
  padding-right: ${props => (props.inline ? wide : wider)};
`;

const Option = styled.option`
  background-color: ${white};
`;
