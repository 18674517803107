import { FieldArray, FormikProps } from 'formik';
import { keyBy } from 'lodash';
import { useMemo } from 'react';
import * as React from 'react';
import styled from 'styled-components/macro';
import { CheckboxesFieldItem } from '../../../../shared/form/inputs/CheckboxesField';
import { TopLevelValidationError } from '../../../../shared/form/TopLevelValidationError';
import { darkGrey } from '../../../../styling/colours';
import { medium, narrow } from '../../../../styling/spacing';
import { assertNotNull } from '../../../../utils/assertNotNull';
import { DataEmailAccessOptions } from '../../../mailing-houses/contacts/MailingHouseContactsFieldArray';
import { WorksheetCheckboxesField } from '../../../worksheets/WorksheetCheckboxesField';
import { WorksheetFieldSet } from '../../../worksheets/WorksheetFieldSet';
import { MailingHouseForMailingBriefForm } from '../dataForMailingBriefForm';
import { MailingBriefFormModel } from '../mailingBriefFormModel';

type Props = {
  mailingHouse: MailingHouseForMailingBriefForm;
  mailingBriefFormModel: MailingBriefFormModel;
  formikProps: FormikProps<MailingBriefFormModel>;
};

export const MailingBriefMailingHouseContactsFieldArray = (
  props: Props &
    (
      | {
          worksheetDependentFieldNamePrefix: 'sortedWorksheets';
          sortedWorksheetIndex: number;
        }
      | {
          worksheetDependentFieldNamePrefix: 'unsortedWorksheet' | 'internationalWorksheet';
        }),
) => {
  const { mailingHouse, mailingBriefFormModel, formikProps } = props;

  const mailingHouseContacts = useMemo(
    () => keyBy(Object.values(mailingHouse.contactsById), c => c.mailingHouseContactId),
    [mailingHouse],
  );

  const mailingHouseFieldContacts =
    props.worksheetDependentFieldNamePrefix === 'sortedWorksheets'
      ? mailingBriefFormModel.sortedWorksheets[props.sortedWorksheetIndex].mailingHouse.contacts
      : props.worksheetDependentFieldNamePrefix === 'unsortedWorksheet'
      ? mailingBriefFormModel.unsortedWorksheet
        ? mailingBriefFormModel.unsortedWorksheet.mailingHouse.contacts
        : []
      : mailingBriefFormModel.internationalWorksheet
      ? mailingBriefFormModel.internationalWorksheet.mailingHouse.contacts
      : [];

  const mailingHouseContactWorksheetDependentFieldNamePrefix =
    props.worksheetDependentFieldNamePrefix === 'sortedWorksheets'
      ? `sortedWorksheets[${props.sortedWorksheetIndex}]`
      : props.worksheetDependentFieldNamePrefix;

  return (
    <WorksheetFieldSet label="Mailing House Contacts">
      {mailingHouseFieldContacts.length > 0 ? (
        <FieldArray
          name="mailingHouse.contacts"
          render={() =>
            Object.values(mailingHouse.contactsById).map((mailingHouseContactResponse, index) => {
              const mailingHouseContactId = mailingHouseContactResponse.mailingHouseContactId;
              const mailingHouseContact = mailingHouseContacts[mailingHouseContactId];
              const { name, jobTitle } = mailingHouseContact;
              const mailingHouseContactFieldName = `${mailingHouseContactWorksheetDependentFieldNamePrefix}.mailingHouse.contacts.${index}`;

              return (
                <WorksheetCheckboxesField
                  key={mailingHouseContactId}
                  renderLabel={() => (
                    <ContactNameAndJobTitleContainer>
                      <ContactName>{name}</ContactName>
                      <ContactJobTitle>- {jobTitle}</ContactJobTitle>
                    </ContactNameAndJobTitleContainer>
                  )}
                  label={undefined}
                >
                  {props.worksheetDependentFieldNamePrefix === 'sortedWorksheets' && (
                    <>
                      <DataEmailAccessOptions>
                        <CheckboxesFieldItem
                          name={`${mailingHouseContactFieldName}.includeOnCarbonCopyCommunication`}
                          label="CC"
                          onChange={(newValue: boolean) => {
                            if (newValue) {
                              formikProps.setFieldValue(
                                `${mailingHouseContactFieldName}.includeOnDataCommunication`,
                                false,
                              );
                            }
                          }}
                          disabled={
                            mailingHouseContact.includeOnDataCommunication === 'LinkAndPassword' ||
                            (mailingHouseContact.includeOnDataCommunication === 'CarbonCopy' &&
                              assertNotNull(
                                mailingBriefFormModel.sortedWorksheets[
                                  props.sortedWorksheetIndex
                                ].mailingHouse.contacts.find(
                                  contact =>
                                    contact.mailingHouseContactId === mailingHouseContactId,
                                ),
                              ).includeOnCarbonCopyCommunication)
                          }
                        />
                        <CheckboxesFieldItem
                          name={`${mailingHouseContactFieldName}.includeOnDataCommunication`}
                          label="Data"
                          onChange={(newValue: boolean) => {
                            if (newValue) {
                              formikProps.setFieldValue(
                                `${mailingHouseContactFieldName}.includeOnCarbonCopyCommunication`,
                                false,
                              );
                            } else if (
                              // If the contact is set to 'Always' include on CarbonCopy communications, they can be upgraded
                              // to receive Data communications, but if Data is then unchecked, the CC checkbox must be
                              // re-checked to ensure they are still included on CarbonCopy communications
                              mailingHouseContact.includeOnDataCommunication === 'CarbonCopy'
                            ) {
                              formikProps.setFieldValue(
                                `${mailingHouseContactFieldName}.includeOnCarbonCopyCommunication`,
                                true,
                              );
                            }
                          }}
                          disabled={
                            mailingHouseContact.includeOnDataCommunication === 'LinkAndPassword'
                          }
                        />
                      </DataEmailAccessOptions>
                      <CheckboxesFieldItem
                        name={`${mailingHouseContactFieldName}.includeOnLabelsCommunication`}
                        label="Labels"
                        disabled={mailingHouseContact.includeOnLabelsCommunication}
                      />
                      <CheckboxesFieldItem
                        name={`${mailingHouseContactFieldName}.includeOnCollectionsCommunication`}
                        label="Collections"
                        disabled={mailingHouseContact.includeOnCollectionsCommunication}
                      />
                      <CheckboxesFieldItem
                        name={`${mailingHouseContactFieldName}.includeOnConsumablesCommunication`}
                        label="Consumables"
                        disabled={mailingHouseContact.includeOnConsumablesCommunication}
                      />
                    </>
                  )}
                  {props.worksheetDependentFieldNamePrefix === 'unsortedWorksheet' && (
                    <CheckboxesFieldItem
                      name={`${mailingHouseContactFieldName}.includeOnUnsortedCommunication`}
                      label="Unsorted confirmation"
                    />
                  )}
                  {props.worksheetDependentFieldNamePrefix === 'internationalWorksheet' && (
                    <CheckboxesFieldItem
                      name={`${mailingHouseContactFieldName}.includeOnInternationalCommunication`}
                      label="International confirmation"
                    />
                  )}
                </WorksheetCheckboxesField>
              );
            })
          }
        />
      ) : (
        <p>This customer does not have any contacts.</p>
      )}
      <MailingHouseContactsTopLevelValidationError
        fieldName={`${mailingHouseContactWorksheetDependentFieldNamePrefix}.mailingHouse`}
        {...formikProps}
      />
    </WorksheetFieldSet>
  );
};

const ContactNameAndJobTitleContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: ${medium};
`;

const ContactName = styled.span`
  margin-bottom: 0;
`;

const ContactJobTitle = styled.span`
  margin-left: ${narrow};
  color: ${darkGrey};
`;

const MailingHouseContactsTopLevelValidationError = styled(TopLevelValidationError)`
  margin-bottom: 0;
`;
