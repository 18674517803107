import { flowRight } from 'lodash';
import { useState } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import { Id } from '../../../models/id';
import { ApiFetchProps, fetchFromApi } from '../../../shared/higher-order-components/fetchFromApi';
import { PageHeader } from '../../../shared/layout/Headers';
import { medium } from '../../../styling/spacing';
import { fetchSecureJson, from } from '../../../utils/api';
import { assertIsNumberOrNull } from '../../../utils/assertIsNumber';
import { RouteComponentProps } from '../../../utils/RouteComponentProps';
import { DataForMailingBriefForm } from './dataForMailingBriefForm';
import { DataForMailingBriefFormContext } from './DataForMailingBriefFormContext';
import { MailingBriefForm } from './MailingBriefForm';

type OwnProps = {
  isConvertingForecastJobToLive: boolean;
};

type RouteProps = RouteComponentProps<{
  jobId?: string;
}>;

export type FetchDataForMailingBriefProps = ApiFetchProps<
  DataForMailingBriefForm,
  'dataForMailingBriefRequest'
>;

type Props = OwnProps & RouteProps & FetchDataForMailingBriefProps;

const ClientPortalMailingBriefComponent = (props: Props) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  return (
    <DataForMailingBriefFormContext.Provider value={props.dataForMailingBriefRequest.response}>
      <PageHeader>
        {props.isConvertingForecastJobToLive ? 'Convert Forecast to Live' : 'Mailing Brief'}
      </PageHeader>
      {showSuccessMessage ? (
        <>
          <SuccessMessage>Your submission was successful. Returning to jobs page...</SuccessMessage>
        </>
      ) : (
        <MailingBriefForm
          dataForMailingBriefForm={props.dataForMailingBriefRequest.response}
          isConvertingForecastJobToLive={props.isConvertingForecastJobToLive}
          onSuccess={() => setShowSuccessMessage(true)}
        />
      )}
    </DataForMailingBriefFormContext.Provider>
  );
};

const mailingBriefFormDataEndpoint = 'client-portal/data-for-mailing-brief-form';

const fetchDataForMailingBriefForm = (jobId: Id | null) =>
  fetchSecureJson<DataForMailingBriefForm>(
    from(jobId ? `${mailingBriefFormDataEndpoint}/${jobId}` : mailingBriefFormDataEndpoint, 'get'),
  );

export const fetchDataForMailingBriefFormEnhancer = fetchFromApi<
  RouteProps & FetchDataForMailingBriefProps,
  Id | null,
  DataForMailingBriefForm,
  'dataForMailingBriefRequest'
>(
  props => assertIsNumberOrNull(props.jobId),
  fetchDataForMailingBriefForm,
  'dataForMailingBriefRequest',
);

const enhance = flowRight(fetchDataForMailingBriefFormEnhancer);

export const ClientPortalMailingBrief = enhance(ClientPortalMailingBriefComponent);

const SuccessMessage = styled.div`
  margin-bottom: ${medium};
`;
