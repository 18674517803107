import * as React from 'react';
import styled from 'styled-components/macro';
import { alertText } from '../styling/colours';
import { narrow, wide } from '../styling/spacing';
import { getUserVisibleMessage } from '../utils/api';
import { AlertCallout } from './Callout';

type ErrorBoxProps = {
  className?: string;
  error: Error | string;
  genericErrorMessage?: string;
  inline?: boolean;
};

export const defaultErrorMessage = 'Oops! An error has occurred. Please try again';

export const errorBoxTestId = 'error';

export const ErrorBox = ({ error, className, genericErrorMessage, inline }: ErrorBoxProps) => {
  const errorMessage =
    typeof error === 'string'
      ? error
      : getUserVisibleMessage(error) || genericErrorMessage || defaultErrorMessage;

  return inline ? (
    <InlineError className={className} data-testid={errorBoxTestId}>
      {errorMessage}
    </InlineError>
  ) : (
    <AlertCallout className={className} data-testid={errorBoxTestId}>
      {errorMessage}
    </AlertCallout>
  );
};

const InlineError = styled.div`
  color: ${alertText};
  margin: ${narrow} 0;
`;

export const HeaderError = styled(ErrorBox)`
  margin-bottom: ${wide};
`;
