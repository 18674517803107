import { CarrierCode } from '../../../../models/carrier';
import { toDateStamp } from '../../../../models/dateStamp';
import { Id } from '../../../../models/id';
import {
  MetadataWithDisplayOrder,
  MetaDataWithDisplayOrderAndIsArchived,
} from '../../../../models/metadata';
import { ScidMailmarkBarcodeType } from '../../../scids/scid';
import { WorksheetConsumableType } from '../../consumables/worksheetConsumable';

export const sortedCellReferenceMaxLength = 200;

export type SortedCellConsumable = 'Magnums' | 'Pallets' | 'Cages' | 'Yorks' | 'NotRequired';
export type SortedCellConsumableMetadata = MetadataWithDisplayOrder<SortedCellConsumable> & {
  worksheetConsumableType: WorksheetConsumableType | null;
};

export type SortedCellFormat = 'Letter' | 'LargeLetter' | 'Packet';
export type SortedCellFormatMetadata = MetadataWithDisplayOrder<SortedCellFormat>;

export type SortedCellPresentation = 'Bag' | 'Bundle' | 'Tray' | 'Pallets';
export type SortedCellPresentationMetadata = MetadataWithDisplayOrder<SortedCellPresentation> & {
  worksheetConsumableType: WorksheetConsumableType | null;
  defaultMaxWeightKg: number;
  maximumMaxWeightKg: number;
  validBundleLabelFormats: Array<SortedCellBundleLabelFormat> | null;
  defaultBundleLabelFormat: SortedCellBundleLabelFormat | null;
};

export type SortedCellServiceType =
  | 'SeventyMailmark'
  | 'SeventyMailmarkEconomy'
  | 'SeventyOcr'
  | 'SeventyCatalogue'
  | 'FourteenHundredDirect'
  | 'SeventyResidue'
  | 'CatalogueMail'
  | 'Cms'
  | 'NineNineNine'
  | 'SeventyMailmarkCatalogue'
  | 'SeventyMailmarkCatalogueEconomy';
export type SortedCellServiceTypeMetadata = MetadataWithDisplayOrder<SortedCellServiceType> & {
  validMailmarkBarcodes: Array<SortedCellMailmarkBarcode> | null;
  defaultMailmarkBarcode: SortedCellMailmarkBarcode | null;
  supportsScids: boolean;
};

export type SortedCellServiceTypeMetadataAndIsArchived = MetaDataWithDisplayOrderAndIsArchived<
  SortedCellServiceType
> & {
  validMailmarkBarcodes: Array<SortedCellMailmarkBarcode> | null;
  defaultMailmarkBarcode: SortedCellMailmarkBarcode | null;
  supportsScids: boolean;
  isArchived: boolean;
};

// TODO: Set SortedCellServiceArchiveDateStamp to 2024-04-01 when this goes live
export const SortedCellServiceArchiveDateStamp = toDateStamp(new Date('2024-04-01'));

export type SortedCellVariant =
  | 'GeneralMail'
  | 'BusinessMail'
  | 'PartiallyAddressed'
  | 'MagSubs'
  | 'AdMail'
  | 'AdMailRespE'
  | 'AdMailRespI';
export type SortedCellVariantMetadata = MetaDataWithDisplayOrderAndIsArchived<SortedCellVariant>;
export const SortedCellVariantArchiveDateStamp = toDateStamp(new Date('2024-01-18'));

export type SortedCellBundleLabelFormat =
  | 'PdfOneUp'
  | 'PdfTwoUp'
  | 'PdfThreeUp'
  | 'PdfFourUp'
  | 'StickyLabels';
export type SortedCellBundleLabelFormatMetadata = MetadataWithDisplayOrder<
  SortedCellBundleLabelFormat
>;

export type SortedCellMailmarkBarcode =
  | 'SevenEncoded'
  | 'Seven'
  | 'NineEncoded'
  | 'Nine'
  | 'TwentyNineEncoded'
  | 'TwentyNine'
  | 'FourState';
export type SortedCellMailmarkBarcodeMetadata = MetadataWithDisplayOrder<
  SortedCellMailmarkBarcode
> & {
  scidMailmarkBarcodeType: ScidMailmarkBarcodeType;
};

export type SortedCell = {
  sortedCellId: Id;
  displayOrder: number;
  cellReference: string;
  serviceType: SortedCellServiceType;
  format: SortedCellFormat;
  variant: SortedCellVariant;
  quantity: number;
  weightInGrams: number;
  presentation: SortedCellPresentation;
  consumable: SortedCellConsumable;
  maxPresentationWeightKg: number;
  maxPresentationFill: number | null;
  bundleLabelFormat: SortedCellBundleLabelFormat | null;
  mailmarkBarcode: SortedCellMailmarkBarcode | null;
};

export type CarrierServiceFormatValidationConfiguration = {
  serviceTypesByCarrierCode: { [carrierCode in CarrierCode]: CarrierServiceTypes };
};

type CarrierServiceTypes = {
  carrier: CarrierCode;
  serviceTypesByCode: { [serviceType in SortedCellServiceType]?: ServiceTypeFormats };
};

type ServiceTypeFormats = {
  serviceTypeCode: SortedCellServiceType;
  isAllowedOnAgencyWorksheets: boolean;
  rulesByFormatCode: { [format in SortedCellFormat]?: CarrierServiceFormatRules };
};

export type CarrierServiceFormatRules = {
  allowedVariantCodesForNonAgencyAgreement: Array<SortedCellVariant>;
  allowedVariantCodesForAgencyAgreement: Array<SortedCellVariant>;
  allowedPresentationCodes: Array<SortedCellPresentation>;
  minWeight: number;
  maxWeight: number;
  selectionWarningsByPresentationCode: { [presentation in SortedCellPresentation]?: string };
};

export type PresentationConsumablesValidationConfiguration = {
  presentationConsumablesConfigurationByCarrierCode: {
    [carrierCode in CarrierCode]: CarrierPresentationConsumablesConfiguration;
  };
};

export type CarrierPresentationConsumablesConfiguration = {
  carrier: CarrierCode;
  allowedConsumablesByPresentationCode: {
    [presentation in SortedCellPresentation]?: PresentationAllowedConsumables;
  };
  defaultsAreOverriddenByMailingHouseCagesAndYorksPreferences: boolean;
  nonDefaultSelectionWarning: string | null;
};

export type PresentationAllowedConsumables = {
  presentationCode: SortedCellPresentation;
  defaultConsumableCode: SortedCellConsumable;
  allowedConsumableCodes: Array<SortedCellConsumable>;
};
