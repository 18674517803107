import { Id } from '../../../../models/id';
import { CustomerContact } from '../../../customers/customerContact';
import { SortedWorksheetCustomerContact } from '../sortedWorksheet';
import { CreateUpdateSortedWorksheetCustomerContactDto } from './createUpdateSortedWorksheetCustomerContactDto';

export type SortedWorksheetCustomerContactFormModel = {
  customerContactId: Id;
  includeOnCarbonCopyCommunication: boolean;
  includeOnDataCommunication: boolean;
  includeOnLabelsCommunication: boolean;
  includeOnCollectionsCommunication: boolean;
  includeOnConsumablesCommunication: boolean;
  includeOnConfirmationCommunication: boolean;
};

export const toCreateUpdateSortedWorksheetCustomerContactDto = ({
  includeOnDataCommunication,
  includeOnCarbonCopyCommunication,
  ...formModel
}: SortedWorksheetCustomerContactFormModel): CreateUpdateSortedWorksheetCustomerContactDto => ({
  ...formModel,
  includeOnDataCommunication: includeOnDataCommunication
    ? 'LinkAndPassword'
    : includeOnCarbonCopyCommunication
    ? 'CarbonCopy'
    : 'Never',
});

export const fromSortedWorksheetCustomerContact = (
  worksheetContact: SortedWorksheetCustomerContact,
  customerContact: CustomerContact,
): SortedWorksheetCustomerContactFormModel => ({
  customerContactId: worksheetContact.customerContactId,
  // The customer contact may have been updated to always be included on categories since
  // the worksheet was edited, so we need to take the value from the customer contact if it's set
  includeOnCarbonCopyCommunication:
    customerContact.includeOnDataCommunication !== 'LinkAndPassword' &&
    worksheetContact.includeOnDataCommunication !== 'LinkAndPassword' &&
    (customerContact.includeOnDataCommunication === 'CarbonCopy' ||
      worksheetContact.includeOnDataCommunication === 'CarbonCopy'),
  includeOnDataCommunication:
    customerContact.includeOnDataCommunication === 'LinkAndPassword' ||
    worksheetContact.includeOnDataCommunication === 'LinkAndPassword',
  includeOnLabelsCommunication:
    customerContact.includeOnLabelsCommunication || worksheetContact.includeOnLabelsCommunication,
  includeOnCollectionsCommunication:
    customerContact.includeOnCollectionsCommunication ||
    worksheetContact.includeOnCollectionsCommunication,
  includeOnConsumablesCommunication:
    customerContact.includeOnConsumablesCommunication ||
    worksheetContact.includeOnConsumablesCommunication,
  includeOnConfirmationCommunication:
    customerContact.includeOnConfirmationCommunication ||
    worksheetContact.includeOnConfirmationCommunication,
});

export const fromNonEditableSortedWorksheetCustomerContact = ({
  customerContactId,
  includeOnDataCommunication,
  includeOnLabelsCommunication,
  includeOnCollectionsCommunication,
  includeOnConsumablesCommunication,
  includeOnConfirmationCommunication,
}: SortedWorksheetCustomerContact): SortedWorksheetCustomerContactFormModel => ({
  customerContactId,
  includeOnCarbonCopyCommunication: includeOnDataCommunication === 'CarbonCopy',
  includeOnDataCommunication: includeOnDataCommunication === 'LinkAndPassword',
  includeOnLabelsCommunication,
  includeOnCollectionsCommunication,
  includeOnConsumablesCommunication,
  includeOnConfirmationCommunication,
});

export const fromCustomerContact = ({
  customerContactId,
  includeOnJobsCode,
  includeOnDataCommunication,
  includeOnLabelsCommunication,
  includeOnCollectionsCommunication,
  includeOnConsumablesCommunication,
  includeOnConfirmationCommunication,
}: CustomerContact): SortedWorksheetCustomerContactFormModel => ({
  customerContactId,
  includeOnDataCommunication:
    includeOnJobsCode === 'Always' && includeOnDataCommunication === 'LinkAndPassword',
  includeOnCarbonCopyCommunication:
    includeOnJobsCode === 'Always' && includeOnDataCommunication === 'CarbonCopy',
  includeOnLabelsCommunication: includeOnJobsCode === 'Always' && includeOnLabelsCommunication,
  includeOnCollectionsCommunication:
    includeOnJobsCode === 'Always' && includeOnCollectionsCommunication,
  includeOnConsumablesCommunication:
    includeOnJobsCode === 'Always' && includeOnConsumablesCommunication,
  includeOnConfirmationCommunication:
    includeOnJobsCode === 'Always' && includeOnConfirmationCommunication,
});
