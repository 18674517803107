import React from 'react';
import { formatDateStamp } from '../../../../models/dateStamp';
import { Dd, DdVerticallyCentered, Dl, DlItem, Dt } from '../../../../shared/DefinitionList';
import { SectionHeader } from '../../../../shared/layout/Headers';
import { Table, TBody, Td, Tr } from '../../../../shared/table/Table';
import { TickOrCross } from '../../../../shared/TickOrCross';
import { internationalWorksheetCollectionByOptionDisplayNames } from '../../../worksheets/international/internationalWorksheet';
import {
  WorksheetDetailsSection,
  WorksheetPrimaryDetailsPanel,
  WorksheetPrimaryDetailsSection,
  WorksheetPrimaryDetailsSectionLeft,
  WorksheetPrimaryDetailsSectionRight,
} from '../../../worksheets/WorksheetDetails';
import { ClientPortalWorksheetDetailsHeader } from '../ClientPortalWorksheetDetailsHeader';
import {
  ClientPortalInternationalWorksheet,
  ClientPortalInternationalWorksheetCustomerContact,
} from './ClientPortalInternationalWorksheet';
import { ClientPortalInternationalWorksheetContactsTable } from './ClientPortalInternationalWorksheetContactsTable';
import { ClientPortalInternationalWorksheetMailingHouseDetails } from './ClientPortalInternationalWorksheetMailingHouseDetails';
import { ViewClientPortalInternationalCellsTable } from './ViewClientPortalInternationalCellsTable';

type Props = {
  internationalWorksheet: ClientPortalInternationalWorksheet;
};

export const ClientPortalInternationalWorksheetDetails = ({ internationalWorksheet }: Props) => {
  return (
    <>
      <ClientPortalWorksheetDetailsHeader worksheet={internationalWorksheet} />
      <WorksheetPrimaryDetailsSection>
        <WorksheetPrimaryDetailsSectionLeft>
          <WorksheetPrimaryDetailsPanel>
            <Dl>
              <DlItem horizontal={true}>
                <Dt>Collection By</Dt>
                <Dd>
                  {internationalWorksheet.collectionBy &&
                    internationalWorksheetCollectionByOptionDisplayNames[
                      internationalWorksheet.collectionBy
                    ]}
                </Dd>
              </DlItem>

              <DlItem horizontal={true}>
                <Dt>Assignee</Dt>
                <Dd>{internationalWorksheet.assigneeName}</Dd>
              </DlItem>

              <DlItem horizontal={false}>
                <Dt>Custom Collection Email Content</Dt>
                <Dd multiline={true}>{internationalWorksheet.customCollectionEmailContent}</Dd>
              </DlItem>

              <DlItem horizontal={true}>
                <Dt>Labels Prepared</Dt>
                <DdVerticallyCentered>
                  <TickOrCross value={internationalWorksheet.labelsPrepared} centered={true} />
                </DdVerticallyCentered>
              </DlItem>

              <DlItem horizontal={true}>
                <Dt>Manifest Prepared</Dt>
                <DdVerticallyCentered>
                  <TickOrCross value={internationalWorksheet.manifestPrepared} centered={true} />
                </DdVerticallyCentered>
              </DlItem>
            </Dl>
          </WorksheetPrimaryDetailsPanel>
        </WorksheetPrimaryDetailsSectionLeft>

        <WorksheetPrimaryDetailsSectionRight withPaddingTop={false}>
          <Table>
            <TBody>
              {internationalWorksheet.labelsSentDate && (
                <Tr>
                  <Td>Labels Sent Date</Td>
                  <Td> {formatDateStamp(internationalWorksheet.labelsSentDate)}</Td>
                </Tr>
              )}
              {internationalWorksheet.manifestSentDate && (
                <Tr>
                  <Td>Manifest Sent Date</Td>
                  <Td> {formatDateStamp(internationalWorksheet.manifestSentDate)}</Td>
                </Tr>
              )}
              <Tr>
                <Td>Collection Date</Td>
                <Td>{formatDateStamp(internationalWorksheet.collectionDate)}</Td>
              </Tr>
            </TBody>
          </Table>
        </WorksheetPrimaryDetailsSectionRight>
      </WorksheetPrimaryDetailsSection>

      <WorksheetDetailsSection>
        <SectionHeader>Cells</SectionHeader>
        <ViewClientPortalInternationalCellsTable
          internationalCells={internationalWorksheet.cells}
        />
      </WorksheetDetailsSection>

      <WorksheetDetailsSection>
        <SectionHeader>Mailing House</SectionHeader>
        <ClientPortalInternationalWorksheetMailingHouseDetails
          internationalWorksheet={internationalWorksheet}
        />
      </WorksheetDetailsSection>

      <WorksheetDetailsSection>
        <SectionHeader>Customer Contacts</SectionHeader>
        <ClientPortalInternationalWorksheetContactsTable<
          ClientPortalInternationalWorksheetCustomerContact
        >
          contacts={internationalWorksheet.customerContacts}
        />
      </WorksheetDetailsSection>
    </>
  );
};
