import * as React from 'react';
import styled from 'styled-components/macro';
import { darkGrey } from '../../styling/colours';
import { medium, narrow } from '../../styling/spacing';

type CustomerContact = {
  firstName: string;
  lastName: string;
  jobTitle: string;
};

export type ContactNameAndJobTitleProps = {
  customerContact: CustomerContact;
};
export const ContactNameAndJobTitle = ({ customerContact }: ContactNameAndJobTitleProps) => (
  <ContactNameAndJobTitleContainer>
    <ContactName>
      {customerContact.firstName} {customerContact.lastName}
    </ContactName>
    <ContactJobTitle>- {customerContact.jobTitle}</ContactJobTitle>
  </ContactNameAndJobTitleContainer>
);

const ContactNameAndJobTitleContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: ${medium};
`;

const ContactName = styled.span`
  margin-bottom: 0;
`;

const ContactJobTitle = styled.span`
  margin-left: ${narrow};
  color: ${darkGrey};
`;
