import { AppInsights } from 'applicationinsights-js';
import { assertNotNull } from './utils/assertNotNull';
import { appHistory } from './utils/routing';

export const setupApplicationInsights = () => {
  if (window.config.applicationInsights.enableApplicationInsights) {
    assertNotNull(AppInsights.downloadAndSetup)({
      instrumentationKey: window.config.applicationInsights.instrumentationKey,
      enableDebug: false, // Set this to true to disable batching of events to aid development
    });

    appHistory.listen(() => AppInsights.trackPageView());
  }
};

export const setApplicationInsightsUser = (userId: string) =>
  AppInsights.setAuthenticatedUserContext(userId);

export const trackError = (error: Error, errorInfo: React.ErrorInfo) =>
  AppInsights && AppInsights.trackException(error, undefined, { ...errorInfo });
