import { User, UserManager, UserManagerSettings } from 'oidc-client';
import * as React from 'react';
import { navigate } from '../../../utils/routing';
import {
  AuthenticationContext,
  AuthenticationContextValue,
} from '../../authentication/authenticationContext';
import {
  clientPortalLoggedOutUrl,
  clientPortalSigninRedirectUrl,
  clientPortalSilentTokenRefreshUrl,
  clientPortalUrl,
} from '../clientPortalUrls';

export const authenticationConfig: UserManagerSettings = {
  authority: window.location.origin,
  client_id: 'client-portal',
  redirect_uri: window.location.origin + clientPortalSigninRedirectUrl,
  response_type: 'code',
  scope: 'openid profile api',
  post_logout_redirect_uri: window.location.origin + clientPortalLoggedOutUrl,
  silent_redirect_uri: window.location.origin + clientPortalSilentTokenRefreshUrl,
  response_mode: 'query',
};

const redirectToUrlAfterSignInStorageKey = 'client-portal-auth:redirect-url';

type State = {
  user: User | null;
  authenticationError: Error | null;
  isLoggingIn: boolean;
  isLoggingOut: boolean;
};

export type AuthenticationProps = {
  isAuthenticated: boolean;
  authenticationError: Error | null;
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  redirectToLogin: () => void;
};

type OwnProps = {
  children: (authentication: AuthenticationProps) => React.ReactNode;
};

const isSignInRedirectCallback = () => window.location.pathname === clientPortalSigninRedirectUrl;

export class ClientPortalAuthentication extends React.Component<OwnProps, State> {
  userManager = new UserManager(authenticationConfig);

  state = {
    user: null,
    authenticationError: null,
    isLoggingIn: isSignInRedirectCallback(),
    isLoggingOut: false,
  };

  async componentDidMount() {
    if (isSignInRedirectCallback()) {
      this.handleSignInRedirectCallback();
    } else {
      try {
        const user = await this.userManager.getUser();

        if (user && !user.expired) {
          this.setState({ user });
        } else {
          this.redirectToLogin();
        }
      } catch (error) {
        // tslint:disable-next-line:no-console
        console.error('Error getting user:', error);
        this.setState({
          user: null,
          authenticationError: error,
          isLoggingIn: false,
          isLoggingOut: false,
        });
      }
    }

    // Event is fired when the user signs out in another tab / their session has expired
    this.userManager.events.addUserSignedOut(this.handleUserSignedOut);
  }

  componentWillUnmount() {
    this.userManager.events.removeUserSignedOut(this.handleUserSignedOut);
  }

  handleSignInRedirectCallback() {
    this.userManager
      .signinRedirectCallback()
      .then((user: User | null) => {
        this.setState(
          {
            user,
            authenticationError: null,
            isLoggingIn: false,
            isLoggingOut: false,
          },
          this.redirectToOriginalPageAfterSignIn,
        );
      })
      .catch(error => {
        // tslint:disable-next-line:no-console
        console.error('Error handling sign-in redirect:', error);
        this.setState(
          {
            user: null,
            authenticationError: error,
            isLoggingIn: false,
            isLoggingOut: false,
          },
          this.redirectToOriginalPageAfterSignIn,
        );
      });
  }

  redirectToLogin = () => {
    sessionStorage.setItem(redirectToUrlAfterSignInStorageKey, window.location.pathname);
    this.setState({ isLoggingIn: true });
    this.userManager.signinRedirect().catch(error => {
      // tslint:disable-next-line:no-console
      console.error('Error handling redirecting to login:', error);
      this.setState({
        user: null,
        authenticationError: error,
        isLoggingIn: false,
        isLoggingOut: false,
      });
    });
  };

  redirectToOriginalPageAfterSignIn = () => {
    const originalUrl = sessionStorage.getItem(redirectToUrlAfterSignInStorageKey);
    sessionStorage.removeItem(redirectToUrlAfterSignInStorageKey);
    navigate(originalUrl || clientPortalUrl, { replace: true });
  };

  logout = () => {
    this.setState({ isLoggingOut: true });
    this.userManager.signoutRedirect();
  };

  getAccessToken = async (): Promise<string> => {
    try {
      let user = await this.userManager.getUser();

      if (user == null || user.expired) {
        console.log('Access token expired, initiating silent renewal'); // tslint:disable-line:no-console
        user = await this.userManager.signinSilent();
      }

      return user.access_token;
    } catch (error) {
      console.error('Error obtaining access token', error); // tslint:disable-line:no-console
      this.redirectToLogin();
      throw error;
    }
  };

  handleUserSignedOut = () => {
    navigate(clientPortalLoggedOutUrl, { suppressUserConfirmation: true });
  };

  authenticationContextValue: AuthenticationContextValue = {
    getAccessToken: this.getAccessToken,
    logout: this.logout,
  };

  render() {
    return (
      <AuthenticationContext.Provider value={this.authenticationContextValue}>
        {this.props.children({
          ...this.state,
          isAuthenticated: this.state.user != null,
          redirectToLogin: this.redirectToLogin,
        })}
      </AuthenticationContext.Provider>
    );
  }
}
