import { Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { Form } from '../../../shared/form/Form';
import { FormErrorBox } from '../../../shared/form/FormErrorBox';
import { FormSubmit } from '../../../shared/form/FormSubmit';
import { PasswordField } from '../../../shared/form/inputs/PasswordField';
import {
  ApiRequestProps,
  withApiRequest,
} from '../../../shared/higher-order-components/withApiRequest';
import { PageHeader } from '../../../shared/layout/Headers';
import { Link } from '../../../shared/navigation/Link';
import { AuthenticatedApiRequest, fetchSecureJson, from, withJsonBody } from '../../../utils/api';
import { RouteComponentProps } from '../../../utils/RouteComponentProps';
import { minLengthIfNotEmpty, notEmpty } from '../../../utils/validation/stringValidators';
import { combineValidators, createValidator } from '../../../utils/validation/validation';
import { clientPortalUrl } from '../clientPortalUrls';

type OwnProps = RouteComponentProps<{}>;

type UpdatePasswordRequestProps = ApiRequestProps<
  UpdatePasswordCommand,
  {},
  'updatePasswordRequest'
>;

type Props = OwnProps & UpdatePasswordRequestProps;

export const UpdateClientPortalPasswordComponent = (props: Props) => {
  const [hasChangedPassword, setHasChangedPassword] = useState(false);

  return (
    <>
      <PageHeader>Reset your password</PageHeader>
      {hasChangedPassword ? (
        <>
          <div>Your password has been updated successfully</div>
          <Link to={clientPortalUrl}>Back to site</Link>
        </>
      ) : (
        <>
          <Formik<UpdatePasswordFormModel>
            initialValues={{
              currentPassword: '',
              newPassword: '',
            }}
            onSubmit={(formModel, { setSubmitting }) => {
              props.updatePasswordRequest.sendRequest(formModel).then(response => {
                if (response.success) {
                  setHasChangedPassword(true);
                } else {
                  setSubmitting(false);
                }
              });
            }}
            validate={createValidator<UpdatePasswordFormModel>({
              currentPassword: notEmpty('Please enter your current password'),
              newPassword: combineValidators(
                notEmpty('Please enter a new password'),
                minLengthIfNotEmpty(8),
              ),
            })}
          >
            {formikProps => (
              <Form {...formikProps}>
                <PasswordField label="Current Password" name="currentPassword" />
                <PasswordField label="New Password" name="newPassword" />
                <FormSubmit
                  errors={formikProps.errors}
                  isSubmitting={formikProps.isSubmitting}
                  submitButtonText="Update Password"
                />
                {props.updatePasswordRequest.error && (
                  <FormErrorBox error={props.updatePasswordRequest.error} />
                )}
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export type UpdatePasswordFormModel = {
  currentPassword: string;
  newPassword: string;
};

type UpdatePasswordCommand = {
  currentPassword: string;
  newPassword: string;
};

const updatePassword = (command: UpdatePasswordCommand): AuthenticatedApiRequest<{}> =>
  fetchSecureJson<{}>(withJsonBody(command)(from('client-portal/user/update-password', 'post')));

const updatePasswordRequestEnhancer = withApiRequest<
  OwnProps,
  UpdatePasswordCommand,
  {},
  'updatePasswordRequest'
>(() => updatePassword, 'updatePasswordRequest');

export const UpdateClientPortalPassword = updatePasswordRequestEnhancer(
  UpdateClientPortalPasswordComponent,
);
