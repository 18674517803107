import * as React from 'react';
import { useEffect } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { ReactComponent as CrossIcon } from '../../images/icons/cross-icon.svg';
import { ReactComponent as TickIcon } from '../../images/icons/tick-circle-icon.svg';
import { categoryGreen, categoryGreenLight, darkGrey } from '../../styling/colours';
import { medium } from '../../styling/spacing';
import { Icon } from '../Icon';
import { MatrixNotification } from './notification';

export const notificationLifetimeSeconds = 7;

type Props = {
  id: number;
  notification: MatrixNotification;
  dismiss: () => void;
  position: number;
};

export const NotificationCard = (props: Props) => {
  useEffect(() => {
    const dismissTimeout = setTimeout(() => props.dismiss(), notificationLifetimeSeconds * 1000);
    return () => clearTimeout(dismissTimeout);
  }, [props.id]);

  return (
    <NotificationCardContainer position={props.position}>
      <TypeIcon colour={categoryGreen}>
        <TickIcon />
      </TypeIcon>
      <NotificationMessage>{props.notification.message}</NotificationMessage>
      <DismissIcon
        colour={darkGrey}
        onClick={props.dismiss}
        data-testid={notificationCardDismissButtonTestId(props.id)}
      >
        <CrossIcon />
      </DismissIcon>
    </NotificationCardContainer>
  );
};

export const notificationCardDismissButtonTestId = (id: number) =>
  `dismiss-notification-button-${id}`;

export const notificationCardWidth = '450px;';
export const notificationCardHeightValue = 50;
export const notificationCardHeight = `${notificationCardHeightValue}px`;

const NotificationMessage = styled.div`
  flex: 1;
`;

const TypeIcon = styled(Icon)`
  flex-shrink: 0;
  margin: 0 ${medium};
  height: 25px;
  width: 25px;
`;

const DismissIcon = styled(Icon)`
  flex-shrink: 0;
  margin: 0 ${medium};
  height: 12px;
  position: relative;
  bottom: 2px;

  cursor: pointer;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`;

const animateInDurationSeconds = 0.5;
const animateOutDurationSeconds = 0.75;

export const dropIn = keyframes`
  from {
    margin-top: -${notificationCardHeight};
    opacity: 0;
  }
  to {
    margin-top: ${medium};
    opacity: 1;
  }
`;

export const slideOut = keyframes`
  from {
    margin-left: 0;
    opacity: 1;
  }
  to {
    margin-left: 40px;
    opacity: 0;
  }
`;

export const NotificationCardContainer = styled.div<{ position: number }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  width: ${notificationCardWidth};
  height: ${notificationCardHeight};

  background-color: ${categoryGreenLight};
  color: ${categoryGreen};

  position: absolute;
  transform: translateY(${props => props.position * (notificationCardHeightValue + 20)}px);

  transition: ${animateInDurationSeconds}s ease;
  animation: ${dropIn} ${animateInDurationSeconds}s ease forwards,
    ${slideOut} ${animateOutDurationSeconds}s ease
      ${notificationLifetimeSeconds - (animateInDurationSeconds + animateOutDurationSeconds)}s
      forwards;

  box-shadow: 0 2px 8px rgba(22, 105, 43, 0.3);
`;
