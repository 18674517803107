import * as React from 'react';
import { useState } from 'react';

export type FormSubmissionContextValue = {
  hasBeenSubmitted: boolean;
  setHasBeenSubmitted: (hasBeenSubmitted: boolean) => void;
};

export const FormSubmissionContext = React.createContext<FormSubmissionContextValue>({
  hasBeenSubmitted: false,
  setHasBeenSubmitted: () => {
    throw new Error('FormSubmission context not set');
  },
});

export const FormSubmissionContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState<boolean>(false);

  return (
    <FormSubmissionContext.Provider
      value={{
        hasBeenSubmitted,
        setHasBeenSubmitted,
      }}
    >
      {children}
    </FormSubmissionContext.Provider>
  );
};
