import { Redirect } from '@reach/router';
import * as React from 'react';
import { useContext } from 'react';
import { RouteComponentProps } from '../../utils/RouteComponentProps';
import { ClientPortalUser, ClientPortalUserContext } from './authentication/clientPortalUser';
import { clientPortalJobsUrl, clientPortalMailingBriefUrl } from './clientPortalUrls';

export const getClientPortalHomePageUrl = (user: ClientPortalUser): string => {
  if (user.customers.length > 0) {
    return clientPortalJobsUrl;
  }
  return clientPortalMailingBriefUrl;
};

type Props = RouteComponentProps<{}>;

export const RedirectToClientPortalHomePage = (props: Props) => {
  const userContext = useContext(ClientPortalUserContext);
  return <Redirect to={getClientPortalHomePageUrl(userContext)} noThrow={true} />;
};
