import { FormikProps } from 'formik';
import { flowRight } from 'lodash';
import { useEffect } from 'react';
import * as React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as CrossIcon } from '../../../../images/icons/cross-icon.svg';
import { PrimaryButton, SecondaryButton } from '../../../../shared/buttons/Button';
import { IconButton } from '../../../../shared/buttons/IconButton';
import { WarningCallout } from '../../../../shared/Callout';
import { InputField } from '../../../../shared/form/inputs/InputField';
import { SelectOptions } from '../../../../shared/form/inputs/Select';
import { SelectField } from '../../../../shared/form/inputs/SelectField';
import { withModal, WithModalProps } from '../../../../shared/higher-order-components/withModal';
import { ModalActionButtons, ModalHeader } from '../../../../shared/Modal';
import { Td, Tr } from '../../../../shared/table/Table';
import { alertText } from '../../../../styling/colours';
import { smallFont } from '../../../../styling/fonts';
import { narrow } from '../../../../styling/spacing';
import { ConsumableLimitationCode } from '../../../mailing-houses/mailingHouse';
import {
  SortedCellBundleLabelFormat,
  SortedCellConsumable,
  SortedCellFormat,
  SortedCellMailmarkBarcode,
  SortedCellPresentation,
  SortedCellServiceType,
  SortedCellVariant,
} from '../../../worksheets/sorted/cells/sortedCell';
import {
  getValidOptionsForCell,
  getWarningsForCell,
} from '../../../worksheets/sorted/cells/sortedCellValidation';
import {
  printingTypeRequiresBundleLabelFormat,
  WorksheetPrintingType,
} from '../../../worksheets/sorted/edit-form/sortedWorksheetFormModel';
import { useClientPortalMetadata } from '../../clientPortalMetadata';
import { MinimumMaxPresentationFillValueThatTriggersReversionCharges } from '../mailingBriefFormModel';
import { MailingBriefSortedCellFormModel } from './mailingBriefSortedCellFormModel';
import { deleteCellButtonTestId } from './MailingBriefSortedCellsFieldArray';

const getSelectOptionsForMetadata = <T extends string, K extends { displayName: string }>(
  codes: Array<T> | null,
  metadata: { [code in T]: K },
): SelectOptions<T> =>
  codes == null ? [] : codes.map(code => ({ value: code, text: metadata[code].displayName }));

type OwnProps<TFormModel> = {
  cell: MailingBriefSortedCellFormModel;
  cellIndex: number;
  carrierCode: 'SecuredMail';
  formProps: FormikProps<TFormModel>;
  removeCell: () => void;
  consumableLimitationCode: ConsumableLimitationCode | null;
  printingType: WorksheetPrintingType | null;
  worksheetFieldName: string;
  isAgencyAgreement: boolean;
};

export type maxFillReversionChargesModalParams = {
  minimumMaxFill: number;
  onReject: () => void;
};

type maxFillReversionChargesModalProps = WithModalProps<
  maxFillReversionChargesModalParams,
  'maxFillReversionChargesModal'
>;

type Props<TFormModel> = OwnProps<TFormModel> & maxFillReversionChargesModalProps;

type ModalProps = {
  onAccept: () => void;
  minimumMaxFill: number;
  onReject: () => void;
};

const MaxFillReversionChargesModal = (props: ModalProps) => (
  <>
    <ModalHeader>Reversion Charges</ModalHeader>
    <p>
      Please note that any sorted jobs of less than {props.minimumMaxFill} items per tray could be
      at risk of reversion charges.
    </p>
    <ModalActionButtons>
      <PrimaryButton onClick={props.onAccept}>Accept</PrimaryButton>
      <SecondaryButton onClick={props.onReject}>Reject</SecondaryButton>
    </ModalActionButtons>
  </>
);

const withMaxFillReversionChargesModal = withModal<
  OwnProps<unknown>,
  maxFillReversionChargesModalParams,
  'maxFillReversionChargesModal'
>(
  'maxFillReversionChargesModal',
  ({ params, closeModal }) => {
    const onReject = () => {
      params.onReject();
      closeModal();
    };
    return (
      <MaxFillReversionChargesModal
        minimumMaxFill={params.minimumMaxFill}
        onReject={onReject}
        onAccept={() => closeModal()}
      />
    );
  },
  { disableClose: true },
);

export const MailingBriefSortedCellFieldsComponent = <TFormModel extends unknown>({
  cell,
  cellIndex,
  carrierCode,
  formProps,
  removeCell,
  consumableLimitationCode,
  printingType,
  worksheetFieldName,
  isAgencyAgreement,
  maxFillReversionChargesModal,
}: Props<TFormModel>) => {
  const metadata = useClientPortalMetadata();

  const currentCellFieldName = `${worksheetFieldName}.cells.${cellIndex}`;

  const worksheetIsForecast = false; // TODO MCP-30 drive this from the mailing brief field

  const validOptionsForCell = getValidOptionsForCell(
    cell,
    carrierCode,
    consumableLimitationCode,
    printingType,
    worksheetIsForecast,
    isAgencyAgreement,
    false,
    false,
    metadata,
  );

  const {
    presentationSelectionWarning,
    nonDefaultConsumableIsSelected,
    nonDefaultConsumableSelectionWarning,
  } = getWarningsForCell(cell, validOptionsForCell);

  const {
    validServiceTypes,
    validFormats,
    validVariants,
    validPresentations,
    serviceFormatRules,
    validConsumables,
    validBundleLabelFormats,
    validMailmarkBarcodes,
  } = validOptionsForCell;

  const cellFieldName = `${worksheetFieldName}.cells.${cellIndex}`;

  const setFieldValueAndMarkUntouched = <T extends unknown>(
    fieldName: keyof MailingBriefSortedCellFormModel,
    value: T | null,
  ) => {
    const formFieldName = `${cellFieldName}.${fieldName}`;
    formProps.setFieldValue(formFieldName, value);
    formProps.setFieldTouched(formFieldName, false);
  };

  const resetField = <T extends unknown>(fieldName: keyof MailingBriefSortedCellFormModel) =>
    setFieldValueAndMarkUntouched(fieldName, null);

  const resetServiceType = () => {
    resetField('serviceType');
  };

  const resetConsumable = () => {
    resetField('consumable');
  };

  const resetMaxPresentationWeight = () => {
    setFieldValueAndMarkUntouched('maxPresentationWeightKg', '');
  };

  const resetPresentation = () => {
    resetField('presentation');
    onChangePresentation(null);
  };

  const resetWeight = () => {
    setFieldValueAndMarkUntouched('weightInGrams', '');
  };

  const resetVariant = () => {
    resetField('variant');
  };

  const resetFormat = () => {
    resetField('format');
  };

  const resetMaxFill = () => {
    setFieldValueAndMarkUntouched('maxPresentationFill', '');
  };

  const onChangeServiceType = (newServiceType: SortedCellServiceType | null) => {
    resetPresentation();
    resetWeight();
    resetVariant();
    resetFormat();
    setMailmarkBarcodeToDefault(newServiceType);
  };

  useEffect(() => {
    if (validServiceTypes && cell.serviceType && !validServiceTypes.includes(cell.serviceType)) {
      onChangeServiceType(null);
      resetServiceType();
    }

    if (validVariants && cell.variant && !validVariants.includes(cell.variant)) {
      resetVariant();
    }
  }, [isAgencyAgreement, onChangeServiceType, resetServiceType, resetVariant]);

  const onChangeFormat = () => {
    resetPresentation();
    resetWeight();
    resetVariant();
  };

  const onChangeWeightInGrams = (newWeightInGrams: string) => {
    setMaxPresentationWeightKg(newWeightInGrams, cell.maxPresentationFill);
  };

  const onChangeMaxPresentationFill = (newMaxPresentationFill: string) => {
    setMaxPresentationWeightKg(cell.weightInGrams, newMaxPresentationFill);
  };

  const setMaxPresentationWeightKg = (weightInGrams: string, maxPresentationFill: string) => {
    if (weightInGrams.trim() === '' || maxPresentationFill.trim() === '') {
      setFieldValueAndMarkUntouched('maxPresentationWeightKg', '');
      return;
    }

    const weightInGramsAsNumber = Number(weightInGrams.trim());
    const maxPresentationFillAsNumber = Number(maxPresentationFill.trim());

    if (isNaN(weightInGramsAsNumber) || isNaN(maxPresentationFillAsNumber)) {
      return;
    }

    const newMaxPresentationWeightKg = (
      (weightInGramsAsNumber * maxPresentationFillAsNumber) /
      1000
    ).toString();

    setFieldValueAndMarkUntouched('maxPresentationWeightKg', newMaxPresentationWeightKg);
  };

  const validateMaxPresentationFill = () => {
    const maxPresentationFillAsNumber = Number(cell.maxPresentationFill.trim());

    if (
      cell.maxPresentationFill === '' ||
      isNaN(maxPresentationFillAsNumber) ||
      (maxPresentationFillAsNumber <= 0 && cell.presentation === 'Tray')
    ) {
      return;
    }

    if (cell.variant === 'MagSubs' && maxPresentationFillAsNumber < 10) {
      maxFillReversionChargesModal.open({ minimumMaxFill: 10, onReject: resetMaxFill });
    } else if (cell.format === 'LargeLetter' && maxPresentationFillAsNumber < 5) {
      maxFillReversionChargesModal.open({ minimumMaxFill: 5, onReject: resetMaxFill });
    } else if (
      cell.format === 'Letter' &&
      maxPresentationFillAsNumber < MinimumMaxPresentationFillValueThatTriggersReversionCharges
    ) {
      maxFillReversionChargesModal.open({
        minimumMaxFill: MinimumMaxPresentationFillValueThatTriggersReversionCharges,
        onReject: resetMaxFill,
      });
    }
  };

  const onChangePresentation = (newPresentation: SortedCellPresentation | null) => {
    if (!worksheetIsForecast) {
      setBundleLabelFormatToDefault(newPresentation);
    }

    if (!carrierCode || !newPresentation) {
      resetConsumable();
      resetMaxPresentationWeight();
      return;
    }

    setConsumableToDefault(newPresentation);

    if (!cell.weightInGrams || !cell.maxPresentationFill) {
      setMaxPresentationWeightToDefault(newPresentation);
    } else {
      setMaxPresentationWeightKg(cell.weightInGrams, cell.maxPresentationFill);
    }
  };

  const setConsumableToDefault = (presentation: SortedCellPresentation) => {
    if (carrierCode == null) {
      return;
    }

    const {
      presentationConsumablesConfigurationByCarrierCode,
    } = metadata.sortedCellValidation.presentationConsumablesValidationConfiguration;

    const presentationConsumablesConfig =
      presentationConsumablesConfigurationByCarrierCode[carrierCode];

    const presentationAllowedConsumables =
      presentationConsumablesConfig == null
        ? null
        : presentationConsumablesConfig.allowedConsumablesByPresentationCode[presentation];

    const {
      defaultsAreOverriddenByMailingHouseCagesAndYorksPreferences,
    } = presentationConsumablesConfig;

    const defaultConsumable =
      presentationAllowedConsumables == null
        ? null
        : (defaultsAreOverriddenByMailingHouseCagesAndYorksPreferences &&
            consumableLimitationCode === 'Yorks' &&
            'Yorks') ||
          (defaultsAreOverriddenByMailingHouseCagesAndYorksPreferences &&
            consumableLimitationCode === 'Cages' &&
            'Cages') ||
          presentationAllowedConsumables.defaultConsumableCode;

    if (!defaultConsumable) {
      resetConsumable();
    } else {
      setFieldValueAndMarkUntouched('consumable', defaultConsumable);
    }
  };

  const setMaxPresentationWeightToDefault = (presentation: SortedCellPresentation) =>
    setFieldValueAndMarkUntouched(
      'maxPresentationWeightKg',
      metadata.sortedCellPresentations[presentation].defaultMaxWeightKg.toString(),
    );

  const setBundleLabelFormatToDefault = (presentation: SortedCellPresentation | null) =>
    setFieldValueAndMarkUntouched(
      'bundleLabelFormat',
      printingType && !printingTypeRequiresBundleLabelFormat(printingType)
        ? null
        : presentation
        ? metadata.sortedCellPresentations[presentation].defaultBundleLabelFormat
        : null,
    );

  const setMailmarkBarcodeToDefault = (serviceType: SortedCellServiceType | null) =>
    setFieldValueAndMarkUntouched(
      'mailmarkBarcode',
      serviceType ? metadata.sortedCellServiceTypes[serviceType].defaultMailmarkBarcode : null,
    );

  return (
    <Tr key={cellIndex}>
      <Td>
        <InputField inline={true} name={`${currentCellFieldName}.cellReference`} />
      </Td>
      <Td>
        <SelectField<SortedCellServiceType>
          inline={true}
          name={`${currentCellFieldName}.serviceType`}
          disabled={!validServiceTypes}
          onChange={(previousValue, newValue) => onChangeServiceType(newValue)}
          options={getSelectOptionsForMetadata(validServiceTypes, metadata.sortedCellServiceTypes)}
        />
      </Td>
      <Td>
        <SelectField<SortedCellFormat>
          inline={true}
          name={`${currentCellFieldName}.format`}
          disabled={!validFormats}
          onChange={() => onChangeFormat()}
          onBlur={() => validateMaxPresentationFill()}
          options={getSelectOptionsForMetadata(validFormats, metadata.sortedCellFormats)}
        />
      </Td>
      <Td>
        <SelectField<SortedCellVariant>
          inline={true}
          name={`${currentCellFieldName}.variant`}
          disabled={!validVariants}
          onBlur={() => validateMaxPresentationFill()}
          options={getSelectOptionsForMetadata(validVariants, metadata.sortedCellVariants)}
        />
      </Td>
      <Td>
        <InputField inline={true} name={`${currentCellFieldName}.quantity`} />
      </Td>
      <Td>
        <InputField
          inline={true}
          name={`${currentCellFieldName}.weightInGrams`}
          disabled={!serviceFormatRules}
          onChange={newValue => onChangeWeightInGrams(newValue)}
        />
      </Td>
      <Td>
        <SelectField<SortedCellPresentation>
          inline={true}
          name={`${currentCellFieldName}.presentation`}
          disabled={!validPresentations}
          onChange={(previousValue, newValue) => onChangePresentation(newValue)}
          options={getSelectOptionsForMetadata(
            validPresentations,
            metadata.sortedCellPresentations,
          )}
        />
        {presentationSelectionWarning && (
          <InlineWarningCallout>{presentationSelectionWarning}</InlineWarningCallout>
        )}
      </Td>
      <Td>
        <SelectField<SortedCellConsumable>
          inline={true}
          name={`${currentCellFieldName}.consumable`}
          disabled={!validConsumables}
          options={getSelectOptionsForMetadata(validConsumables, metadata.sortedCellConsumables)}
        />
        {nonDefaultConsumableIsSelected && nonDefaultConsumableSelectionWarning && (
          <InlineWarningCallout>{nonDefaultConsumableSelectionWarning}</InlineWarningCallout>
        )}
      </Td>
      <Td>
        <InputField
          inline={true}
          name={`${currentCellFieldName}.maxPresentationFill`}
          onBlur={() => validateMaxPresentationFill()}
          onChange={newValue => onChangeMaxPresentationFill(newValue)}
        />
      </Td>
      <Td>
        <InputField
          inline={true}
          name={`${currentCellFieldName}.maxPresentationWeightKg`}
          disabled={!cell.presentation || cell.presentation === 'Tray'}
        />
      </Td>
      <Td>
        <SelectField<SortedCellBundleLabelFormat>
          inline={true}
          name={`${currentCellFieldName}.bundleLabelFormat`}
          disabled={!validBundleLabelFormats}
          options={getSelectOptionsForMetadata(
            validBundleLabelFormats,
            metadata.sortedCellBundleLabelFormats,
          )}
          nullOptionText={validBundleLabelFormats ? undefined : 'N/A'}
        />
      </Td>
      <Td>
        <SelectField<SortedCellMailmarkBarcode>
          inline={true}
          name={`${currentCellFieldName}.mailmarkBarcode`}
          disabled={!validMailmarkBarcodes}
          options={getSelectOptionsForMetadata(
            validMailmarkBarcodes,
            metadata.sortedCellMailmarkBarcodes,
          )}
          nullOptionText={validMailmarkBarcodes ? undefined : 'N/A'}
        />
      </Td>
      <Td>
        <DeleteButton
          onClick={removeCell}
          colour={alertText}
          data-testid={deleteCellButtonTestId(cellIndex)}
        >
          <CrossIcon />
        </DeleteButton>
      </Td>
    </Tr>
  );
};

const enhance = flowRight(withMaxFillReversionChargesModal);

export const MailingBriefSortedCellFields = enhance(MailingBriefSortedCellFieldsComponent);

const InlineWarningCallout = styled(WarningCallout)`
  margin-top: ${narrow};
  padding: ${narrow};
  font-size: ${smallFont};
`;

const DeleteButton = styled(IconButton)`
  height: 20px;
  width: 20px;
  margin-top: 8px;
`;
