import { includes, intersection, orderBy, values } from 'lodash';
import { Id } from '../../../models/id';
import { Metadata, WorksheetStatusCode, WorksheetTransitionName } from '../../../models/metadata';
import { getSelectOptions, SelectOptions } from '../../../shared/form/inputs/Select';
import { fetchSecureJson, from, withJsonBody } from '../../../utils/api';
import { assertNotNull } from '../../../utils/assertNotNull';
import { Permission } from '../../authentication/permissions';
import { User } from '../../authentication/user';
import { Job } from '../../jobs/job';
import { Worksheet, WorksheetMailingHouseDetails, WorksheetType } from '../worksheet';
import { OtherBuyingPrice, OtherCommissionItem, OtherSellingPrice } from './prices/otherPrices';

export type OtherWorksheet = Worksheet & {
  type: OtherWorksheetType;
  otherWorksheetId: Id;
  mailingHouse: WorksheetMailingHouseDetails | null;
  processingCentre: OtherWorksheetProcessingCentre | null;
  notes: string | null;
  buyingPrices: Array<OtherBuyingPrice> | null;
  sellingPrices: Array<OtherSellingPrice> | null;
  commissionItems: Array<OtherCommissionItem> | null;
};

export type OtherWorksheetType =
  | 'Fulfilment'
  | 'DataCleansing'
  | 'Production'
  | 'BagAndTag'
  | 'Returns';

export const otherWorksheetTypes: Array<OtherWorksheetType> = [
  'Fulfilment',
  'DataCleansing',
  'Production',
  'BagAndTag',
  'Returns',
];

export const isOtherWorksheetType = (type: WorksheetType): type is OtherWorksheetType =>
  includes(otherWorksheetTypes, type);

export const isOtherWorksheet = (worksheet: Worksheet): worksheet is OtherWorksheet =>
  isOtherWorksheetType(worksheet.type);

export const otherWorksheetEditPermissions: { [type in OtherWorksheetType]: Permission } = {
  Fulfilment: 'CreateAndEditFulfilmentWorksheets',
  DataCleansing: 'CreateAndEditDataCleansingWorksheets',
  Production: 'CreateAndEditProductionWorksheets',
  BagAndTag: 'CreateAndEditBagAndTagWorksheets',
  Returns: 'CreateAndEditReturnsWorksheets',
};

export const otherWorksheetApplySimpleTransitionsPermissions: {
  [type in OtherWorksheetType]: Permission;
} = {
  Fulfilment: 'ApplyFulfilmentWorksheetSimpleTransitions',
  DataCleansing: 'ApplyDataCleansingWorksheetSimpleTransitions',
  Production: 'ApplyProductionWorksheetSimpleTransitions',
  BagAndTag: 'ApplyBagAndTagWorksheetSimpleTransitions',
  Returns: 'ApplyReturnsWorksheetSimpleTransitions',
};

export type OtherWorksheetTypeWithPrices = Exclude<OtherWorksheetType, 'Fulfilment'>;
export type OtherWorksheetTypeWithoutPrices = Exclude<
  OtherWorksheetType,
  OtherWorksheetTypeWithPrices
>;

export const otherWorksheetCanHavePrices = (
  type: OtherWorksheetType,
): type is OtherWorksheetTypeWithPrices => type !== 'Fulfilment';

export const otherWorksheetEditBuyingPricePermissions: {
  [type in OtherWorksheetTypeWithPrices]: Permission;
} = {
  DataCleansing: 'EditDataCleansingBuyingPrices',
  Production: 'EditProductionBuyingPrices',
  BagAndTag: 'EditBagAndTagBuyingPrices',
  Returns: 'EditReturnsBuyingPrices',
};

export const otherWorksheetViewSellingPricePermissions: {
  [type in OtherWorksheetTypeWithPrices]: Permission;
} = {
  DataCleansing: 'ViewDataCleansingSellingPrices',
  Production: 'ViewProductionSellingPrices',
  BagAndTag: 'ViewBagAndTagSellingPrices',
  Returns: 'ViewReturnsSellingPrices',
};

export const otherWorksheetEditSellingPricePermissions: {
  [type in OtherWorksheetTypeWithPrices]: Permission;
} = {
  DataCleansing: 'EditDataCleansingSellingPrices',
  Production: 'EditProductionSellingPrices',
  BagAndTag: 'EditBagAndTagSellingPrices',
  Returns: 'EditReturnsSellingPrices',
};

export const otherWorksheetsEditPriceAttachmentsPermission: {
  [type in OtherWorksheetTypeWithoutPrices]: Permission;
} = {
  Fulfilment: 'EditFulfilmentPrices',
};

export const userCanCreateOrEditOtherWorksheets = (user: User): boolean =>
  intersection(values(otherWorksheetEditPermissions), user.permissions).length > 0;

export const otherWorksheetRequiresMailingHouse = (type: OtherWorksheetType): boolean =>
  type === 'Production';

export const otherWorksheetRequiresProcessingCentre = (type: OtherWorksheetType): boolean =>
  type !== 'Production';

export type OtherWorksheetProcessingCentre =
  | 'Bristol'
  | 'WestsidePark'
  | 'Warrington'
  | 'Luton'
  | 'Maidstone'
  | 'AJT'
  | 'DcOffsite';

export const otherWorksheetProcessingCentreDisplayNames: {
  [schedule in OtherWorksheetProcessingCentre]: string;
} = {
  Bristol: 'Bristol',
  WestsidePark: 'Westside Park',
  Warrington: 'Warrington',
  Luton: 'Luton',
  Maidstone: 'Maidstone',
  AJT: 'AJ & T',
  DcOffsite: 'DC OffSite',
};

export const otherWorksheetProcessingCentres = Object.keys(
  otherWorksheetProcessingCentreDisplayNames,
) as Array<OtherWorksheetProcessingCentre>;

export const otherProcessingCentreSelectOptions: SelectOptions<
  OtherWorksheetProcessingCentre
> = getSelectOptions(otherWorksheetProcessingCentres, otherWorksheetProcessingCentreDisplayNames);

// Simple transitions are ones for which buttons are shown
// on the worksheet details panel and the manage worksheets table

export type OtherWorksheetSimpleTransition = {
  displayOrder: number;
  transitionName: WorksheetTransitionName;
  endpoint: string;
};

type OtherWorksheetSimpleTransitions = Array<OtherWorksheetSimpleTransition>;

export const applyOtherSimpleTransition = (
  otherWorksheetId: Id,
  simpleTransition: OtherWorksheetSimpleTransition,
) =>
  fetchSecureJson<Job>(
    withJsonBody({ otherWorksheetId })(
      from(`other-worksheets/${otherWorksheetId}/${simpleTransition.endpoint}`, 'put'),
    ),
  );

export const otherWorksheetSimpleTransitions: OtherWorksheetSimpleTransitions = [
  {
    displayOrder: 0,
    transitionName: 'SubmitToFinance',
    endpoint: 'submit-to-finance',
  },
];

export const getApplicableOtherSimpleTransitions = (
  type: OtherWorksheetType,
  statusCode: WorksheetStatusCode,
  metadata: Metadata,
) =>
  orderBy(
    otherWorksheetSimpleTransitions.filter((simpleTransition: OtherWorksheetSimpleTransition) => {
      const transitionStatuses = assertNotNull(
        metadata.worksheetTransitions[simpleTransition.transitionName].statusesByWorksheetType[
          type
        ],
      );
      return includes(transitionStatuses.fromStatuses, statusCode);
    }),
    simpleTransition => simpleTransition.displayOrder,
  );
