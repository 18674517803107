import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { ReactComponent as SolidSquareIcon } from '../../../images/icons/solid-square-icon.svg';
import { ReactComponent as TickSquareIcon } from '../../../images/icons/tick-square-icon.svg';
import {
  alertText,
  blue,
  blueHoverAccent,
  darkGrey,
  fontGrey,
  lightGrey,
  lightGreyHoverAccent,
  successText,
  warningText,
} from '../../../styling/colours';
import { regularFont } from '../../../styling/fonts';
import { fastTransitionDuration } from '../../../styling/transitions';

export const checkboxInputTestId = 'checkbox-input';
export const checkboxIconContainerTestId = 'checkbox-icon-container';

type CheckboxProps = {
  name: string;
  label?: string;
  value: boolean;
  onChange: (newValue: boolean) => void;
  onBlur?: () => void;
  disabled?: boolean;
  valid?: boolean;
  invalid?: boolean;
  showWarning?: boolean;
  invertedColour?: boolean;
  'data-testid'?: string;
};

type CheckboxState = {
  focused: boolean;
};

export class Checkbox extends React.Component<CheckboxProps, CheckboxState> {
  state = { focused: false };

  onFocus = () => this.setState({ focused: true });

  onBlur = () => {
    this.setState({ focused: false }, () => this.props.onBlur && this.props.onBlur());
  };

  onChange = () => {
    this.props.onChange(!this.props.value);
  };

  render() {
    return (
      <CheckboxFieldContainer>
        <CheckboxLabel
          disabled={this.props.disabled}
          invalid={this.props.invalid}
          showWarning={this.props.showWarning}
          valid={this.props.valid}
        >
          <CheckboxInput
            type="checkbox"
            id={this.props.name}
            name={this.props.name}
            onChange={this.onChange}
            disabled={this.props.disabled}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            data-testid={this.props['data-testid'] || checkboxInputTestId}
            value={this.props.value == null ? 'false' : this.props.value.toString()}
          />
          <CheckboxIconContainer
            checked={this.props.value}
            disabled={this.props.disabled}
            focused={this.state.focused}
            invertedColour={this.props.invertedColour}
            data-testid={checkboxIconContainerTestId}
          >
            {!this.props.value && <SolidSquareIcon />}
            {this.props.value && <TickSquareIcon />}
          </CheckboxIconContainer>
          {this.props.label && (
            <CheckboxLabelText htmlFor={this.props.name}>{this.props.label}</CheckboxLabelText>
          )}
        </CheckboxLabel>
      </CheckboxFieldContainer>
    );
  }
}

const CheckboxFieldContainer = styled.div`
  display: inline-block;
`;

const CheckboxInput = styled.input`
  margin: 0;
  height: ${regularFont};
  width: ${regularFont};
  z-index: 1;
  opacity: 0;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;

type CheckboxLabelProps = {
  disabled?: boolean;
  valid?: boolean;
  invalid?: boolean;
  showWarning?: boolean;
};

const CheckboxLabel = styled.label<CheckboxLabelProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${props =>
    (props.valid && successText) ||
    (props.invalid && alertText) ||
    (props.showWarning && warningText) ||
    (props.disabled && darkGrey) ||
    fontGrey};
  user-select: none;
`;

type CheckboxIconContainerProps = {
  checked: boolean;
  disabled?: boolean;
  focused: boolean;
  invertedColour?: boolean;
};

const CheckboxIconContainer = styled.div<CheckboxIconContainerProps>`
  height: ${regularFont};
  width: ${regularFont};
  position: absolute;
  color: ${props =>
    (props.checked && (props.invertedColour ? lightGreyHoverAccent : blue)) ||
    lightGreyHoverAccent};
  outline: 1px solid ${props => (props.focused && darkGrey) || 'transparent'};
  transition: all ${fastTransitionDuration} ease;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  ${CheckboxLabel}:hover & {
    color: ${props =>
      props.disabled ? (props.checked && blue) || lightGreyHoverAccent : blueHoverAccent};
  }

  ${props =>
    props.checked &&
    css`
      svg {
        background-color: ${props.invertedColour ? blue : lightGrey};
      }
    `}
`;

const CheckboxLabelText = styled.label`
  margin-left: 10px;
`;
