import { isSunday } from 'date-fns';
import { includes } from 'lodash';
import * as React from 'react';
import { SyntheticEvent, useContext } from 'react';
import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components/macro';
import { ClientPortalMetadataContext } from '../../../features/client-portal/clientPortalMetadata';
import { DateStamp, isOnTheWeekend, toDate, toDateStamp } from '../../../models/dateStamp';
import { MetadataContext } from '../../../models/metadata';
import {
  blue,
  blueHoverAccent,
  categoryRed,
  categoryRedLight,
  fontGrey,
  greyHoverAccent,
  lightGrey,
  lightGreyHoverAccent,
  white,
} from '../../../styling/colours';
import { narrow } from '../../../styling/spacing';
import { fastTransitionDuration } from '../../../styling/transitions';
import { Input } from './InputField';

type Props = {
  name: string;
  value: DateStamp | null;
  inline?: boolean;
  valid?: boolean;
  invalid?: boolean;
  excludeNonWorkingDays?: boolean;
  excludeSundays?: boolean;
  showWarning?: boolean;
  disabled?: boolean;
  onChange?: (newDate: DateStamp | null) => void;
  onBlur?: () => void;
  minDate?: DateStamp | null;
  maxDate?: DateStamp | null;
};

export const dateFieldFormatString = 'dd/MM/yyyy';

export const DatePicker = (props: Props) => {
  const { value, inline, valid, invalid, showWarning, disabled, onChange, onBlur } = props;

  const valueAsDate = value != null ? toDate(value) : null;
  const minDate = props.minDate == null ? undefined : toDate(props.minDate);
  const maxDate = props.maxDate == null ? undefined : toDate(props.maxDate);

  const onChangeDate = (newDate: Date | null, event?: SyntheticEvent) => {
    if (onChange == null) {
      return;
    }
    const newValue: DateStamp | null = newDate != null ? toDateStamp(newDate) : null;
    onChange(newValue);
  };

  const metadata = useContext(MetadataContext);
  const clientPortalMetadata = useContext(ClientPortalMetadataContext);

  const bankHolidays =
    metadata != null
      ? metadata.bankHolidays
      : clientPortalMetadata != null
      ? clientPortalMetadata.bankHolidays
      : null;

  if (bankHolidays == null) {
    throw new Error('Could not determine bank holidays (is there a metadata context initialised?)');
  }

  const isWorkingDay = (date: Date): boolean => {
    if (isOnTheWeekend(date)) {
      return false;
    }
    if (includes(bankHolidays, toDateStamp(date))) {
      return false;
    }
    return true;
  };

  const dateFilter = (date: Date): boolean => {
    if (props.excludeNonWorkingDays && !isWorkingDay(date)) {
      return false;
    }
    if (props.excludeSundays && isSunday(date)) {
      return false;
    }
    return true;
  };

  return (
    <DatePickerContainer>
      <ReactDatePicker
        customInput={
          <Input valid={valid} invalid={invalid} showWarning={showWarning} inline={inline} />
        }
        id={props.name}
        disabled={disabled}
        onChange={onChangeDate}
        dateFormat={dateFieldFormatString}
        selected={valueAsDate}
        onBlur={onBlur}
        minDate={minDate}
        maxDate={maxDate}
        name={props.name}
        locale="en-GB" // So that the week starts on Monday
        dayClassName={getDateClassName(bankHolidays)}
        filterDate={dateFilter}
        autoComplete="off"
      />
    </DatePickerContainer>
  );
};

const dayBlockAndElement = 'react-datepicker__day';

const weekendDateModifierName = 'weekend-day';
export const weekendDateClassName = `${dayBlockAndElement}--${weekendDateModifierName}`;

const bankHolidayDateModifierName = 'bank-holiday';
export const bankHolidayDateClassName = `${dayBlockAndElement}--${bankHolidayDateModifierName}`;

export const getDateClassName = (bankHolidays: Array<DateStamp>) => (date: Date): string => {
  const classNames = [];

  if (isOnTheWeekend(date)) {
    classNames.push(weekendDateClassName);
  }

  if (includes(bankHolidays, toDateStamp(date))) {
    classNames.push(bankHolidayDateClassName);
  }

  return classNames.join(' ');
};

export const DatePickerContainer = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    font-family: 'Calibri', sans-serif;
    border-radius: 0;

    &__input-container {
      width: 100%;
    }

    &__header {
      background-color: ${lightGrey};
      border-radius: 0;
    }

    &__current-month,
    &__day-name {
      color: ${fontGrey};
    }

    &__day {
      color: ${fontGrey};
      border-radius: 0 !important;
      transition: background-color ${fastTransitionDuration} ease,
        color ${fastTransitionDuration} ease;

      &--${weekendDateModifierName} {
        background-color: ${lightGrey};
        font-style: italic;
      }

      &--${bankHolidayDateModifierName} {
        border: solid 1px ${categoryRed};
        background-color: ${categoryRedLight};
        font-style: italic;
        position: relative;

        &:hover::after {
          content: 'Bank Holiday';
          background-color: ${categoryRedLight};
          border: ${categoryRed} solid 1px;
          display: block;
          position: absolute;
          padding: 0 ${narrow};
          top: -1px;
          left: 30px;
          color: ${categoryRed};
          z-index: 1;
          box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.4);
        }
      }

      &--today {
        background-color: ${lightGreyHoverAccent};
        color: ${fontGrey};
      }

      &--keyboard-selected {
        background-color: ${blue};
        color: ${white};
      }

      &--disabled {
        opacity: 0.5;
        cursor: not-allowed;

        &::after {
          opacity: 0;
        }
      }

      &--selected {
        background-color: ${blueHoverAccent};
        color: ${white};
      }

      &:hover {
        background-color: ${greyHoverAccent};
        color: ${white};
      }
    }
  }
`;
