import { Id } from '../../../../models/id';
import { CustomerContact } from '../../../customers/customerContact';
import { InternationalWorksheetCustomerContact } from '../internationalWorksheet';
import { CreateUpdateInternationalWorksheetCustomerContactDto } from './createUpdateInternationalWorksheetCustomerContactDto';

export type InternationalWorksheetCustomerContactFormModel = {
  customerContactId: Id;
  includeOnCommunications: boolean;
};

export const toCreateUpdateInternationalWorksheetCustomerContactDto = (
  formModel: InternationalWorksheetCustomerContactFormModel,
): CreateUpdateInternationalWorksheetCustomerContactDto => ({
  ...formModel,
});

export const fromInternationalWorksheetCustomerContact = ({
  customerContactId,
  includeOnCommunications,
}: InternationalWorksheetCustomerContact): InternationalWorksheetCustomerContactFormModel => ({
  customerContactId,
  includeOnCommunications,
});

export const fromCustomerContact = ({
  customerContactId,
}: CustomerContact): InternationalWorksheetCustomerContactFormModel => ({
  customerContactId,
  includeOnCommunications: false,
});
