export const homeUrl = () => '/';
const homeUrlSlash = (path: string) => homeUrl() + path;

export const clientServicesManageWorksheetsUrl = () =>
  homeUrlSlash('client-services/manage-worksheets');

export const internationalManageWorksheetsUrl = () =>
  homeUrlSlash('international/manage-worksheets');

export const operationsManageWorksheetsUrl = () => homeUrlSlash('operations/manage-worksheets');

export const connectManageWorksheetsUrl = () => homeUrlSlash('connect/manage-worksheets');

export const dataProcessingUrl = () => homeUrlSlash('data-processing');

export const managerApprovalUrl = () => homeUrlSlash('manager-approval');

export const financeUrl = () => homeUrlSlash('finance');

export const collectionsUrl = () => homeUrlSlash('collections');

export const consumablesUrl = () => homeUrlSlash('consumables');

export const customersUrl = () => homeUrlSlash('customers');

export const mailingHousesUrl = () => homeUrlSlash('mailing-houses');

export const manageScidsUrl = () => homeUrlSlash('manage-scids');
export const manageUcidsUrl = () => homeUrlSlash('manage-ucids');

export const internationalWorksheetsUrl = () => homeUrlSlash('international');

export const unsortedWorksheetsUrl = () => homeUrlSlash('unsorted');

export const clientPortalDataUploadsUrl = () => homeUrlSlash('customer-file-uploads');

export const reportsUrl = () => homeUrlSlash('reports');

export const loggedOutUrl = () => homeUrlSlash('logged-out');
