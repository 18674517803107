import { FieldArray, FieldArrayRenderProps, FormikProps } from 'formik';
import { isEmpty, some, sum } from 'lodash';
import * as React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components/macro';
import { HollowButton } from '../../../../shared/buttons/Button';
import { WarningCallout } from '../../../../shared/Callout';
import { standardVerticalFormFieldSpacing } from '../../../../shared/form/FormField';
import { TopLevelValidationError } from '../../../../shared/form/TopLevelValidationError';
import {
  FullWidthFixedTable,
  TBody,
  Td,
  TFoot,
  Th,
  THead,
  Tr,
} from '../../../../shared/table/Table';
import { tdgGreen } from '../../../../styling/colours';
import { medium, narrow } from '../../../../styling/spacing';
import { memoize } from '../../../../utils/memoize/memoize';
import { useIsFirstRender } from '../../../../utils/useIsFirstRender';
import { ConsumableLimitationCode } from '../../../mailing-houses/mailingHouse';
import { SortedCellPresentation } from '../../../worksheets/sorted/cells/sortedCell';
import {
  printingTypeRequiresBundleLabelFormat,
  WorksheetPrintingType,
} from '../../../worksheets/sorted/edit-form/sortedWorksheetFormModel';
import { useClientPortalMetadata } from '../../clientPortalMetadata';
import { MailingBriefMailingHouseFormModel } from '../mailingBriefMailingHouseFormModel';
import { MailingBriefSortedCellFields } from './MailingBriefSortedCellFields';
import {
  getInitialMailingBriefSortedCellFormModel,
  MailingBriefSortedCellFormModel,
} from './mailingBriefSortedCellFormModel';

type BaseWorksheetFormModel = {
  dataProcessingAssigneeUserId: string | null;
  carrierCode: 'SecuredMail';
  mailingHouse: MailingBriefMailingHouseFormModel;
  cells: Array<MailingBriefSortedCellFormModel>;
};

type Props<TFormModel, TWorksheet extends BaseWorksheetFormModel> = {
  worksheetFieldName: string;
  worksheetFormModel: TWorksheet;
  formProps: FormikProps<TFormModel>;
  consumableLimitationCode: ConsumableLimitationCode | null;
  printingType: WorksheetPrintingType | null;
  isAgencyAgreement: boolean;
};

const totalQuantityWarningThreshold = 4000;

export const deleteCellButtonTestId = (cellIndex: number) => `deleteCellButton${cellIndex}`;

export const MailingBriefSortedCellsFieldArray = <
  TFormModel,
  TWorksheet extends BaseWorksheetFormModel
>(
  props: Props<TFormModel, TWorksheet>,
) => {
  const {
    worksheetFieldName,
    worksheetFormModel,
    formProps,
    consumableLimitationCode,
    printingType,
    isAgencyAgreement,
  } = props;

  const metadata = useClientPortalMetadata();

  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    if (isFirstRender) {
      return;
    }

    for (let cellIndex = 0; cellIndex < worksheetFormModel.cells.length; cellIndex++) {
      const presentation = worksheetFormModel.cells[cellIndex].presentation;
      if (presentation != null) {
        setConsumableToDefault(cellIndex, presentation);
      }
    }
  }, [worksheetFormModel.carrierCode, worksheetFormModel.mailingHouse.mailingHouseId]);

  useEffect(() => {
    if (isFirstRender) {
      return;
    }

    worksheetFormModel.cells.forEach((cell, cellIndex) =>
      setBundleLabelFormatToDefault(cellIndex, cell.presentation),
    );
  }, [printingType]);

  const addCell = () => {
    const currentCells = worksheetFormModel.cells;
    const newCell: MailingBriefSortedCellFormModel = {
      ...getInitialMailingBriefSortedCellFormModel(),
    };
    formProps.setFieldValue(`${worksheetFieldName}.cells`, [...currentCells, newCell]);
  };

  const removeCell = (arrayHelpers: FieldArrayRenderProps, cellIndex: number) => {
    arrayHelpers.remove(cellIndex);
  };

  const getCellFieldName = (cellIndex: number) => `${worksheetFieldName}.cells.${cellIndex}`;

  const setFieldValueAndMarkUntouched = <T extends unknown>(
    cellIndex: number,
    fieldName: keyof MailingBriefSortedCellFormModel,
    value: T | null,
  ) => {
    const formFieldName = `${getCellFieldName(cellIndex)}.${fieldName}`;
    formProps.setFieldValue(formFieldName, value);
    formProps.setFieldTouched(formFieldName, false);
  };

  const resetField = <T extends unknown>(
    cellIndex: number,
    fieldName: keyof MailingBriefSortedCellFormModel,
  ) => setFieldValueAndMarkUntouched(cellIndex, fieldName, null);

  const resetConsumable = (cellIndex: number) => {
    resetField(cellIndex, 'consumable');
  };

  const setConsumableToDefault = (cellIndex: number, presentation: SortedCellPresentation) => {
    const { carrierCode } = worksheetFormModel;

    if (carrierCode == null) {
      return;
    }

    const {
      presentationConsumablesConfigurationByCarrierCode,
    } = metadata.sortedCellValidation.presentationConsumablesValidationConfiguration;

    const presentationConsumablesConfig =
      presentationConsumablesConfigurationByCarrierCode[carrierCode];

    const presentationAllowedConsumables =
      presentationConsumablesConfig == null
        ? null
        : presentationConsumablesConfig.allowedConsumablesByPresentationCode[presentation];

    const {
      defaultsAreOverriddenByMailingHouseCagesAndYorksPreferences,
    } = presentationConsumablesConfig;

    const defaultConsumable =
      presentationAllowedConsumables == null
        ? null
        : (defaultsAreOverriddenByMailingHouseCagesAndYorksPreferences &&
            consumableLimitationCode === 'Yorks' &&
            'Yorks') ||
          (defaultsAreOverriddenByMailingHouseCagesAndYorksPreferences &&
            consumableLimitationCode === 'Cages' &&
            'Cages') ||
          presentationAllowedConsumables.defaultConsumableCode;

    if (!defaultConsumable) {
      resetConsumable(cellIndex);
    } else {
      setFieldValueAndMarkUntouched(cellIndex, 'consumable', defaultConsumable);
    }
  };

  const setBundleLabelFormatToDefault = (
    cellIndex: number,
    presentation: SortedCellPresentation | null,
  ) =>
    setFieldValueAndMarkUntouched(
      cellIndex,
      'bundleLabelFormat',
      printingType && !printingTypeRequiresBundleLabelFormat(printingType)
        ? null
        : presentation
        ? metadata.sortedCellPresentations[presentation].defaultBundleLabelFormat
        : null,
    );

  const getTotalQuantityAcrossAllCellsOrNull = memoize((quantityStrings: Array<string>) => {
    const quantities = quantityStrings.map(quantity => {
      const trimmedQuantity = quantity.trim();
      return !trimmedQuantity ? null : Number(trimmedQuantity);
    });

    return isEmpty(quantities) || some(quantities, quantity => quantity == null || isNaN(quantity))
      ? null
      : sum(quantities);
  });

  const renderFieldArray = (arrayHelpers: FieldArrayRenderProps) => {
    const { carrierCode } = worksheetFormModel;
    const { cells } = worksheetFormModel;

    const totalQuantityAcrossAllCellsOrNull = getTotalQuantityAcrossAllCellsOrNull(
      cells.map(cell => cell.quantity),
    );

    const anyCellsUsingSoonToBeRemovedServices = cells.some(
      cell => cell.serviceType === 'SeventyOcr' || cell.serviceType === 'FourteenHundredDirect',
    );

    return (
      <Container>
        <EditCellsTable>
          <THead>
            <Tr>
              <CellReferenceColumnHeader>File Ref</CellReferenceColumnHeader>
              <Th>Service Type</Th>
              <Th>Format</Th>
              <Th>Variant</Th>
              <QuantityColumnHeader>Quantity</QuantityColumnHeader>
              <WeightColumnHeader>Weight (g)</WeightColumnHeader>
              <Th>Presentation</Th>
              <Th>Consumable</Th>
              <Th>Max Fill</Th>
              <Th>Max Weight (kg)</Th>
              <Th>Bundle Label</Th>
              <Th>Mailmark Barcode</Th>
              <DeleteCellColumnHeader />
            </Tr>
          </THead>
          <TBody>
            {!!cells.length &&
              cells.map((cell, cellIndex) => (
                <MailingBriefSortedCellFields
                  key={cellIndex}
                  cell={cell}
                  cellIndex={cellIndex}
                  carrierCode={carrierCode}
                  formProps={formProps}
                  removeCell={() => removeCell(arrayHelpers, cellIndex)}
                  consumableLimitationCode={consumableLimitationCode}
                  printingType={printingType}
                  worksheetFieldName={worksheetFieldName}
                  isAgencyAgreement={isAgencyAgreement}
                />
              ))}
          </TBody>
          <TFoot>
            <Tr>
              <Td colSpan={13}>
                <AddCellButton onClick={addCell}>+ Add Cell</AddCellButton>
              </Td>
            </Tr>
          </TFoot>
        </EditCellsTable>
        <CellsTopLevelValidationError {...formProps} fieldName={`${worksheetFieldName}.cells`} />
        {totalQuantityAcrossAllCellsOrNull != null &&
        totalQuantityAcrossAllCellsOrNull < totalQuantityWarningThreshold ? (
          <TotalQuantityWarningCallout>
            Total quantity under {totalQuantityWarningThreshold.toLocaleString()}
          </TotalQuantityWarningCallout>
        ) : null}
        {anyCellsUsingSoonToBeRemovedServices ? (
          <TotalQuantityWarningCallout>
            The 1400 Direct and 70 OCR service types will be removed as of 1st April 2024. Please
            contact your Account Manager for alternative services.
          </TotalQuantityWarningCallout>
        ) : null}
      </Container>
    );
  };

  return <FieldArray name={`${props.worksheetFieldName}.cells`} render={renderFieldArray} />;
};

const Container = styled.div`
  margin-bottom: ${standardVerticalFormFieldSpacing};
`;

const AddCellButton = styled(HollowButton)`
  margin-bottom: ${medium};
  width: 100%;
  height: 100%;
  color: ${tdgGreen};
  border: none;
`;

const EditCellsTable = styled(FullWidthFixedTable)`
  ${Td} {
    padding: ${narrow} ${narrow};
    vertical-align: top;
  }
`;

const CellReferenceColumnHeader = styled(Th)``;

const QuantityColumnHeader = styled(Th)`
  width: 90px;
`;

const WeightColumnHeader = styled(Th)`
  width: 90px;
`;

const DeleteCellColumnHeader = styled(Th)`
  width: 30px;
`;

const TotalQuantityWarningCallout = styled(WarningCallout)`
  margin-top: ${medium};
`;

const CellsTopLevelValidationError = styled(TopLevelValidationError)`
  margin-top: ${medium};
`;
