import * as React from 'react';
import styled from 'styled-components/macro';
import { NoDataTableDataCell, TBody, Td, Th, THead, Tr } from '../../../../shared/table/Table';
import { TickOrCross } from '../../../../shared/TickOrCross';
import {
  WorksheetContactDetailsTableData,
  WorksheetContactsTable,
} from '../../../worksheets/contacts/WorksheetContactsTable';
import { ClientPortalWorksheetContactDetails } from '../../ClientPortalWorksheetContactDetails';
import { ClientPortalSortedWorksheetMailingHouseContact } from './ClientPortalSortedWorksheet';

type Props = {
  contacts: Array<ClientPortalSortedWorksheetMailingHouseContact>;
};

export const ClientPortalSortedWorksheetMailingHouseContactsTable = ({ contacts }: Props) => (
  <WorksheetContactsTable>
    <THead>
      <Tr>
        <Th>Contact Details</Th>
        <DataTableHeader>CC</DataTableHeader>
        <DataTableHeader>Data</DataTableHeader>
        <LabelsTableHeader>Labels</LabelsTableHeader>
        <CollectionsTableHeader>Collections</CollectionsTableHeader>
        <ConsumablesTableHeader>Consumables</ConsumablesTableHeader>
      </Tr>
    </THead>
    <TBody>
      {contacts.length === 0 && (
        <Tr>
          <NoDataTableDataCell colSpan={6}>
            -- No contacts have been configured on this worksheet --
          </NoDataTableDataCell>
        </Tr>
      )}
      {contacts.map((contact, index) => (
        <MailingHouseContactRow key={index} contact={contact} />
      ))}
    </TBody>
  </WorksheetContactsTable>
);

const IncludeOnCommunicationTableHeader = styled(Th)`
  text-align: center;
`;

const LabelsTableHeader = styled(IncludeOnCommunicationTableHeader)`
  width: 90px;
`;

const DataTableHeader = styled(IncludeOnCommunicationTableHeader)`
  width: 90px;
`;

const CollectionsTableHeader = styled(IncludeOnCommunicationTableHeader)`
  width: 120px;
`;

const ConsumablesTableHeader = styled(IncludeOnCommunicationTableHeader)`
  width: 120px;
`;

type ContactRowProps = {
  contact: ClientPortalSortedWorksheetMailingHouseContact;
};

const MailingHouseContactRow = ({ contact }: ContactRowProps) => {
  return (
    <Tr>
      <WorksheetContactDetailsTableData>
        <ClientPortalWorksheetContactDetails contact={contact} />
      </WorksheetContactDetailsTableData>
      <Td>
        <TickOrCross centered={true} value={contact.includeOnDataCommunication === 'CarbonCopy'} />
      </Td>
      <Td>
        <TickOrCross
          centered={true}
          value={contact.includeOnDataCommunication === 'LinkAndPassword'}
        />
      </Td>
      <Td>
        <TickOrCross centered={true} value={contact.includeOnLabelsCommunication} />
      </Td>
      <Td>
        <TickOrCross centered={true} value={contact.includeOnCollectionsCommunication} />
      </Td>
      <Td>
        <TickOrCross centered={true} value={contact.includeOnConsumablesCommunication} />
      </Td>
    </Tr>
  );
};
