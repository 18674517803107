// Polyfills (required for public facing pages):
import '@babel/polyfill';
import 'fast-text-encoding';
import 'react-app-polyfill/ie11';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { setupApplicationInsights } from './applicationInsights';
import { ClientPortalApp } from './features/client-portal/ClientPortalApp';

setupApplicationInsights();
ReactDOM.render(<ClientPortalApp />, document.getElementById('root') as HTMLElement);
