import { connect, FormikContext } from 'formik';
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as CrossIcon } from '../../../../images/icons/cross-icon.svg';
import { IconButton } from '../../../../shared/buttons/IconButton';
import { inlineFormInputHeightPixels } from '../../../../shared/form/formInputStyling';
import { CheckboxField } from '../../../../shared/form/inputs/CheckboxField';
import { InputField } from '../../../../shared/form/inputs/InputField';
import { getSelectOptions } from '../../../../shared/form/inputs/Select';
import { SelectField } from '../../../../shared/form/inputs/SelectField';
import { Td, Tr } from '../../../../shared/table/Table';
import { alertText } from '../../../../styling/colours';
import { narrow } from '../../../../styling/spacing';
import { assertNotNull } from '../../../../utils/assertNotNull';
import {
  UnsortedCellFormat,
  unsortedCellFormatDisplayNames,
  UnsortedCellProcessingType,
  unsortedCellProcessingTypes,
} from '../../../worksheets/unsorted/cells/unsortedCell';
import { getValidOptionsForCell } from '../../../worksheets/unsorted/cells/unsortedCellValidator';
import { useClientPortalMetadata } from '../../clientPortalMetadata';
import { CustomerForMailingBriefForm } from '../dataForMailingBriefForm';
import { useDataForMailingBriefForm } from '../DataForMailingBriefFormContext';
import { MailingBriefFormModel } from '../mailingBriefFormModel';
import {
  getAllowedFormatsFromProcessingType,
  getServiceTypeFromProcessingType,
  MailingBriefUnsortedCellFormat,
  MailingBriefUnsortedCellFormModel,
} from './mailingBriefUnsortedCellFormModel';

type OwnProps = {
  worksheetFieldName: string;
  cell: MailingBriefUnsortedCellFormModel;
  cellIndex: number;
  onChangeFormat: () => void;
  onChangeProcessingType: () => void;
  removeCell: () => void;
};

type Props = OwnProps & { formik: FormikContext<MailingBriefFormModel> };

const MailingBriefUnsortedCellFieldsComponent = (props: Props) => {
  const metadata = useClientPortalMetadata();
  const dataForMailingBriefForm = useDataForMailingBriefForm();

  const {
    worksheetFieldName,
    cell,
    cellIndex,
    onChangeFormat,
    onChangeProcessingType,
    removeCell,
  } = props;

  const selectedCustomerId = props.formik.values.customerId;

  const selectedCustomer: CustomerForMailingBriefForm | null =
    selectedCustomerId == null
      ? null
      : assertNotNull(dataForMailingBriefForm.customersByCustomerId[selectedCustomerId]);

  useSetServiceTypeFromProcessingType(props.formik, selectedCustomer, cell, cellIndex);

  const currentCellFieldName = `${worksheetFieldName}.cells.${cellIndex}`;

  const validOptionsForCell = getValidOptionsForCell(cell, metadata.unsortedCellValidation);

  const { isPpiPrintedAvailable, isReturnAddressPrintingAvailable } = validOptionsForCell;

  const validFormats: Array<MailingBriefUnsortedCellFormat> = getAllowedFormatsFromProcessingType(
    cell.processingType,
  );

  const formatOptions = getSelectOptions(validFormats, unsortedCellFormatDisplayNames);

  const processingTypeOptions = getSelectOptions(unsortedCellProcessingTypes, {
    Machine: 'Mech',
    Manual: 'Non-Mech',
  });

  return (
    <Tr key={cell.uniqueFormId}>
      <InputTd>
        <InputField inline={true} name={`${currentCellFieldName}.cellReference`} />
      </InputTd>
      <InputTd>
        <SelectField<UnsortedCellProcessingType>
          inline={true}
          name={`${currentCellFieldName}.processingType`}
          onChange={onChangeProcessingType}
          options={processingTypeOptions}
        />
      </InputTd>
      <InputTd>
        <SelectField<UnsortedCellFormat>
          inline={true}
          name={`${currentCellFieldName}.format`}
          onChange={onChangeFormat}
          options={formatOptions}
        />
      </InputTd>
      <InputTd>
        <InputField inline={true} name={`${currentCellFieldName}.quantity`} />
      </InputTd>
      <InputTd>
        <InputField inline={true} name={`${currentCellFieldName}.itemWeightInGrams`} />
      </InputTd>
      <NonInputTd>
        <CheckboxField
          inline={true}
          name={`${currentCellFieldName}.isReturnAddressPrinting`}
          disabled={!isReturnAddressPrintingAvailable}
        />
      </NonInputTd>
      <NonInputTd>
        <CheckboxField
          inline={true}
          name={`${currentCellFieldName}.isPpiPrinted`}
          disabled={!isPpiPrintedAvailable}
        />
      </NonInputTd>
      <DeleteCell>
        <DeleteButton
          onClick={removeCell}
          colour={alertText}
          data-testid={deleteCellButtonTestId(cellIndex)}
        >
          <CrossIcon />
        </DeleteButton>
      </DeleteCell>
    </Tr>
  );
};

export const MailingBriefUnsortedCellFields = connect<OwnProps, MailingBriefFormModel>(
  MailingBriefUnsortedCellFieldsComponent,
);

const useSetServiceTypeFromProcessingType = (
  formikContext: FormikContext<MailingBriefFormModel>,
  customer: CustomerForMailingBriefForm | null,
  cell: MailingBriefUnsortedCellFormModel,
  cellIndex: number,
) => {
  const { setFieldValue } = formikContext;

  // TODO MCP-6: Set this to the correct value once the Sorted element is complete
  const someSortedCellsAreMailmark = false;

  const calculatedServiceType = getServiceTypeFromProcessingType({
    processingType: cell.processingType,
    someSortedCellsAreMailmark,
    customerHasBillUnsortedAsMailmark: customer != null && customer.billUnsortedAsMailmark,
  });

  const currentServiceType = cell.service;

  useEffect(() => {
    if (currentServiceType !== calculatedServiceType) {
      setFieldValue(`unsortedWorksheet.cells.${cellIndex}.service`, calculatedServiceType);
    }
  }, [calculatedServiceType, currentServiceType]);
};

export const deleteCellButtonTestId = (cellIndex: number) => `deleteCellButton${cellIndex}`;

const InputTd = styled(Td)`
  padding: ${narrow} ${narrow};
  vertical-align: top;
`;

const NonInputTd = styled(Td)`
  padding: ${narrow};
  padding-top: ${inlineFormInputHeightPixels / 2}px;
  vertical-align: top;
`;

const DeleteCell = styled(Td)`
  padding: ${inlineFormInputHeightPixels / 2}px ${narrow} ${narrow} ${narrow};
  vertical-align: top;
`;

const DeleteButton = styled(IconButton)`
  height: 20px;
  width: 20px;
`;
