import { assertIsNumber } from '../../../../utils/assertIsNumber';
import { CreateUpdateInternationalCellDto } from '../../../worksheets/international/cells/createUpdateInternationalCellDto';
import { InternationalCellFormModel } from '../../../worksheets/international/cells/internationalCellFormModel';

export type MailingBriefInternationalCellFormModel = InternationalCellFormModel & {};

export const getInitialMailingBriefInternationalCellFormModel = (): MailingBriefInternationalCellFormModel => ({
  internationalCellId: null,
  cellReference: '',
  quantity: '',
  weightInGrams: '',
  widthInMillimetres: '',
  heightInMillimetres: '',
  thicknessInMillimetres: '',
});

export const toCreateUpdateInternationalCellDto = (
  formModel: MailingBriefInternationalCellFormModel,
  displayOrder: number,
): CreateUpdateInternationalCellDto => ({
  internationalCellId: formModel.internationalCellId,
  displayOrder,
  cellReference: formModel.cellReference,
  quantity: assertIsNumber(formModel.quantity),
  weightInGrams: assertIsNumber(formModel.weightInGrams),
  widthInMillimetres: assertIsNumber(formModel.widthInMillimetres),
  heightInMillimetres: assertIsNumber(formModel.heightInMillimetres),
  thicknessInMillimetres: assertIsNumber(formModel.thicknessInMillimetres),
});
