import * as React from 'react';
import { Id } from '../../../models/id';

export type ClientPortalUser = {
  userId: Id;
  name: string;
  email: string;
  customers: Array<{ customerId: Id; name: string }>;
  mailingHouses: Array<{ mailingHouseId: Id; name: string }>;
};

export const ClientPortalUserContext = React.createContext<ClientPortalUser>(
  (null as unknown) as ClientPortalUser,
);
