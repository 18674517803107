import { isRedirect } from '@reach/router';
import * as React from 'react';
import { Component } from 'react';
import { trackError } from '../applicationInsights';

type ErrorBoundaryProps = { errorMessage: React.ReactNode };

type State = { error: Error | null };

class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  state = { error: null };

  static getDerivedStateFromError(error: Error): State {
    return { error: isRedirect(error) ? null : error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    trackError(error, errorInfo);
  }

  render() {
    if (this.state.error != null) {
      return this.props.errorMessage;
    }

    return <>{this.props.children}</>;
  }
}

export const MatrixErrorBoundary = (props: { children: React.ReactNode }) => (
  <ErrorBoundary
    errorMessage={
      <div>
        <h3>Sorry, something has gone wrong</h3>
        <p>Something has caused the site to crash.</p>
        <p>Please ensure you are using this site in Chrome - other browsers are not supported.</p>
        <p>
          Please refresh the page and try again. If the issue continues, please contact the IT
          department.
        </p>
      </div>
    }
  >
    {props.children}
  </ErrorBoundary>
);

export const ClientPortalErrorBoundary = (props: { children: React.ReactNode }) => (
  <ErrorBoundary
    errorMessage={
      <div>
        <h3>Sorry, something has gone wrong</h3>
        <p>Something has caused the site to crash.</p>
        <p>
          Please refresh the page and try again. If the issue continues, please contact your ONEPOST
          account manager.
        </p>
      </div>
    }
  >
    {props.children}
  </ErrorBoundary>
);
