import { FormikTouched } from 'formik';
import * as React from 'react';
import { FormEvent, useEffect } from 'react';
import styled from 'styled-components/macro';
import { wide } from '../../styling/spacing';
import { ButtonGroup } from '../buttons/Button';
import { FileUploadContextProvider } from '../files/FileUploadContext';
import { NavigationWarningIfFormTouched } from '../navigation/NavigationConfirmationPrompt';
import { formFieldMaxWidth } from './FormField';
import { FormOptionsContext } from './FormOptionsContext';
import { FormSubmissionContext, FormSubmissionContextProvider } from './FormSubmissionContext';

export const FormContainer = styled.div`
  max-width: ${formFieldMaxWidth};
`;

type FormDisplayProps<TFormModel> = {
  fullWidth?: boolean;
  className?: string;
  showWarningsInitially?: boolean;
  validateForm?: () => void;
};

type FormProps<TFormModel> = {
  touched: FormikTouched<unknown>;
  isSubmitting: boolean;
  handleSubmit: (e?: FormEvent<HTMLFormElement>) => void;
  children?: React.ReactNode;
} & FormDisplayProps<TFormModel>;

export const Form = <TFormModel extends unknown>(props: FormProps<TFormModel>) => {
  useEffect(() => {
    if (props.showWarningsInitially) {
      if (!props.validateForm) {
        throw Error('If you want to show warnings, then you must pass in the validateForm prop');
      }
      props.validateForm();
    }
  }, [props.showWarningsInitially, props.validateForm]);

  const onSubmit = (setHasBeenSubmitted: (hasBeenSubmitted: boolean) => void) => (
    event: FormEvent<HTMLFormElement>,
  ): void => {
    if (!event.defaultPrevented) {
      setHasBeenSubmitted(true);
      props.handleSubmit(event);
      event.preventDefault();
    }
  };

  return (
    <FormSubmissionContextProvider>
      <FormSubmissionContext.Consumer>
        {({ setHasBeenSubmitted }) => (
          <FileUploadContextProvider>
            <FormOptionsContext.Provider
              value={{ showWarningsInitially: !!props.showWarningsInitially }}
            >
              <NavigationWarningIfFormTouched
                touched={props.touched}
                isSubmitting={props.isSubmitting}
              />
              {props.fullWidth ? (
                <form onSubmit={onSubmit(setHasBeenSubmitted)} className={props.className}>
                  {props.children}
                </form>
              ) : (
                <NarrowForm onSubmit={onSubmit(setHasBeenSubmitted)} className={props.className}>
                  {props.children}
                </NarrowForm>
              )}
            </FormOptionsContext.Provider>
          </FileUploadContextProvider>
        )}
      </FormSubmissionContext.Consumer>
    </FormSubmissionContextProvider>
  );
};

const NarrowForm = styled.form`
  max-width: ${formFieldMaxWidth};
`;

export const FormButtonGroup = styled(ButtonGroup)`
  margin-top: 0;
  margin-bottom: ${wide};
  display: flex;
  justify-content: flex-end;
`;
