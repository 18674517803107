import { keyBy, orderBy } from 'lodash';
import { Id } from '../../../models/id';
import { Metadata, WorksheetStatusCode } from '../../../models/metadata';
import { Validator } from '../../../utils/validation/validation';
import { User } from '../../authentication/user';
import { Customer } from '../../customers/customer';
import { MailingHouseForJobForm } from '../../jobs/dataForJobForm';
import { getWorksheetsUserHasPermissionToCreateAndEdit, Job } from '../../jobs/job';
import {
  fromInternationalWorksheet,
  getInternationalWorksheetValidator,
  InternationalWorksheetFormModel,
} from '../international/edit-form/internationalWorksheetFormModel';
import { isInternationalWorksheet } from '../international/internationalWorksheet';
import {
  fromOtherWorksheet,
  getOtherWorksheetValidator,
  OtherWorksheetFormModel,
} from '../other/edit-form/otherWorksheetFormModel';
import { isOtherWorksheet, isOtherWorksheetType } from '../other/otherWorksheet';
import {
  fromSortedWorksheet,
  getLiveSortedWorksheetValidator,
  SortedWorksheetFormModel,
  WorksheetPrintingType,
} from '../sorted/edit-form/sortedWorksheetFormModel';
import { isSortedWorksheet } from '../sorted/sortedWorksheet';
import {
  fromUnsortedWorksheet,
  UnsortedWorksheetFormModel,
} from '../unsorted/edit-form/unsortedWorksheetFormModel';
import { getUnsortedWorksheetValidator } from '../unsorted/edit-form/unsortedWorksheetValidator';
import { isUnsortedWorksheet } from '../unsorted/unsortedWorksheet';
import { isEditable } from '../worksheet';

export type WorksheetFormModel =
  | SortedWorksheetFormModel
  | UnsortedWorksheetFormModel
  | InternationalWorksheetFormModel
  | OtherWorksheetFormModel;

export const isSortedWorksheetFormModel = (
  worksheetFormModel: WorksheetFormModel,
): worksheetFormModel is SortedWorksheetFormModel => worksheetFormModel.type === 'Sorted';

export const isUnsortedWorksheetFormModel = (
  worksheetFormModel: WorksheetFormModel,
): worksheetFormModel is UnsortedWorksheetFormModel => worksheetFormModel.type === 'Unsorted';

export const isInternationalWorksheetFormModel = (
  worksheetFormModel: WorksheetFormModel,
): worksheetFormModel is InternationalWorksheetFormModel =>
  worksheetFormModel.type === 'International';

export const isOtherWorksheetFormModel = (
  worksheetFormModel: WorksheetFormModel,
): worksheetFormModel is OtherWorksheetFormModel => isOtherWorksheetType(worksheetFormModel.type);

export const getWorksheetValidator = (
  formModel: WorksheetFormModel,
  customer: Customer,
  metadata: Metadata,
  user: User,
  mailingHousesById: { [mailingHouseId: number]: MailingHouseForJobForm },
  dataToMailingHouseDateMustBeTodayOrLater: boolean,
  isJobCreatedBeforeSortedCellServiceArchiveDate: boolean,
  isJobCreatedBeforeSortedCellVariantArchiveDate: boolean,
): Validator<WorksheetFormModel> => {
  if (isSortedWorksheetFormModel(formModel)) {
    return getLiveSortedWorksheetValidator(
      formModel,
      dataToMailingHouseDateMustBeTodayOrLater,
      customer,
      metadata,
      mailingHousesById,
      user,
      isJobCreatedBeforeSortedCellServiceArchiveDate,
      isJobCreatedBeforeSortedCellVariantArchiveDate,
    ) as Validator<WorksheetFormModel>;
  }

  if (isUnsortedWorksheetFormModel(formModel)) {
    return getUnsortedWorksheetValidator(
      formModel,
      customer,
      metadata.unsortedCellValidation,
    ) as Validator<WorksheetFormModel>;
  }

  if (isInternationalWorksheetFormModel(formModel)) {
    return getInternationalWorksheetValidator(formModel) as Validator<WorksheetFormModel>;
  }

  if (isOtherWorksheetFormModel(formModel)) {
    return getOtherWorksheetValidator(formModel) as Validator<WorksheetFormModel>;
  }

  throw new Error('Could not determine type of worksheet form model');
};

export const mapWorksheetFormModelsFromJob = (
  job: Job,
  customer: Customer,
  mailingHouses: { [mailingHouseId: number]: MailingHouseForJobForm },
  metadata: Metadata,
  user: User,
  options: {
    effectiveStatus?: WorksheetStatusCode;
    clearDataProcessingAssignee?: boolean;
    printingType?: WorksheetPrintingType;
  } = {},
): Array<WorksheetFormModel> =>
  getWorksheetsUserHasPermissionToCreateAndEdit(job, user.permissions)
    .filter(worksheet => isEditable(worksheet, metadata))
    .map(worksheet => {
      if (isSortedWorksheet(worksheet)) {
        return fromSortedWorksheet(worksheet, mailingHouses, customer, metadata, user, options);
      }
      if (isUnsortedWorksheet(worksheet)) {
        return fromUnsortedWorksheet(worksheet, mailingHouses, customer);
      }
      if (isInternationalWorksheet(worksheet)) {
        return fromInternationalWorksheet(worksheet, mailingHouses, customer);
      }
      if (isOtherWorksheet(worksheet)) {
        return fromOtherWorksheet(worksheet);
      }
      throw new Error('Could not map worksheet to form model');
    });

export const mergeExistingContactsWithActualContacts = <
  TContact,
  TWorksheetContact,
  TWorksheetContactFormModel
>(
  worksheetContacts: Array<TWorksheetContact>,
  actualContacts: Array<TContact>,
  getContactId: (contact: TContact | TWorksheetContact | TWorksheetContactFormModel) => Id,
  fromWorksheetContact: (
    worksheetContact: TWorksheetContact,
    actualContact: TContact,
  ) => TWorksheetContactFormModel,
  fromContact: (contact: TContact) => TWorksheetContactFormModel,
  getOrderByProperty: (contact: TContact) => TContact[keyof TContact],
): Array<TWorksheetContactFormModel> => {
  const validContactsById = keyBy(actualContacts, getContactId);

  const worksheetContactsByContactId = keyBy(worksheetContacts, getContactId);

  const missingContacts = actualContacts.filter(
    c => worksheetContactsByContactId[getContactId(c)] == null,
  );

  const validWorksheetContacts = worksheetContacts.filter(
    c => validContactsById[getContactId(c)] != null,
  );

  const worksheetContactFormModels = [
    ...validWorksheetContacts.map(worksheetContact =>
      fromWorksheetContact(worksheetContact, validContactsById[getContactId(worksheetContact)]),
    ),
    ...missingContacts.map(fromContact),
  ];

  return orderBy(worksheetContactFormModels, c =>
    getOrderByProperty(validContactsById[getContactId(c)]),
  );
};
