import { LocationDescriptorObject, UnregisterCallback } from 'history';
import { HistoryAdapter } from 'with-url-state';
import {
  createHistoryWithNavigationConfirmation,
  withSuppressNavigationWarningState,
} from './historyWithNavigationConfirmation';

export const navigationConfirmationHistory = createHistoryWithNavigationConfirmation();

export const appHistory = navigationConfirmationHistory.history;

export const navigate = (
  to: string,
  options?: { suppressUserConfirmation?: boolean; replace?: boolean },
) => {
  navigationConfirmationHistory.history.navigate(to, {
    state: withSuppressNavigationWarningState(
      {},
      options && options.suppressUserConfirmation != null ? options.suppressUserConfirmation : true,
    ),
    replace: options && options.replace,
  });
};

export const urlStateHistoryAdapter: HistoryAdapter = {
  listen: (listener: () => void): UnregisterCallback =>
    appHistory.listen(({ location }) => {
      return listener();
    }),
  location: window.location,
  push: (location: LocationDescriptorObject) => {
    return appHistory.navigate(`${location.pathname}${location.search}`, {
      state: withSuppressNavigationWarningState(window.history.state),
    });
  },
  replace: (location: LocationDescriptorObject) => {
    return appHistory.navigate(`${location.pathname}${location.search}`, {
      state: withSuppressNavigationWarningState(window.history.state),
      replace: true,
    });
  },
};
