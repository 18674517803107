import { mapValues, uniqueId } from 'lodash';
import { DateStamp, workingDaysBetween } from '../../../../models/dateStamp';
import { Metadata } from '../../../../models/metadata';
import { assertIsNumber } from '../../../../utils/assertIsNumber';
import { assertNotNull } from '../../../../utils/assertNotNull';
import { UnsortedWorksheetFormModel } from '../edit-form/unsortedWorksheetFormModel';
import {
  UnsortedCell,
  UnsortedCellFormat,
  UnsortedCellProcessingType,
  UnsortedCellServiceType,
  UnsortedCellVariant,
} from './unsortedCell';

export type UnsortedCellFormModel = {
  unsortedCellId: number | null;
  uniqueFormId: string; // Need a unique ID to use for key for React components
  cellReference: string;
  service: UnsortedCellServiceType | null;
  format: UnsortedCellFormat | null;
  processingType: UnsortedCellProcessingType | null;
  variant: UnsortedCellVariant | null;
  quantity: string;
  itemWeightInGrams: string;
  isPpiPrinted: boolean;
  isReturnAddressPrinting: boolean;
  isOverlabel: boolean;
  isSignedFor: boolean;
  isTracked: boolean;
  collectionQuantitiesByDate: { [collectionDate in DateStamp]?: string | null };
};

export type CreateUpdateUnsortedCellDto = {
  unsortedCellId: number | null;
  displayOrder: number;
  cellReference: string;
  service: UnsortedCellServiceType;
  format: UnsortedCellFormat;
  processingType: UnsortedCellProcessingType;
  variant: UnsortedCellVariant;
  quantity: number | null;
  itemWeightInGrams: number;
  isPpiPrinted: boolean;
  isReturnAddressPrinting: boolean;
  isOverlabel: boolean;
  isSignedFor: boolean;
  isTracked: boolean;
  collections: Array<CreateUpdateUnsortedCellCollectionDto>;
};

type CreateUpdateUnsortedCellCollectionDto = {
  collectionDate: DateStamp;
  quantity: number | null;
};

export const fromUnsortedCell = (cell: UnsortedCell): UnsortedCellFormModel => ({
  unsortedCellId: cell.unsortedCellId,
  uniqueFormId: uniqueId(),
  cellReference: cell.cellReference,
  service: cell.service,
  format: cell.format,
  processingType: cell.processingType,
  variant: cell.variant,
  quantity: cell.quantity === null ? '' : cell.quantity.toString(),
  itemWeightInGrams: cell.itemWeightInGrams.toString(),
  isPpiPrinted: cell.isPpiPrinted,
  isReturnAddressPrinting: cell.isReturnAddressPrinting,
  isOverlabel: cell.isOverlabel,
  isSignedFor: cell.isSignedFor,
  isTracked: cell.isTracked,
  collectionQuantitiesByDate: mapValues(cell.collections, collection =>
    collection.quantity != null ? collection.quantity.toString() : null,
  ),
});

export const toCreateUpdateUnsortedCellDto = (
  formModel: UnsortedCellFormModel,
  worksheetFormModel: UnsortedWorksheetFormModel,
  displayOrder: number,
  metadata: Metadata,
): CreateUpdateUnsortedCellDto => ({
  unsortedCellId: formModel.unsortedCellId,
  displayOrder,
  cellReference: formModel.cellReference,
  service: assertNotNull(formModel.service),
  format: assertNotNull(formModel.format),
  processingType: assertNotNull(formModel.processingType),
  variant: assertNotNull(formModel.variant),
  quantity:
    worksheetFormModel.collectionSchedule === 'Daily' ||
    formModel.quantity == null ||
    formModel.quantity.trim() === ''
      ? null
      : assertIsNumber(formModel.quantity),
  itemWeightInGrams: assertIsNumber(formModel.itemWeightInGrams),
  isPpiPrinted: formModel.isPpiPrinted,
  isOverlabel: formModel.isOverlabel,
  isSignedFor: formModel.isSignedFor,
  isTracked: formModel.isTracked,
  isReturnAddressPrinting: formModel.isReturnAddressPrinting,
  collections:
    worksheetFormModel.collectionSchedule === 'Daily'
      ? workingDaysBetween(
          assertNotNull(worksheetFormModel.firstCollectionDate),
          assertNotNull(worksheetFormModel.lastCollectionDate),
          metadata.bankHolidays,
        ).map(date => {
          const quantity = formModel.collectionQuantitiesByDate[date];
          return {
            collectionDate: date,
            quantity: quantity == null || quantity.trim() === '' ? null : assertIsNumber(quantity),
          };
        })
      : [],
});
