import { useContext } from 'react';
import * as React from 'react';
import { DateStamp } from '../../models/dateStamp';
import { SortedCellMetadata } from '../../models/metadata';
import {
  SortedCellServiceType,
  SortedCellServiceTypeMetadata,
} from '../worksheets/sorted/cells/sortedCell';
import { UnsortedCellValidationMetadata } from '../worksheets/unsorted/cells/unsortedCell';

export type ClientPortalMetadata = SortedCellMetadata & {
  unsortedCellValidation: UnsortedCellValidationMetadata;
  bankHolidays: Array<DateStamp>;
  sortedCellServiceTypes: {
    [serviceTypeCode in SortedCellServiceType]: SortedCellServiceTypeMetadata;
  };
};

export const ClientPortalMetadataContext = React.createContext<ClientPortalMetadata | undefined>(
  undefined,
);

export const useClientPortalMetadata = (): ClientPortalMetadata => {
  const contextOrUndefined = useContext(ClientPortalMetadataContext);

  if (contextOrUndefined == null) {
    throw new Error('Client Portal Metadata Context is not defined');
  }

  return contextOrUndefined;
};
