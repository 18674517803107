import * as React from 'react';
import { WarningBox } from '../../../shared/WarningBox';
import { ClientPortalDataCleansingWorksheetDetails } from '../worksheets/dataCleansing/ClientPortalDataCleansingWorksheetDetails';
import { ClientPortalInternationalWorksheetDetails } from '../worksheets/international/ClientPortalInternationalWorksheetDetails';
import { ClientPortalSortedWorksheetDetails } from '../worksheets/sorted/ClientPortalSortedWorksheetDetails';
import { ClientPortalUnsortedWorksheetDetails } from '../worksheets/unsorted/ClientPortalUnsortedWorksheetDetails';
import { ClientPortalJob } from './ClientPortalJob';

type ClientPortalJobWorksheetProps = {
  job: ClientPortalJob;
};

export const ClientPortalJobWorksheets = (props: ClientPortalJobWorksheetProps) => {
  const jobHasNoWorksheets =
    props.job.sortedWorksheets.length === 0 &&
    props.job.unsortedWorksheet == null &&
    props.job.internationalWorksheet == null &&
    props.job.dataCleansingWorksheet == null;

  if (jobHasNoWorksheets) {
    return <WarningBox message={'This job has no worksheets.'} />;
  }

  return (
    <>
      {props.job.sortedWorksheets.map((sortedWorksheet, index) => (
        <ClientPortalSortedWorksheetDetails
          sortedWorksheetIndex={index}
          sortedWorksheet={sortedWorksheet}
          job={props.job}
          key={sortedWorksheet.sortedWorksheetId}
        />
      ))}

      {props.job.hasMultipleUnsortedWorksheets && (
        <WarningBox
          message={
            'Please see your account manager for more details on the unsorted elements of this job.'
          }
        />
      )}
      {props.job.unsortedWorksheet !== null && (
        <ClientPortalUnsortedWorksheetDetails unsortedWorksheet={props.job.unsortedWorksheet} />
      )}
      {props.job.hasMultipleInternationalWorksheets && (
        <WarningBox
          message={
            'Please see your account manager for more details on the international elements of this job.'
          }
        />
      )}
      {props.job.internationalWorksheet !== null && (
        <ClientPortalInternationalWorksheetDetails
          internationalWorksheet={props.job.internationalWorksheet}
        />
      )}
      {props.job.hasMultipleDataCleansingWorksheets && (
        <WarningBox
          message={
            'Please see your account manager for more details on the data cleansing elements of this job.'
          }
        />
      )}
      {props.job.dataCleansingWorksheet !== null && (
        <ClientPortalDataCleansingWorksheetDetails
          dataCleansingWorksheet={props.job.dataCleansingWorksheet}
        />
      )}
    </>
  );
};
