import { FormikProps } from 'formik';
import * as React from 'react';
import { CollectionDate } from '../collectionDate/CollectionDate';
import { MailingHouseForMailingBriefForm } from '../dataForMailingBriefForm';
import { MailingBriefFormModel } from '../mailingBriefFormModel';
import { MailingBriefMailingHouse } from '../mailingHouse/MailingBriefMailingHouse';
import { MailingBriefInternationalCellsFieldArray } from './MailingBriefInternationalCellsFieldArray';

export const mailingBriefInternationalWorksheetFormFieldsTestId =
  'mailing-brief-international-worksheet-form-fields';

export const MailingBriefInternationalWorksheetFormFields = (props: {
  isSplitJob: boolean;
  formikProps: FormikProps<MailingBriefFormModel>;
  mailingHouseResponses: { [mailingHouseId: number]: MailingHouseForMailingBriefForm };
  isConvertingForecastJobToLive: boolean;
}) => {
  const mailingHouseId = props.formikProps.values.internationalWorksheet
    ? props.formikProps.values.internationalWorksheet.mailingHouse.mailingHouseId
    : null;

  return (
    <div data-testid={mailingBriefInternationalWorksheetFormFieldsTestId}>
      {props.isSplitJob && (
        <>
          <CollectionDate
            formikProps={props.formikProps}
            worksheetDependentFieldNamePrefix="internationalWorksheet"
          />
          <MailingBriefMailingHouse
            formikProps={props.formikProps}
            mailingHouseResponses={props.mailingHouseResponses}
            isConvertingForecastJobToLive={props.isConvertingForecastJobToLive}
            worksheetDependentFieldNamePrefix="internationalWorksheet"
            mailingHouseId={mailingHouseId}
          />
        </>
      )}

      <MailingBriefInternationalCellsFieldArray />
    </div>
  );
};
