import { filter } from 'lodash';
import * as React from 'react';
import { Component } from 'react';

export type HeaderActionData = {
  id: string;
  content: React.ReactNode;
};

export type HeaderActionsContextValue = {
  addAction: (breadcrumb: HeaderActionData) => void;
  updateAction: (breadcrumb: HeaderActionData) => void;
  removeAction: (id: string) => void;
  actions: Array<HeaderActionData>;
};

export const HeaderActionsContext = React.createContext<HeaderActionsContextValue>({
  addAction: () => {
    throw new Error('Header action context not initialised');
  },
  updateAction: () => {
    throw new Error('Header action context not initialised');
  },
  removeAction: () => {
    throw new Error('Header action context not initialised');
  },
  actions: [],
});

type HeaderActionsState = {
  actions: Array<HeaderActionData>;
};

export class HeaderActionsProvider extends Component<{}, HeaderActionsState> {
  state: HeaderActionsState = {
    actions: [],
  };

  addAction = (action: HeaderActionData) =>
    this.setState(({ actions }) => ({ actions: [...actions, action] }));

  updateAction = (data: HeaderActionData) =>
    this.setState(({ actions }) => ({
      actions: actions.map(action => (action.id === data.id ? data : action)),
    }));

  removeAction = (id: string) =>
    this.setState(({ actions }) => ({
      actions: filter(actions, action => action.id !== id),
    }));

  render() {
    return (
      <HeaderActionsContext.Provider
        value={{
          addAction: this.addAction,
          updateAction: this.updateAction,
          removeAction: this.removeAction,
          actions: this.state.actions,
        }}
      >
        {this.props.children}
      </HeaderActionsContext.Provider>
    );
  }
}
