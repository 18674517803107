import * as React from 'react';
import { useContext } from 'react';
import { FileUploadContext } from '../files/FileUploadContext';
import { ButtonProps } from './Button';
import { ProgressButton } from './ProgressButton';

type SubmitButtonProps = ButtonProps & { isSubmitting: boolean };

export const SubmitButton = ({ children, isSubmitting, ...rest }: SubmitButtonProps) => {
  const fileUploadContext = useContext(FileUploadContext);
  const showLoadingSpinner = isSubmitting || fileUploadContext.filesAreUploading;

  return (
    <ProgressButton inProgress={showLoadingSpinner} type="submit" {...rest}>
      {children}
    </ProgressButton>
  );
};
