import { some } from 'lodash';
import { DateStamp } from '../../models/dateStamp';
import { Id } from '../../models/id';
import {
  canApplyTransition,
  Metadata,
  WorksheetStatusCode,
  WorksheetTransitionName,
} from '../../models/metadata';
import { getSelectOptions } from '../../shared/form/inputs/Select';
import { SplitJobDetails } from '../jobs/job';
import { MailingHouseAddress } from '../mailing-houses/mailingHouse';
import { WorksheetAttachment } from './worksheetAttachment';

export type Worksheet = {
  worksheetId: Id;
  worksheetReference: number;
  statusCode: WorksheetStatusCode;
  type: WorksheetType;
  splitFromJob: SplitJobDetails | null;
  displayOrder: number;
  mailingHouse: WorksheetMailingHouseDetails | null;
  collectionDate: DateStamp;
  estimatedValueInPounds: number;
  isOnCreditHold: boolean;
  attachments: Array<WorksheetAttachment>;
};

export type WorksheetMailingHouseContact = {
  mailingHouseContactId: Id;
  name: string;
  jobTitle: string;
  landline: string;
  mobile: string;
  email: string;
  notes: string;
};

export type WorksheetCustomerContact = {
  customerContactId: Id;
  name: string;
  jobTitle: string;
  landline: string;
  mobile: string;
  email: string;
  notes: string;
};

export type WorksheetType =
  | 'Sorted'
  | 'Unsorted'
  | 'International'
  | 'Fulfilment'
  | 'DataCleansing'
  | 'Production'
  | 'BagAndTag'
  | 'Returns';

export const worksheetTypeDisplayNames: { [worksheetType in WorksheetType]: string } = {
  Sorted: 'Sorted',
  Unsorted: 'Unsorted',
  International: 'International',
  Fulfilment: 'Fulfilment',
  DataCleansing: 'Data Cleansing',
  Production: 'Production',
  BagAndTag: 'Bag And Tag',
  Returns: 'Returns',
};

export const worksheetTypes = Object.keys(worksheetTypeDisplayNames) as Array<WorksheetType>;

export const worksheetTypeSelectOptions = getSelectOptions<WorksheetType>(
  worksheetTypes,
  worksheetTypeDisplayNames,
);

export type ArchivedWorksheetLabelDeliveryService = 'NineAm' | 'TenAm' | 'Noon' | 'SaturdayTenAm';

export type ActiveWorksheetLabelDeliveryService =
  | 'Anytime'
  | 'SameDayCourier'
  | 'Overseas'
  | 'PreNineAm'
  | 'PreOnePm';

export type WorksheetLabelDeliveryService =
  | ArchivedWorksheetLabelDeliveryService
  | ActiveWorksheetLabelDeliveryService;

export type WorksheetFlags = {
  isUrgent: boolean;
  isOnFirstJob: boolean;
  hasComplaint: boolean;
  customerIsOnCreditHold: boolean;
  worksheetIsOnCreditHold: boolean;
  jobIsMissingPurchaseOrder: boolean;
  jobIsMissingPurchaseOrderWithImminentCollection: boolean;
  customerNeedsTlc: boolean;
  customerIsProForma: boolean;
  hasBeenRerun: boolean;
  isSplitJob: boolean;
  isForecast: boolean;
  wasForecast: boolean;
  isSftpDataProvisioning: boolean;
  consumablesHaveBeenOrdered: boolean;
};

export type WorksheetMailingHouseDetails = {
  mailingHouseId: Id;
  referenceNumber: number;
  name: string;
  notes: string;
  address: MailingHouseAddress;
};

export const isActiveWorksheet = (worksheet: Worksheet, metadata: Metadata) =>
  !metadata.worksheetStatuses[worksheet.statusCode].isCompleted;

export const isEditable = (worksheet: Worksheet, metadata: Metadata) =>
  metadata.worksheetStatuses[worksheet.statusCode].canEditWorksheet;

export const isInvoiced = (worksheet: Worksheet) => worksheet.statusCode === 'Invoiced';

export const isNotCancelledWorksheet = (worksheet: Worksheet) =>
  worksheet.statusCode !== 'Cancelled';

export const isInSubmittableStatus = (worksheet: Worksheet, metadata: Metadata) => {
  const submittableTransitions: Array<WorksheetTransitionName> = [
    'SubmitToManagerApproval',
    'SubmitToCreditCheckAndBypassManagerApproval',
    'SubmitAndBypassChecks',
    'SubmitClientSubmittedWorksheetToCreditCheck',
    'SubmitClientSubmittedWorksheetAndBypassCreditCheck',
  ];

  return some(submittableTransitions, transition =>
    canApplyTransition(transition, worksheet, metadata),
  );
};
