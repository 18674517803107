import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';
import EyeIcon from '../../../images/icons/eye-solid.svg';
import { formInputHeight } from '../formInputStyling';
import { InputField, InputFieldProps } from './InputField';

export const PasswordField = (props: InputFieldProps) => {
  const [isPasswordRevealed, setIsPasswordRevealed] = useState(false);

  return (
    <InputContainer>
      <InputField {...props} type={isPasswordRevealed ? 'text' : 'password'} />
      <PasswordRevealToggle onClick={() => setIsPasswordRevealed(!isPasswordRevealed)} />
    </InputContainer>
  );
};

const InputContainer = styled.div`
  position: relative;
`;

const PasswordRevealToggle = styled.div`
  background: url(${EyeIcon}) no-repeat center;
  height: ${formInputHeight};
  width: 28px;
  position: absolute;
  top: 0;
  right: 15px;
  display: inline-block;
  z-index: 1000;
  cursor: pointer;
  opacity: 1;
  transition: all 0.25s ease;

  &:hover {
    opacity: 0.75;
  }
`;
