import {
  addDays,
  eachDay,
  format,
  isAfter,
  isBefore,
  isFuture,
  isWeekend,
  max,
  parse,
  startOfToday,
  startOfTomorrow,
  subDays,
} from 'date-fns';
import { includes, range } from 'lodash';

export type DateStamp = string;
export const dateStampFormatString = 'YYYY-MM-DD';

export const toDateStamp = (date: Date): DateStamp => format(date, dateStampFormatString);

export const toDate = (dateStamp: DateStamp): Date => parse(dateStamp);

export const getTodayAsDateStamp = (): DateStamp => toDateStamp(startOfToday());

export const getTomorrowAsDateStamp = (): DateStamp => toDateStamp(startOfTomorrow());

export const getDaysIntoThePastAsDateStamp = (daysIntoThePast: number): DateStamp =>
  toDateStamp(subDays(startOfToday(), daysIntoThePast));

export const getDaysIntoTheFutureAsDateStamp = (daysIntoTheFuture: number): DateStamp =>
  toDateStamp(addDays(startOfToday(), daysIntoTheFuture));

export const formatDateStamp = (dateStamp: DateStamp | null, formatString?: string): string =>
  dateStamp ? format(toDate(dateStamp), formatString || 'DD/MM/YYYY') : '';

export const isDateStampBefore = (dateStamp: DateStamp, dateStampToCompare: DateStamp): boolean =>
  isBefore(dateStamp, dateStampToCompare);

export const isDateStampOnOrBefore = (
  dateStamp: DateStamp,
  dateStampToCompare: DateStamp,
): boolean => dateStamp === dateStampToCompare || isBefore(dateStamp, dateStampToCompare);

export const isDateStampAfter = (dateStamp: DateStamp, dateStampToCompare: DateStamp): boolean =>
  isAfter(dateStamp, dateStampToCompare);

export const isDateStampOnOrAfter = (
  dateStamp: DateStamp,
  dateStampToCompare: DateStamp,
): boolean => dateStamp === dateStampToCompare || isAfter(dateStamp, dateStampToCompare);

export const isDateStampInTheFuture = (dateStamp: DateStamp): boolean => isFuture(dateStamp);

export const isTodayOrLater = (dateStamp: DateStamp): boolean =>
  isDateStampOnOrAfter(dateStamp, getTodayAsDateStamp());

export const isWithinNextDays = (dateStamp: DateStamp, days: number): boolean =>
  isTodayOrLater(dateStamp) &&
  isDateStampOnOrBefore(dateStamp, getDaysIntoTheFutureAsDateStamp(days));

export const dayAfter = (dateStamp: DateStamp): DateStamp =>
  toDateStamp(addDays(toDate(dateStamp), 1));

export const maxDateStamp = (dateStamp: DateStamp, otherDateStamp: DateStamp): DateStamp =>
  toDateStamp(max(dateStamp, otherDateStamp));

export const isOnTheWeekend = (date: Date): boolean => isWeekend(date);

export const isWorkingDay = (date: Date, bankHolidays: Array<DateStamp>): boolean =>
  !isWeekend(date) && !includes(bankHolidays, toDateStamp(date));

export const previousWorkingDay = (
  dateStamp: DateStamp,
  bankHolidays: Array<DateStamp>,
): DateStamp => {
  let date = toDate(dateStamp);
  do {
    date = subDays(date, 1);
  } while (!isWorkingDay(date, bankHolidays));
  return toDateStamp(date);
};

export const nextWorkingDay = (dateStamp: DateStamp, bankHolidays: Array<DateStamp>): DateStamp => {
  let date = toDate(dateStamp);
  do {
    date = addDays(date, 1);
  } while (!isWorkingDay(date, bankHolidays));
  return toDateStamp(date);
};

export const workingDaysFrom = (
  referenceDate: DateStamp,
  numberOfDays: number,
  bankHolidays: Array<DateStamp>,
) =>
  range(numberOfDays).reduce(currentDay => nextWorkingDay(currentDay, bankHolidays), referenceDate);

export const workingDaysBefore = (
  referenceDate: DateStamp,
  numberOfDays: number,
  bankHolidays: Array<DateStamp>,
) =>
  range(numberOfDays).reduce(
    currentDay => previousWorkingDay(currentDay, bankHolidays),
    referenceDate,
  );

export const workingDaysFromNow = (numberOfDays: number, bankHolidays: Array<DateStamp>) =>
  workingDaysFrom(getTodayAsDateStamp(), numberOfDays, bankHolidays);

export const datesBetween = (startDate: DateStamp, endDate: DateStamp): Array<DateStamp> =>
  isDateStampOnOrBefore(startDate, endDate) ? eachDay(startDate, endDate).map(toDateStamp) : [];

export const workingDaysBetween = (
  startDate: DateStamp,
  endDate: DateStamp,
  bankHolidays: Array<DateStamp>,
): Array<DateStamp> =>
  datesBetween(startDate, endDate).filter(date => isWorkingDay(toDate(date), bankHolidays));
