import * as React from 'react';
import { getComponentDisplayName } from '../../utils/getComponentDisplayName';
import { Modal } from '../Modal';

type ModalProps<TParams> = { open: (params: TParams) => void; close: () => void };

export type WithModalProps<TParams, TModalName extends string> = {
  [modalName in TModalName]: ModalProps<TParams>;
};

export type WithModalEnhancer<TOwnProps, TParams, TModalName extends string> = (
  WrappedComponent: React.ComponentType<TOwnProps & WithModalProps<TParams, TModalName>>,
) => React.ComponentType<TOwnProps>;

type WithModalState<TParams> = {
  params: TParams | null;
  isOpen: boolean;
};

export type WithModalRenderProps<TOwnProps, TParams> = {
  ownProps: TOwnProps;
  params: TParams;
  closeModal: () => void;
};

export const withModal = <TOwnProps, TParams, TModalName extends string>(
  modalName: TModalName,
  renderModal: (renderProps: WithModalRenderProps<TOwnProps, TParams>) => React.ReactNode,
  options?: { wide?: boolean; disableClose?: boolean },
): WithModalEnhancer<TOwnProps, TParams, TModalName> => (
  WrappedComponent: React.ComponentType<TOwnProps & WithModalProps<TParams, TModalName>>,
): React.ComponentType<TOwnProps> =>
  class WithModalWrapper extends React.Component<TOwnProps, WithModalState<TParams>> {
    static displayName = `withModal(${getComponentDisplayName(WrappedComponent)})`;

    state: WithModalState<TParams> = { params: null, isOpen: false };

    openModal = (params: TParams) => {
      this.setState({ params, isOpen: true });
    };

    closeModal = () => {
      this.setState({ params: null, isOpen: false });
    };

    render() {
      const modalProps: WithModalProps<TParams, TModalName> = {
        [modalName]: { open: this.openModal, close: this.closeModal },
      };

      const { params, isOpen } = this.state;

      return (
        <>
          <WrappedComponent {...this.props} {...modalProps} />
          {isOpen && params != null && (
            <Modal
              isOpen={isOpen}
              onClose={this.closeModal}
              wide={options != null && options.wide}
              disableClose={options != null && options.disableClose}
            >
              {params && renderModal({ ownProps: this.props, params, closeModal: this.closeModal })}
            </Modal>
          )}
        </>
      );
    }
  };
