import { FormikErrors, FormikTouched, getIn } from 'formik';
import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { alertAccent, alertText } from '../../styling/colours';
import { bold } from '../../styling/fonts';
import { medium, wide } from '../../styling/spacing';

type Props<T> = {
  fieldName: string;
  errors: FormikErrors<T>;
  touched: FormikTouched<T>;
  className?: string;
  inline?: boolean;
};

export class TopLevelValidationError<T> extends React.Component<Props<T>> {
  render() {
    const props = this.props;
    const { fieldName } = props;

    const error: string | object | null | undefined = getIn(props.errors, fieldName);
    const touched = getIn(props.touched, fieldName);

    return error && touched && typeof error === 'string' ? (
      <TopLevelValidationErrorBox
        visible={true}
        className={props.className}
        data-testid={topLevelValidationErrorTestId(fieldName)}
        inline={props.inline}
      >
        {error}
      </TopLevelValidationErrorBox>
    ) : props.inline ? (
      <TopLevelValidationErrorBox visible={false} className={props.className} inline={props.inline}>
        Placeholder
      </TopLevelValidationErrorBox>
    ) : null;
  }
}

export const topLevelValidationErrorTestId = (fieldName: string) =>
  `${fieldName}.topLevelValidationError`;

const TopLevelValidationErrorBox = styled.div<{ visible: boolean; inline: boolean | undefined }>`
  ${props =>
    props.inline
      ? css`
          font-weight: ${bold};
        `
      : css`
          background-color: ${alertAccent};
          padding: ${medium};
          border: solid 1px ${alertText};
        `};

  margin-bottom: ${wide};
  color: ${alertText};
  opacity: ${props => (props.visible ? 1 : 0)};
`;
