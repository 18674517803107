import { startsWith } from 'lodash';
import * as React from 'react';
import { Component, ComponentClass, ComponentType } from 'react';
import {
  AuthenticationContext,
  AuthenticationContextValue,
} from '../../features/authentication/authenticationContext';
import { AbortablePromise, ApiResponse, isRequestAbortedError } from '../../utils/api';
import { getComponentDisplayName } from '../../utils/getComponentDisplayName';

export type ApiRequestState<T> = {
  response: T | null;
  inProgress: boolean;
  error: Error | null;
};

export type Response<TBody> = { success: true; body: TBody } | { success: false; error: Error };

export type ApiRequestPropValue<TParams, TResponse> = ApiRequestState<TResponse> & {
  sendRequest: (params: TParams) => Promise<Response<TResponse>>;
  abortRequest: () => void;
};

export type ApiRequestProps<TParams, TResponse, TPropName extends string> = {
  [propName in TPropName]: ApiRequestPropValue<TParams, TResponse>;
};

export type ApiRequestEnhancer<TOwnProps, TParams, TResponse, TPropName extends string> = (
  WrappedComponent: ComponentType<TOwnProps & ApiRequestProps<TParams, TResponse, TPropName>>,
) => ComponentType<TOwnProps>;

export const withApiRequest = <TOwnProps, TParams, TResponse, TPropName extends string>(
  mapPropsToRequestProducer: (
    props: TOwnProps,
  ) => (
    params: TParams,
  ) => (accessToken: string | null) => AbortablePromise<ApiResponse<TResponse>>,
  propName: TPropName,
  mapPropsToOptions?: (props: TOwnProps) => { unauthenticatedRequest?: boolean },
): ApiRequestEnhancer<TOwnProps, TParams, TResponse, TPropName> => (
  WrappedComponent: ComponentType<TOwnProps & ApiRequestProps<TParams, TResponse, TPropName>>,
): ComponentClass<TOwnProps> =>
  class ApiRequestWrapper extends Component<TOwnProps, ApiRequestState<TResponse>> {
    static displayName = `withApiRequest(${getComponentDisplayName(WrappedComponent)})`;

    static contextType = AuthenticationContext;
    context!: AuthenticationContextValue;

    state = {
      response: null,
      inProgress: false,
      error: null,
    };

    request: AbortablePromise<ApiResponse<TResponse>> | null = null;
    lastRequestWasAbortedDueToConcurrentRequest: boolean = false;
    unmounted = false;

    sendRequest = async (params: TParams): Promise<Response<TResponse>> => {
      if (this.request != null) {
        this.lastRequestWasAbortedDueToConcurrentRequest = true;
        this.request.abort();
        this.request = null;
      } else {
        this.lastRequestWasAbortedDueToConcurrentRequest = false;
      }

      this.setState({ inProgress: true, error: null });

      let accessToken: string | null;
      if (mapPropsToOptions != null && mapPropsToOptions(this.props).unauthenticatedRequest) {
        accessToken = null;
      } else {
        try {
          accessToken = await this.context.getAccessToken();
        } catch (error) {
          this.setState({ inProgress: false, error });
          return Promise.reject('Could not fetch access token');
        }
      }

      this.request = mapPropsToRequestProducer(this.props)(params)(accessToken);
      return this.request
        .then(response => {
          this.request = null;
          if (!this.unmounted) {
            this.setState({
              response: response.body,
              inProgress: false,
              error: null,
            });
          }
          return { success: true as true, body: response.body };
        })
        .catch((error: Error) => {
          if (this.lastRequestWasAbortedDueToConcurrentRequest && isRequestAbortedError(error)) {
            // If a request is aborted due to another request occurring, we don't want to display an error
            // - we just want to keep displaying a loading indicator
            this.lastRequestWasAbortedDueToConcurrentRequest = false;
            return { success: false as false, error };
          }

          if (startsWith(error.message, 'Nock')) {
            // tslint:disable-next-line:no-console If a mocked api request in a test fails, then we want to know about it
            console.error(error);
          }

          this.request = null;
          if (!this.unmounted) {
            this.setState({
              inProgress: false,
              error: isRequestAbortedError(error)
                ? new Error('The request was cancelled as it had taken too long')
                : error,
            });
          }
          return { success: false as false, error };
        });
    };

    componentWillUnmount() {
      this.unmounted = true;
      this.abortRequest();
    }

    abortRequest = () => {
      if (this.request != null) {
        this.request.abort();
        this.request = null;
      }
    };

    render() {
      const apiRequestProp: ApiRequestPropValue<TParams, TResponse> = {
        ...this.state,
        sendRequest: this.sendRequest,
        abortRequest: this.abortRequest,
      };
      const apiRequestProps: ApiRequestProps<TParams, TResponse, TPropName> = {
        [propName]: apiRequestProp,
      };
      return <WrappedComponent {...this.props} {...apiRequestProps} />;
    }
  };
