import * as React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as BoltSolidIcon } from '../../images/icons/bolt-solid.svg';
import { ReactComponent as ExclamationIcon } from '../../images/icons/exclamation-circle-solid.svg';
import { ReactComponent as PaperclipSolidIcon } from '../../images/icons/paperclip-solid.svg';
import { ReactComponent as WorksheetOnCreditHoldIcon } from '../../images/icons/worksheet-on-hold.svg';
import { useMetadata } from '../../models/metadata';
import { WarningCallout } from '../../shared/Callout';
import {
  fieldLabelContainerWidthPixels,
  fieldLabelMarginRightPixels,
  maxInputWidth,
} from '../../shared/form/FormField';
import { WarningBox } from '../../shared/WarningBox';
import { SortedWorksheet, validatePresenceOfScidsAndUcids } from './sorted/sortedWorksheet';

export const WorksheetIsUrgentWarning = () => (
  <WarningBox message="This worksheet is urgent" icon={<BoltSolidIcon />} />
);

export const WorksheetOnCreditHoldWarning = (props: { message: string }) => (
  <WarningBox message={props.message} icon={<WorksheetOnCreditHoldIcon />} />
);

export const SortedWorksheetMissingRawDataAttachmentWarning = () => (
  <WarningBox
    message="This worksheet is missing a raw data attachment"
    icon={<PaperclipSolidIcon />}
  />
);

export const WorksheetCheckedOutInDataProcessing = (props: { message: string }) => (
  <WarningBox message={props.message} icon={<ExclamationIcon />} />
);

export const SortedWorksheetConsumablesDateWithinTwoDaysWarning = () => (
  <WarningBox
    message="This worksheet's consumables date is within the next two days"
    icon={<ExclamationIcon />}
  />
);

export const SortedWorksheetMissingCellsWarning = () => (
  <WarningBox message="This worksheet does not have any cells" />
);

export const WorksheetMissingConfirmationCustomerContactWarning = () => (
  <WarningBox message="This worksheet does not have any Confirmation customer contacts" />
);

export const StyledWarningCallout = styled(WarningCallout)`
  padding: 12px;
  margin-bottom: 35px;
  margin-left: ${fieldLabelContainerWidthPixels + fieldLabelMarginRightPixels}px;
  max-width: ${maxInputWidth};
`;

export const SortedWorksheetUcidAndScidWarnings = ({
  worksheet,
}: {
  worksheet: SortedWorksheet;
}) => {
  const metadata = useMetadata();
  const {
    isMissingUcid,
    isMissingNationalUcid,
    isMissingZonalUcid,
    isMissingScid,
    isMissingNationalScid,
    isMissingZonalScid,
  } = validatePresenceOfScidsAndUcids(worksheet, metadata);

  return (
    <>
      {isMissingUcid && <WarningBox message="This worksheet does not have any UCIDs" />}
      {isMissingNationalUcid && !isMissingZonalUcid && (
        <WarningBox message="This worksheet does not have a National UCID" />
      )}
      {!isMissingNationalUcid && isMissingZonalUcid && (
        <WarningBox message="This worksheet does not have a Zonal UCID" />
      )}
      {isMissingScid && <WarningBox message="This worksheet does not have any SCIDs" />}
      {isMissingNationalScid && !isMissingZonalScid && (
        <WarningBox message="This worksheet does not have a National SCID" />
      )}
      {!isMissingNationalScid && isMissingZonalScid && (
        <WarningBox message="This worksheet does not have a Zonal SCID" />
      )}
    </>
  );
};
