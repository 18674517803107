import { isEmpty, orderBy } from 'lodash';
import React from 'react';
import {
  FullWidthTable,
  NoDataTableDataCell,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from '../../../../shared/table/Table';
import { useClientPortalMetadata } from '../../clientPortalMetadata';
import { ClientPortalSortedCell } from './ClientPortalSortedCell';

type Props = {
  cells: Array<ClientPortalSortedCell>;
};

export const ViewClientPortalSortedCellsTable = ({ cells }: Props) => {
  const metadata = useClientPortalMetadata();
  return (
    <FullWidthTable>
      <THead>
        <Tr>
          <Th>Cell Reference</Th>
          <Th>Service Type</Th>
          <Th>Format</Th>
          <Th>Variant</Th>
          <Th>Quantity</Th>
          <Th>Weight (g)</Th>
          <Th>Presentation</Th>
          <Th>Consumable</Th>
          <Th>Max Fill</Th>
          <Th>Max Weight (kg)</Th>
          <Th>Bundle Label</Th>
          <Th>Mailmark Barcode</Th>
        </Tr>
      </THead>
      <TBody>
        {isEmpty(cells) ? (
          <Tr>
            <NoDataTableDataCell colSpan={12}>
              -- This worksheet does not have any cells --
            </NoDataTableDataCell>
          </Tr>
        ) : (
          orderBy(cells, cell => cell.displayOrder).map(cell => (
            <Tr key={cell.sortedCellId}>
              <Td>{cell.cellReference}</Td>
              <Td>{metadata.sortedCellServiceTypes[cell.serviceType].displayName}</Td>
              <Td>{metadata.sortedCellFormats[cell.format].displayName}</Td>
              <Td>{metadata.sortedCellVariants[cell.variant].displayName}</Td>
              <Td>{cell.quantity.toLocaleString()}</Td>
              <Td>{cell.weightInGrams.toLocaleString()}</Td>
              <Td>{metadata.sortedCellPresentations[cell.presentation].displayName}</Td>
              <Td>{metadata.sortedCellConsumables[cell.consumable].displayName}</Td>
              <Td>{cell.maxPresentationFill}</Td>
              <Td>{cell.maxPresentationWeightKg}</Td>
              <Td>
                {cell.bundleLabelFormat &&
                  metadata.sortedCellBundleLabelFormats[cell.bundleLabelFormat].displayName}
              </Td>
              <Td>
                {cell.mailmarkBarcode &&
                  metadata.sortedCellMailmarkBarcodes[cell.mailmarkBarcode].displayName}
              </Td>
            </Tr>
          ))
        )}
      </TBody>
    </FullWidthTable>
  );
};
