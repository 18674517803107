import { FormikProps } from 'formik';
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { Id } from '../../../models/id';
import { SelectField } from '../../../shared/form/inputs/SelectField';
import {
  CustomerForMailingBriefForm,
  SubCustomerForMailingBriefForm,
} from './dataForMailingBriefForm';
import { customerHasSubCustomers, MailingBriefFormModel } from './mailingBriefFormModel';

export const CustomerAndSubCustomer = (props: {
  customers: { [id: number]: CustomerForMailingBriefForm };
  formikProps: FormikProps<MailingBriefFormModel>;
  subCustomers: { [id: number]: Array<SubCustomerForMailingBriefForm> };
  hasExistingForecastJob: boolean;
}) => {
  const { customers, formikProps, subCustomers, hasExistingForecastJob } = props;
  const [isLoadingExistingForecastJob, setIsLoadingExistingForecastJob] = useState<boolean>(
    props.hasExistingForecastJob,
  );

  // If the customer changes, we need to clear the sub customer to avoid errors on submission
  useEffect(() => {
    if (isLoadingExistingForecastJob) {
      setIsLoadingExistingForecastJob(false);
    } else if (!!formikProps.values.subCustomerId) {
      formikProps.setFieldValue('subCustomerId', null);
    }
  }, [formikProps.values.customerId]);

  return (
    <>
      {Object.keys(customers).length > 1 && (
        <SelectField<Id>
          label="Customer"
          name="customerId"
          options={sortBy(
            Object.values(customers).map(customer => ({
              text: `${customer.name} - ${customer.code}`,
              value: customer.customerId,
            })),
            c => c.text,
          )}
          nullOptionText="None"
        />
      )}
      {!!formikProps.values.customerId &&
        customerHasSubCustomers(subCustomers, formikProps.values.customerId) && (
          <SelectField<Id>
            label="Sub Customer"
            name="subCustomerId"
            options={subCustomers[formikProps.values.customerId].map(subCustomer => ({
              text: subCustomer.name,
              value: subCustomer.subCustomerId,
            }))}
            nullOptionText="None"
          />
        )}
    </>
  );
};
