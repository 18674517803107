import * as React from 'react';
import styled from 'styled-components/macro';
import { medium, wide } from '../../styling/spacing';
import { PaginationControl } from '../buttons/PaginationControl';
import { Filter } from '../Filter';
import { formInputHeight } from '../form/formInputStyling';
import { LoadingIndicator } from '../LoadingIndicator';
import { LoadingOverlay } from '../LoadingOverlay';
import { SortDirection, Table } from './Table';

export const defaultTableItemsPerPage = 40;
export const paginatedTableTestId = 'paginated-table';

export type PaginatedTableQuery<TSortColumn> = {
  pageNumber: number;
  sortByColumn?: TSortColumn;
  sortDirection?: 'Asc' | 'Desc';
  filterQuery: string;
  itemsPerPage?: number;
};

export type PaginatedTableListResponse<TSortColumn> = {
  currentPage: number;
  sortDirection: SortDirection;
  sortByColumn: TSortColumn;
  totalPages: number;
};

export type ControlledPaginatedTableProps<TSortByColumn> = {
  totalPages: number;
  isLoading: boolean;
  children: (
    sortByColumn: TSortByColumn,
    sortDirection: SortDirection,
    onSortColumn: (sortByColumn: TSortByColumn, sortDirection: SortDirection) => void,
  ) => React.ReactNode;
  hasData: boolean;
  actions?: React.ReactNode;
  className?: string;
};

type PaginatedTableSortProps<TSortByColumn> = {
  initialSortByColumn: TSortByColumn;
  initialSortDirection: SortDirection;
};

export type PaginatedTableProps<TSortByColumn> = ControlledPaginatedTableProps<TSortByColumn> &
  PaginatedTableSortProps<TSortByColumn> & {
    onQueryChange: (query: PaginatedTableQuery<TSortByColumn>) => void;
  };

export type PaginationState<TSortByColumn> = {
  pageNumber: number;
  sortByColumn: TSortByColumn;
  sortDirection: SortDirection;
  filterQuery: string;
};

export const getInitialTableState = <TSortByColumn extends unknown>(
  props: PaginatedTableSortProps<TSortByColumn>,
): PaginationState<TSortByColumn> => ({
  pageNumber: 1,
  sortDirection: props.initialSortDirection,
  sortByColumn: props.initialSortByColumn,
  filterQuery: '',
});

export const getPaginationQuery = <TSortByColumn extends unknown>(
  state: PaginationState<TSortByColumn>,
): PaginatedTableQuery<TSortByColumn> => ({
  pageNumber: state.pageNumber,
  filterQuery: state.filterQuery,
  itemsPerPage: defaultTableItemsPerPage,
  ...(state.sortDirection !== 'None' && {
    sortByColumn: state.sortByColumn,
    sortDirection: state.sortDirection,
  }),
});

type TableStateProps<TSortByColumn> = {
  tableState: PaginationState<TSortByColumn>;
  setTableState: (state: PaginationState<TSortByColumn>) => void;
};

type Props<TSortByColumn> = ControlledPaginatedTableProps<TSortByColumn> &
  TableStateProps<TSortByColumn>;

export class ControlledPaginatedTable<TSortByColumn> extends React.Component<Props<TSortByColumn>> {
  onSetCurrentPage = (pageNumber: number) =>
    this.props.setTableState({
      ...this.props.tableState,
      pageNumber,
    });

  onSortColumn = (sortByColumn: TSortByColumn, sortDirection: SortDirection) =>
    this.props.setTableState({
      ...this.props.tableState,
      pageNumber: 1,
      sortByColumn,
      sortDirection,
    });

  onFilterTable = (filterQuery: string) =>
    this.props.setTableState({
      ...this.props.tableState,
      pageNumber: 1,
      filterQuery,
    });

  render() {
    const paginationControlProps = {
      totalPages: this.props.totalPages,
      currentPage: this.props.tableState.pageNumber,
      setCurrentPage: this.onSetCurrentPage,
    };

    if (!this.props.hasData) {
      return <LoadingIndicator />;
    }

    return (
      <PaginatedTableContainer className={this.props.className} data-testid={paginatedTableTestId}>
        <TopControlContainer>
          <FilterContainer>
            <StyledFilter
              value={this.props.tableState.filterQuery}
              onSubmitFilterQuery={this.onFilterTable}
            />
          </FilterContainer>
          <TopPaginationControl {...paginationControlProps} />
          <ActionsContainer>
            {this.props.actions && <Actions>{this.props.actions}</Actions>}
          </ActionsContainer>
        </TopControlContainer>
        <LoadingOverlay isLoading={this.props.isLoading || false}>
          {this.props.children(
            this.props.tableState.sortByColumn,
            this.props.tableState.sortDirection,
            this.onSortColumn,
          )}
        </LoadingOverlay>
        <BottomControlContainer>
          <BottomPaginationControl {...paginationControlProps} />
        </BottomControlContainer>
      </PaginatedTableContainer>
    );
  }
}

const PaginatedTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & ${Table} {
    table-layout: fixed;
    width: 100%;
  }
`;

const TopControlContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap-reverse;
  align-items: flex-end;
  justify-content: center;
  margin: -${medium} 0;
`;

const TopPaginationControl = styled(PaginationControl)`
  margin: ${medium} auto;
  height: ${formInputHeight};
`;

const BottomControlContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const BottomPaginationControl = styled(PaginationControl)`
  margin-top: ${wide};
`;

const filterWidth = '360px';

const StyledFilter = styled(Filter)`
  width: ${filterWidth};
`;

const FilterContainer = styled.div`
  flex: 1 0 ${filterWidth};
  margin: ${medium} 0;
`;

const ActionsContainer = styled.div`
  flex: 1 1 ${filterWidth};
  display: flex;
  justify-content: flex-end;
`;

const Actions = styled.div`
  margin: ${medium} 0;
`;
