import * as React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as ExclamationCircleSolid } from '../images/icons/exclamation-circle-solid.svg';
import { medium } from '../styling/spacing';
import { WarningCallout } from './Callout';

type Props = {
  message: string;
  icon?: React.ReactNode;
  className?: string;
};

export const warningBoxMessageTestId = 'warning-box-message';

export const WarningBox = (props: Props) => (
  <WarningCallout className={props.className}>
    <MessageContainer>
      <WarningIconContainer>{props.icon || <ExclamationCircleSolid />}</WarningIconContainer>
      <div data-testid={warningBoxMessageTestId}>{props.message}</div>
    </MessageContainer>
  </WarningCallout>
);

const MessageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const warningIconSize = '25px';

const WarningIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${warningIconSize};
  height: ${warningIconSize};
  margin-right: ${medium};

  > svg {
    height: ${warningIconSize};
  }
`;
