import { find, flatMap, map, orderBy, uniq } from 'lodash';
import { useContext, useMemo } from 'react';
import * as React from 'react';
import { Permission } from '../features/authentication/permissions';
import { User, userHasPermission } from '../features/authentication/user';
import { BuyingPriceSupplierRulesMetadata } from '../features/suppliers/supplier';
import {
  WorksheetConsumableType,
  WorksheetConsumableTypeMetadata,
} from '../features/worksheets/consumables/worksheetConsumable';
import { InternationalWorksheetCarrier } from '../features/worksheets/international/internationalWorksheet';
import {
  OtherPriceServiceType,
  OtherPriceServiceTypeMetadata,
} from '../features/worksheets/other/prices/otherPrices';
import {
  CarrierServiceFormatValidationConfiguration,
  PresentationConsumablesValidationConfiguration,
  SortedCellBundleLabelFormat,
  SortedCellBundleLabelFormatMetadata,
  SortedCellConsumable,
  SortedCellConsumableMetadata,
  SortedCellFormat,
  SortedCellFormatMetadata,
  SortedCellMailmarkBarcode,
  SortedCellMailmarkBarcodeMetadata,
  SortedCellPresentation,
  SortedCellPresentationMetadata,
  SortedCellServiceType,
  SortedCellServiceTypeMetadataAndIsArchived,
  SortedCellVariant,
  SortedCellVariantMetadata,
} from '../features/worksheets/sorted/cells/sortedCell';
import { UnsortedCellValidationMetadata } from '../features/worksheets/unsorted/cells/unsortedCell';
import { Worksheet, WorksheetType } from '../features/worksheets/worksheet';
import { SelectOptions } from '../shared/form/inputs/Select';
import { DateStamp } from './dateStamp';
import { Id } from './id';

export type WorksheetTypeMetadata = {
  code: WorksheetType;
  worksheetStatuses: Array<WorksheetStatusCode>;
};

export type WorksheetStatusCode =
  | 'AwaitingCollection'
  | 'AwaitingCreditCheck'
  | 'AwaitingDataProcessing'
  | 'AwaitingDataProcessingChecking'
  | 'AwaitingManagerApproval'
  | 'AwaitingPricing'
  | 'Cancelled'
  | 'CancelledAwaitingInvoicing'
  | 'Collected'
  | 'CollectionBooked'
  | 'DataProcessing'
  | 'DataProcessingChecking'
  | 'DataProcessingCheckingFailed'
  | 'DataProcessingCheckingPassed'
  | 'Delivered'
  | 'Draft'
  | 'Forecast'
  | 'InProgress'
  | 'Invoiced'
  | 'ManagerApprovalFailed'
  | 'PartiallyCollected'
  | 'PendingCollection'
  | 'ReadyForInvoicing'
  | 'SubmittedByClient';

export type WorksheetStatus = {
  code: WorksheetStatusCode;
  displayName: string;
  displayOrder: number;
  isCompleted: boolean;
  canEditWorksheet: boolean;
  editSortedJobDetails: SortedWorksheetEditRestriction;
  editSortedPoNumber: SortedWorksheetEditRestriction;
  editSortedWorksheetSpecification: SortedWorksheetEditRestriction;
  editSortedSlaOverride: SortedWorksheetEditRestriction;
  editSortedMailingHouseContacts: SortedWorksheetEditRestriction;
  editSortedCustomerContacts: SortedWorksheetEditRestriction;
  editSortedDataToOnepostDate: SortedWorksheetEditRestriction;
  editSortedDataToMailingHouseDate: SortedWorksheetEditRestriction;
  editSortedConsumablesDate: SortedWorksheetEditRestriction;
  editSortedCollectionDate: SortedWorksheetEditRestriction;
  editSortedRoyalMailHandoverDate: SortedWorksheetEditRestriction;
  editSortedCells: SortedWorksheetEditRestriction;
  deleteSortedCells: SortedWorksheetEditRestriction;
  editScids: SortedWorksheetEditRestriction;
  editUcids: SortedWorksheetEditRestriction;
  editSortedWorksheetAttachments: SortedWorksheetEditRestriction;
  editRawDataAttachments: SortedWorksheetEditRestriction;
  editInputDataAttachments: SortedWorksheetEditRestriction;
  editSeedAttachments: SortedWorksheetEditRestriction;
  editProofAttachments: SortedWorksheetEditRestriction;
  editSortedJobAttachments: SortedWorksheetEditRestriction;
  editPrices: WorksheetEditRestriction;
  editSortedOutputCells: WorksheetEditRestriction;
  canEditSortedOperationDetails: boolean;
  canBeSplitToNewJob: boolean;
  canBePutOnOrOffHold: boolean;
  canSendMailingHouseDataDownloadEmails: boolean;
  canEditMailingHouseDataProvisioningSettings: boolean;
  canDownloadUnsortedConsignmentNote: boolean;
};

export type WorksheetEditRestriction = {
  isEditable: boolean;
  requiresPermission: Permission | null;
};

export const canEdit = (restriction: WorksheetEditRestriction, user: User): boolean =>
  restriction.isEditable &&
  (restriction.requiresPermission == null ||
    userHasPermission(user, restriction.requiresPermission));

export type SortedWorksheetEditRestriction = {
  isEditable: boolean;
  requiresPermission: Permission | null;
  shouldPromptForRerun: boolean;
  isOnlyEditableByDataProcessingAssignee: boolean;
};

export type WorksheetTransitionName =
  | 'ApproveCreditCheck'
  | 'BookCollection'
  | 'Cancel'
  | 'CompleteCollection'
  | 'CompleteDataProcessing'
  | 'CompleteInternationalPricing'
  | 'ConvertToLive'
  | 'FailDataProcessingChecking'
  | 'FailManagerApproval'
  | 'Invoice'
  | 'MoveToInProgress'
  | 'PassDataProcessingChecking'
  | 'PassManagerApprovalAndSubmitToCreditCheck'
  | 'PassManagerApprovalAndBypassCreditCheck'
  | 'ReceiveCollection'
  | 'Rerun'
  | 'RevertToAwaitingCollection'
  | 'RevertToAwaitingPricing'
  | 'RevertToCollectionBooked'
  | 'RevertToInProgress'
  | 'RevertToPartiallyCollected'
  | 'RevertToPendingCollection'
  | 'SendForDataProcessingChecking'
  | 'StartDataProcessing'
  | 'StartDataProcessingChecking'
  | 'StartPartialCollection'
  | 'StopDataProcessing'
  | 'StopDataProcessingChecking'
  | 'SubmitAndBypassChecks'
  | 'SubmitClientSubmittedWorksheetAndBypassCreditCheck'
  | 'SubmitClientSubmittedWorksheetToCreditCheck'
  | 'SubmitToCreditCheckAndBypassManagerApproval'
  | 'SubmitToFinance'
  | 'SubmitToManagerApproval';

export type WorksheetTransition = {
  name: WorksheetTransitionName;
  statusesByWorksheetType: {
    [worksheetType in WorksheetType]?: {
      fromStatuses: Array<WorksheetStatusCode>;
      toStatus: WorksheetStatusCode;
    };
  };
};

export const getAvailableStatusesForWorksheetType = (
  metadata: Metadata,
  worksheetType: WorksheetType,
): Array<WorksheetStatusCode> => metadata.worksheetTypes[worksheetType].worksheetStatuses;

export const getAvailableStatusesForWorksheetTypes = (
  metadata: Metadata,
  worksheetTypes: Array<WorksheetType>,
): Array<WorksheetStatusCode> =>
  orderBy(
    uniq(
      flatMap(
        worksheetTypes,
        worksheetType => metadata.worksheetTypes[worksheetType].worksheetStatuses,
      ),
    ),
    (statusCode: WorksheetStatusCode) => metadata.worksheetStatuses[statusCode].displayOrder,
  );

export const canApplyTransition = (
  transitionName: WorksheetTransitionName,
  worksheet: Worksheet,
  metadata: Metadata,
) => {
  const transition =
    metadata.worksheetTransitions[transitionName].statusesByWorksheetType[worksheet.type];
  return transition && find(transition.fromStatuses, status => status === worksheet.statusCode);
};

export type MetadataWithDisplayOrder<TCode> = {
  code: TCode;
  displayName: string;
  displayOrder: number;
};

export type MetaDataWithDisplayOrderAndIsArchived<TCode> = {
  code: TCode;
  displayName: string;
  displayOrder: number;
  isArchived: boolean;
};

export type SortedCellValidationMetadata = {
  carrierServiceFormatValidationConfiguration: CarrierServiceFormatValidationConfiguration;
  presentationConsumablesValidationConfiguration: PresentationConsumablesValidationConfiguration;
};

export type InternationalWorksheetCarrierMetadata = {
  carrierCode: InternationalWorksheetCarrier;
  supplierId: Id | null;
  displayName: string;
};

export type SortedCellMetadata = {
  sortedCellConsumables: { [consumableCode in SortedCellConsumable]: SortedCellConsumableMetadata };
  sortedCellFormats: { [formatCode in SortedCellFormat]: SortedCellFormatMetadata };
  sortedCellPresentations: {
    [presentationCode in SortedCellPresentation]: SortedCellPresentationMetadata;
  };
  sortedCellServiceTypes: {
    [serviceTypeCode in SortedCellServiceType]: SortedCellServiceTypeMetadataAndIsArchived;
  };
  sortedCellVariants: { [variantCode in SortedCellVariant]: SortedCellVariantMetadata };
  sortedCellBundleLabelFormats: {
    [bundleLabelFormatCode in SortedCellBundleLabelFormat]: SortedCellBundleLabelFormatMetadata;
  };
  sortedCellMailmarkBarcodes: {
    [mailmarkBarcodeCode in SortedCellMailmarkBarcode]: SortedCellMailmarkBarcodeMetadata;
  };
  sortedCellValidation: SortedCellValidationMetadata;
};

export type Metadata = SortedCellMetadata & {
  matrixIsInPilotPeriod: boolean;
  nonSortedWorksheetsEnabled: boolean;
  worksheetTypes: { [type in WorksheetType]: WorksheetTypeMetadata };
  worksheetStatuses: { [statusCode in WorksheetStatusCode]: WorksheetStatus };
  worksheetTransitions: { [name in WorksheetTransitionName]: WorksheetTransition };
  worksheetConsumableTypes: {
    [consumableType in WorksheetConsumableType]: WorksheetConsumableTypeMetadata;
  };
  unsortedCellValidation: UnsortedCellValidationMetadata;
  bankHolidays: Array<DateStamp>;
  notificationsPublicKey: string;
  buyingPriceSupplierRules: BuyingPriceSupplierRulesMetadata;
  internationalWorksheetCarriers: {
    [supplierCode in InternationalWorksheetCarrier]: InternationalWorksheetCarrierMetadata;
  };
  otherPriceServiceTypes: {
    [serviceType in OtherPriceServiceType]: OtherPriceServiceTypeMetadata;
  };
};

export const MetadataContext = React.createContext<Metadata>((null as unknown) as Metadata);

export const useMetadata = (): Metadata => useContext(MetadataContext);

export const getSelectOptionsFromMetadata = <TCode extends string>(
  metadata: { [code in TCode]: { code: TCode; displayName: string } },
): SelectOptions<TCode> =>
  map(metadata, metadataValue => ({
    text: metadataValue.displayName,
    value: metadataValue.code,
  }));

export const useMetadataSelectOptions = <TCode extends string>(
  metadata: { [code in TCode]: { code: TCode; displayName: string } },
): SelectOptions<TCode> => useMemo(() => getSelectOptionsFromMetadata(metadata), [metadata]);
