import React from 'react';
import { formatDateStamp } from '../../../../models/dateStamp';
import { SectionHeader } from '../../../../shared/layout/Headers';
import { Table, TBody, Td, Tr } from '../../../../shared/table/Table';
import { WorksheetDetailsSection } from '../../../worksheets/WorksheetDetails';
import { ClientPortalWorksheetDetailsHeader } from '../ClientPortalWorksheetDetailsHeader';
import { ClientPortalWorksheetMailingHouseDetails } from '../ClientPortalWorksheetMailingHouseDetails';
import { ClientPortalDataCleansingWorksheet } from './ClientPortalDataCleansingWorksheet';

type Props = {
  dataCleansingWorksheet: ClientPortalDataCleansingWorksheet;
};

export const ClientPortalDataCleansingWorksheetDetails = ({ dataCleansingWorksheet }: Props) => (
  <>
    <ClientPortalWorksheetDetailsHeader worksheet={dataCleansingWorksheet} />
    <WorksheetDetailsSection>
      <Table>
        <TBody>
          <Tr>
            <Td>Collection Date</Td>
            <Td>{formatDateStamp(dataCleansingWorksheet.collectionDate)}</Td>
          </Tr>
        </TBody>
      </Table>
    </WorksheetDetailsSection>
    {dataCleansingWorksheet.mailingHouse && (
      <WorksheetDetailsSection>
        <SectionHeader>Mailing House</SectionHeader>
        <ClientPortalWorksheetMailingHouseDetails
          mailingHouse={dataCleansingWorksheet.mailingHouse}
        />
      </WorksheetDetailsSection>
    )}
  </>
);
