import { Field, FieldProps, getIn } from 'formik';
import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components/macro';
import { smallFont } from '../../styling/fonts';
import { medium } from '../../styling/spacing';
import { FieldError } from './FieldError';
import { FieldLabel, FieldLabelProps } from './FieldLabel';
import { formInputContentHeight, formInputPadding } from './formInputStyling';
import { FormOptionsContext } from './FormOptionsContext';
import { Input } from './inputs/InputField';

// Should match the total vertical distance between form fields.
export const standardVerticalFormFieldSpacing = '33px';

type FormFieldProps = {
  name: string;
  label: string;
  children: (
    props: FieldProps & { valid: boolean; invalid: boolean; showWarning: boolean },
  ) => React.ReactNode;
  renderLabel?: (props: FieldLabelProps) => React.ReactNode;
  optional: boolean | undefined;
  disabled: boolean | undefined;
  type?: string;
  className?: string;
};

export const FormField = (props: FormFieldProps) => {
  const formOptions = useContext(FormOptionsContext);
  return (
    <FieldContainer className={props.className}>
      <Field name={props.name} type={props.type}>
        {({ form, field }: FieldProps) => {
          const touched = getIn(form.touched, props.name);
          const error = getIn(form.errors, props.name);
          const invalid = touched && error;
          const valid = touched && !error;
          const showWarning = formOptions.showWarningsInitially && error;

          return (
            <>
              <FieldLabelContainer>
                {props.renderLabel ? (
                  props.renderLabel({ ...props, valid, invalid, showWarning })
                ) : (
                  <InputAlignedFieldLabel
                    label={props.label}
                    optional={props.optional}
                    name={props.name}
                    disabled={props.disabled}
                    valid={valid}
                    invalid={invalid}
                    showWarning={showWarning}
                  />
                )}
              </FieldLabelContainer>
              <InputContainer>
                {props.children({ form, field, valid, invalid, showWarning })}
                <FormFieldError
                  showError={touched && error}
                  showWarning={showWarning}
                  for={props.name}
                >
                  {error || 'error-placeholder'}
                </FormFieldError>
              </InputContainer>
            </>
          );
        }}
      </Field>
    </FieldContainer>
  );
};

export const InlineFormField = (props: FormFieldProps) => {
  const formOptions = useContext(FormOptionsContext);
  return (
    <FieldContainer inline={true} className={props.className}>
      <Field name={props.name} type={props.type}>
        {({ form, field }: FieldProps) => {
          const touched = getIn(form.touched, props.name);
          const error = getIn(form.errors, props.name);
          const invalid = touched && error;
          const valid = touched && !error;
          const showWarning = formOptions.showWarningsInitially && error;
          return (
            <>
              <InputContainer inline={true}>
                {props.children({ form, field, valid, invalid, showWarning })}
                {(invalid || showWarning) && (
                  <FormFieldError
                    showError={invalid}
                    showWarning={showWarning}
                    for={props.name}
                    inline={true}
                  >
                    {error}
                  </FormFieldError>
                )}
              </InputContainer>
            </>
          );
        }}
      </Field>
    </FieldContainer>
  );
};

type DisabledFormFieldWithValueProps = {
  name: string;
  label: string;
  value: string;
};

export const DisabledFormFieldWithValue = ({
  name,
  label,
  value,
}: DisabledFormFieldWithValueProps) => (
  <FieldContainer>
    <FieldLabelContainer>
      <InputAlignedFieldLabel
        label={label}
        name={name}
        disabled={true}
        valid={false}
        invalid={false}
        showWarning={false}
      />
    </FieldLabelContainer>
    <InputContainer>
      <Input value={value} disabled={true} name={name} id={name} />
      <FormFieldError showError={false} showWarning={false} for={name}>
        error-placeholder
      </FormFieldError>
    </InputContainer>
  </FieldContainer>
);

export const maxInputWidthPixels = 910;
export const maxInputWidth = `${maxInputWidthPixels}px`;
export const minInputWidth = '190px';

export const FieldContainer = styled.div<{ inline?: boolean }>`
  margin-bottom: ${props => (props.inline ? 0 : medium)};
  display: flex;
`;

export const fieldLabelMarginRightPixels = 30;
export const fieldLabelMarginRight = `${fieldLabelMarginRightPixels}px`;

export const fieldLabelContainerWidthPixels = 260;
export const fieldLabelContainerWidth = `${fieldLabelContainerWidthPixels}px`;

export const formFieldMaxWidthPixels =
  maxInputWidthPixels + fieldLabelMarginRightPixels + fieldLabelContainerWidthPixels;
export const formFieldMaxWidth = `${formFieldMaxWidthPixels}px`;

export const FieldLabelContainer = styled.div`
  flex: 0 1 ${fieldLabelContainerWidth};
  margin-right: ${fieldLabelMarginRight};
`;

const InputAlignedFieldLabel = styled(FieldLabel)`
  margin-top: ${formInputPadding};
  height: ${formInputContentHeight};
`;

const InputContainer = styled.div<{ inline?: boolean }>`
  flex: 1 0 ${props => !props.inline && minInputWidth};
  max-width: ${maxInputWidth};
`;

export const FormFieldError = styled(FieldError)<{ inline?: boolean }>`
  margin-top: 3px;
  font-size: ${props => (props.inline ? smallFont : null)};
  font-weight: ${props => (props.inline ? 'normal' : null)};
`;
