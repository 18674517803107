export type Permission =
  // Customers
  | 'AccessCustomersUserIsNotBusinessManagerFor'
  | 'ViewCustomer'
  | 'ViewCustomerSeedAttachments'
  | 'ViewCustomerSortedRatecardAttachments'
  | 'ViewCustomerUnsortedRatecardAttachments'
  | 'ViewCustomerOtherAttachments'
  | 'EditCustomerNotes'
  | 'EditCustomerFinancialFields'
  | 'EditCustomerAgencyAgreements'
  | 'EditCustomerAgencyAgreementNumber'
  | 'EditCustomerManagers'
  | 'EditCustomerCategory'
  | 'EditCustomerSla'
  | 'EditCustomerContacts'
  | 'EditCustomerRoyalMailFields'
  | 'EditCustomerWorksheetApprovalThreshold'
  | 'EditSubCustomers'
  | 'EditCustomerSeedAttachments'
  | 'EditCustomerSortedRatecardAttachments'
  | 'EditCustomerUnsortedRatecardAttachments'
  | 'EditCustomerOtherAttachments'
  | 'EditCustomerBillingOptions'
  | 'PutCustomerOnOrOffCreditHold'
  | 'PutCustomerOnOrOffTlc'
  | 'CreateCustomerComment'
  | 'InviteCustomerContactToClientPortal'

  // Jobs
  | 'ViewJob'
  | 'CreateJob'
  | 'EditJob'
  | 'EditJobOperationsDetails'
  | 'SplitJob'
  | 'CreateJobComment'
  | 'SubmitJob'
  | 'ReviewManagerApproval'
  | 'PutWorksheetOnOrOffCreditHold'
  | 'ApproveCreditCheck'
  | 'SendInvoicesToDimensions'
  | 'CreateForecastJob'
  | 'EditForecastJob'
  | 'ConvertForecastJobToLive'
  | 'ViewJobAttachment'

  // Worksheets
  | 'ViewWorksheetsOnCreditHoldScreen'
  | 'ViewClientServicesManageWorksheetsScreen'
  | 'ViewInternationalManageWorksheetsScreen'
  | 'ViewOperationsManageWorksheetsScreen'
  | 'ViewFinanceManageWorksheetsScreen'
  | 'ViewConnectManageWorksheetsScreen'
  | 'PrioritiseDataProcessingWorksheets'
  | 'ViewDataProcessingTaskList'
  | 'PerformDataProcessing'
  | 'ForceUnassignDataProcessingWorksheet'
  | 'CreateAndEditSortedWorksheets'
  | 'CreateAndEditUnsortedWorksheets'
  | 'CreateAndEditInternationalWorksheets'
  | 'CreateAndEditFulfilmentWorksheets'
  | 'CreateAndEditDataCleansingWorksheets'
  | 'CreateAndEditProductionWorksheets'
  | 'CreateAndEditBagAndTagWorksheets'
  | 'CreateAndEditReturnsWorksheets'
  | 'EditSortedWorksheetContactsDuringCollections'
  | 'EditWorksheetDatesDuringCollections'
  | 'RerunSortedWorksheets'
  | 'OrderConsumables'
  | 'EditSortedWorksheetConsumables'
  | 'EditWorksheetCarrierResponses'
  | 'ViewBuyingPrices'
  | 'EditSortedOutputCells'
  | 'EditSortedBuyingPrices'
  | 'EditInternationalBuyingPrices'
  | 'EditUnsortedBuyingPrices'
  | 'EditDataCleansingBuyingPrices'
  | 'EditProductionBuyingPrices'
  | 'EditBagAndTagBuyingPrices'
  | 'EditReturnsBuyingPrices'
  | 'ViewSortedSellingPrices'
  | 'ViewInternationalSellingPrices'
  | 'ViewUnsortedSellingPrices'
  | 'ViewDataCleansingSellingPrices'
  | 'ViewProductionSellingPrices'
  | 'ViewBagAndTagSellingPrices'
  | 'ViewReturnsSellingPrices'
  | 'EditSortedSellingPrices'
  | 'EditInternationalSellingPrices'
  | 'EditUnsortedSellingPrices'
  | 'EditDataCleansingSellingPrices'
  | 'EditProductionSellingPrices'
  | 'EditBagAndTagSellingPrices'
  | 'EditReturnsSellingPrices'
  | 'EditFulfilmentPrices'
  | 'EditSellingPricesForCustomersWhoDoNotAllowBusinessManagersToEditSellingPrices'
  | 'EditBuyingAndSellingPricesAfterInvoicing'
  | 'EditSortedOutputCellsAfterCollection'
  | 'SendSortedWorksheetCollectionEmails'
  | 'SkipSortedWorksheetCollectionEmail'
  | 'SendUnsortedWorksheetCollectionEmails'
  | 'SkipUnsortedWorksheetCollectionEmail'
  | 'SendInternationalWorksheetCollectionEmails'
  | 'SkipInternationalWorksheetCollectionEmail'
  | 'ConfirmCollections'
  | 'DownloadCmsCollectionsForecast'
  | 'DownloadSecuredMailCollectionsForecast'
  | 'SubmitSortedWorksheetsToFinance'
  | 'SendAndInvalidateMailingHouseDataDownloadEmails'
  | 'EditSortedWorksheetMailingHouseDataDownloadSettings'
  | 'CancelSortedWorksheets'
  | 'CancelUnsortedWorksheets'
  | 'CancelInternationalWorksheets'
  | 'CancelFulfilmentWorksheets'
  | 'CancelDataCleansingWorksheets'
  | 'CancelProductionWorksheets'
  | 'CancelBagAndTagWorksheets'
  | 'CancelReturnsWorksheets'
  | 'ApplyInternationalWorksheetSimpleTransitions'
  | 'ApplyUnsortedWorksheetSimpleTransitions'
  | 'ApplyFulfilmentWorksheetSimpleTransitions'
  | 'ApplyDataCleansingWorksheetSimpleTransitions'
  | 'ApplyProductionWorksheetSimpleTransitions'
  | 'ApplyBagAndTagWorksheetSimpleTransitions'
  | 'ApplyReturnsWorksheetSimpleTransitions'
  | 'ViewRawDataWorksheetAttachment'
  | 'ViewSortedDataWorksheetAttachment'
  | 'ViewMultisortOutputWorksheetAttachment'
  | 'ViewOtherWorksheetAttachment'
  | 'EditRawDataWorksheetAttachment'
  | 'EditSortedDataWorksheetAttachment'
  | 'EditMultisortOutputWorksheetAttachment'
  | 'EditOtherWorksheetAttachment'

  // MailingHouses
  | 'ViewMailingHouse'
  | 'CreateMailingHouse'
  | 'EditMailingHouse'
  | 'CreateMailingHouseComment'
  | 'InviteMailingHouseContactToClientPortal'

  // Files
  | 'UploadFiles'

  // Reports
  | 'DownloadFourAndSevenDayForecastReport'
  | 'DownloadSecuredMailRollingForecastReport'
  | 'DownloadCarrierActivityReport'
  | 'DownloadOperationsActivityReportForAllCustomers'
  | 'DownloadOperationsActivityReportAsKeyAccountManager'
  | 'DownloadFinanceActivityReport'
  | 'DownloadBilledViaDeliverReport'
  | 'DownloadMailingHouseListReport'
  | 'DownloadJicLevyReport'
  | 'DownloadSeedReport'
  | 'DownloadBusinessManagerMarginReport'
  | 'DownloadFinanceCostReport'
  | 'DownloadCustomerReport'
  | 'DownloadUnsortedCollectionsReport'
  | 'DownloadUnsortedPreAlert'
  | 'DownloadWorksheetSubmissionReport'
  | 'DownloadCancelledReport'

  // Other
  | 'ViewManageScidsScreen'
  | 'CreateScid'
  | 'ViewManageUcidsScreen'
  | 'CreateAndEditUcids'
  | 'ViewManageUcidsAndScidsForCustomersUserIsNotBusinessManagerFor'
  | 'ViewAndResolveClientPortalDataUploads';

export const permissions: { [permission in Permission]: Permission } = {
  // Customers
  AccessCustomersUserIsNotBusinessManagerFor: 'AccessCustomersUserIsNotBusinessManagerFor',
  ViewCustomer: 'ViewCustomer',
  ViewCustomerSeedAttachments: 'ViewCustomerSeedAttachments',
  ViewCustomerSortedRatecardAttachments: 'ViewCustomerSortedRatecardAttachments',
  ViewCustomerUnsortedRatecardAttachments: 'ViewCustomerUnsortedRatecardAttachments',
  ViewCustomerOtherAttachments: 'ViewCustomerOtherAttachments',

  EditCustomerNotes: 'EditCustomerNotes',
  EditCustomerFinancialFields: 'EditCustomerFinancialFields',
  EditCustomerAgencyAgreements: 'EditCustomerAgencyAgreements',
  EditCustomerAgencyAgreementNumber: 'EditCustomerAgencyAgreementNumber',
  EditCustomerManagers: 'EditCustomerManagers',
  EditCustomerCategory: 'EditCustomerCategory',
  EditCustomerSla: 'EditCustomerSla',
  EditCustomerContacts: 'EditCustomerContacts',
  EditCustomerRoyalMailFields: 'EditCustomerRoyalMailFields',
  EditCustomerWorksheetApprovalThreshold: 'EditCustomerWorksheetApprovalThreshold',
  EditSubCustomers: 'EditSubCustomers',
  EditCustomerSeedAttachments: 'EditCustomerSeedAttachments',
  EditCustomerSortedRatecardAttachments: 'EditCustomerSortedRatecardAttachments',
  EditCustomerUnsortedRatecardAttachments: 'EditCustomerUnsortedRatecardAttachments',
  EditCustomerOtherAttachments: 'EditCustomerOtherAttachments',
  EditCustomerBillingOptions: 'EditCustomerBillingOptions',

  PutCustomerOnOrOffCreditHold: 'PutCustomerOnOrOffCreditHold',
  PutCustomerOnOrOffTlc: 'PutCustomerOnOrOffTlc',

  CreateCustomerComment: 'CreateCustomerComment',

  InviteCustomerContactToClientPortal: 'InviteCustomerContactToClientPortal',

  // Jobs
  ViewJob: 'ViewJob',
  CreateJob: 'CreateJob',
  EditJob: 'EditJob',
  EditJobOperationsDetails: 'EditJobOperationsDetails',
  SplitJob: 'SplitJob',
  CreateJobComment: 'CreateJobComment',

  SubmitJob: 'SubmitJob',
  ReviewManagerApproval: 'ReviewManagerApproval',
  PutWorksheetOnOrOffCreditHold: 'PutWorksheetOnOrOffCreditHold',
  ApproveCreditCheck: 'ApproveCreditCheck',
  SendInvoicesToDimensions: 'SendInvoicesToDimensions',

  CreateForecastJob: 'CreateForecastJob',
  EditForecastJob: 'EditForecastJob',
  ConvertForecastJobToLive: 'ConvertForecastJobToLive',

  ViewJobAttachment: 'ViewJobAttachment',

  // Worksheets
  ViewClientServicesManageWorksheetsScreen: 'ViewClientServicesManageWorksheetsScreen',
  ViewInternationalManageWorksheetsScreen: 'ViewInternationalManageWorksheetsScreen',
  ViewOperationsManageWorksheetsScreen: 'ViewOperationsManageWorksheetsScreen',
  ViewFinanceManageWorksheetsScreen: 'ViewFinanceManageWorksheetsScreen',
  ViewConnectManageWorksheetsScreen: 'ViewConnectManageWorksheetsScreen',
  PrioritiseDataProcessingWorksheets: 'PrioritiseDataProcessingWorksheets',
  ViewDataProcessingTaskList: 'ViewDataProcessingTaskList',
  ViewWorksheetsOnCreditHoldScreen: 'ViewWorksheetsOnCreditHoldScreen',
  PerformDataProcessing: 'PerformDataProcessing',
  ForceUnassignDataProcessingWorksheet: 'ForceUnassignDataProcessingWorksheet',

  EditSortedWorksheetContactsDuringCollections: 'EditSortedWorksheetContactsDuringCollections',
  EditWorksheetDatesDuringCollections: 'EditWorksheetDatesDuringCollections',

  OrderConsumables: 'OrderConsumables',

  CreateAndEditSortedWorksheets: 'CreateAndEditSortedWorksheets',
  CreateAndEditUnsortedWorksheets: 'CreateAndEditUnsortedWorksheets',
  CreateAndEditInternationalWorksheets: 'CreateAndEditInternationalWorksheets',
  CreateAndEditFulfilmentWorksheets: 'CreateAndEditFulfilmentWorksheets',
  CreateAndEditDataCleansingWorksheets: 'CreateAndEditDataCleansingWorksheets',
  CreateAndEditProductionWorksheets: 'CreateAndEditProductionWorksheets',
  CreateAndEditBagAndTagWorksheets: 'CreateAndEditBagAndTagWorksheets',
  CreateAndEditReturnsWorksheets: 'CreateAndEditReturnsWorksheets',

  RerunSortedWorksheets: 'RerunSortedWorksheets',
  EditSortedWorksheetConsumables: 'EditSortedWorksheetConsumables',
  EditWorksheetCarrierResponses: 'EditWorksheetCarrierResponses',

  ViewBuyingPrices: 'ViewBuyingPrices',
  EditSortedOutputCells: 'EditSortedOutputCells',
  EditSortedBuyingPrices: 'EditSortedBuyingPrices',
  EditInternationalBuyingPrices: 'EditInternationalBuyingPrices',
  EditUnsortedBuyingPrices: 'EditUnsortedBuyingPrices',
  EditDataCleansingBuyingPrices: 'EditDataCleansingBuyingPrices',
  EditProductionBuyingPrices: 'EditProductionBuyingPrices',
  EditBagAndTagBuyingPrices: 'EditBagAndTagBuyingPrices',
  EditReturnsBuyingPrices: 'EditReturnsBuyingPrices',

  ViewSortedSellingPrices: 'ViewSortedSellingPrices',
  ViewInternationalSellingPrices: 'ViewInternationalSellingPrices',
  ViewUnsortedSellingPrices: 'ViewUnsortedSellingPrices',
  ViewDataCleansingSellingPrices: 'ViewDataCleansingSellingPrices',
  ViewProductionSellingPrices: 'ViewProductionSellingPrices',
  ViewBagAndTagSellingPrices: 'ViewBagAndTagSellingPrices',
  ViewReturnsSellingPrices: 'ViewReturnsSellingPrices',

  EditSortedSellingPrices: 'EditSortedSellingPrices',
  EditInternationalSellingPrices: 'EditInternationalSellingPrices',
  EditUnsortedSellingPrices: 'EditUnsortedSellingPrices',
  EditDataCleansingSellingPrices: 'EditDataCleansingSellingPrices',
  EditProductionSellingPrices: 'EditProductionSellingPrices',
  EditBagAndTagSellingPrices: 'EditBagAndTagSellingPrices',
  EditReturnsSellingPrices: 'EditReturnsSellingPrices',

  EditFulfilmentPrices: 'EditFulfilmentPrices',

  EditSellingPricesForCustomersWhoDoNotAllowBusinessManagersToEditSellingPrices:
    'EditSellingPricesForCustomersWhoDoNotAllowBusinessManagersToEditSellingPrices',
  EditBuyingAndSellingPricesAfterInvoicing: 'EditBuyingAndSellingPricesAfterInvoicing',
  EditSortedOutputCellsAfterCollection: 'EditSortedOutputCellsAfterCollection',

  SendSortedWorksheetCollectionEmails: 'SendSortedWorksheetCollectionEmails',
  SkipSortedWorksheetCollectionEmail: 'SkipSortedWorksheetCollectionEmail',
  SendUnsortedWorksheetCollectionEmails: 'SendUnsortedWorksheetCollectionEmails',
  SkipUnsortedWorksheetCollectionEmail: 'SkipUnsortedWorksheetCollectionEmail',
  SendInternationalWorksheetCollectionEmails: 'SendInternationalWorksheetCollectionEmails',
  SkipInternationalWorksheetCollectionEmail: 'SkipInternationalWorksheetCollectionEmail',
  ConfirmCollections: 'ConfirmCollections',
  SubmitSortedWorksheetsToFinance: 'SubmitSortedWorksheetsToFinance',

  DownloadCmsCollectionsForecast: 'DownloadCmsCollectionsForecast',
  DownloadSecuredMailCollectionsForecast: 'DownloadSecuredMailCollectionsForecast',

  SendAndInvalidateMailingHouseDataDownloadEmails:
    'SendAndInvalidateMailingHouseDataDownloadEmails',
  EditSortedWorksheetMailingHouseDataDownloadSettings:
    'EditSortedWorksheetMailingHouseDataDownloadSettings',

  CancelSortedWorksheets: 'CancelSortedWorksheets',
  CancelUnsortedWorksheets: 'CancelUnsortedWorksheets',
  CancelInternationalWorksheets: 'CancelInternationalWorksheets',
  CancelFulfilmentWorksheets: 'CancelFulfilmentWorksheets',
  CancelDataCleansingWorksheets: 'CancelDataCleansingWorksheets',
  CancelProductionWorksheets: 'CancelProductionWorksheets',
  CancelBagAndTagWorksheets: 'CancelBagAndTagWorksheets',
  CancelReturnsWorksheets: 'CancelReturnsWorksheets',

  ApplyInternationalWorksheetSimpleTransitions: 'ApplyInternationalWorksheetSimpleTransitions',
  ApplyUnsortedWorksheetSimpleTransitions: 'ApplyUnsortedWorksheetSimpleTransitions',
  ApplyFulfilmentWorksheetSimpleTransitions: 'ApplyFulfilmentWorksheetSimpleTransitions',
  ApplyDataCleansingWorksheetSimpleTransitions: 'ApplyDataCleansingWorksheetSimpleTransitions',
  ApplyProductionWorksheetSimpleTransitions: 'ApplyProductionWorksheetSimpleTransitions',
  ApplyBagAndTagWorksheetSimpleTransitions: 'ApplyBagAndTagWorksheetSimpleTransitions',
  ApplyReturnsWorksheetSimpleTransitions: 'ApplyReturnsWorksheetSimpleTransitions',

  ViewRawDataWorksheetAttachment: 'ViewRawDataWorksheetAttachment',
  ViewSortedDataWorksheetAttachment: 'ViewSortedDataWorksheetAttachment',
  ViewMultisortOutputWorksheetAttachment: 'ViewMultisortOutputWorksheetAttachment',
  ViewOtherWorksheetAttachment: 'ViewOtherWorksheetAttachment',

  EditRawDataWorksheetAttachment: 'EditRawDataWorksheetAttachment',
  EditSortedDataWorksheetAttachment: 'EditSortedDataWorksheetAttachment',
  EditMultisortOutputWorksheetAttachment: 'EditMultisortOutputWorksheetAttachment',
  EditOtherWorksheetAttachment: 'EditOtherWorksheetAttachment',

  // MailingHouses
  ViewMailingHouse: 'ViewMailingHouse',
  CreateMailingHouse: 'CreateMailingHouse',
  EditMailingHouse: 'EditMailingHouse',
  CreateMailingHouseComment: 'CreateMailingHouseComment',
  InviteMailingHouseContactToClientPortal: 'InviteMailingHouseContactToClientPortal',

  // Files
  UploadFiles: 'UploadFiles',

  // Reports
  DownloadFourAndSevenDayForecastReport: 'DownloadFourAndSevenDayForecastReport',
  DownloadSecuredMailRollingForecastReport: 'DownloadSecuredMailRollingForecastReport',
  DownloadCarrierActivityReport: 'DownloadCarrierActivityReport',
  DownloadOperationsActivityReportForAllCustomers:
    'DownloadOperationsActivityReportForAllCustomers',
  DownloadOperationsActivityReportAsKeyAccountManager:
    'DownloadOperationsActivityReportAsKeyAccountManager',
  DownloadFinanceActivityReport: 'DownloadFinanceActivityReport',
  DownloadBilledViaDeliverReport: 'DownloadBilledViaDeliverReport',
  DownloadMailingHouseListReport: 'DownloadMailingHouseListReport',
  DownloadJicLevyReport: 'DownloadJicLevyReport',
  DownloadSeedReport: 'DownloadSeedReport',
  DownloadBusinessManagerMarginReport: 'DownloadBusinessManagerMarginReport',
  DownloadFinanceCostReport: 'DownloadFinanceCostReport',
  DownloadCustomerReport: 'DownloadCustomerReport',
  DownloadUnsortedCollectionsReport: 'DownloadUnsortedCollectionsReport',
  DownloadUnsortedPreAlert: 'DownloadUnsortedPreAlert',
  DownloadWorksheetSubmissionReport: 'DownloadWorksheetSubmissionReport',
  DownloadCancelledReport: 'DownloadCancelledReport',

  // Other
  ViewManageScidsScreen: 'ViewManageScidsScreen',
  CreateScid: 'CreateScid',

  ViewManageUcidsScreen: 'ViewManageUcidsScreen',
  CreateAndEditUcids: 'CreateAndEditUcids',

  ViewManageUcidsAndScidsForCustomersUserIsNotBusinessManagerFor:
    'ViewManageUcidsAndScidsForCustomersUserIsNotBusinessManagerFor',

  ViewAndResolveClientPortalDataUploads: 'ViewAndResolveClientPortalDataUploads',
};

export const editCustomerPermissions: Array<Permission> = [
  permissions.EditCustomerNotes,
  permissions.EditCustomerFinancialFields,
  permissions.EditCustomerAgencyAgreements,
  permissions.EditCustomerAgencyAgreementNumber,
  permissions.EditCustomerManagers,
  permissions.EditCustomerSla,
  permissions.EditCustomerContacts,
  permissions.EditCustomerRoyalMailFields,
  permissions.EditCustomerWorksheetApprovalThreshold,
  permissions.EditSubCustomers,
  permissions.EditCustomerSeedAttachments,
  permissions.EditCustomerSortedRatecardAttachments,
  permissions.EditCustomerUnsortedRatecardAttachments,
  permissions.EditCustomerOtherAttachments,
  permissions.EditCustomerBillingOptions,
];

export const viewWorksheetSellingPricePermissions: Array<Permission> = [
  permissions.ViewSortedSellingPrices,
  permissions.ViewInternationalSellingPrices,
  permissions.ViewUnsortedSellingPrices,
  permissions.ViewDataCleansingSellingPrices,
  permissions.ViewProductionSellingPrices,
  permissions.ViewBagAndTagSellingPrices,
  permissions.ViewReturnsSellingPrices,
];

export const editWorksheetBuyingPricePermissions: Array<Permission> = [
  permissions.EditSortedBuyingPrices,
  permissions.EditInternationalBuyingPrices,
  permissions.EditUnsortedBuyingPrices,
  permissions.EditDataCleansingBuyingPrices,
  permissions.EditProductionBuyingPrices,
  permissions.EditBagAndTagBuyingPrices,
  permissions.EditReturnsBuyingPrices,
];

export const editWorksheetSellingPricePermissions: Array<Permission> = [
  permissions.EditSortedSellingPrices,
  permissions.EditInternationalSellingPrices,
  permissions.EditUnsortedSellingPrices,
  permissions.EditDataCleansingSellingPrices,
  permissions.EditProductionSellingPrices,
  permissions.EditBagAndTagSellingPrices,
  permissions.EditReturnsSellingPrices,
];

export const reportPermissions: Array<Permission> = [
  permissions.DownloadFourAndSevenDayForecastReport,
  permissions.DownloadSecuredMailRollingForecastReport,
  permissions.DownloadCarrierActivityReport,
  permissions.DownloadMailingHouseListReport,
  permissions.DownloadSeedReport,
  permissions.DownloadBusinessManagerMarginReport,
  permissions.DownloadFinanceCostReport,
  permissions.DownloadWorksheetSubmissionReport,
  permissions.DownloadBilledViaDeliverReport,
  permissions.DownloadCancelledReport,
];
