import { FormikProps } from 'formik';
import { useEffect } from 'react';
import * as React from 'react';
import { CustomerForMailingBriefForm } from './dataForMailingBriefForm';
import { fromCustomerContact } from './mailingBriefCustomerContactFormModel';
import { MailingBriefCustomerContactsFieldArray } from './MailingBriefCustomerContactsFieldArray';
import { MailingBriefFormModel } from './mailingBriefFormModel';

export const CustomerContacts = (props: {
  formikProps: FormikProps<MailingBriefFormModel>;
  customerResponses: { [customerId: number]: CustomerForMailingBriefForm };
  isConvertingForecastJobToLive: boolean;
}) => {
  useEffect(() => {
    if (props.formikProps.values.customerId === null) {
      props.formikProps.setFieldValue('customerContacts', []);
    } else {
      const customer = props.customerResponses[props.formikProps.values.customerId];
      props.formikProps.setFieldValue(
        'customerContacts',
        customer.customerContacts.map(contact => fromCustomerContact(contact)),
      );
    }
  }, [props.formikProps.values.customerId]);

  if (
    props.formikProps.values.forecastJobOption === 'Forecast' &&
    !props.isConvertingForecastJobToLive
  ) {
    return null;
  }

  return (
    <>
      {!!props.formikProps.values.customerId && (
        <MailingBriefCustomerContactsFieldArray
          customer={props.customerResponses[props.formikProps.values.customerId]}
          mailingBriefFormModel={props.formikProps.values}
          formProps={props.formikProps}
        />
      )}
    </>
  );
};
