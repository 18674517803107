import styled from 'styled-components/macro';
import { medium, narrow } from '../styling/spacing';

type Props = {
  foregroundColour: string;
  backgroundColour: string;
};

export const Badge = styled.div<Props>`
  padding: ${narrow} ${medium};
  border: solid 1px transparent;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: ${props => props.foregroundColour};
  background-color: ${props => props.backgroundColour};
`;

export const OutlinedBadge = styled.div<Props>`
  padding: ${narrow} ${medium};
  border: solid 1px ${props => props.foregroundColour};
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  color: ${props => props.foregroundColour};
  background-color: ${props => props.backgroundColour};
`;

export const SmallBadge = styled.div<Props>`
  padding: 3px 7px;
  border-radius: 5px;
  color: ${props => props.foregroundColour};
  background-color: ${props => props.backgroundColour};
`;
